import AppConfig from '@/App.config';
import LinearLoading from '@/components/linear-loading';
import ApiService from '@/services/Api.service';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { Alert, Box, Button, Snackbar, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  backgroundWrapper: {
    backgroundColor: theme.palette.secondary.light,
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '13px'
  },
  emailInput: {
    marginTop: '60px'
  },
  errorColor: {
    color: theme.palette.error.main
  },
  iconPosition: {
    fontSize: '1rem',
    marginRight: '.5rem'
  },
  inputFields: {
    alightItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  loginButton: {
    display: 'flex',
    marginTop: '22px',
    padding: '8px 22px'
  },
  loginWrapper: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${grey[300]}`,
    borderRadius: '4px',
    height: '325px',
    margin: '0 auto',
    marginTop: '25vh',
    padding: '4em 2em 2em 2em',
    textAlign: 'center',
    width: '396px'
  },
  logo: {
    height: '34px',
    width: '100%'
  },
  successColor: {
    color: theme.palette.success.main
  },
  vestwellLogo: {
    height: '40px',
    marginTop: '.5rem',
    width: 'auto'
  }
}));

interface Login {
  email: string;
}

const LoginRoute: React.FunctionComponent<Record<string, unknown>> = () => {
  const classes = useStyles();
  useTheme();
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  const findEmailAndRouteToLogin = useCallback(
    async (formData: { email: string }) => {
      const { email } = formData;
      const trimmedEmail = email.trim().toLowerCase();
      const userLoginRoute: string | undefined =
        await ApiService.getJsonWithoutHeaders('authz-api/organization/user', {
          email: trimmedEmail
        });
      await loginWithRedirect({
        login_hint: trimmedEmail,
        organization: userLoginRoute
      });
    },
    [loginWithRedirect]
  );

  const loginCallback = useCallback(async () => {
    const query = new URLSearchParams(location.search);

    if (query.get('state')?.includes('RPID=')) {
      const relayState = new URL(
        decodeURIComponent(query.get('state')).split('RPID=')[1]
      );

      loginWithRedirect({
        fragment: `/fragment${relayState.pathname}${relayState.hash}`,
        redirectUri: relayState.origin
      });
    }

    if (location.hash?.includes('#/fragment') && query.get('code')) {
      const fragment = location.hash.split('#/fragment')[1];
      sessionStorage.setItem('lastPage', fragment);
      loginWithRedirect();
    }

    if (query.get('authorized') === 'true') {
      await loginWithRedirect();
    }
  }, [loginWithRedirect, location]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(AppConfig.landingPage, { replace: true });
    } else {
      loginCallback();
    }
  }, [isAuthenticated, navigate, loginCallback]);

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .required('Email is required')
      .email('Please provide a valid email')
  });

  const handleSubmit = async (
    values: Login,
    formikHelpers: FormikHelpers<Login>
  ) => {
    const errors = await formikHelpers.validateForm();
    if (!errors?.email) {
      try {
        await findEmailAndRouteToLogin(values);
      } catch (e) {
        formikHelpers.setErrors({
          email: "We don't recognize the email that you've entered."
        });
      }
    }
  };

  const logoutMessage = sessionStorage.getItem('logoutMessage');

  return (
    <>
      {isLoading && <LinearLoading />}
      {!isLoading && !isAuthenticated && (
        <Box className={classes.backgroundWrapper}>
          <Snackbar
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            open={!!logoutMessage}>
            <Alert severity='warning' variant='filled'>
              {logoutMessage}
            </Alert>
          </Snackbar>
          <Box className={classes.loginWrapper}>
            <Box className={classes.logo}>
              <img
                alt={AppConfig.branding.logoAlt}
                className={classes.vestwellLogo}
                src={AppConfig.branding.logoPath}
              />
            </Box>
            <Formik
              initialValues={{ email: '' }}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={LoginSchema}>
              {formik => {
                return (
                  <Form className={classes.inputFields}>
                    <Field
                      as={TextField}
                      className={classes.emailInput}
                      color='primary'
                      data-testid='login-input'
                      error={
                        formik.errors?.email && Boolean(formik.errors?.email)
                      }
                      fullWidth
                      label='Email'
                      name='email'
                      type='username'
                      variant='outlined'
                    />
                    {formik.errors?.email && (
                      <div
                        className={clsx(classes.container, classes.errorColor)}>
                        <ErrorOutlineIcon className={classes.iconPosition} />
                        {formik.errors.email}
                      </div>
                    )}
                    {formik.isSubmitting ? <LinearLoading /> : ''}
                    <Button
                      className={
                        formik.errors?.email ? '' : classes.loginButton
                      }
                      disabled={formik.isSubmitting}
                      fullWidth
                      role='button'
                      size='large'
                      type='submit'
                      variant='contained'>
                      Continue
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LoginRoute;
