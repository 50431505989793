import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import Accordion, {
  AccordionDetails,
  AccordionSummary
} from '@/components/accordion/Accordion.component';
import { CardHeader } from '@/components/card';
import CircularLoading from '@/components/circular-loading';
import { useDialog } from '@/contexts/DialogContext';
import PlanDocumentCategoryGroup from '@/models/PlanDocumentCategoryGroupDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';

import { orderBy } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';

import PlanDocumentsTable from './PlanDocumentsTable.component';
import UploadPlanDialog from './UploadPlanDialog.component';

const SECTION_HEADER_HEIGHT = 50;

const MissingGroupsOrDocuments = (): JSX.Element => {
  return (
    <Box
      alignItems='center'
      data-testid='no-data'
      display='flex'
      flexDirection='column'
      height='100%'
      justifyContent='center'>
      <InsertDriveFileOutlinedIcon
        sx={{ color: grey[300], fontSize: '60px' }}
      />
      <Typography variant='body1'> No Documents</Typography>
      <Typography variant='body2'>
        Create a new document group to start
      </Typography>
    </Box>
  );
};

const DocumentsLoading = (): JSX.Element => {
  return (
    <Box data-testid='documents-loading'>
      <Stack
        alignItems='center'
        justifyContent='center'
        sx={{
          minHeight: '233px'
        }}>
        <CircularLoading size='32px' />
      </Stack>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  accordionDetails: {
    marginLeft: theme.spacing(6)
  }
}));

interface PlanDocumentsGroupProps {
  sponsorPlanId: number;
  categoryId: number;
  useEffectiveDate: boolean;
  parentDocKey?: string;
  categoryName: string;
}

export type GroupsWithDates = PlanDocumentCategoryGroup & {
  effectiveDate?: string;
  createdAt?: string;
};

const PlanDocumentsGroup: React.FunctionComponent<PlanDocumentsGroupProps> = ({
  sponsorPlanId,
  categoryId,
  categoryName,
  useEffectiveDate,
  parentDocKey
}) => {
  const { openDialog } = useDialog();
  const [hideEmptyDocuments, toggleHideEmptyDocuments] = useToggle(true);
  const {
    data: documentCategoryGroups,
    isLoading,
    isError
  } = useQuery(
    ['PlanService.getDocumentCategoryGroupings', sponsorPlanId, categoryId],
    async () =>
      PlanService.getDocumentCategoryGroupings(sponsorPlanId, categoryId),
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const adHocDocsQuery = useQuery(
    ['PlanService.getDocumentCategoryLegacy', sponsorPlanId, categoryId],
    async () =>
      PlanService.getDocumentCategoryLegacy(sponsorPlanId, categoryId),
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<number>();

  const { isAllowed: canCreateNewGrouping } = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_DOCUMENTS_GROUPINGS_CREATE]
  });
  const rootRef = useRef<HTMLDivElement>(null);

  const uploadNewDocumentConfigPerCategory: Record<
    number,
    { documentKey: string; documentName: string }
  > = {
    1: {
      documentKey: 'Plan Documents/Plan Adoption Agreement',
      documentName: 'Plan Adoption Agreement'
    },
    2: {
      documentKey: 'Plan Documents/Plan Services Agreement',
      documentName: 'Plan Service Agreement'
    }
  };
  const refinedDocs = useMemo(() => {
    if (!documentCategoryGroups) return [];
    if (!adHocDocsQuery.data?.length) return documentCategoryGroups;
    const allAdHocDocs = orderBy(adHocDocsQuery.data, ['createdAt'], ['desc']);
    const adHocGroup = documentCategoryGroups.findIndex(
      g => g.name.toLowerCase() === 'ad hoc'
    );

    if (~adHocGroup) {
      return [
        ...documentCategoryGroups.slice(0, adHocGroup),
        ...documentCategoryGroups.slice(adHocGroup + 1),
        { documents: allAdHocDocs, groupingId: -1, name: 'Ad Hoc' }
      ];
    }

    return [
      ...documentCategoryGroups,
      { documents: allAdHocDocs, groupingId: -1, name: 'Ad Hoc' }
    ];
  }, [adHocDocsQuery.data, documentCategoryGroups]);

  const sortedGroups = useMemo(() => {
    if (!refinedDocs) return [];

    const groupsWithDates = refinedDocs.map(group => {
      const document = group.documents.find(d =>
        useEffectiveDate ? d.documentKey === parentDocKey : !!d.effectiveDate
      );
      const oldestDocument = orderBy(group.documents, 'createdAt', ['asc'])[0];

      return {
        ...group,
        createdAt: document?.createdAt || oldestDocument.createdAt,
        effectiveDate: document?.effectiveDate
      };
    });

    const groupsWithEffectiveDate: GroupsWithDates[] = [];
    const groupsWithCreatedAtDate: GroupsWithDates[] = [];
    const groupsWithNoDates: GroupsWithDates[] = [];
    const adHocGroup: GroupsWithDates[] = [];

    groupsWithDates.forEach(group => {
      if (group.name === 'Ad Hoc') {
        adHocGroup.push(group);
      } else if (!group.effectiveDate && !group.createdAt) {
        groupsWithNoDates.push(group);
      } else if (!group.effectiveDate) {
        groupsWithCreatedAtDate.push(group);
      } else {
        groupsWithEffectiveDate.push(group);
      }
    });

    groupsWithEffectiveDate.sort(
      (groupA, groupB) =>
        new Date(groupB.effectiveDate || 0).getTime() -
        new Date(groupA.effectiveDate || 0).getTime()
    );

    groupsWithCreatedAtDate.sort(
      (groupA, groupB) =>
        new Date(groupB.createdAt || 0).getTime() -
        new Date(groupA.createdAt || 0).getTime()
    );

    return [
      ...groupsWithEffectiveDate,
      ...groupsWithCreatedAtDate,
      ...groupsWithNoDates,
      ...adHocGroup
    ];
  }, [refinedDocs, parentDocKey, useEffectiveDate]);

  useEffect(() => {
    setExpanded(undefined);
  }, [categoryId]);

  return (
    <div
      data-testid={`plan-documents-group-category-id-${categoryId}`}
      ref={rootRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
      }}>
      <CardHeader
        actionButtonsProps={[
          {
            'data-testid': 'document-create-new-group',
            label: 'NEW GROUP',
            onClick: () =>
              openDialog({
                customContent: (
                  <UploadPlanDialog
                    categoryId={categoryId}
                    documentGroups={sortedGroups}
                    documentKey={
                      uploadNewDocumentConfigPerCategory[categoryId]
                        ?.documentKey
                    }
                    documentName={
                      uploadNewDocumentConfigPerCategory[categoryId]
                        ?.documentName
                    }
                    newGroupConfig={{
                      categoryId: categoryId,
                      entityId: sponsorPlanId,
                      entityType: 'plan',
                      name: categoryName
                    }}
                    planId={sponsorPlanId}
                  />
                )
              }),
            variant: 'text',
            visible: canCreateNewGrouping
          }
        ]}
        title='Documents'>
        <FormControlLabel
          componentsProps={{
            typography: {
              sx: {
                // so that the label aligns with the button
                fontSize: 14,
                lineHeight: '24px',
                py: '5px'
              }
            }
          }}
          control={
            <Checkbox
              checked={hideEmptyDocuments}
              data-testid='hide-empty-documents-checkbox'
              onChange={toggleHideEmptyDocuments}
            />
          }
          label='Hide Empty Documents'
        />
      </CardHeader>
      {(isLoading || adHocDocsQuery.isFetching) && <DocumentsLoading />}

      {!(isLoading || adHocDocsQuery.isFetching) &&
        !!sortedGroups?.length &&
        sortedGroups.map((group, groupIndex) => {
          let effectiveDateString = '';

          if (useEffectiveDate && group.effectiveDate) {
            effectiveDateString = ` | Effective ${formatters.formatFromIsoDateCustom(
              group.effectiveDate.toString(),
              'MM/DD/YYYY'
            )}`;
          } else if (!useEffectiveDate && group.effectiveDate) {
            const year = formatters.formatFromIsoDateCustom(
              group.effectiveDate.toString(),
              'YYYY'
            );
            effectiveDateString = ` | Plan Year 01/01/${year} - 12/31/${year}`;
          }

          return (
            <Accordion
              data-testid={`plan-document-group-${group.groupingId}-accordion`}
              expanded={expanded === group.groupingId}
              key={group.groupingId}
              onChange={(event, newIsExpanded) => {
                setExpanded(
                  expanded === group.groupingId ? undefined : group.groupingId
                );
                if (newIsExpanded) {
                  const topScroll =
                    (rootRef?.current?.getBoundingClientRect()?.top || 0) +
                    window.scrollY +
                    groupIndex * SECTION_HEADER_HEIGHT;

                  window.scrollTo({
                    behavior: 'smooth',
                    left: 0,
                    top: topScroll
                  });
                }
              }}>
              <AccordionSummary
                data-testid={`plan-document-group-${group.groupingId}-accordion-summary`}>
                <Box
                  alignItems='center'
                  display='flex'
                  flexDirection='row'
                  width='100%'>
                  <Typography
                    data-testid={`plan-document-group-${group.groupingId}-accordion-summary-name`}>
                    {group.name + effectiveDateString}
                  </Typography>
                  <Box marginLeft='auto'>
                    <Typography
                      data-testid={`plan-document-group-${group.groupingId}-accordion-summary-n-docs`}
                      variant='body2'>
                      {group.documents?.filter(d => !!d.fileName).length} docs
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                className={classes.accordionDetails}
                data-testid={`plan-document-group-${group.groupingId}-accordion-details`}>
                <PlanDocumentsTable
                  categoryId={categoryId}
                  documentGroups={documentCategoryGroups}
                  documents={group.documents.filter(doc =>
                    hideEmptyDocuments ? doc.fileName : true
                  )}
                  groupId={group.groupingId}
                  sponsorPlanId={sponsorPlanId}
                  useEffectiveDate={useEffectiveDate}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}

      {isError ||
        adHocDocsQuery.isError ||
        (!(isLoading || adHocDocsQuery.isFetching) && !sortedGroups?.length && (
          <MissingGroupsOrDocuments />
        ))}
    </div>
  );
};
export default PlanDocumentsGroup;
