import CollapsibleTable from '@/components/collapsible-table';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import { ForfeitureEventModalData } from '@/consts/forfeiture.constants';
import ForfeitureEventDto from '@/models/ForfeitureEventsDTO.model';
import ForfeitureTrackerDto from '@/models/ForfeitureTrackerDTO.model';
import ForfeitureEventTableCell from '@/routes/plans/plan-detail/PlanForfeituresTab/ForfeitureEventTableCell.component';
import { PlanService } from '@/services/Plan.service';
import VestwellTheme from '@/theme/Vestwell.theme';
import formatters from '@/utils/Formatters';
import { Add } from '@mui/icons-material';
import { Box, Button, Theme, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React from 'react';

import ForfeitureEventCollapsible from './ForfeitureEventCollapsible.component';
import ForfeitureEventModal from './ForfeitureEventModal.component';
import ForfeitureTrackerTableCell from './ForfeitureTrackerTableCell.component';

interface ForfeitureEventsAndUsageProps {
  sponsorPlanId: number;
  planParticipants: string[];
}

const forfeitureEventsColumns = [
  { field: 'date', headerName: 'Date', width: 130 },
  { field: 'amount', headerName: 'Amount', width: 130 },
  { field: 'reason', headerName: 'Reason', width: 260 },
  { field: 'updatedAt', headerName: 'Updated', width: 210 },
  { field: 'action', headerName: 'Action', width: 50 }
];

const forfeitureUsageColumns = [
  { field: 'date', headerName: 'Date', width: 130 },
  { field: 'status', headerName: 'Status', width: 130 },
  { field: 'amount', headerName: 'Amount', width: 130 },
  { field: 'type', headerName: 'Type', width: 260 },
  { field: 'update', headerName: 'Updated', width: 130 }
];

const ForfeitureEventsAndUsage = (
  props: ForfeitureEventsAndUsageProps
): JSX.Element => {
  const { sponsorPlanId, planParticipants } = props;
  const [isOpen, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const eventsQuery = useQuery<ForfeitureEventDto>(
    ['PlanService.getForfeitureEvents', sponsorPlanId],
    () => PlanService.getForfeitureEvents(sponsorPlanId),
    {
      enabled: Boolean(sponsorPlanId),
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  const trackerQuery = useQuery<ForfeitureTrackerDto>(
    ['PlanService.getForfeitureTrackerData', sponsorPlanId],
    () => PlanService.getForfeitureTrackerData(sponsorPlanId),
    {
      enabled: Boolean(sponsorPlanId),
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  const forfeitureEvents =
    eventsQuery.data?.data.map(e => {
      return {
        amount: e.data.attributes.amt,
        context: e.data.attributes.context,
        date: formatters.formatFromIsoDateCustom(
          e.data.attributes.date,
          'MM/DD/YYYY'
        ),
        id: e.data.id,
        note: e.data.attributes.note,
        participant: (planParticipants || []).find(p =>
          p.includes(e.data.attributes.context.participantId)
        ),
        planId: sponsorPlanId,
        planParticipants: planParticipants || [],
        reason: e.data.attributes.reason,
        updatedAt: formatters.formatFromIsoDateCustom(
          e.data.attributes.updatedAt,
          'MM/DD/YYYY, h:mm:ss A'
        ),
        updatedBy: e.data.attributes.updatedBy
      };
    }) || [];

  const forfeitureUsages =
    trackerQuery.data?.data.map(t => {
      return {
        amount: t.data.attributes.amount,
        date: formatters.formatFromIsoDateCustom(
          t.data.attributes.createdAt,
          'MM/DD/YYYY'
        ),
        status: t.data.attributes.status,
        type: t.data.attributes.type,
        update: formatters.formatFromIsoDateCustom(
          t.data.attributes.updatedAt,
          'MM/DD/YYYY, h:mm:ss A'
        )
      };
    }) || [];

  const makeTabsElement = (): SimpleTabsProps => {
    const ForfeitureEventsTab: TabData = {
      component: (
        <Box sx={{ marginTop: (theme: Theme) => theme.spacing(-3) }}>
          <CollapsibleTable
            cellComponent={ForfeitureEventTableCell}
            collapsibleComponent={ForfeitureEventCollapsible}
            columns={forfeitureEventsColumns}
            tableData={forfeitureEvents}
          />
        </Box>
      ),
      hash: 'events',
      label: 'EVENTS',
      path: `/plans/${sponsorPlanId}/forfeitures/events`
    };
    const ForfeitureUsageTab: TabData = {
      component: (
        <Box sx={{ marginTop: (theme: Theme) => theme.spacing(-3) }}>
          <CollapsibleTable
            cellComponent={ForfeitureTrackerTableCell}
            columns={forfeitureUsageColumns}
            tableData={forfeitureUsages}
          />
        </Box>
      ),
      hash: 'usage',
      label: 'USAGE',
      path: `/plans/${sponsorPlanId}/forfeitures/usage`
    };

    const registeredTabs = [ForfeitureEventsTab, ForfeitureUsageTab];

    return {
      tabs: registeredTabs,
      tabsAriaLabel: 'forfeiture-data-grid-tabs'
    };
  };

  const tabs = makeTabsElement();

  return (
    <>
      <div className='Plan-Forfeitures' data-testid='plan-forfeitures-card'>
        <Box
          border='1px solid'
          borderColor='#E5E5E5'
          borderRadius='0.25rem'
          mb='1.5rem'>
          <Box
            alignItems='center'
            borderBottom='1px solid'
            borderColor='#E5E5E5'
            display='flex'
            justifyContent='space-between'
            minHeight='70px'
            paddingRight='1.5rem'>
            <Typography ml='16px' variant='h5'>
              Event & Usage
            </Typography>
            <Button
              color='primary'
              data-testid='forfeiture-events-and-usage-add-event-btn'
              onClick={handleClickOpen}
              variant='outlined'>
              ADD EVENT <Add sx={{ fontSize: '1.2rem' }} />
            </Button>
            <ForfeitureEventModal
              buttonText={ForfeitureEventModalData.CREATE_EVENT_MODAL_BUTTON}
              initialValues={{
                amount: '',
                date: dayjs(Date.now()).format('YYYY-MM-DD'),
                note: '',
                participant: '',
                planId: sponsorPlanId,
                reason: '',
                ucid: '',
                year: ''
              }}
              isOpenModal={isOpen}
              modalTitle='Create Forfeiture Event'
              planParticipants={planParticipants}
              setOpen={setOpen}
            />
          </Box>
          <Box
            bgcolor={VestwellTheme.palette.background.paper}
            display='flex-inline'
            fontSize='0.875rem'
            margin='0'>
            <SimpleTabs
              enableNestedHash
              {...tabs}
              parentTabPath={`/plans/${props.sponsorPlanId}/forfeitures`}
            />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default ForfeitureEventsAndUsage;
