import AccessControl from '@/components/access-control/AccessControl.component';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { StartRegularContribution } from '@/routes/plans/plan-contribution-submission';
import { SkipSubmission } from '@/routes/plans/plan-detail/PlanContributionsTab/SkipSubmission.component';
import { Button, Unstable_Grid2 as Grid } from '@mui/material';

import React, { FC, useMemo } from 'react';
import { useToggle } from 'react-use';

type SubmissionActionsProps = {
  contribution: Record<string, any>;
};

const statuses = new Set([
  'Due',
  'Upcoming',
  'Late',
  'Late Critical',
  'Missing'
]);

export const SubmissionActions: FC<SubmissionActionsProps> = props => {
  const [isSkip, toggleSkip] = useToggle(false);

  const isEnabled = useMemo(
    () => statuses.has(props.contribution?.payrollDueStatus),
    [props.contribution?.payrollDueStatus]
  );

  return isEnabled ? (
    <Grid container display='flex' spacing={2}>
      <Grid>
        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_CONTRIBUTIONS_ACTION]}>
          <Button data-testid='contributions-skip-button' onClick={toggleSkip}>
            Skip
          </Button>
          <SkipSubmission
            data={{
              actualPayDate: props.contribution?.actualPayrollDate,
              division: props.contribution?.division,
              sponsorId: props.contribution?.sponsorId,
              sponsorPlanId: props.contribution?.sponsorPlanId
            }}
            isOpen={isSkip}
            onClose={toggleSkip}
          />
        </AccessControl>
      </Grid>
      <Grid>
        <AccessControl requires={[FeatureLevelPermissions.WRITE_CONTRIBUTION]}>
          <StartRegularContribution
            division={props.contribution?.division}
            payrollDate={props.contribution?.payrollDate}
            planId={props.contribution.sponsorPlanId}
            sponsorId={props.contribution.sponsorId}
          />
        </AccessControl>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};
