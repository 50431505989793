import Constants from '@/consts/planDesign.constants';
import { PlanDesignDto } from '@/models';
import {
  ClientSuccessManager,
  ClientSuccessManagerDto
} from '@/models/ClientSuccessManager.model';
import {
  FundingSource,
  PlanDesign,
  SafeHarborType
} from '@/models/PlanDesign.model';
import { PooledPlan, PooledPlanDesign } from '@/models/PooledPlanDTO.model';
import { ScheduledChangePayloadItem } from '@/models/ScheduledChangesDTO.model';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';

import { entries, filter, isEqual, keys, uniqBy } from 'lodash';

import DefaultTemplate from './plan-design-templates/templates/default-template/DefaultTemplate';
import StarterVestwellWorkplace from './plan-design-templates/templates/starter/vestwell-workplace/StarterVestwellWorkplace';
import { getUiSchema } from './schemas';

const templates: Record<string, any> = {
  StarterVestwellWorkplace
};

const convertFundingSourcesToApiState = (
  data: Record<string, unknown>
): FundingSource[] | [] => {
  const employerMatch = Constants.vestingSchedules.find(
    (schedule: { id: number; name: string }) =>
      schedule.name === data.employerMatch
  );

  const profitSharing = Constants.vestingSchedules.find(
    (schedule: { id: number; name: string }) =>
      schedule.name === data.profitSharing
  );

  const QACASafeHarborMatch = Constants.vestingSchedules.find(
    (schedule: { id: number; name: string }) =>
      schedule.name === data.QACASafeHarborMatch
  );

  const QACASafeHarborNonElective = Constants.vestingSchedules.find(
    (schedule: { id: number; name: string }) =>
      schedule.name === data.QACASafeHarborNonElective
  );

  return [
    {
      fundingSourceId: 13,
      fundingSourceName: 'Employer Match',
      vestingScheduleId:
        employerMatch?.id === -1 ? undefined : employerMatch?.id,
      vestingScheduleName: employerMatch?.name
    },
    {
      fundingSourceId: 3,
      fundingSourceName: 'Profit Sharing',
      vestingScheduleId:
        profitSharing?.id === -1 ? undefined : profitSharing?.id,
      vestingScheduleName: profitSharing?.name
    },
    {
      fundingSourceId: 14,
      fundingSourceName: 'QACA SH Match',
      vestingScheduleId:
        QACASafeHarborMatch?.id === -1 ? undefined : QACASafeHarborMatch?.id,
      vestingScheduleName: QACASafeHarborMatch?.name
    },
    {
      fundingSourceId: 15,
      fundingSourceName: 'QACA SH Nonelective',
      vestingScheduleId:
        QACASafeHarborNonElective?.id === -1
          ? undefined
          : QACASafeHarborNonElective?.id,
      vestingScheduleName: QACASafeHarborNonElective?.name
    }
  ];
};

const flattenPlanDesign = (planDesignData: PlanDesign) => {
  let planDesignDataFlat: Record<string, any> = {};
  entries(planDesignData).forEach(([, value]) => {
    planDesignDataFlat = { ...planDesignDataFlat, ...value };
  });
  return planDesignDataFlat;
};

export const getEditPlanDesignValues = (
  planDesignData: PlanDesign | PooledPlanDesign,
  planData: DefaultTemplate,
  editPlanDetailsForm: Record<string, any>
): PlanDesign => {
  const editPlanDetails = {
    ...flattenPlanDesign(planDesignData),
    ...editPlanDetailsForm
  };

  return {
    distributionFeatures: {
      ...planDesignData.distributionFeatures,
      allowHardshipElective: planData.allowHardshipElective.convertToApiState(
        editPlanDetails.allowHardshipElective
      ),
      allowHardshipMatch: planData.allowHardshipMatch.convertToApiState(
        editPlanDetails.allowHardshipMatch
      ),
      allowHardshipProfitSharing:
        planData.allowHardshipProfitSharing.convertToApiState(
          editPlanDetails.allowHardshipProfitSharing
        ),
      allowHardshipRollover: planData.allowHardshipRollover.convertToApiState(
        editPlanDetails.allowHardshipRollover
      ),
      allowHardshipSafeHarbor:
        planData.allowHardshipSafeHarbor.convertToApiState(
          editPlanDetails.allowHardshipSafeHarbor
        ),
      allowHardshipTransfer: planData.allowHardshipTransfer.convertToApiState(
        editPlanDetails.allowHardshipTransfer
      ),
      allowHardshipWithdrawals:
        planData.allowHardshipWithdrawals.convertToApiState(
          editPlanDetails.allowHardshipWithdrawals
        ),
      allowHardshipWithdrawalsFromRothAccount:
        planData.allowHardshipWithdrawalsFromRothAccount.convertToApiState(
          editPlanDetails.allowHardshipWithdrawalsFromRothAccount
        ),
      allowInServiceAtEarlyAge:
        planData.allowInServiceAtEarlyAge.convertToApiState(
          editPlanDetails.allowInServiceAtEarlyAge
        ),
      allowInServiceAtEarlyAgeFromAllAccounts:
        planData.allowInServiceAtEarlyAgeFromAllAccounts.convertToApiState(
          editPlanDetails.allowInServiceAtEarlyAgeFromAllAccounts
        ),
      allowInServiceAtNormalAge:
        planData.allowInServiceAtNormalAge.convertToApiState(
          editPlanDetails.allowInServiceAtNormalAge
        ),
      allowInServiceAtNormalAgeFromAllAccounts:
        planData.allowInServiceAtNormalAgeFromAllAccounts.convertToApiState(
          editPlanDetails.allowInServiceAtNormalAgeFromAllAccounts
        ),
      allowInServiceAtSpecifiedAge:
        planData.allowInServiceAtSpecifiedAge.convertToApiState(
          editPlanDetails.allowInServiceAtSpecifiedAge
        ),
      allowInServiceFromPartialVestedAccount:
        planData.allowInServiceFromPartialVestedAccount.convertToApiState(
          editPlanDetails.allowInServiceFromPartialVestedAccount
        ),
      allowInServiceFromRolloverAccount:
        planData.allowInServiceFromRolloverAccount.convertToApiState(
          editPlanDetails.allowInServiceFromRolloverAccount
        ),
      allowInServiceUponDisability:
        planData.allowInServiceUponDisability.convertToApiState(
          editPlanDetails.allowInServiceUponDisability
        ),
      allowPartialDistributionsAfterTermination:
        planData.allowPartialDistributionsAfterTermination.convertToApiState(
          editPlanDetails.allowPartialDistributionsAfterTermination
        ),
      allowPermissibleWithdrawal:
        planData.allowPermissibleWithdrawal.convertToApiState(
          editPlanDetails.allowPermissibleWithdrawal
        ),
      allowSpecifiedAgeFromRothAccount:
        planData.allowSpecifiedAgeFromRothAccount.convertToApiState(
          editPlanDetails.allowSpecifiedAgeFromRothAccount
        ),
      childFee: planData.childFee.convertToApiState(editPlanDetails.childFee),
      correctiveFee: planData.correctiveFee.convertToApiState(
        editPlanDetails.correctiveFee
      ),
      deathFee: planData.deathFee.convertToApiState(editPlanDetails.deathFee),
      disabilityFee: planData.disabilityFee.convertToApiState(
        editPlanDetails.disabilityFee
      ),
      earlyRetirementAgeType: planData.earlyRetirementAgeType.convertToApiState(
        editPlanDetails.earlyRetirementAgeType
      ),
      hardshipCriteria: planData.hardshipCriteria.convertToApiState(
        editPlanDetails.hardshipCriteria
      ),
      hardshipFee: planData.hardshipFee.convertToApiState(
        editPlanDetails.hardshipFee
      ),
      inServiceFee: planData.inServiceFee.convertToApiState(
        editPlanDetails.inServiceFee
      ),
      inServiceSpecifiedAge: planData.inServiceSpecifiedAge.convertToApiState(
        editPlanDetails.inServiceSpecifiedAge
      ),
      normalRetirementAge: planData.normalRetirementAge.convertToApiState(
        editPlanDetails.normalRetirementAge
      ),
      normalRetirementAgeType:
        planData.normalRetirementAgeType.convertToApiState(
          editPlanDetails.normalRetirementAgeType
        ),
      participantTerminationFee:
        planData.participantTerminationFee.convertToApiState(
          editPlanDetails.participantTerminationFee
        ),
      permissibleTimeframe: planData.permissibleTimeframe.convertToApiState(
        editPlanDetails.permissibleTimeframe
      ),
      permissiveFee: planData.permissiveFee.convertToApiState(
        editPlanDetails.permissiveFee
      ),
      planTerminationFee: planData.planTerminationFee.convertToApiState(
        editPlanDetails.planTerminationFee
      ),
      qdroFee: planData.qdroFee.convertToApiState(editPlanDetails.qdroFee),
      rmdFee: planData.rmdFee.convertToApiState(editPlanDetails.rmdFee),
      rolloverFee: planData.rolloverFee.convertToApiState(
        editPlanDetails.rolloverFee
      )
    },
    effectiveDate: {
      ...planDesignData.effectiveDate,
      blackoutEndDate: planData.blackoutEndDate.convertToApiState(
        editPlanDetails.blackoutEndDate
      ),
      blackoutStartDate: planData.blackoutStartDate.convertToApiState(
        editPlanDetails.blackoutStartDate
      ),
      liquidationDate: planData.liquidationDate.convertToApiState(
        editPlanDetails.liquidationDate
      ),
      originalEffectivePlanDate:
        planData.originalEffectivePlanDate.convertToApiState(
          editPlanDetails.originalEffectivePlanDate
        ),
      planYearEnd: planData.planYearEnd.convertToApiState(
        editPlanDetails.planYearEnd
      ),
      profitSharingStartDate: planData.profitSharingStartDate.convertToApiState(
        editPlanDetails.profitSharingStartDate
      ),
      safeHarborEffectiveDate:
        planData.safeHarborEffectiveDate.convertToApiState(
          editPlanDetails.safeHarborEffectiveDate
        ),
      salaryRothDeferralEffectiveDate:
        planData.salaryRothDeferralEffectiveDate.convertToApiState(
          editPlanDetails.salaryRothDeferralEffectiveDate
        ),
      vestwellStartDate: planData.vestwellStartDate.convertToApiState(
        editPlanDetails.vestwellStartDate
      )
    },
    eligibilityFeatures: {
      ...planDesignData.eligibilityFeatures,
      eligibilityRules: planData.serviceCalculationType.convertToApiState(
        editPlanDetails.serviceCalculationType,
        planData.minimumAge.convertToApiState(editPlanDetails.minimumAge),
        planData.lengthOfEmploymentRequired.convertToApiState(
          editPlanDetails.lengthOfEmploymentRequired
        ),
        planData.hoursRequired.convertToApiState(editPlanDetails.hoursRequired)
      ),
      entryDateFrequency: planData.entryDateFrequency.convertToApiState(
        editPlanDetails.entryDateFrequency
      ),
      entryDateFrequencyId:
        planData.entryDateFrequency.entryDateFrequencies.find(
          (f: { id: number; type: string }) =>
            f.type === editPlanDetails.entryDateFrequency
        )?.id,
      excludeLeasedEmployees: planData.excludeLeasedEmployees.convertToApiState(
        editPlanDetails.excludeLeasedEmployees
      ),
      specialEntryDate: planData.specialEntryDate.convertToApiState(
        editPlanDetails.specialEntryDate
      ),
      specialParticipationDate:
        planData.specialParticipationDate.convertToApiState(
          editPlanDetails.specialParticipationDate
        )
    },
    employeeContribution: {
      ...planDesignData.employeeContribution,
      allowAfterTaxContribution:
        planData.allowAfterTaxContribution.convertToApiState(
          editPlanDetails.allowAfterTaxContribution
        ),
      allowPretaxContribution:
        planData.allowPretaxContribution.convertToApiState(
          editPlanDetails.allowPretaxContribution
        ),
      autoEnrollAmount: planData.autoEnrollAmount.convertToApiState(
        editPlanDetails.autoEnrollAmount
      ),
      autoEnrollEffectiveDate:
        planData.autoEnrollEffectiveDate.convertToApiState(
          editPlanDetails.autoEnrollEffectiveDate
        ),
      autoEscalateAmount: planData.autoEscalateAmount.convertToApiState(
        editPlanDetails.autoEscalateAmount
      ),
      autoEscalateMaximum: planData.autoEscalateMaximum.convertToApiState(
        editPlanDetails.autoEscalateMaximum
      ),
      deferInDollars: planData.deferInDollars.convertToApiState(
        editPlanDetails.deferInDollars
      ),
      isSalaryRothDeferral: planData.isSalaryRothDeferral.convertToApiState(
        editPlanDetails.isSalaryRothDeferral
      )
    },
    employerContribution: {
      ...planDesignData.employerContribution,
      allowEmployerProfitSharing:
        planData.allowEmployerProfitSharing.convertToApiState(
          editPlanDetails.allowEmployerProfitSharing
        ),
      allowNonSafeHarborMatch:
        planData.allowNonSafeHarborMatch.convertToApiState(
          editPlanDetails.allowNonSafeHarborMatch
        ),
      allowRollover: editPlanDetails.allowRollover,
      allowSafeHarborNonElective:
        planData.allowSafeHarborNonElective.convertToApiState(editPlanDetails),
      discretionaryMatchFundingIntent:
        planData.discretionaryMatchFundingIntent.convertToApiState(
          editPlanDetails.discretionaryMatchFundingIntent
        ),
      documentMatchFrequency: planData.documentMatchFrequency.convertToApiState(
        editPlanDetails.documentMatchFrequency
      ),
      earlyRetirementAge: planData.earlyRetirementAge.convertToApiState(
        editPlanDetails.earlyRetirementAge
      ),
      formula:
        planData.contributionMatchFormula.convertToApiState(editPlanDetails),
      isSafeHarbor: planData.isSafeHarbor.convertToApiState(editPlanDetails),
      matchFrequency: planData.matchFrequency.convertToApiState(
        editPlanDetails.matchFrequency
      ),
      nonSafeHarborMatchType: planData.nonSafeHarborMatchType.convertToApiState(
        editPlanDetails.nonSafeHarborMatchType
      ),
      notes: planData.notes.convertToApiState(editPlanDetails.notes),
      profitSharingStrategy: planData.profitSharingStrategy.convertToApiState(
        editPlanDetails.profitSharingStrategy
      ),
      safeHarborMatchType: planData.safeHarborMatchType.convertToApiState(
        editPlanDetails.safeHarborMatchType
      )
    },
    forceOutPreferences: {
      ...planDesignData.forceOutPreferences,
      excludeRollover: planData.excludeRollover.convertToApiState(
        editPlanDetailsForm.excludeRollover
      ),
      isAllowed: planData.isAllowed.convertToApiState(
        editPlanDetailsForm.isAllowed
      ),
      isAutomated: planData.isAutomated.convertToApiState(
        editPlanDetailsForm.isAutomated
      ),
      maxAmount: planData.maxAmount.convertToApiState(
        editPlanDetailsForm.maxAmount
      ),
      rolloverMin: planData.rolloverMin.convertToApiState(
        editPlanDetailsForm.rolloverMin
      )
    },
    forfeiturePreferences: {
      ...planDesignData.forfeiturePreferences,
      offsetEmployerContribution:
        planData.offsetEmployerContribution.convertFromYesNoToBoolean(
          editPlanDetails.offsetEmployerContribution
        ),
      payPlanFees: planData.payPlanFees.convertFromYesNoToBoolean(
        editPlanDetails.payPlanFees
      )
    },
    loanFeatures: {
      ...planDesignData.loanFeatures,
      allowLoans: planData.allowLoans.convertToApiState(
        editPlanDetails.allowLoans
      ),
      allowResidencePurchaseExtension:
        editPlanDetails.allowLoans === 'Yes'
          ? planData.allowResidencePurchaseExtension.convertToApiState(
              editPlanDetails.allowResidencePurchaseExtension
            )
          : undefined,
      hasPriorLoans: planData.hasPriorLoans.convertToApiState(
        editPlanDetails.hasPriorLoans
      ),
      loanEmail:
        editPlanDetails.loanEmail || planDesignData.loanFeatures?.loanEmail,
      maintenanceFee: planData.maintenanceFee.convertToApiState(
        editPlanDetails.maintenanceFee ||
          planDesignData.loanFeatures?.maintenanceFee
      ),
      maxOutstandingLoans:
        editPlanDetails.allowLoans === 'Yes'
          ? planData.maxOutstandingLoans.convertToApiState(
              editPlanDetails.maxOutstandingLoans
            )
          : undefined,
      maxYearsResidencePurchase:
        editPlanDetails.allowResidencePurchaseExtension === 'Yes'
          ? planData.maxYearsResidencePurchase.convertToApiState(
              editPlanDetails.maxYearsResidencePurchase
            )
          : undefined,
      originationFee: planData.originationFee.convertToApiState(
        editPlanDetails.originationFee ||
          planDesignData.loanFeatures?.originationFee
      )
    },
    offboardingInformation: {
      ...planDesignData.offboardingInformation,
      blackoutEndDate: planData.offboardingBlackoutEndDate.convertToApiState(
        editPlanDetails.offboardingBlackoutEndDate
      ),
      blackoutStartDate:
        planData.offboardingBlackoutStartDate.convertToApiState(
          editPlanDetails.offboardingBlackoutStartDate
        ),
      deconvertedToMep: planData.deconvertedToMep.convertToApiState(
        editPlanDetails.deconvertedToMep
      ),
      lastPayrollDate: planData.lastPayrollDate.convertToApiState(
        editPlanDetails.lastPayrollDate
      ),
      terminationDate: planData.terminationDate.convertToApiState(
        editPlanDetails.terminationDate
      )
    },
    overview: {
      ...planDesignData.overview,
      adminStatus: planData.adminStatus.convertToApiState(
        editPlanDetails.adminStatus
      ),
      clientSuccessManagerId: planData.clientSuccessManagerName.csms.find(
        (csm: ClientSuccessManager) =>
          csm.name === editPlanDetails.clientSuccessManagerName
      )?.csmId,
      clientSuccessManagerName:
        planData.clientSuccessManagerName.convertToApiState(
          editPlanDetails.clientSuccessManagerName
        ),
      trustee: planData.trustee.convertToApiState(editPlanDetails.trustee)
    },
    recordkeeperAndCustodian: {
      ...planDesignData.recordkeeperAndCustodian,
      adoptionAgreementDocumentProvider:
        planData.adoptionAgreementDocumentProvider.convertToApiState(
          editPlanDetails.adoptionAgreementDocumentProvider
        ),
      custodianAccountNumber: planData.custodianAccountNumber.convertToApiState(
        editPlanDetails.custodianAccountNumber
      ),
      externalHardDollarBillingAccountNumber:
        planData.externalHardDollarBillingAccountNumber.convertToApiState(
          editPlanDetails.externalHardDollarBillingAccountNumber
        ),
      externalPlanId: planData.externalPlanId.convertToApiState(
        editPlanDetails.externalPlanId
      ),
      opportunityId: planData.opportunityId.convertToApiState(
        editPlanDetails.opportunityId
      ),
      partnerSystemName: planData.partnerSystemName.convertToApiState(
        editPlanDetails.partnerSystemName
      ),
      priorProviderId: planData.priorProvider.convertToApiState(
        editPlanDetails.priorProviderId
      ),
      recordkeeperId: Constants.recordkeepers.find(
        (rk: { id: number; name: string }) =>
          rk.name === editPlanDetails.partnerSystemName
      )?.id
    },
    vestingPreferences: {
      ...planDesignData.vestingPreferences,
      excludeServicePriorTo18YearsOld:
        planData.excludeServicePriorTo18YearsOld.convertToApiState(
          editPlanDetails.excludeServicePriorTo18YearsOld
        ),
      excludeServicePriorToPlanExistence:
        planData.excludeServicePriorToPlanExistence.convertToApiState(
          editPlanDetails.excludeServicePriorToPlanExistence
        ),
      fundingSources: convertFundingSourcesToApiState(editPlanDetails),
      hoursOfServiceRequired: planData.hoursOfServiceRequired.convertToApiState(
        editPlanDetails.hoursOfServiceRequired,
        editPlanDetails.vestingMethod
      ),
      vestingMethod: planData.vestingMethod.convertToApiState(
        editPlanDetails.vestingMethod
      )
    }
  };
};

export const hideFieldFromTpa = (fieldKey: string): boolean => {
  const forbiddenTpaFields = [
    'vestwellStartDate',
    'liquidationDate',
    'blackoutStartDate',
    'blackoutEndDate',
    'allowAfterTaxContribution'
  ];
  return forbiddenTpaFields.includes(fieldKey);
};

const formatUiValue = (value: any, component: string) => {
  return component === 'DatePicker' && value
    ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
    : `${value === undefined || value === null ? '' : value}`;
};

export const editPlanDetailsFormToScheduleChangesPayload = (
  currentValues: Record<string, any>,
  scheduledValues: Record<string, any>,
  appendPlanDetailsValues?: {
    planDesignData?: PlanDesignDto;
    planData?: DefaultTemplate;
  }
): ScheduledChangePayloadItem[] => {
  const differences: Record<string, string> = filter(
    keys(scheduledValues),
    key => scheduledValues[key] !== currentValues[key]
  ).reduce((a, v) => ({ ...a, [v]: v }), {});

  const newPayload: ScheduledChangePayloadItem[] = [];

  const shouldAppendPlanDetailsValues =
    appendPlanDetailsValues?.planDesignData &&
    appendPlanDetailsValues?.planData;

  const editPlanDetailsPreviousValuesFlatt = shouldAppendPlanDetailsValues
    ? flattenPlanDesign(
        getEditPlanDesignValues(
          appendPlanDetailsValues.planDesignData.data,
          appendPlanDetailsValues.planData,
          currentValues
        )
      )
    : {};

  const editPlanDetailsValues: PlanDesign = shouldAppendPlanDetailsValues
    ? getEditPlanDesignValues(
        appendPlanDetailsValues.planDesignData.data,
        appendPlanDetailsValues.planData,
        scheduledValues
      )
    : {};

  const editPlanDetailsValuesFlatt = shouldAppendPlanDetailsValues
    ? flattenPlanDesign(editPlanDetailsValues)
    : {};

  const differencesSpecialValues: Record<string, string> = filter(
    keys(editPlanDetailsValuesFlatt),
    key =>
      !isEqual(
        editPlanDetailsValuesFlatt[key],
        editPlanDetailsPreviousValuesFlatt[key]
      ) &&
      !differences[key] &&
      editPlanDetailsValuesFlatt[key] !== undefined
  ).reduce((a, v) => ({ ...a, [v]: v }), {});

  entries(differencesSpecialValues).forEach(([key]) => {
    newPayload.push({
      fieldName: key,
      uiCurrentValue: '',
      uiLabel: '',
      uiScheduledValue: '',
      value: editPlanDetailsValuesFlatt[key]
    });
  });

  const renamedFieldKey = {
    dollarCap: 'dollarCapId'
  };

  const valueRenamedFieldKey = {
    blackoutEndDate: editPlanDetailsValues?.effectiveDate?.blackoutEndDate,
    blackoutStartDate: editPlanDetailsValues?.effectiveDate?.blackoutStartDate,
    dollarCap: editPlanDetailsValuesFlatt?.formula?.dollarCapId,
    offboardingBlackoutEndDate:
      editPlanDetailsValues?.offboardingInformation?.blackoutEndDate,
    offboardingBlackoutStartDate:
      editPlanDetailsValues?.offboardingInformation?.blackoutStartDate
  };

  entries(getUiSchema().design).forEach(([, value]) => {
    if (value.fields) {
      value.fields.forEach(field => {
        if (differences[field.key]) {
          differences[field.key] = null;
          if (renamedFieldKey[field.key]) {
            newPayload.push({
              fieldName: renamedFieldKey[field.key],
              uiCurrentValue: '',
              uiLabel: '',
              uiScheduledValue: '',
              value: valueRenamedFieldKey[field.key]
            });
          }
          newPayload.push({
            fieldName: field.key,
            uiCurrentValue: formatUiValue(
              currentValues[field.key],
              field.component
            ),
            uiLabel: field.label,
            uiScheduledValue: formatUiValue(
              scheduledValues[field.key],
              field.component
            ),
            value:
              valueRenamedFieldKey[field.key] ||
              editPlanDetailsValuesFlatt[field.key]
          });
        }
      });
    }

    if (value.sections) {
      value.sections.forEach(section =>
        section.fields.forEach(field => {
          if (differences[field.key]) {
            differences[field.key] = null;
            if (renamedFieldKey[field.key]) {
              newPayload.push({
                fieldName: renamedFieldKey[field.key],
                uiCurrentValue: '',
                uiLabel: '',
                uiScheduledValue: '',
                value: valueRenamedFieldKey[field.key]
              });
            }
            newPayload.push({
              fieldName: field.key,
              uiCurrentValue: formatUiValue(
                currentValues[field.key],
                field.component
              ),
              uiLabel: field.label,
              uiScheduledValue: formatUiValue(
                scheduledValues[field.key],
                field.component
              ),
              value: editPlanDetailsValuesFlatt[field.key]
            });
          }
        })
      );
    }
  });

  const fieldsNotInSchemaLabels = {
    capped: 'Capped',
    dollarCap: 'Cap Amount',
    firstTierCap: 'First Tier Contribution',
    firstTierPercent: 'First Tier Match',
    formulaDefined: 'Formula Defined',
    secondTierCap: 'Second Tier Contribution',
    secondTierPercent: 'Second Tier Match',
    tier: 'Tier'
  };

  Object.entries(differences)
    .filter(([key, value]) => key !== 'effectiveDate' && value)
    .forEach(([key]) => {
      if (renamedFieldKey[key]) {
        newPayload.push({
          fieldName: renamedFieldKey[key],
          uiCurrentValue: '',
          uiLabel: '',
          uiScheduledValue: '',
          value: valueRenamedFieldKey[key]
        });
      }
      newPayload.push({
        fieldName: key,
        uiCurrentValue: formatUiValue(currentValues[key], ''),
        uiLabel: fieldsNotInSchemaLabels[key] || '',
        uiScheduledValue: formatUiValue(scheduledValues[key], ''),
        value: scheduledValues[key]
      });
    });

  return [...newPayload]
    .map(payloadItem => {
      const newPayloadItem = { ...payloadItem };

      if (newPayloadItem.fieldName === 'formula') {
        newPayloadItem.fieldName = 'tiers';
        newPayloadItem.value = newPayloadItem.value.tiers;
      }
      if (newPayloadItem.fieldName === 'formulaDefined') {
        newPayloadItem.value =
          editPlanDetailsValuesFlatt?.formula?.formulaDefined;
      }
      return newPayloadItem;
    })
    .filter(
      payloadItem =>
        (payloadItem.uiCurrentValue !== payloadItem.uiScheduledValue ||
          payloadItem.uiLabel === '') &&
        payloadItem.fieldName !== 'scheduledChangesEffectiveDate'
    );
};

export const uploadingDataToPlanData = (
  uploadingData: PlanDesign,
  planDesignData: PlanDesignDto,
  csms?: ClientSuccessManagerDto,
  isTpaUser?: boolean,
  isPooledPlan?: boolean,
  safeHarborTypes?: SafeHarborType[]
) => {
  const planDesignTypeName = planDesignData?.data?.overview?.planDesignTypeName;

  const mergedPlanDesign = Object.entries(uploadingData).reduce(
    (topLevelAcc, [planDesignSectionName, planDesignSectionObject]) => {
      // first level is section of plan design (e.g. 'vestingPreferences' or 'distributionFeatures')
      return {
        ...topLevelAcc,
        [planDesignSectionName]: Object.entries(planDesignSectionObject).reduce(
          (acc, [planDesignFieldName, planDesignFieldValue]) => {
            // second level is the field of plan design section (e.g. 'vestingPreferences.vestingMethod' or 'distributionFrequency.earlyRetirementAgeType')

            // have to compare nested arrays fundingSources and eligibilityRules
            if (
              planDesignFieldName === 'fundingSources' &&
              Array.isArray(planDesignFieldValue)
            ) {
              return {
                ...acc,
                [planDesignFieldName]: uniqBy(
                  [
                    ...planDesignFieldValue,
                    ...(topLevelAcc.vestingPreferences?.fundingSources || [])
                  ],
                  'fundingSourceId'
                )
              };
            }

            if (
              planDesignFieldName === 'eligibilityRules' &&
              Array.isArray(planDesignFieldValue)
            ) {
              return {
                ...acc,
                [planDesignFieldName]: [
                  ...([
                    planDesignFieldValue.find(
                      rule => rule.eligibilityRequirementTypeName === 'Age'
                    )
                  ] || [
                      topLevelAcc.eligibilityFeatures?.eligibilityRules?.find(
                        rule => rule.eligibilityRequirementTypeName === 'Age'
                      )
                    ] ||
                    []),
                  ...([
                    planDesignFieldValue.find(
                      rule => rule.eligibilityRequirementTypeName !== 'Age'
                    )
                  ] || [
                      topLevelAcc.eligibilityFeatures?.eligibilityRules?.find(
                        rule => rule.eligibilityRequirementTypeName !== 'Age'
                      )
                    ] ||
                    [])
                ]
              };
            }

            return {
              ...acc,
              [planDesignFieldName]: planDesignFieldValue
            };
          },
          topLevelAcc[planDesignSectionName as keyof PlanDesign]
        )
      };
    },
    planDesignData.data
  );

  const data = {
    ...mergedPlanDesign,
    csms: csms || [],
    isPooledPlan: !!isPooledPlan,
    isTpaUser: !!isTpaUser
  };

  const template = planDesignTypeName
    ? new templates[planDesignTypeName.split(' ').join('')](data)
    : new DefaultTemplate(data);

  template.safeHarborMatchType?.set(
    data.employerContribution?.safeHarborMatchType,
    safeHarborTypes || []
  );

  return template;
};

export const planDataToFormValues = (planData: Record<string, any>) => {
  return Object.entries(planData).reduce(
    (acc: Record<string, any>, [key, value]) => {
      acc[key] = value.output === 0 ? value.output : value.output || '';

      if (Object.keys(value.editModeValues || {})?.length) {
        acc = { ...acc, ...value.editModeValues };
      }

      return acc;
    },
    {}
  );
};

export const getPlanTemplate = (
  config: {
    planDesignData?: PlanDesignDto;
    pooledPlanData?: PooledPlan;
    isPooledPlan?: boolean;
    isEsa?: boolean;
  },
  csms?: ClientSuccessManagerDto
) => {
  if (!config.isPooledPlan && config.planDesignData?.data) {
    const planDesignTypeName =
      config.planDesignData?.data?.overview?.planDesignTypeName;

    const data = {
      ...config.planDesignData.data,
      csms: csms || [],
      isEsa: config.isEsa,
      isTpaUser: userService.isTpaUser()
    };

    return planDesignTypeName
      ? new templates[planDesignTypeName.split(' ').join('')](data)
      : new DefaultTemplate(data);
  } else if (config.pooledPlanData?.pooledPlanDefault?.planDesign) {
    return new DefaultTemplate({
      ...config.pooledPlanData.pooledPlanDefault?.planDesign,
      csms: csms || [],
      isPooledPlan: true,
      isTpaUser: userService.isTpaUser()
    });
  }

  return {};
};
