import { useSnackbar } from '@/contexts/SnackBarContext';
import TickerSelect, {
  TickerLookupSearchResult
} from '@/routes/ops/investments/investment-table/TickerSelect.component';
import { Typography } from '@mui/material';

import { times } from 'lodash';
import React, { useMemo } from 'react';

import InvestmentTable, {
  InvestmentTableProps
} from './InvestmentTable.component';
import NumericEditor from './numericEditor';

const NUMBER_OF_TICKERS = 30;
const NUMBER_OF_MODELS = 10;
const TICKER_WIDTH = 140;

type AllocationTableProps = Pick<
  InvestmentTableProps,
  'gridRef' | 'defaultColDef' | 'onGridReady' | 'alignedGrids'
> & {
  lookupTickerCallback?: (
    ticker: string,
    numberOfResults: number
  ) => Promise<TickerLookupSearchResult[]>;
  setFieldValue: (field: string, value: any) => void;
  models: any[];
  allocations: any[];
  readonly: boolean;
};

const AllocationTable: React.FC<AllocationTableProps> = props => {
  const {
    gridRef,
    defaultColDef,
    onGridReady,
    alignedGrids,
    lookupTickerCallback,
    readonly,
    setFieldValue,
    models,
    allocations
  } = props;

  const snackbar = useSnackbar();

  const onEditAllocationCellEditRequest = (params: any) => {
    if (params.column.colId === 'symbol') return;
    const rowIndex = params.context.allocations.findIndex(
      (row: any) => row.symbol === params.data.symbol
    );
    params.context.setFieldValue(
      `allocations[${rowIndex}][${params.column.colId}]`,
      Number(params.value)
    );
  };

  const columnDefs = useMemo(() => {
    return [
      {
        cellEditor: TickerSelect,
        cellEditorParams: {
          lookupTickerCallback,
          onCommit: (params: any) => {
            if (params.value && !params.remove) {
              if (
                allocations.find(
                  (allocation: any) => allocation.symbol === params.value.symbol
                )
              ) {
                snackbar.showSnackbar({
                  message: 'Ticker already exists',
                  severity: 'error'
                });
              } else {
                setFieldValue(
                  `allocations[${params.id}].symbol`,
                  params.value.symbol
                );
                setFieldValue(
                  `allocations[${params.id}].cusip`,
                  params.value.cusip
                );
                setFieldValue(
                  `allocations[${params.id}].fundName`,
                  params.value.fundName
                );
              }
            } else {
              setFieldValue(
                `allocations`,
                allocations.filter((a: any) => a.symbol !== params.value.symbol)
              );
            }
          }
        },
        cellEditorPopup: true,
        cellRenderer: (params: any) => {
          const editable = params.colDef.editable(params);
          if (params.value) return params.value;

          return (
            <Typography color='rgba(0, 0, 0, 0.6)'>
              {editable ? 'Enter Ticker' : ''}
            </Typography>
          );
        },
        comparator: (valueA?: string, valueB?: string) =>
          !valueA || !valueB ? 0 : valueA.localeCompare(valueB),
        editable: (params: any) => {
          return (
            !readonly &&
            (!!params.context.allocations[params.node.rowIndex] ||
              !!params.context.allocations[params.node.rowIndex - 1] ||
              params.node.rowIndex === 0)
          );
        },
        field: 'symbol',
        flex: 1,
        headerName: '',
        maxWidth: TICKER_WIDTH,
        minWidth: TICKER_WIDTH,
        pinned: true,
        sortable: true,
        width: TICKER_WIDTH
      },
      {
        cellRenderer: (params: any) => {
          return (
            <Typography
              color='rgba(0, 0, 0, 0.6)'
              overflow='hidden'
              textOverflow='ellipsis'
              title={params.value || ''}>
              {params.value || ' '}
            </Typography>
          );
        },
        field: 'fundName',
        flex: 2,
        headerName: '',
        minWidth: 235,
        pinned: true
      },

      ...times(
        models.length + 1 > NUMBER_OF_MODELS
          ? models.length + 1
          : NUMBER_OF_MODELS,
        columnIndex => {
          const field = `${columnIndex}`;
          return {
            cellEditor: NumericEditor,
            cellEditorParams: (params: any) => ({
              snackbar,
              value:
                params.context.allocations[params.node.rowIndex]?.[columnIndex]
            }),
            cellRenderer: (params: any) => {
              const editable = params.colDef.editable(params);

              return editable ? (
                params.valueFormatted
              ) : (
                <Typography color='rgba(0, 0, 0, 0.6)'>
                  {params.valueFormatted}
                </Typography>
              );
            },
            editable: (params: any) => {
              return (
                !readonly &&
                !!params.context.models[columnIndex] &&
                !!params.context.allocations[params.node.rowIndex]
              );
            },
            field,
            flex: 0.75,
            headerName: '',
            minWidth: 165,
            sortable: false,
            valueFormatter: (params: any) => {
              const editable = params.colDef.editable(params);
              return editable || readonly
                ? `${params.data[field] || '0'}%`
                : '%';
            }
          };
        }
      )
    ];
  }, [
    lookupTickerCallback,
    snackbar,
    models.length,
    allocations,
    setFieldValue,
    readonly
  ]);

  const allocationRows = useMemo(() => {
    const neededAllocationsLength = NUMBER_OF_TICKERS - allocations.length;
    return [
      ...allocations,
      ...times(
        neededAllocationsLength > 0 ? neededAllocationsLength : 1,
        () => {
          return {};
        }
      )
    ];
  }, [allocations]);

  return (
    <InvestmentTable
      alignedGrids={alignedGrids}
      columnDefs={columnDefs}
      context={{
        allocations,
        models,
        setFieldValue
      }}
      defaultColDef={defaultColDef}
      gridRef={gridRef}
      headerHeight={0}
      onCellEditRequest={onEditAllocationCellEditRequest}
      onGridReady={onGridReady}
      rowData={allocationRows}
    />
  );
};

export default AllocationTable;
