import CircularLoading from '@/components/circular-loading';
import {
  HceEmployee,
  TestExecutionResultsDto
} from '@/models/YearEndTestingDTO.model';
import { PlanService } from '@/services/Plan.service';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';

import { FieldArray, useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { HceEmployeeLine } from './HceEmployeeLine.component';

type HceFieldProps = {
  label: string;
};

type HceFieldParams = {
  sponsorPlanId: string;
};

const StyledTableCell = styled(TableCell)(() => ({
  borderBottom: 'none'
}));

export const HceField: FC<HceFieldProps> = props => {
  const form = useFormikContext<TestExecutionResultsDto>();
  const params = useParams<HceFieldParams>();

  const planDesignQuery = useQuery(
    ['PlanService.getPlanDesignById', params.sponsorPlanId],
    () => PlanService.getPlanDesignById(params.sponsorPlanId),
    {
      enabled: Boolean(params.sponsorPlanId),
      staleTime: Infinity
    }
  );

  return (
    <FieldArray name={`${props.label}.additionalData.hce`}>
      {({ remove, push }) => (
        <>
          <TableRow>
            <StyledTableCell>
              <Typography color='textPrimary' variant='body2'>
                HCE
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              {planDesignQuery.isFetching ? (
                <CircularLoading />
              ) : (
                <Button
                  data-testid={`${props.label}-add-hce-btn`}
                  onClick={() =>
                    push({
                      employeeInputValue: '',
                      value: ''
                    })
                  }
                  startIcon={<AddCircleOutlineIcon />}
                  variant='text'>
                  Add Employee
                </Button>
              )}
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>
              <Stack
                alignItems='flex-start'
                data-testid={`${props.label}-hce-array`}
                gap={2}>
                {form.values[props.label].additionalData.hce?.map(
                  (emp: HceEmployee, index: number) => (
                    <HceEmployeeLine
                      fundingSources={
                        planDesignQuery.data?.data.vestingPreferences
                          ?.fundingSources
                      }
                      index={index}
                      key={index}
                      label={props.label}
                      removeLine={() => remove(index)}
                    />
                  )
                )}
              </Stack>
            </StyledTableCell>
          </TableRow>
        </>
      )}
    </FieldArray>
  );
};

HceField.displayName = 'HceField';
