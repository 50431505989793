import { ParentAccountDto } from '@/models/ops/ParentAccountDTO.model';
import ParentAccountService from '@/services/ops/accounts/ParentAccount.service';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CustodianId } from '@vestwell-sub-accounting/models/common/CustodianId';

export const useGetParentAccountByCustodianId = (
  custodianAccountNumber?: string
): UseQueryResult<ParentAccountDto | undefined> => {
  return useQuery(
    ['ParentAccountService.get', custodianAccountNumber, CustodianId.matrix],
    () =>
      custodianAccountNumber === undefined
        ? undefined
        : ParentAccountService.get({
            custodianAccountNumber,
            custodianId: CustodianId.matrix
          }),
    {
      enabled: Boolean(custodianAccountNumber)
    }
  );
};
