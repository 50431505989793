import Card, { CardContent } from '@/components/card';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import ParentAccountService from '@/services/ops/accounts/ParentAccount.service';
import BalanceService from '@/services/ops/balances/Balance.service';
import CurrentDatesAndWindowsService from '@/services/ops/current-dates-and-windows/CurrentDatesAndWindows.service';
import ReconExceptionService from '@/services/ops/recon-exceptions/ReconException.service';
import formatters from '@/utils/Formatters';
import { Divider, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';
import { CustodianId } from '@vestwell-sub-accounting/models/common/CustodianId';
import { BreakageStatus } from '@vestwell-sub-accounting/models/recon/BreakageStatus';

import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { FC, useContext, useMemo } from 'react';

import { AlertContext } from '../../AlertContext';
import { CardHeader } from '../common/CardHeader.component';

export const CashTransferRejectedExpandedFromAccountBalances: FC = () => {
  const alert = useContext(AlertContext);
  const { showSnackbar } = useSnackbar();

  const request = alert.details?.request ?? {};

  const fromSubAccount = request.fromSubAccount ?? {};
  const fromParentAccount = fromSubAccount?.parentAccount ?? {};

  const getCurrentDatesAndWindowsQuery = useQuery(
    ['CurrentDatesAndWindowsService.get'],
    async () => {
      const response = await CurrentDatesAndWindowsService.get();

      return {
        tradingDate: dayjs(response.tradingDate)
      };
    },
    {
      cacheTime: 0,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch current trade date and processing windows: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const searchReconExceptionQuery = useQuery(
    [
      'ReconExceptionService.search',
      {
        page: 1,
        pageSize: 0,
        parentAccountId: fromParentAccount.id,
        status: [BreakageStatus.Open]
      }
    ],
    async () => {
      const reconExceptions = await ReconExceptionService.search({
        page: 1,
        pageSize: 0,
        parentAccountId: fromParentAccount.id,
        status: [BreakageStatus.Open]
      });
      return reconExceptions;
    }
  );

  const minDate = useMemo(() => {
    if (!searchReconExceptionQuery.data?.results.length) {
      return null;
    }
    let initDate = new Date(
      searchReconExceptionQuery.data.results[0].exceptionDate
    ).getTime();

    for (const exception of searchReconExceptionQuery.data.results) {
      const date = new Date(exception.exceptionDate).getTime();
      if (date < initDate) {
        initDate = date;
      }
    }

    return dayjs(initDate);
  }, [searchReconExceptionQuery.data]);

  const maxDate = useMemo(() => {
    if (!searchReconExceptionQuery.data?.results.length) {
      return null;
    }
    let initDate = new Date(
      searchReconExceptionQuery.data.results[0].exceptionDate
    ).getTime();

    for (const exception of searchReconExceptionQuery.data.results) {
      const date = new Date(exception.exceptionDate).getTime();
      if (date > initDate) {
        initDate = date;
      }
    }

    return dayjs(initDate);
  }, [searchReconExceptionQuery.data]);

  const getParentToCustodianCashBalancesQuery = useQuery(
    [
      'ParentAccountService.getParentToCustodianCashBalances',
      getCurrentDatesAndWindowsQuery.data?.tradingDate,
      fromParentAccount.id
    ],
    async () => {
      const response =
        await ParentAccountService.getParentToCustodianCashBalances({
          endDate: maxDate.add(7, 'day').format('YYYY-MM-DD'),
          parentAccountId: fromParentAccount.id,
          startDate: minDate.subtract(7, 'day').format('YYYY-MM-DD')
        });
      if (response.results.length === 0) {
        return null;
      }
      return response.results[response.results.length - 1];
    },
    {
      enabled:
        Boolean(getCurrentDatesAndWindowsQuery.data?.tradingDate) &&
        Boolean(fromParentAccount.id) &&
        Boolean(searchReconExceptionQuery.data?.results.length > 0),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getParentAccountQuery = useQuery(
    ['ParentAccountService.get', fromParentAccount.custodianAccountNumber],
    () =>
      ParentAccountService.get({
        custodianAccountNumber: fromParentAccount.custodianAccountNumber,
        custodianId: CustodianId.matrix
      }),
    {
      enabled: Boolean(fromParentAccount.custodianAccountNumber),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Parent account request failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getSubAccountTradeBalanceQuery = useQuery(
    ['BalanceService.get', fromSubAccount.id],
    () =>
      BalanceService.get(fromSubAccount.id, {
        dateType: PositionDateType.Trade
      }),
    {
      enabled: Boolean(fromSubAccount.id),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getSubaccountSettlementBalanceQuery = useQuery(
    ['BalanceService.get', fromSubAccount.id],
    () =>
      BalanceService.get(fromSubAccount.id, {
        dateType: PositionDateType.Settlement
      }),
    {
      enabled: Boolean(fromSubAccount.id),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  return (
    <Card size='small'>
      <CardHeader title='From Account Balances' />
      <Divider />
      <CardContent>
        <Stack spacing={5}>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Stack flex={1} spacing={1}>
              <div>
                <Typography variant='subtitle1'>Parent Account</Typography>
                <Typography variant='subtitle2'>
                  {fromParentAccount?.accountName}
                </Typography>
              </div>
              <TextStack direction='column'>
                <TextStackItem>
                  <TextLabel>Current Balance</TextLabel>
                  <TextValue>
                    {getParentToCustodianCashBalancesQuery.data
                      ?.tradeDateBalance
                      ? formatters.formatDollars(
                          getParentToCustodianCashBalancesQuery.data
                            ?.tradeDateBalance
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Settlement Balance</TextLabel>
                  <TextValue>
                    {getParentToCustodianCashBalancesQuery.data
                      ?.settlementDateBalance
                      ? formatters.formatDollars(
                          getParentToCustodianCashBalancesQuery.data
                            ?.settlementDateBalance
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
              </TextStack>
              <Typography variant='caption'>
                {`As of date: ${getCurrentDatesAndWindowsQuery.data?.tradingDate.format(
                  'MM/DD/YYYY'
                )}`}
              </Typography>
            </Stack>
            <Divider flexItem orientation='vertical' />
            <Stack flex={1} spacing={1}>
              <div>
                <Typography variant='subtitle1'>Sub Account</Typography>
                <Typography variant='subtitle2'>
                  {formatters.displayCase(fromSubAccount?.accountType)}
                </Typography>
              </div>
              <TextStack direction='column'>
                <TextStackItem>
                  <TextLabel>Current Balance</TextLabel>
                  <TextValue>
                    {getSubAccountTradeBalanceQuery.data?.cash?.confirmed
                      ? formatters.formatDollars(
                          getSubAccountTradeBalanceQuery.data.cash.confirmed
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Settlement Balance</TextLabel>
                  <TextValue>
                    {getSubaccountSettlementBalanceQuery.data?.cash?.confirmed
                      ? formatters.formatDollars(
                          getSubaccountSettlementBalanceQuery.data.cash
                            .confirmed
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
              </TextStack>
              <Typography variant='caption'>
                {`As of date: ${getCurrentDatesAndWindowsQuery.data?.tradingDate.format(
                  'MM/DD/YYYY'
                )}`}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={1}>
            <Typography variant='subtitle1'>Custodian</Typography>
            <TextStack direction='column'>
              <TextStackItem>
                <TextLabel>Cash Balance</TextLabel>
                <TextValue>
                  {getParentAccountQuery.data?.cashBalance
                    ? formatters.formatDollars(
                        getParentAccountQuery.data?.cashBalance
                      )
                    : ''}
                </TextValue>
              </TextStackItem>
            </TextStack>
            <Typography variant='caption'>
              {`Effective date: ${
                getParentToCustodianCashBalancesQuery.data
                  ? formatters.formatFromIsoDateCustom(
                      getParentToCustodianCashBalancesQuery.data.effectiveDate,
                      'MM/DD/YYYY'
                    )
                  : '-'
              }`}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

CashTransferRejectedExpandedFromAccountBalances.displayName =
  'CashTransferRejectedExpandedFromAccountBalances';
