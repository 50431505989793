import { CellComponentProps } from '@/components/collapsible-table';
import formatters from '@/utils/Formatters';
import { Box, TableCell } from '@mui/material';

import React from 'react';

const ForfeitureTrackerTableCell: React.FunctionComponent<
  CellComponentProps
> = (props: CellComponentProps) => {
  const { row, column } = props;

  let field = (
    <Box sx={{ fontSize: theme => theme.spacing(2) }}>{row[column.field]}</Box>
  );

  if (column.field === 'amount') {
    field = (
      <Box sx={{ fontSize: theme => theme.spacing(2) }}>
        {formatters.formatDollars(row[column.field])}
      </Box>
    );
  }

  if (column.field === 'status') {
    field = (
      <Box
        component='span'
        sx={{
          backgroundColor: '#EEEEEE',
          borderRadius: '28px',
          color: '#757575',
          fontSize: theme => theme.spacing(2),
          fontWeight: 500,
          padding: '5px',
          whiteSpace: 'nowrap',
          width: 'auto'
        }}>
        {row[column.field]}
      </Box>
    );
  }

  return (
    <TableCell component='th' scope='row'>
      {field}
    </TableCell>
  );
};

export default ForfeitureTrackerTableCell;
