import CollapsibleTable from '@/components/collapsible-table';
import LinearLoading from '@/components/linear-loading';
import SimpleDropdown from '@/components/simple-dropdown';
import SimpleTextarea from '@/components/simple-textarea';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { UpdateBillingPeriodDto } from '@/models/ops/fees/BillingDTO.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Link as MuiLink,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import BillingDetailsTableCell from './BillingDetailsTableCell.component';

const columns = [
  { field: 'plan', headerName: 'Plan', width: 130 },
  { field: 'status', headerName: 'Status', width: 130 },
  { field: 'requestedAmount', headerName: 'Requested Amount', width: 130 },
  { field: 'collectedAmount', headerName: 'Collected Amount', width: 130 }
];

const FeePreviewRoute: React.FC = () => {
  const params = useParams();

  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const dialog = useDialog();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [planId, setPlanId] = useState(undefined);
  const [status, setStatus] = useState(undefined);

  const billingPeriodQuery = useQuery(
    ['PlanService.billingPeriodId', params.billingPeriodId],
    () => PlanService.getBillingPeriodById(+params.billingPeriodId),
    {
      staleTime: Infinity
    }
  );

  const downloadDocumentQuery = useQuery(
    ['ActionCenterService.getDocument', billingPeriodQuery.data?.documentId],
    () => {
      return ActionCenterService.getDocument(
        billingPeriodQuery.data?.documentId
      );
    },
    {
      enabled: !!billingPeriodQuery.data?.documentId,
      staleTime: Infinity
    }
  );

  const billingFeesQuery = useQuery(
    [
      'ProgramService.getBillingFees',
      +params.billingPeriodId,
      rowsPerPage,
      pageNumber,
      planId,
      status
    ],
    async () => {
      return PlanService.getBillingFees(
        +params.billingPeriodId,
        rowsPerPage,
        pageNumber,
        planId,
        status
      );
    },
    {
      enabled: !!+params.billingPeriodId
    }
  );

  const submitRequest = useMutation(
    ['PlanService.submitRequest'],
    () => {
      return PlanService.submitBillingRequest(billingPeriodQuery.data?.id);
    },
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Failed to submit a billing period request!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        snackbar.showSnackbar({
          message: 'Successfully submitted a new billing period!',
          severity: 'success'
        });

        queryClient.invalidateQueries();
      }
    }
  );

  const updateBillingPeriod = useMutation(
    ['PlanService.updateBillingPeriod'],
    (dto: UpdateBillingPeriodDto) => {
      return PlanService.updateBillingPeriod(billingPeriodQuery.data?.id, dto);
    },
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Failed to update a billing period request!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        snackbar.showSnackbar({
          message: 'Successfully updated a billing period!',
          severity: 'success'
        });

        queryClient.invalidateQueries();
      }
    }
  );

  const openEditNotesModal = useCallback(
    () =>
      dialog.openDialog({
        actionButtons: {
          cancelButton: {
            children: 'Cancel'
          },
          submitButton: {
            children: 'Save'
          }
        },
        onSubmit: values => {
          updateBillingPeriod.mutate({ notes: values.notes });
        },
        steps: [
          {
            fields: {
              notes: {
                component: (
                  <SimpleTextarea label='' name='notes' placeholder='Notes' />
                ),
                initialValue: billingPeriodQuery.data?.notes,
                label: 'Notes'
              }
            },
            title: 'Edit Notes'
          }
        ]
      }),
    [billingPeriodQuery.data]
  );

  const onClickDownloadDoc = data => {
    InMemoryFileDownloadService.triggerFileDownload(
      data?.base64Data,
      data?.originalFileName
    );
    return null;
  };

  const handleSubmit = values => {
    setPlanId(values.planId || undefined);
    setStatus(values.status || undefined);
    setPageNumber(1);
  };

  const onSubmitClick = useCallback(() => {
    submitRequest.mutateAsync();
  }, []);

  return billingPeriodQuery.isFetching || downloadDocumentQuery.isFetching ? (
    <LinearLoading />
  ) : (
    <>
      <Stack
        alignItems='center'
        direction='row'
        divider={<span>/</span>}
        spacing={2}>
        <Link to='/ops/fee-management'>
          <Typography color={blue[500]} variant='body2'>
            Fees & Billings
          </Typography>
        </Link>
        <Typography variant='body2'>Submission Details</Typography>
      </Stack>
      <Stack alignItems='center' direction='row' spacing={2}>
        <Typography variant='h4'>
          {' '}
          {billingPeriodQuery.data?.periodStartDate
            ? `${formatters.formatFromIsoDateCustom(
                billingPeriodQuery.data?.periodStartDate,
                'MM/DD/YYYY'
              )} - ${formatters.formatFromIsoDateCustom(
                billingPeriodQuery.data?.periodEndDate,
                'MM/DD/YYYY'
              )}`
            : formatters.formatFromIsoDateCustom(
                billingPeriodQuery.data?.periodEndDate,
                'MM/DD/YYYY'
              )}{' '}
        </Typography>
      </Stack>
      {!billingPeriodQuery.data?.submittedAt &&
        !billingPeriodQuery.data?.isDone && (
          <LoadingButton
            loading={submitRequest.isLoading}
            onClick={onSubmitClick}
            sx={{ my: 3 }}
            variant='contained'>
            SUBMIT THIS BILLING PERIOD
          </LoadingButton>
        )}

      {billingPeriodQuery.data?.submittedAt &&
        !billingPeriodQuery.data?.isDone && (
          <LoadingButton
            loading={updateBillingPeriod.isLoading}
            onClick={() => updateBillingPeriod.mutate({ isDone: true })}
            sx={{ mt: 3 }}
            variant='outlined'>
            MARK RESOLVED
          </LoadingButton>
        )}
      <Card sx={{ mt: 3 }} variant='outlined'>
        <CardContent>
          <Stack
            direction='row'
            divider={<Divider flexItem orientation='vertical' />}
            spacing={1}>
            <TextStack direction='column' spacing={1}>
              <TextStackItem>
                <TextLabel>Fee Type</TextLabel>
                <TextValue>{billingPeriodQuery.data?.purpose}</TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Total Amount</TextLabel>
                <TextValue>
                  {formatters.formatDollars(
                    billingPeriodQuery.data?.requestedAmount
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Collected Amount</TextLabel>
                <TextValue>{EMPTY_FIELD_PLACEHOLDER}</TextValue>
              </TextStackItem>
            </TextStack>
            <TextStack direction='column' spacing={1}>
              <TextStackItem>
                <TextLabel>ID</TextLabel>
                <TextValue>{billingPeriodQuery.data?.id}</TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Submitted</TextLabel>
                <TextValue>
                  {formatters.formatFromIsoDateCustom(
                    billingPeriodQuery.data?.submittedAt,
                    'MM/DD/YYYY'
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Uploaded File</TextLabel>
                <TextValue>
                  <MuiLink
                    component='button'
                    data-testid='download-billing-file-link'
                    onClick={() =>
                      onClickDownloadDoc(downloadDocumentQuery.data)
                    }
                    textAlign='left'
                    underline='hover'>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <Typography
                        overflow='hidden'
                        textOverflow='ellipsis'
                        whiteSpace='nowrap'
                        width={350}>
                        {downloadDocumentQuery.data?.originalFileName}
                      </Typography>{' '}
                      <FileDownloadOutlinedIcon fontSize='small' />
                    </Stack>
                  </MuiLink>
                </TextValue>
              </TextStackItem>
            </TextStack>
            <Box>
              <Stack mb={2}>
                <Typography variant='body2'>Note</Typography>
                <Typography variant='caption'>
                  {billingPeriodQuery.data?.notes}
                </Typography>
              </Stack>
              <LoadingButton onClick={openEditNotesModal}>EDIT</LoadingButton>
            </Box>
          </Stack>
        </CardContent>
      </Card>

      <Paper sx={{ my: 2 }} variant='outlined'>
        <div>
          <Typography sx={{ m: 2 }} variant='h5'>
            Billing Details
          </Typography>
          <Divider />
          <Formik
            initialValues={{
              planId: '',
              status: ''
            }}
            onSubmit={handleSubmit}>
            {formik => (
              <Form data-testid='query-form'>
                <Stack direction='row' maxWidth='50%' mx={2} my={2} spacing={2}>
                  <TextField
                    data-testid='search-plans'
                    fullWidth
                    name='planId'
                    onChange={formik.handleChange}
                    placeholder='Search by Plan ID'
                    title='Search by Plan ID'
                    value={formik.values.planId}
                    variant='outlined'
                  />
                  <SimpleDropdown
                    fieldId='status'
                    fieldName='Status'
                    fieldOptions={[
                      { option: 'All', value: '' },
                      { option: 'Not yet submitted', value: 'Created' },
                      { option: 'Processing', value: 'Processing' },
                      { option: 'Failed', value: 'Failed' },
                      { option: 'Complete', value: 'Complete' }
                    ]}
                    onChange={event => {
                      formik.setFieldValue('status', event.target.value);
                      setPageNumber(1);
                    }}
                  />
                  <Button
                    data-testid='billing-apply-button'
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </div>
        {billingPeriodQuery.isLoading ? (
          <LinearLoading />
        ) : (
          <CollapsibleTable
            cellComponent={BillingDetailsTableCell}
            columns={columns}
            data-testid='billing-details-table-component'
            pager={{
              metaCount: billingFeesQuery.data?.meta.count,
              onPageNumberChanged: (zeroIndexedPageNumber: number) => {
                return setPageNumber(zeroIndexedPageNumber + 1);
              },
              onRowsPerPageChanged: (newRowsPerPage: number) => {
                return setRowsPerPage(newRowsPerPage);
              },
              pageNumber: pageNumber - 1,
              rowsPerPage
            }}
            tableData={billingFeesQuery.data?.data || []}
          />
        )}
      </Paper>
    </>
  );
};

export default FeePreviewRoute;
