import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Field, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';

interface ForfeitureInfoProps {
  sponsorPlanId: number;
}

const ValueChangeListener = () => {
  const {
    values,
    submitForm
  }: { values: Record<string, any>; submitForm: () => Promise<any> } =
    useFormikContext() as {
      values: Record<string, any>;
      submitForm: () => Promise<any>;
    };

  useEffect(() => {
    if (values.date) {
      submitForm();
    }
  }, [values.date, submitForm]);

  return null;
};

const ForfeitureInfo: React.FunctionComponent<ForfeitureInfoProps> = (
  props: ForfeitureInfoProps
) => {
  const { sponsorPlanId } = props;
  const [date, setDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));

  const planQuery = useQuery(
    ['PlanService.getPlanById', sponsorPlanId?.toString()],
    () => {
      return PlanService.getPlanById(sponsorPlanId);
    },
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const cashBalanceQuery = useQuery(
    ['PlanService.getCashBalance', sponsorPlanId, date],
    () => {
      return PlanService.getCashBalance(sponsorPlanId, date);
    },
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const forfeitureBalance = useMemo(
    () =>
      cashBalanceQuery?.data?.find(d => d.accountType === 'forfeiture')
        ?.balance || 0,
    [cashBalanceQuery?.data]
  );

  const validateDate = (value: Date) => {
    if (!value) return 'Required';
    else if (value.toString() === 'Invalid Date') return 'Wrong date value!';
    else if (
      value &&
      planQuery.isSuccess &&
      dayjs(value).isBefore(planQuery.data?.data.attributes.effectiveDate)
    )
      return `Date cannot be before ${planQuery.data?.data.attributes.effectiveDate}`;
    else if (dayjs(value).isAfter(Date.now())) {
      return 'Date cannot be in the future.';
    }

    return null;
  };

  return (
    <Box data-testid='plan-info-card' sx={{ display: 'flex' }}>
      <Card
        elevation={0}
        sx={{
          justifyContent: 'center',
          maxHeight: '100%'
        }}>
        <CardContent sx={{ alignItems: 'center', display: 'flex' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Formik
              initialValues={{
                date: dayjs(Date.now()).format('YYYY-MM-DD')
              }}
              onSubmit={e => {
                setDate(dayjs(e.date).format('YYYY-MM-DD'));
              }}>
              {formikProps => {
                return (
                  <Form data-testid='filter-form'>
                    <Stack alignItems='flex-start' justifyContent='flex-start'>
                      <FormControl
                        fullWidth
                        size='small'
                        sx={{
                          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
                            WebkitTransform: 'translate(14px, 8px) scale(1)',
                            transform: 'translate(14px, 8px) scale(1)'
                          }
                        }}>
                        <Field
                          as={DatePicker}
                          displayEmpty
                          input={<OutlinedInput label='As-of Date' notched />}
                          label='As-of Date'
                          name='date'
                          onChange={(newValue: Date | null) => {
                            formikProps.setFieldValue('date', newValue);
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              data-testid='end-date-input'
                              error={Boolean(formikProps.errors.date)}
                              sx={{
                                '& .MuiInputBase-input': {
                                  py: 1
                                },
                                py: 0
                              }}
                            />
                          )}
                          validate={validateDate}
                          value={dayjs(formikProps.values.date)}
                        />
                        <FormHelperText error>
                          {formikProps.errors.date}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <ValueChangeListener />
                  </Form>
                );
              }}
            </Formik>
          </LocalizationProvider>
          <Box
            sx={{
              borderLeft: theme => `1px solid ${theme.palette.grey[300]}`,
              marginLeft: '24px',
              paddingLeft: '24px'
            }}>
            <Typography
              color={theme => theme.palette.grey[700]}
              variant='caption'>
              Total Balance
            </Typography>
            <Typography variant='h4'>
              {formatters.formatDollars(forfeitureBalance)}{' '}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ForfeitureInfo;
