import DatePicker from '@/components/date-picker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import useSponsor from '@/hooks/useSponsor';
import {
  DeconversionStatus,
  PlanDeconversionDto,
  UpdateDeconversionDto
} from '@/models/PlanDeconversionDTO.model';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { CheckCircle, Error, ErrorOutline, List } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Tooltip
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import { blue } from '@mui/material/colors';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

import DisbursementInstructions from './DisbursementInstructions.component';

interface ChecklistProps {
  sponsorPlanId: number;
  deconversion?: PlanDeconversionDto;
  hasSuccessRequest?: boolean;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: 'auto',
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

interface DeconversionDateProps {
  title: string;
  date?: string;
  handleSubmit: (date?: string) => void;
  isLoading: boolean;
  disableAction?: boolean;
  validation?: any;
}
const DeconversionDate: React.FC<DeconversionDateProps> = ({
  title,
  date,
  handleSubmit,
  isLoading,
  disableAction,
  validation
}: DeconversionDateProps) => {
  return (
    <>
      <Typography sx={{ mb: 3 }} variant='h6'>
        {title}
      </Typography>
      <Formik
        initialValues={{
          date: date
        }}
        key={`deconversion-date-${title}-formik`}
        onSubmit={values => {
          if (values?.date)
            handleSubmit(
              formatters.formatFromIsoDateCustom(values.date, 'MM/DD/YYYY')
            );
        }}
        validationSchema={validation}>
        {formikProps => {
          return (
            <Form
              data-testid={`deconversion-date-${title}-form`}
              key={`deconversion-date-${title}-form`}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <FormControl variant='outlined'>
                  <Field
                    as={DatePicker}
                    autoComplete='off'
                    data-testid={`deconversion-${title}-input`}
                    key={title}
                    label={title}
                    name='date'
                    size='small'
                    sx={{ width: 200 }}
                    variant='outlined'
                  />
                  <FormHelperText error>
                    {formikProps.errors.date}
                  </FormHelperText>
                </FormControl>

                <LoadingButton
                  data-testid={`deconversion-${title}-button`}
                  disabled={disableAction}
                  loading={isLoading}
                  sx={{ height: 30, width: 70 }}
                  type='submit'
                  variant='contained'>
                  Save
                </LoadingButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const Checklist: React.FunctionComponent<ChecklistProps> = ({
  sponsorPlanId,
  deconversion,
  hasSuccessRequest
}: ChecklistProps) => {
  const userInfo = userService.getUser();
  const isSubmittingUser = userInfo?.nickname === deconversion?.submittedBy?.id;

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (deconversion?.status) {
      const pendingOrComplete = [
        DeconversionStatus.PendingLiquidation,
        DeconversionStatus.Complete
      ].includes(deconversion.status);
      setExpanded(!pendingOrComplete);
    }
  }, [deconversion?.status]);

  const [submitBtnClicked, setSubmitBtnClick] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [tabContent, setTabContent] = useState<{
    index: number;
    content: JSX.Element | null;
  }>({
    content: null,
    index: -1
  });
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const { data: planDesignData } = useQuery(
    [`PlanService.getPlanDesignById(${sponsorPlanId})`],
    () => {
      return PlanService.getPlanDesignById(sponsorPlanId);
    },
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const sponsor = useSponsor(sponsorPlanId.toString());
  const isSponsorAddressSet = useMemo(() => {
    return (
      !!sponsor.data?.data.attributes.address.address1.length &&
      !!sponsor.data?.data.attributes.address.city.length &&
      !!sponsor.data?.data.attributes.address.state.length &&
      !!sponsor.data?.data.attributes.address.zip.length
    );
  }, [sponsor.data?.data.attributes.address]);

  const { mutateAsync: saveDate, isLoading: dateLoading } = useMutation(
    [`PlanService.updateBlackoutDate ${sponsorPlanId}`],
    (data: { date: string; key: 'liquidation' | 'blackout' }) =>
      PlanService.updateDeconversionDate(
        sponsorPlanId as number,
        data.date,
        data.key
      ),
    {
      onError: () => {
        showSnackbar({ message: 'Failed!', severity: 'error' });
      },
      onSuccess: async () => {
        queryClient.fetchQuery([
          `PlanService.getPlanDesignById(${sponsorPlanId})`
        ]);
        queryClient.fetchQuery([
          `PlanService.getPlanDeconversion(${sponsorPlanId})`
        ]);
        showSnackbar({ message: 'Successfully updated!', severity: 'success' });
      }
    }
  );

  const { mutateAsync: updateDeconversion, isLoading: deconversionLoading } =
    useMutation(
      [`PlanService.updateDeconversion ${sponsorPlanId}`],
      (data: UpdateDeconversionDto) => PlanService.updateDeconversion(data),
      {
        onError: (error: any) => {
          if (error?.errors)
            setUpdateError(JSON.stringify(error?.errors, null, 2));

          showSnackbar({
            message: error?.message ?? 'Failed',
            severity: 'error'
          });
        },
        onSuccess: async () => {
          setUpdateError(null);
          queryClient.fetchQuery([
            `PlanService.getPlanDesignById(${sponsorPlanId})`
          ]);
          queryClient.fetchQuery([
            `PlanService.getPlanDeconversion(${sponsorPlanId})`
          ]);

          showSnackbar({
            message: 'Successfully updated!',
            severity: 'success'
          });
        }
      }
    );

  const disburseCashMutation = useMutation(
    [`PlanService.disburseCash ${sponsorPlanId}`],
    () => PlanService.disburseCash(sponsorPlanId),
    {
      onError: (error: any) => {
        showSnackbar({
          message: error?.message ?? 'Failed',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        setUpdateError(null);
        queryClient.fetchQuery([
          `PlanService.getPlanDesignById(${sponsorPlanId})`
        ]);
        queryClient.fetchQuery([
          `PlanService.getPlanDeconversion(${sponsorPlanId})`
        ]);
        queryClient.fetchQuery([
          `PlanService.getDeconversionRequest(${sponsorPlanId})`
        ]);

        showSnackbar({
          message: 'Successfully updated!',
          severity: 'success'
        });
      }
    }
  );

  const deconversionDestinations = useQuery(
    [`PlanService.deconversionDestinations(${sponsorPlanId})`],
    () => {
      return PlanService.getDeconversionDestinations(sponsorPlanId);
    },
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const blackoutStartDate =
    planDesignData?.data.offboardingInformation?.blackoutStartDate;

  const disableActions =
    deconversion?.status &&
    deconversion.status !== DeconversionStatus.NotScheduled;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const tabs = [
    {
      component: (
        <DeconversionDate
          date={blackoutStartDate}
          disableAction={disableActions}
          handleSubmit={date => date && saveDate({ date, key: 'blackout' })}
          isLoading={dateLoading}
          title='Blackout date'
        />
      ),
      data: blackoutStartDate,
      info: blackoutStartDate
        ? `Starting ${formatters.formatFromIsoDateCustom(
            blackoutStartDate,
            'MM/DD/YY'
          )}`
        : 'Required',
      title: 'Blackout date'
    },
    {
      component: (
        <DeconversionDate
          date={deconversion?.liquidationDate}
          disableAction={disableActions}
          handleSubmit={date => date && saveDate({ date, key: 'liquidation' })}
          isLoading={dateLoading}
          title='Liquidation date'
          validation={yup.object({
            date: yup
              .date()
              .required('Date is required')
              .min(dayjs(), 'Liquidation date needs to be in the future!')
          })}
        />
      ),
      data: deconversion?.liquidationDate,
      info: deconversion?.liquidationDate
        ? formatters.formatFromIsoDateCustom(
            deconversion?.liquidationDate,
            'MM/DD/YY'
          )
        : 'Required',
      title: 'Liquidation date'
    },
    {
      component: (
        <DisbursementInstructions
          data={deconversion?.disbursementInstructions}
          deconversionDestinations={deconversionDestinations?.data?.data}
          disableAction={disableActions}
          sponsorPlanId={sponsorPlanId}
        />
      ),
      data: deconversion?.disbursementInstructions,
      info: deconversion?.disbursementInstructions
        ? deconversion?.disbursementInstructions?.deliveryMethod
        : 'Required',
      title: 'Disbursement Instructions'
    }
  ];

  return (
    <Card sx={{ mt: 5 }} variant='outlined'>
      <CardHeader
        action={
          <ExpandMore
            aria-expanded={expanded}
            aria-label='show more'
            expand={expanded}
            onClick={handleExpandClick}>
            <ExpandMoreIcon />
          </ExpandMore>
        }
        sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
        title='Deconversion checklist'
      />
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent
          sx={{
            display: 'flex',
            padding: 0
          }}>
          <Box
            key='deconversion-tabs'
            sx={{
              backgroundColor: 'white',
              borderRight: `1px solid ${theme.palette.grey[300]}`,
              p: 2,
              width: 450
            }}>
            {tabs.map((tab, index) => (
              <React.Fragment
                key={`deconversion-tab-item-${tab.title}-${index}`}>
                <Box
                  key={`deconversion-tab-item-${tab.title}-${index}`}
                  onClick={() =>
                    setTabContent({ content: tab.component, index })
                  }
                  sx={{
                    alignItems: 'center',
                    backgroundColor:
                      index === tabContent.index ? blue[50] : 'white',
                    display: 'flex',
                    p: 1
                  }}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: 1,
                      mr: 2,
                      width: 160
                    }}>
                    {tab.data ? (
                      <CheckCircle color='success' />
                    ) : (
                      <ErrorOutline color='error' />
                    )}
                    <Typography variant='subtitle1'>{tab.title}</Typography>
                  </Box>
                  <Typography variant='subtitle1'>{tab.info}</Typography>
                  <KeyboardArrowRight
                    color='primary'
                    fontSize='small'
                    sx={{ float: 'right', ml: 'auto' }}
                  />
                </Box>
                {(index + 1) % 3 === 0 && <Divider sx={{ my: 1 }} />}
              </React.Fragment>
            ))}
            <Box
              key='deconversion-tab-item-button'
              sx={{ alignItems: 'center', display: 'flex', mb: 1 }}>
              {(!deconversion?.status ||
                (deconversion?.status &&
                  [
                    DeconversionStatus.NotScheduled,
                    DeconversionStatus.PendingApproval
                  ].includes(deconversion.status))) && (
                <Tooltip
                  title={
                    isSponsorAddressSet ? '' : 'Please add sponsor address'
                  }>
                  <span>
                    <LoadingButton
                      disabled={
                        !blackoutStartDate ||
                        !deconversion ||
                        !deconversion.disbursementInstructions ||
                        !deconversion.liquidationDate ||
                        (isSubmittingUser &&
                          deconversion?.status ===
                            DeconversionStatus.PendingApproval) ||
                        !isSponsorAddressSet
                      }
                      fullWidth
                      loading={submitBtnClicked && deconversionLoading}
                      onClick={() => {
                        const dto = {
                          isApproved:
                            deconversion?.status ===
                            DeconversionStatus.PendingApproval,
                          isSubmitted:
                            deconversion?.status ===
                            DeconversionStatus.NotScheduled,
                          sponsorPlanId,
                          status:
                            deconversion?.status ===
                            DeconversionStatus.NotScheduled
                              ? DeconversionStatus.PendingApproval
                              : DeconversionStatus.Scheduled
                        } as UpdateDeconversionDto;

                        setSubmitBtnClick(true);
                        updateDeconversion(dto);
                      }}
                      variant='contained'>
                      {deconversion?.status === DeconversionStatus.NotScheduled
                        ? 'Submit for Approval'
                        : 'Schedule Liquidation Request'}
                    </LoadingButton>
                  </span>
                </Tooltip>
              )}
              {deconversion?.status === DeconversionStatus.PendingLiquidation &&
                deconversion?.disbursementInstructions.deliveryMethod ===
                  'NONE' &&
                hasSuccessRequest &&
                !disburseCashMutation.isSuccess && (
                  <LoadingButton
                    fullWidth
                    loading={disburseCashMutation.isLoading}
                    onClick={() => disburseCashMutation.mutateAsync()}
                    variant='contained'>
                    Disburse Cash
                  </LoadingButton>
                )}
              {updateError && (
                <Tooltip
                  disableFocusListener
                  sx={{ maxWidth: 500 }}
                  title={
                    <React.Fragment>
                      <Typography
                        component='pre'
                        sx={{
                          fontSize: 12,
                          whiteSpace: 'pre-wrap',
                          wordWrap: 'break-word'
                        }}>
                        {updateError}
                      </Typography>
                    </React.Fragment>
                  }>
                  <Error color='error' sx={{ mx: 1 }} />
                </Tooltip>
              )}
            </Box>
            {deconversion?.status &&
              [
                DeconversionStatus.Scheduled,
                DeconversionStatus.PendingApproval
              ].includes(deconversion.status) && (
                <LoadingButton
                  fullWidth
                  loading={!submitBtnClicked && deconversionLoading}
                  onClick={() => {
                    const dto = {
                      sponsorPlanId,
                      status: DeconversionStatus.NotScheduled
                    } as UpdateDeconversionDto;

                    setSubmitBtnClick(false);
                    updateDeconversion(dto);
                  }}
                  sx={{ mb: 1 }}
                  variant='outlined'>
                  {deconversion?.status === DeconversionStatus.PendingApproval
                    ? 'Cancel Approval request'
                    : 'Cancel Scheduled Liquidation'}
                </LoadingButton>
              )}
          </Box>
          {tabContent.content ? (
            <Box key='deconversion-details' sx={{ minWidth: '50%', p: 2 }}>
              {tabContent.content}
            </Box>
          ) : (
            <Box
              key='deconversion-details'
              sx={{ margin: 'auto', textAlign: 'center' }}>
              <List />
              <Typography variant='subtitle1'>
                Select an item to view details
              </Typography>
            </Box>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default Checklist;
