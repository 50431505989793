import AccessControl from '@/components/access-control/AccessControl.component';
import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { ClientSuccessManagerDto } from '@/models/ClientSuccessManager.model';
import {
  PlanDesign,
  PlanDesignDto,
  SafeHarborType
} from '@/models/PlanDesign.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import StatusObserver from '@/routes/plans/plan-detail/PlanTab/formik-observers/StatusObserver';
import {
  getNavigationSchema,
  getUiSchema
} from '@/routes/plans/plan-detail/PlanTab/schemas';
import Validations from '@/routes/plans/plan-detail/PlanTab/validations';
import { PlanService } from '@/services/Plan.service';
import { PlanDesignService } from '@/services/PlanDesign.service';
import VestwellTheme from '@/theme/Vestwell.theme';
import { LockOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  styled,
  Theme,
  Typography,
  TypographyProps
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import dayjs from 'dayjs';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { defer } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { EditScheduleChangeDialog } from '../../../../components/scheduled-changes/EditScheduleChangeDialog.component';
import { EditPlanDesignForm } from './EditPlanDesignForm.component';
import { useGetField } from './getField.hook';
import DefaultTemplate from './plan-design-templates/templates/default-template/DefaultTemplate';
import {
  editPlanDetailsFormToScheduleChangesPayload,
  hideFieldFromTpa,
  planDataToFormValues
} from './utils';

const DEFAULT_VALUE = '--';

const useStyles = makeStyles((theme: Theme) => ({
  actionButtons: {
    backgroundColor: theme.palette.background.default,
    bottom: 0,
    position: 'sticky',
    zIndex: 1
  },
  field: {
    marginTop: '1rem !important'
  },
  greyBorder: {
    border: `1px solid ${theme.palette.grey[300]}`
  },
  greyBorderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)'
  },
  planDesignBox: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`
  }
}));

const StyledTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'active'
})<TypographyProps & { active?: boolean }>(props => ({
  color: props.active ? props.theme.palette.info.main : 'rgba(0, 0, 0, 0.6)',
  cursor: 'pointer',
  marginBottom: '1.125rem'
}));

type PlanDetailsProps = {
  activeTab: string;
  csms?: ClientSuccessManagerDto;
  data: DefaultTemplate;
  isEsa?: boolean;
  isLoading: boolean;
  isPooledPlan: boolean;
  isStateIRA: boolean;
  isTpaUser?: boolean;
  onCloseDialog: () => void;
  onCreateScheduleChange: (values: Record<string, unknown>) => void;
  onOpenDialog: (params: unknown) => void;
  onUpdate: (
    values: Record<string, unknown>,
    pooledPlan: boolean,
    confirmed: boolean
  ) => Promise<void>;
  onUpload: (data: PlanDesign) => void;
  planDesignData: PlanDesignDto;
  sponsorPlanId: number;
  uploadingData: PlanDesign;
};

export const PlanDetails: React.FC<PlanDetailsProps> = props => {
  const classes = useStyles();

  const permissions = useHasPermissions({
    hideFromTPA: dayjs().isAfter(props.data.vestwellStartDate?.output)
  });

  const navigationSchema = useMemo(
    () => getNavigationSchema(props.isEsa),
    [props.isEsa]
  );

  const [editPlanDesignDialogOpen, setEditPlanDesignDialogOpen] =
    useState(false);
  const [activeNavigationItem, setActiveNavigationItem] = useState<string>(
    navigationSchema[props.activeTab][0].key
  );
  const [isUpdatePlanDesignDialogOpen, setIsUpdatePlanDesignDialogOpen] =
    useState(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isDialogInteractionLocked, setIsDialogInteractionLocked] =
    useState(false);

  const [file, setFile] = useState<File | null>();
  const [uploadingError, setUploadingError] = useState('');
  const [isUploading, toggleUploading] = useToggle(false);

  const [unsupportedValues, setUnsupportedValues] = useState<
    Record<string, Record<string, unknown>>
  >({});

  const safeHarborTypes = useQuery<SafeHarborType[]>(
    ['PlanDesignService.getSafeHarborTypes'],
    () => PlanDesignService.getSafeHarborTypes(),
    {
      staleTime: Infinity
    }
  );

  const uiSchema = useMemo(() => getUiSchema(props.isEsa), [props.isEsa]);

  const unsupportedValuesLength = useMemo(() => {
    return Object.entries(unsupportedValues).length;
  }, [unsupportedValues]);

  const onSelect = useCallback(async files => {
    try {
      toggleUploading(true);
      setUploadingError('');

      setFile(files[0]);

      const fileData = await files[0].arrayBuffer();

      const { headers, rows }: { headers: string[]; rows: string[][] } =
        PlanDesignService.parseSheet(fileData);

      const result = PlanDesignService.getPlanDesignFromFile(
        props.sponsorPlanId,
        headers,
        rows,
        safeHarborTypes.data
      );

      props.onUpload(result.supportedValues);

      setUnsupportedValues(result.unsupportedValues);
    } catch (e) {
      setUploadingError(e.message || 'Failed to read your file');
      props.onUpload({});
      setUnsupportedValues({});
    } finally {
      toggleUploading(false);
    }
  }, []);

  const pooledPlanQuery = useQuery(
    ['PlanService.getPooledPlanById', props.sponsorPlanId],
    () => {
      return PlanService.getPooledPlanById(props.sponsorPlanId);
    },
    {
      enabled: props.isPooledPlan
    }
  );

  const initialValues = useMemo<Record<string, string>>(() => {
    return planDataToFormValues(props.data);
  }, [props.data]);

  const hideSectionFromTpa = (sectionName: string): boolean => {
    const forbiddenTpaSections = ['Recordkeeper and Custodian', 'Fee'];
    return forbiddenTpaSections.includes(sectionName);
  };

  const isFieldHidden = (fieldKey: string): boolean => {
    return ['allowAfterTaxContribution'].includes(fieldKey);
  };

  const { getField } = useGetField(
    props.data,
    initialValues,
    props.isPooledPlan,
    props.sponsorPlanId
  );

  const overrideFieldOutput = (fieldKey: string, output: string) => {
    const outputValue =
      fieldKey === 'partnerSystemName' &&
      output === 'Vestwell Sub-Accounting Platform'
        ? 'SIERRA'
        : Number(output) === 0
          ? output
          : output || DEFAULT_VALUE;
    return <Typography>{outputValue}</Typography>;
  };

  useEffect(() => {
    setActiveNavigationItem(navigationSchema[props.activeTab][0].key);
  }, [props.activeTab]);

  const resetUploadState = () => {
    props.onUpload({});
    setFile(null);
    setUnsupportedValues({});
    setUploadingError('');
  };

  const handleSubmit = useCallback(
    async (args: {
      formValues: Record<string, unknown>;
      isPooledPlan: boolean;
      isConfirmed: boolean;
    }) => {
      setIsDialogInteractionLocked(true);
      await props.onUpdate(
        args.formValues,
        args.isPooledPlan,
        args.isConfirmed
      );

      setIsConfirmDialogOpen(false);
      setIsUpdatePlanDesignDialogOpen(false);
      resetUploadState();
      defer(() => {
        setIsDialogInteractionLocked(false);
      });
    },
    [props.onUpdate, setIsConfirmDialogOpen, setIsDialogInteractionLocked]
  );

  const { search } = useLocation();
  const navigate = useNavigate();

  const isModalLocked =
    props.isPooledPlan &&
    (!pooledPlanQuery.data ||
      pooledPlanQuery.data?.pooledPlanStatus === 'confirmed');

  useEffect(() => {
    const launchModalQueryParam = 'edit_plan_design';

    const searchParams = new URLSearchParams(location.search);

    const shouldLaunchModal =
      props.isPooledPlan && !!searchParams.get(launchModalQueryParam);

    if (shouldLaunchModal) {
      if (!isModalLocked) {
        setIsUpdatePlanDesignDialogOpen(true);

        searchParams.delete(launchModalQueryParam);

        defer(() => {
          navigate(
            {
              search: searchParams.toString()
            },
            { replace: true }
          );
        });
      }
    }
  }, [
    props.isPooledPlan,
    search,
    navigate,
    setIsUpdatePlanDesignDialogOpen,
    isModalLocked
  ]);

  return (
    <Box display='flex'>
      <Box
        bgcolor={VestwellTheme.palette.background.paper}
        borderRadius='0.25rem'
        className={classes.greyBorder}
        width='75%'>
        {props.activeTab === 'design' && (
          <>
            <Box
              className={clsx(classes.greyBorderBottom, classes.planDesignBox)}
              display='flex'
              justifyContent='flex-end'>
              {!props.isPooledPlan ? (
                <EditScheduleChangeDialog
                  changeType='plan_design'
                  disableContinue={isUploading}
                  editForm={
                    <EditPlanDesignForm
                      activeNavigationItem={activeNavigationItem}
                      csms={props.csms}
                      data={props.data}
                      file={file}
                      isEsa={props.isEsa}
                      isPooledPlan={props.isPooledPlan}
                      isTpaUser={props.isTpaUser}
                      isUploading={isUploading}
                      onSelectFile={onSelect}
                      planDesignData={props.planDesignData}
                      sponsorPlanId={props.sponsorPlanId}
                      unsupportedValues={unsupportedValues}
                      unsupportedValuesLength={unsupportedValuesLength}
                      uploadingData={props.uploadingData}
                      uploadingError={uploadingError}
                    />
                  }
                  hideDatePicker={props.isStateIRA}
                  initialValues={initialValues}
                  makeScheduledChangesPayload={
                    editPlanDetailsFormToScheduleChangesPayload
                  }
                  makeScheduledChangesPayloadConfig={{
                    planData: props.data,
                    planDesignData: props.planDesignData
                  }}
                  onClose={() => {
                    resetUploadState();
                    setEditPlanDesignDialogOpen(false);
                  }}
                  onSubmit={async values => {
                    props.onCreateScheduleChange(values);
                    setEditPlanDesignDialogOpen(false);
                    resetUploadState();
                  }}
                  open={editPlanDesignDialogOpen}
                  sponsorPlanId={props.sponsorPlanId}
                  title='Edit Plan Design'
                  validationSchema={Validations.makeDesignTabFieldsSchema({
                    isDesignDefaults: false
                  })}
                />
              ) : (
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values: Record<string, unknown>) => {
                    return handleSubmit({
                      formValues: values,
                      isConfirmed: false,
                      isPooledPlan: props.isPooledPlan
                    });
                  }}
                  validateOnChange
                  validationSchema={Validations.makeDesignTabFieldsSchema({
                    isDesignDefaults: props.isPooledPlan
                  })}>
                  {(formikProps: FormikProps<FormikValues>) => (
                    <>
                      <Dialog
                        fullWidth
                        maxWidth='md'
                        onClose={() => {
                          setIsUpdatePlanDesignDialogOpen(false);
                          setIsConfirmDialogOpen(false);
                          setIsDialogInteractionLocked(false);
                          formikProps.resetForm();
                        }}
                        onKeyUp={async event => {
                          if (event.key === 'Enter' && formikProps.isValid) {
                            return handleSubmit({
                              formValues: formikProps.values,
                              isConfirmed: false,
                              isPooledPlan: props.isPooledPlan
                            });
                          }
                        }}
                        open={isUpdatePlanDesignDialogOpen}>
                        <DialogTitle>Edit Plan Design</DialogTitle>
                        <DialogContent>
                          <EditPlanDesignForm
                            activeNavigationItem={activeNavigationItem}
                            csms={props.csms}
                            data={props.data}
                            file={file}
                            isPooledPlan={props.isPooledPlan}
                            isTpaUser={props.isTpaUser}
                            isUploading={isUploading}
                            onSelectFile={onSelect}
                            planDesignData={props.planDesignData}
                            sponsorPlanId={props.sponsorPlanId}
                            unsupportedValues={unsupportedValues}
                            unsupportedValuesLength={unsupportedValuesLength}
                            uploadingData={props.uploadingData}
                            uploadingError={uploadingError}
                          />
                        </DialogContent>

                        <DialogActions
                          classes={{ root: classes.actionButtons }}>
                          <Button
                            disabled={
                              isDialogInteractionLocked || !formikProps.isValid
                            }
                            onClick={async () => {
                              await handleSubmit({
                                formValues: formikProps.values,
                                isConfirmed: false,
                                isPooledPlan: props.isPooledPlan
                              });
                            }}>
                            Save as Draft
                          </Button>
                          <Button
                            disabled={
                              isDialogInteractionLocked || !formikProps.isValid
                            }
                            onClick={() => {
                              setIsUpdatePlanDesignDialogOpen(false);
                              setIsConfirmDialogOpen(true);
                            }}
                            type='button'
                            variant='contained'>
                            Confirm Plan Design
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog
                        fullWidth
                        maxWidth='md'
                        onClose={() => {
                          setIsUpdatePlanDesignDialogOpen(false);
                          setIsConfirmDialogOpen(false);
                          setIsDialogInteractionLocked(false);
                          formikProps.resetForm();
                        }}
                        open={isConfirmDialogOpen}>
                        <DialogTitle>Confirm Plan Design</DialogTitle>
                        <DialogContent>
                          <DialogContentText>Once Confirmed:</DialogContentText>
                          <List sx={{ listStyleType: 'disc', pl: 4 }}>
                            <ListItem sx={{ display: 'list-item' }}>
                              Adopter plans can be added
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                              Plan design can no longer be edited
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                              If you are not finished setting plan design,
                              please Save As Draft and you can return to it
                              later
                            </ListItem>
                          </List>
                          <DialogActions>
                            <Button
                              disabled={isDialogInteractionLocked}
                              onClick={() => {
                                setIsDialogInteractionLocked(false);
                                setIsConfirmDialogOpen(false);
                                setIsUpdatePlanDesignDialogOpen(true);
                              }}>
                              Back to Editing
                            </Button>
                            <Button
                              disabled={isDialogInteractionLocked}
                              onClick={async () => {
                                await handleSubmit({
                                  formValues: formikProps.values,
                                  isConfirmed: true,
                                  isPooledPlan: props.isPooledPlan
                                });
                              }}
                              type='button'
                              variant='contained'>
                              Confirm
                            </Button>
                          </DialogActions>
                        </DialogContent>
                      </Dialog>
                    </>
                  )}
                </Formik>
              )}
              <AccessControl
                requiresOneOf={[FeatureLevelPermissions.WRITE_PLAN_DESIGN]}>
                <LoadingButton
                  color='primary'
                  data-testid='edit-design-info-button'
                  disabled={permissions.isForbidden || isModalLocked}
                  endIcon={isModalLocked && <LockOutlined fontSize='small' />}
                  loading={props.isLoading}
                  onClick={() =>
                    !props.isPooledPlan
                      ? setEditPlanDesignDialogOpen(true)
                      : setIsUpdatePlanDesignDialogOpen(true)
                  }
                  variant='outlined'>
                  {!props.isPooledPlan ? 'EDIT' : 'EDIT PLAN DESIGN'}
                </LoadingButton>
              </AccessControl>
            </Box>
          </>
        )}
        <Box>
          {props.activeTab === 'generalInfo' && (
            <>
              <Box
                className={clsx(
                  classes.greyBorderBottom,
                  classes.planDesignBox
                )}
                display='flex'
                justifyContent='flex-end'>
                <AccessControl
                  requires={[FeatureLevelPermissions.WRITE_PLAN_OVERVIEW]}>
                  {!props.isPooledPlan && (
                    <Button
                      data-testid='general-info-edit-button'
                      onClick={() =>
                        props.onOpenDialog({
                          customContent: (
                            <Formik
                              initialValues={initialValues}
                              onSubmit={(values: Record<string, unknown>) => {
                                return handleSubmit({
                                  formValues: values,
                                  isConfirmed: false,
                                  isPooledPlan: props.isPooledPlan
                                });
                              }}
                              validateOnChange
                              validationSchema={
                                Validations.makeGeneralInfoTabFieldsSchema({
                                  isDesignDefaults: props.isPooledPlan
                                })[activeNavigationItem]
                              }>
                              {(formikProps: FormikProps<FormikValues>) => (
                                <Form>
                                  <StatusObserver data={props.data} />
                                  <DialogTitle>Edit Plan Design</DialogTitle>
                                  <DialogContent>
                                    <Box>
                                      <Typography align='center' variant='h6'>
                                        {
                                          uiSchema.generalInfo[
                                            activeNavigationItem
                                          ]?.title
                                        }
                                      </Typography>
                                      <Box mt={3}>
                                        {uiSchema.generalInfo[
                                          activeNavigationItem
                                        ]?.fields &&
                                          uiSchema.generalInfo[
                                            activeNavigationItem
                                          ]?.fields?.map(field =>
                                            getField(
                                              field,
                                              formikProps.errors,
                                              {}
                                            )
                                          )}
                                        {uiSchema.generalInfo[
                                          activeNavigationItem
                                        ]?.sections &&
                                          uiSchema.generalInfo[
                                            activeNavigationItem
                                          ]?.sections?.map(section =>
                                            section.fields?.map(field =>
                                              getField(
                                                field,
                                                formikProps.errors,
                                                {}
                                              )
                                            )
                                          )}
                                      </Box>
                                    </Box>
                                  </DialogContent>
                                  <DialogActions
                                    classes={{
                                      root: classes.actionButtons
                                    }}>
                                    <Button
                                      disabled={
                                        props.isPooledPlan
                                          ? formikProps.isSubmitting
                                          : false
                                      }
                                      onClick={() => {
                                        formikProps.resetForm();
                                        return props.onCloseDialog();
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disabled={
                                        formikProps.isSubmitting ||
                                        !formikProps.isValid
                                      }
                                      type='submit'
                                      variant='contained'>
                                      Save
                                    </Button>
                                  </DialogActions>
                                </Form>
                              )}
                            </Formik>
                          )
                        })
                      }
                      variant='outlined'>
                      EDIT
                    </Button>
                  )}
                </AccessControl>
              </Box>
            </>
          )}
          <Box className={classes.planDesignBox}>
            <Typography>
              {uiSchema[props.activeTab][activeNavigationItem]?.title}
            </Typography>
            {uiSchema[props.activeTab][activeNavigationItem]?.fields && (
              <>
                <Box
                  display='flex'
                  flexDirection={
                    props.activeTab === 'preferences' ? 'column' : 'row'
                  }
                  flexWrap='wrap'
                  mt='1rem'>
                  {uiSchema[props.activeTab][activeNavigationItem]?.fields?.map(
                    field => {
                      switch (props.activeTab) {
                        case 'preferences':
                          return getField(field);
                        default:
                          if (props.data[field.key]?.isHidden) {
                            return null;
                          }

                          return (
                            <Box
                              className={classes.field}
                              data-testid={`${field.key}-box`}
                              key={field.key}
                              mb='1rem'
                              width='50%'>
                              <Typography
                                className={classes.label}
                                variant='caption'>
                                {field.label}
                              </Typography>
                              {overrideFieldOutput(
                                field.key,
                                props.data[field.key]?.output
                              )}
                            </Box>
                          );
                      }
                    }
                  )}
                </Box>
              </>
            )}

            {uiSchema[props.activeTab][activeNavigationItem]?.sections &&
              uiSchema[props.activeTab][activeNavigationItem]?.sections?.map(
                section => (
                  <>
                    <Box mt='1rem'>
                      <Typography variant='body2'>{section.title}</Typography>
                      <Box display='flex' flexWrap='wrap' mt='1rem'>
                        {section.fields.map(
                          (field: { key: string; label: string }) =>
                            !isFieldHidden(field.key) && (
                              <AccessControl
                                hideFromTPA={hideFieldFromTpa(field.key)}
                                key={field.key}>
                                <Box
                                  className={classes.field}
                                  key={field.key}
                                  mb='1rem'
                                  width='50%'>
                                  <Typography
                                    className={classes.label}
                                    data-testid={`${section.title}-${field.key}-field`}
                                    variant='caption'>
                                    {field.label}
                                  </Typography>
                                  <Typography
                                    data-testid={`${section.title}-${field.key}-value`}>
                                    {props.data[field.key]?.output ||
                                      DEFAULT_VALUE}
                                  </Typography>
                                </Box>
                              </AccessControl>
                            )
                        )}
                      </Box>
                    </Box>
                  </>
                )
              )}
          </Box>
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        padding='1rem 2rem'
        width='25%'>
        {Object.values(navigationSchema[props.activeTab]).map(item => (
          <React.Fragment key={`${item.key}-wrapper`}>
            <AccessControl
              hideFromTPA={hideSectionFromTpa(item.label)}
              key={`${item.key}-access`}>
              <StyledTypography
                active={activeNavigationItem === item.key}
                data-testid={`${item.key}-navigation-tab`}
                key={item.key}
                onClick={() => setActiveNavigationItem(item.key)}
                variant='body2'>
                {item.label}
              </StyledTypography>
            </AccessControl>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

PlanDetails.displayName = 'PlanDetails';
