import AccessControl from '@/components/access-control/AccessControl.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { ParticipantInfo } from '@/models';
import {
  FindingTextMap,
  FraudrankerResultData
} from '@/models/FraudrankerResult.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import formatters from '@/utils/Formatters';
import { Circle } from '@mui/icons-material';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  List,
  ListItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';

import React, { useMemo, useState } from 'react';

type FraudInfoCardProps = {
  participantInfo: ParticipantInfo;
  mutate: UseMutateFunction<any, unknown, any, unknown>;
  fraudrankerResult?: FraudrankerResultData;
  jiraTicket?: string;
  fraudNotes?: string;
};

const gridTextColor = {
  color: 'black'
};

const FraudInfoCard: React.FunctionComponent<FraudInfoCardProps> = (
  props: FraudInfoCardProps
) => {
  const { participantInfo, mutate, fraudrankerResult, jiraTicket, fraudNotes } =
    props;
  const [isFraudNotesEditing, setIsFraudNotesEditing] = useState(false);
  const [fraudNote, setFraudNote] = useState(fraudNotes);

  const findings = useMemo(
    () => [
      ...new Set(fraudrankerResult?.findings.map(i => FindingTextMap[i] || i))
    ],
    [fraudrankerResult?.findings]
  );

  const jiraTicketLink = fraudrankerResult?.jiraTicketLink ?? jiraTicket;

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Card sx={{ marginBottom: 0 }} variant='outlined'>
          <CardHeader
            subheader={
              jiraTicketLink ? (
                <>
                  Jira Ticket:&nbsp;
                  <Link href={jiraTicketLink} target='_blank'>
                    {jiraTicketLink.substring(
                      jiraTicketLink.lastIndexOf('/') + 1
                    )}
                  </Link>
                </>
              ) : (
                EMPTY_FIELD_PLACEHOLDER
              )
            }
            sx={{
              borderBottom: theme => `1px solid ${theme.palette.grey[300]}`,
              height: '89px'
            }}
            title='Fraud Info'
          />
          <CardContent sx={{ paddingTop: 1 }}>
            <AccessControl
              requires={[FeatureLevelPermissions.WRITE_FRAUD_NOTES]}>
              {isFraudNotesEditing && (
                <Stack mb={2} spacing={1}>
                  <TextField
                    label='Note'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFraudNote(event.target.value);
                    }}
                    size='small'
                    value={fraudNote}
                  />
                  <Stack direction='row' spacing={1}>
                    <Button
                      disableElevation
                      onClick={() =>
                        mutate(fraudNote, {
                          onError: () => {
                            setIsFraudNotesEditing(false);
                          },
                          onSuccess: () => {
                            setIsFraudNotesEditing(false);
                          }
                        })
                      }
                      size='small'
                      variant='contained'>
                      SAVE
                    </Button>
                    <Button
                      onClick={() => setIsFraudNotesEditing(false)}
                      size='small'>
                      CANCEL
                    </Button>
                  </Stack>
                </Stack>
              )}
            </AccessControl>
            {!isFraudNotesEditing && (
              <Stack alignItems='center' direction='row' mb={2} spacing={2}>
                <NoteAltOutlinedIcon color='action' fontSize='small' />
                <Typography>{fraudNotes}</Typography>
                <AccessControl
                  requires={[FeatureLevelPermissions.WRITE_FRAUD_NOTES]}>
                  {fraudNotes ? (
                    <Button onClick={() => setIsFraudNotesEditing(true)}>
                      EDIT
                    </Button>
                  ) : (
                    <Button onClick={() => setIsFraudNotesEditing(true)}>
                      ADD NOTE
                    </Button>
                  )}
                </AccessControl>
              </Stack>
            )}

            <Stack
              direction='row'
              divider={<Divider flexItem orientation='vertical' />}
              mb={2}
              spacing={4}>
              <Box>
                <Typography variant='body2'>Score</Typography>
                <Typography
                  sx={{
                    alignItems: 'center',
                    color: theme => theme.palette.grey[600],
                    display: 'flex'
                  }}
                  variant='h6'>
                  <Circle
                    sx={{
                      color: fraudrankerResult?.fraudRank.toLowerCase(),
                      mr: 1
                    }}
                  />{' '}
                  {fraudrankerResult?.fraudScore}
                </Typography>
              </Box>
              <Box>
                <Typography variant='body2'>Findings</Typography>
                <List
                  data-testid='fraud-findings'
                  sx={{
                    color: theme => theme.palette.grey[600],
                    listStyleType: 'disc',
                    pl: 3,
                    pt: 0
                  }}>
                  {findings.map(i => (
                    <ListItem key={i} sx={{ display: 'list-item', padding: 0 }}>
                      <Typography variant='body2'>{i}</Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Stack>
            <TextStack direction='column' textLabelColumnWidth='167px'>
              {!!fraudrankerResult?.ipAddresses && (
                <TextStackItem fieldName='fraud-ip'>
                  <TextLabel>IP Address</TextLabel>
                  <TextValue
                    links={[
                      {
                        label: `${fraudrankerResult?.ipAddresses}`,
                        to: `https://scamalytics.com/ip/${fraudrankerResult?.ipAddresses}`
                      }
                    ]}>
                    {' '}
                  </TextValue>
                </TextStackItem>
              )}
              <TextStackItem>
                <TextLabel>Account Last Updated At</TextLabel>
                <TextValue data-testid='fraud-account-last-updated-at'>
                  {participantInfo.updatedAt
                    ? formatters.formatFromIsoDateCustom(
                        participantInfo.updatedAt,
                        'MM/DD/YYYY [at] h:mm A'
                      )
                    : null}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Bank Account Owner</TextLabel>
                <TextValue data-testid='fraud-bank-account-owner'>
                  {fraudrankerResult?.plaidAccountOwners?.names &&
                  fraudrankerResult?.plaidAccountOwners?.names.length > 1 ? (
                    <List
                      sx={{
                        color: theme => theme.palette.grey[600],
                        listStyleType: 'disc',
                        pl: 3,
                        pt: 0
                      }}>
                      {fraudrankerResult?.plaidAccountOwners?.names.map(
                        name => (
                          <ListItem
                            key={name}
                            sx={{ display: 'list-item', padding: 0 }}>
                            <Typography sx={gridTextColor} variant='body1'>
                              {name}
                            </Typography>
                          </ListItem>
                        )
                      )}
                    </List>
                  ) : (
                    fraudrankerResult?.plaidAccountOwners?.names?.[0] ?? null
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Bank Account Address</TextLabel>
                <TextValue data-testid='fraud-bank-account-address'>
                  {fraudrankerResult?.plaidAccountOwners?.addresses &&
                  fraudrankerResult?.plaidAccountOwners?.addresses.length >
                    1 ? (
                    <List
                      sx={{
                        color: theme => theme.palette.grey[600],
                        listStyleType: 'disc',
                        pl: 3,
                        pt: 0
                      }}>
                      {fraudrankerResult?.plaidAccountOwners?.addresses.map(
                        address => (
                          <ListItem
                            key={address.street}
                            sx={{ display: 'list-item', padding: 0 }}>
                            <Typography sx={gridTextColor} variant='body1'>
                              {`${address.street} 
                              ${address.city}, ${address.region}, ${address.postalCode}`}
                            </Typography>
                          </ListItem>
                        )
                      )}
                    </List>
                  ) : fraudrankerResult?.plaidAccountOwners?.addresses?.[0] ? (
                    `${fraudrankerResult?.plaidAccountOwners?.addresses[0]?.street} 
                      ${fraudrankerResult?.plaidAccountOwners?.addresses[0]?.city},
                      ${fraudrankerResult?.plaidAccountOwners?.addresses[0]?.region},
                      ${fraudrankerResult?.plaidAccountOwners?.addresses[0]?.postalCode}`
                  ) : null}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Phone</TextLabel>
                <TextValue data-testid='fraud-phone'>
                  {fraudrankerResult?.plaidAccountOwners?.phoneNumbers &&
                  fraudrankerResult?.plaidAccountOwners?.phoneNumbers.length >
                    1 ? (
                    <List
                      sx={{
                        color: theme => theme.palette.grey[600],
                        listStyleType: 'disc',
                        pl: 3,
                        pt: 0
                      }}>
                      {fraudrankerResult?.plaidAccountOwners?.phoneNumbers.map(
                        phone => (
                          <ListItem
                            key={phone.data}
                            sx={{ display: 'list-item', padding: 0 }}>
                            <Typography sx={gridTextColor} variant='body1'>
                              {phone.data}
                            </Typography>
                          </ListItem>
                        )
                      )}
                    </List>
                  ) : (
                    fraudrankerResult?.plaidAccountOwners?.phoneNumbers?.[0]
                      ?.data ?? null
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Email</TextLabel>
                <TextValue data-testid='fraud-email'>
                  {fraudrankerResult?.plaidAccountOwners?.emails &&
                  fraudrankerResult?.plaidAccountOwners?.emails.length > 1 ? (
                    <List
                      sx={{
                        color: theme => theme.palette.grey[600],
                        listStyleType: 'disc',
                        pl: 3,
                        pt: 0
                      }}>
                      {fraudrankerResult?.plaidAccountOwners?.emails.map(
                        email => (
                          <ListItem
                            key={email.data}
                            sx={{ display: 'list-item', padding: 0 }}>
                            <Typography sx={gridTextColor} variant='body1'>
                              {email.data}
                            </Typography>
                          </ListItem>
                        )
                      )}
                    </List>
                  ) : (
                    fraudrankerResult?.plaidAccountOwners?.emails?.[0]?.data ??
                    null
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>MFA Last Disabled At</TextLabel>
                <TextValue data-testid='mfa-last-disabled-at'>
                  {participantInfo.mfaLastDisabled
                    ? formatters.formatFromIsoDateCustom(
                        participantInfo.mfaLastDisabled,
                        'MM/DD/YYYY [at] h:mm A'
                      )
                    : null}
                </TextValue>
              </TextStackItem>
            </TextStack>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default FraudInfoCard;
