import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import DataTable, {
  DataTableBadgeCell,
  DataTableMenuCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { OffsettingOperationalAccountTypes } from '@/models/ops/transactions/ReverseTransactionRequest.model';
import { SearchTransactionsRequest } from '@/models/ops/transactions/SearchTransactionsRequest.model';
import { TransactionDto } from '@/models/ops/transactions/TransactionDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import TransactionService from '@/services/ops/transactions/Transactions.service';
import { userService } from '@/services/User.service';
import DOMInteraction from '@/utils/DOMInteraction';
import formatters from '@/utils/Formatters';
import { json2csvParser } from '@/utils/Json2csvParser';
import { useUrlStateParams } from '@/utils/Url';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import { MenuItem, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';
import { TransactionStatus } from '@vestwell-sub-accounting/models/accountsAndLedgers/TransactionStatus';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import CancelTransactionDialog from './CancelTransactionDialog.component';
import ConfirmTransactionDialog from './ConfirmTransactionDialog.component';
import EditTransactionDialog from './EditTransactionDialog.component';
import ReverseTransactionDialog from './ReverseTransactionDialog.component';
import TransactionDetailCellRenderer, {
  transactionDetailCellRendererFields
} from './TransactionDetailCellRenderer.component';
import {
  SearchFormValues,
  TransactionsFiltersForm
} from './TransactionsFiltersForm.component';

type TransactionsProps = {
  accountId: string;
  forcedAccountLevel?: AccountLevel;
  hideFilters?: boolean;
  hideHeader?: boolean;
  hideActions?: boolean;
  hideDetailCell?: boolean;
  customDefaultFilters?: Partial<SearchTransactionsRequest>;
  customOrder?: { column?: keyof TransactionDto; direction?: 'asc' | 'desc' };
  includeColumns?: string[];
  preventSearchInUrl?: boolean;
};

const Transactions = ({
  accountId,
  forcedAccountLevel,
  hideFilters = false,
  hideHeader = false,
  hideActions = false,
  hideDetailCell = false,
  customDefaultFilters,
  customOrder,
  includeColumns,
  preventSearchInUrl = false
}: TransactionsProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_TRANSACTIONS
  );

  const statusesExceptReverseAndCancelled = Object.values(
    TransactionStatus
  ).filter(
    status =>
      ![TransactionStatus.Reversed, TransactionStatus.Cancelled].includes(
        status
      )
  );

  const gridRef = useRef<AgGridReact>(null);
  const [orderBy, setOrderBy] = useState<keyof TransactionDto | undefined>(
    customOrder?.column
  );
  const [orderByDirection, setOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >(customOrder?.direction);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openReverseDialog, setOpenReverseDialog] = useState(false);
  const [
    offsettingOperationalAccountType,
    setOffsettingOperationalAccountType
  ] = useState<OffsettingOperationalAccountTypes | undefined>();
  const [selectedTransactions, setSelectedTransactions] = useState<
    TransactionDto[]
  >([]);

  const defaultAccountLevel = forcedAccountLevel || AccountLevel.ParentAccount;

  const nonUrlDefaultQueryParams: SearchTransactionsRequest = {
    accountId,
    accountLevel: defaultAccountLevel,
    dateType: PositionDateType.Trade,
    endingDate: dayjs().format('YYYY-MM-DD'),
    status: [...statusesExceptReverseAndCancelled],
    ...customDefaultFilters // overwrites the above defaults
  };

  const [query, setQuery] = useUrlStateParams<SearchTransactionsRequest>(
    nonUrlDefaultQueryParams,
    'query',
    value => {
      if (preventSearchInUrl) {
        return '';
      }
      // remove any values that are the same as the default
      const cleanedQuery = formatters.objectDiff<SearchTransactionsRequest>(
        value,
        nonUrlDefaultQueryParams
      );
      return JSON.stringify(cleanedQuery) !== '{}' // only add query if it's not empty
        ? encodeURIComponent(JSON.stringify(cleanedQuery))
        : '';
    },
    value => {
      try {
        const urlQuery = JSON.parse(decodeURIComponent(value));
        return {
          ...nonUrlDefaultQueryParams,
          ...urlQuery
        };
      } catch {
        return nonUrlDefaultQueryParams;
      }
    }
  );
  const [accountLevel, setAccountLevel] = useState<AccountLevel>(
    query.accountLevel || defaultAccountLevel
  );
  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    value => (!preventSearchInUrl ? String(value) : ''),
    value => (!isNaN(Number(value)) ? Number(value) : 1)
  );
  const [pageSize, setPageSize] = useUrlStateParams(
    25,
    'pageSize',
    value => (!preventSearchInUrl ? String(value) : ''),
    value => (!isNaN(Number(value)) ? Number(value) : 25)
  );

  const { data, isFetching, isInitialLoading } = useQuery(
    [
      'TransactionService.search',
      query,
      page,
      pageSize,
      orderBy,
      orderByDirection
    ],
    () => {
      const queryWithPagination = {
        ...query,
        page,
        pageSize
      };
      if (orderBy) {
        queryWithPagination.orderBy = orderBy;
      }
      if (orderByDirection) {
        queryWithPagination.orderByDirection = orderByDirection;
      }
      return TransactionService.search(queryWithPagination);
    },
    {
      keepPreviousData: true,
      onError: (err: any) => {
        showSnackbar({
          message: `Transaction search failed: ${err?.message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  const handleSubmit = (formData: SearchFormValues) => {
    setPage(1);
    setQuery(prev => ({
      ...prev,

      amountFrom:
        formData.amountFrom === '' || formData.amountFrom === null
          ? undefined
          : parseFloat(formData.amountFrom).toFixed(2),

      amountTo:
        formData.amountTo === '' || formData.amountTo === null
          ? undefined
          : parseFloat(formData.amountTo).toFixed(2),

      beginningDate: formData.beginningDate || undefined,
      // the api will look up the cusip matching the provided cusipOrSymbol
      // but maybe we will eventually move the check to the frontend for some autocomplete
      // this would be a good spot to do that check
      cusip: formData.cusipOrSymbol || undefined,
      dateType: formData.dateType || PositionDateType.Trade,
      endingDate: formData.endingDate || undefined,
      sourceTransactionId: formData.sourceTransactionId || undefined,
      status: formData.status,
      tracerId: formData.tracerId || undefined,
      transactionBaseType:
        formData.transactionBaseType?.length > 0
          ? formData.transactionBaseType
          : undefined,
      transactionTypeCode: formData.transactionTypeCode || undefined,
      transferSubAccountId: formData.transferSubAccountId || undefined,
      unitsFrom:
        formData.unitsFrom === '' || formData.unitsFrom === null
          ? undefined
          : parseFloat(formData.unitsFrom).toFixed(3),
      unitsTo:
        formData.unitsTo === '' || formData.unitsTo === null
          ? undefined
          : parseFloat(formData.unitsTo).toFixed(3)
    }));
  };

  /**
   * Used to convert the query object into the recognized props and value formats of the filter form.
   * Specifically the incoming query from the url on page load
   */
  const queryToInputValues = (
    incomingQuery: SearchTransactionsRequest
  ): Partial<SearchFormValues> => {
    const inputValues: Partial<SearchFormValues> = {};
    if (!incomingQuery) {
      return inputValues;
    }
    if (incomingQuery.dateType) {
      inputValues.dateType = incomingQuery.dateType;
    }
    if (incomingQuery.cusip) {
      inputValues.cusipOrSymbol = incomingQuery.cusip;
    }
    if (incomingQuery.transactionBaseType) {
      inputValues.transactionBaseType = incomingQuery.transactionBaseType;
    }
    if (incomingQuery.transactionTypeCode) {
      inputValues.transactionTypeCode = incomingQuery.transactionTypeCode;
    }
    if (incomingQuery.status) {
      inputValues.status = incomingQuery.status;
    }
    if (incomingQuery.beginningDate) {
      inputValues.beginningDate = incomingQuery.beginningDate;
    }
    if (incomingQuery.endingDate) {
      inputValues.endingDate = incomingQuery.endingDate;
    }
    if (incomingQuery.transferSubAccountId) {
      inputValues.transferSubAccountId = incomingQuery.transferSubAccountId;
    }
    if (incomingQuery.amountFrom) {
      inputValues.amountFrom = incomingQuery.amountFrom;
    }
    if (incomingQuery.amountTo) {
      inputValues.amountTo = incomingQuery.amountTo;
    }
    if (incomingQuery.unitsFrom) {
      inputValues.unitsFrom = incomingQuery.unitsFrom;
    }
    if (incomingQuery.unitsTo) {
      inputValues.unitsTo = incomingQuery.unitsTo;
    }
    if (incomingQuery.sourceTransactionId) {
      inputValues.sourceTransactionId = incomingQuery.sourceTransactionId;
    }
    return inputValues;
  };

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      cellRenderer: (cellData: { data: TransactionDto }) => (
        <>
          {!hideActions &&
            hasWritePermissions &&
            [TransactionStatus.Pending, TransactionStatus.Confirmed].includes(
              cellData.data.status
            ) && (
              <DataTableMenuCell>
                {cellData.data.status === TransactionStatus.Pending && (
                  <MenuItem
                    onClick={() => {
                      setSelectedTransactions([cellData.data]);
                      setOpenCancelDialog(true);
                    }}>
                    Cancel
                  </MenuItem>
                )}
                {cellData.data.status === TransactionStatus.Pending && (
                  <MenuItem
                    onClick={() => {
                      setOpenConfirmationDialog(true);
                      setSelectedTransactions([cellData.data]);
                    }}>
                    Update to confirmed
                  </MenuItem>
                )}
                {cellData.data.status === TransactionStatus.Confirmed && (
                  <MenuItem
                    onClick={() => {
                      setSelectedTransactions([cellData.data]);
                      setOpenEditDialog(true);
                    }}>
                    Edit
                  </MenuItem>
                )}
                {cellData.data.status === TransactionStatus.Confirmed && (
                  <MenuItem
                    onClick={() => {
                      setSelectedTransactions([cellData.data]);
                      setOpenReverseDialog(true);
                    }}>
                    Reverse (No Offset)
                  </MenuItem>
                )}
                {cellData.data.status === TransactionStatus.Confirmed && (
                  <MenuItem
                    onClick={() => {
                      setOffsettingOperationalAccountType(SubAccountType.error);
                      setSelectedTransactions([cellData.data]);
                      setOpenReverseDialog(true);
                    }}>
                    Reverse &amp; Offset
                  </MenuItem>
                )}
              </DataTableMenuCell>
            )}
        </>
      ),
      cellStyle: {
        paddingLeft: 16,
        paddingRight: 16
      },
      field: 'sourceTransactionId',
      headerName: '',
      hide: hideActions && hideDetailCell,
      maxWidth: 110,
      minWidth: 110,
      suppressColumnsToolPanel: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: TransactionDto }) => (
        <DataTableBadgeCell
          color={
            {
              CANCELLED: 'neutral',
              CONFIRMED: 'success',
              PENDING: 'neutral',
              REVERSED: 'neutral'
            }[cellData.data.status] as 'neutral' | 'success'
          }>
          {formatters.capitalizeFirstChar(cellData.data.status.toLowerCase())}
        </DataTableBadgeCell>
      ),
      field: 'status',
      headerName: 'Status',
      resizable: true,
      sortable: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: TransactionDto }) => {
        const displayBaseType = formatters.getValueKey(
          TransactionBaseType,
          cellData.data.transactionBaseType
        );
        return (
          <DataTableStackCell
            primary={
              displayBaseType ? formatters.displayCase(displayBaseType) : ''
            }
            secondary={
              cellData.data.transactionTypeCode
                ? formatters.displayCase(cellData.data.transactionTypeCode)
                : ''
            }
          />
        );
      },
      field: 'transactionBaseType',
      headerName: 'Base Type / Type',
      resizable: true,
      sortable: true
    },
    {
      cellRenderer: (cellData: { data: TransactionDto }) => {
        return (
          <DataTableStackCell
            primary={
              formatters.formatSecurityName(
                cellData.data.security?.symbol,
                cellData.data.security?.cusip
              ) || '\u2014'
            }
            secondary={cellData.data.security?.description}
          />
        );
      },
      field: 'security.symbol',
      headerName: 'Security',
      resizable: true,
      sortable: true
    },
    {
      field: 'units',
      headerName: 'Units',
      resizable: true,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: number }) =>
        value ? formatters.formatDecimal(value, 3) : ''
    },
    {
      field: 'securityUnitPrice',
      headerName: 'Price',
      resizable: true,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value ? formatters.formatDollars(value) : ''
    },
    {
      field: 'amount',
      headerName: 'Amount',
      resizable: true,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value ? formatters.formatDollars(value) : ''
    },
    {
      field: 'tradeDate',
      headerName: 'Trade Date',
      resizable: true,
      sortable: true,
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
    },
    {
      field: 'sourceTransactionId',
      headerName: 'Source ID',
      resizable: true,
      sortable: true,
      tooltipField: 'sourceTransactionId'
    }
  ]);

  useEffect(() => {
    // if account type is changed, reflect that in the main query
    setQuery(q => {
      return {
        ...q,
        accountLevel
      };
    });
    setColumnDefs(cd => {
      const newColumnDefs = [...cd];
      const accountIdColumnIndex = newColumnDefs.findIndex(
        col => col.field === 'accountId'
      );
      if (
        accountLevel === AccountLevel.SubAccount &&
        forcedAccountLevel !== AccountLevel.SubAccount &&
        accountIdColumnIndex === -1
      ) {
        // sub account type but no account id column so add one
        newColumnDefs.splice(2, 0, {
          field: 'accountId',
          headerName: 'Sub Account ID',
          resizable: true,
          tooltipField: 'accountId'
        });
      } else if (
        accountLevel === AccountLevel.SubAccount &&
        forcedAccountLevel !== AccountLevel.SubAccount &&
        accountIdColumnIndex !== -1
      ) {
        // remove column when hidden if it already exists
        newColumnDefs.splice(accountIdColumnIndex, 1);
      } else if (
        accountLevel === AccountLevel.ParentAccount &&
        accountIdColumnIndex !== -1
      ) {
        // parent account type and account id column is present so remove it
        newColumnDefs.splice(accountIdColumnIndex, 1);
      }
      const transferSubAccountIdColumnIndex = newColumnDefs.findIndex(
        col => col.field === 'transferSubAccountId'
      );
      if (
        accountLevel === AccountLevel.SubAccount &&
        transferSubAccountIdColumnIndex === -1
      ) {
        newColumnDefs.push({
          field: 'transferSubAccountId',
          headerName: 'Transfer Sub Account ID',
          hide: true,
          minWidth: 200,
          sortable: true
        });
      } else if (
        accountLevel === AccountLevel.ParentAccount &&
        transferSubAccountIdColumnIndex !== -1
      ) {
        // parent account type and transfer sub account id column is present so remove it
        newColumnDefs.splice(transferSubAccountIdColumnIndex, 1);
      }

      return newColumnDefs;
    });
  }, [accountLevel, forcedAccountLevel]);

  const detailCellRenderer = useMemo(() => {
    return TransactionDetailCellRenderer;
  }, []);

  const formatTransactions = (
    transactions: TransactionDto[]
  ): Record<string, any>[] => {
    // fetch columns from grid reference to support column reordering
    const gridColumns = gridRef?.current?.columnApi?.getAllGridColumns() || [];
    return transactions.map(transaction => {
      const formattedTransaction: Record<string, any> = {};

      // add the properties from the columns with matching header and formatting
      gridColumns.forEach((col, colIndex) => {
        if (colIndex === 0) return; // don't include the action column

        const userProvidedColDef = col.getUserProvidedColDef();
        if (!userProvidedColDef) return;

        // don't include columns that are missing from the includeColumns prop if one was provided
        if (
          userProvidedColDef.field &&
          includeColumns &&
          !includeColumns?.includes(userProvidedColDef.field)
        ) {
          return;
        }

        if (userProvidedColDef.field === 'transactionBaseType') {
          // since this field is actually a combination of base type and type
          // we need to manually format it and add it as separate fields
          const displayBaseType = formatters.getValueKey(
            TransactionBaseType,
            transaction.transactionBaseType
          );
          formattedTransaction['Base Type'] = displayBaseType
            ? formatters.displayCase(displayBaseType)
            : '';
          formattedTransaction.Type = transaction.transactionTypeCode
            ? formatters.displayCase(transaction.transactionTypeCode)
            : '';
        } else if (userProvidedColDef.field === 'security.symbol') {
          // since this field is actually a combination of the desired output of symbol and cusip
          // we need to manually format it and add it as separate fields
          formattedTransaction.Ticker = transaction.security?.symbol || '';
          formattedTransaction.Cusip = transaction.security?.cusip || '';
        } else if (
          typeof userProvidedColDef.valueFormatter !== 'undefined' &&
          typeof userProvidedColDef.valueFormatter !== 'string'
        ) {
          // special formatting was specified
          formattedTransaction[userProvidedColDef.headerName as string] =
            userProvidedColDef.valueFormatter({
              value:
                transaction[userProvidedColDef.field as keyof TransactionDto]
            } as any);
        } else if (typeof userProvidedColDef.cellRenderer === 'function') {
          // the column uses a custom renderer so use it and convert it to text
          formattedTransaction[userProvidedColDef.headerName as string] =
            DOMInteraction.JSXToTextContent(
              userProvidedColDef.cellRenderer({ data: transaction } as any)
            );
        } else {
          formattedTransaction[userProvidedColDef.headerName as string] =
            transaction[userProvidedColDef.field as keyof TransactionDto];
        }
      });

      // add the properties from the expandable section of the row
      Object.keys(transactionDetailCellRendererFields).forEach(fieldId => {
        const field = transactionDetailCellRendererFields[fieldId];
        formattedTransaction[field.label] = field.valueFormatter(
          transaction[fieldId as keyof TransactionDto]
        );
      });

      return formattedTransaction;
    });
  };

  /**
   * @description Reruns the transaction query with a limit of 0 and offset of 0
   * to get all data then convert the data to csv and prompt user to download it
   */
  const exportTransactions = async () => {
    // rerun the query with a page of 1 and pageSize of 0 to get all data
    // then convert the data to csv and download it
    const downloadQuery = {
      ...query,
      page: 1,
      pageSize: 0
    } as SearchTransactionsRequest;
    const transactionsResponse = await TransactionService.search(downloadQuery);
    const transactions = transactionsResponse.results;
    const formattedTransactions = formatTransactions(transactions);
    const csv = await json2csvParser(formattedTransactions);
    DOMInteraction.triggerDownload(
      csv,
      `transactions-${accountId}-${accountLevel}.csv`
    );
  };

  const accountLevelDisplay = (value: AccountLevel): string => {
    return formatters.displayCase(formatters.getValueKey(AccountLevel, value));
  };

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
    } else {
      setOrderBy(newSort[0].colId as keyof TransactionDto);
      setOrderByDirection(newSort[0].sort);
    }
  };

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  return (
    <>
      <CancelTransactionDialog
        accountId={accountId}
        // using the default account level vs the current state ensures the sub account
        // transactions in parent account views show the parent account details in the summary
        accountLevel={defaultAccountLevel}
        onClose={() => {
          setOpenCancelDialog(false);
          setSelectedTransactions([]);
        }}
        open={openCancelDialog}
        transactions={selectedTransactions}
      />
      <ConfirmTransactionDialog
        accountId={accountId}
        // using the default account level vs the current state ensures the sub account
        // transactions in parent account views show the parent account details in the summary
        accountLevel={defaultAccountLevel}
        onClose={() => {
          setOpenConfirmationDialog(false);
          setSelectedTransactions([]);
        }}
        open={openConfirmationDialog}
        transaction={selectedTransactions[0]}
      />
      {selectedTransactions.length > 0 && (
        <EditTransactionDialog
          accountId={accountId}
          // using the default account level vs the current state ensures the sub account
          // transactions in parent account views show the parent account details in the summary
          accountLevel={defaultAccountLevel}
          onClose={() => {
            setOpenEditDialog(false);
            setSelectedTransactions([]);
          }}
          open={openEditDialog}
          transaction={selectedTransactions[0]}
        />
      )}
      <ReverseTransactionDialog
        accountId={accountId}
        // using the default account level vs the current state ensures the sub account
        // transactions in parent account views show the parent account details in the summary
        accountLevel={defaultAccountLevel}
        offsettingOperationalAccountType={offsettingOperationalAccountType}
        onClose={() => {
          setOpenReverseDialog(false);
          setOffsettingOperationalAccountType(undefined);
          setSelectedTransactions([]);
        }}
        open={openReverseDialog}
        transactions={selectedTransactions}
      />
      <Card>
        {!hideHeader && (
          <CardHeader
            actionButtonsProps={[
              {
                disabled:
                  isFetching ||
                  !data?.results ||
                  (Array.isArray(data.results) && !data.results.length),
                label: (
                  <Stack
                    alignItems='center'
                    direction='row'
                    justifyContent='center'
                    spacing={1}>
                    <Stack // Stack component to prevent weird svg container height when using Box
                      sx={{ fontSize: '20px' }}>
                      <DownloadIcon fontSize='inherit' />
                    </Stack>
                    <span>Export CSV</span>
                  </Stack>
                ),
                onClick: () => {
                  exportTransactions();
                }
              }
            ]}
            data-testid='transactions-header'
            loading={data?.results && isFetching}
            title={`${
              forcedAccountLevel ? '' : `${accountLevelDisplay(accountLevel)} `
            }Transactions`}
            toggle={
              forcedAccountLevel
                ? undefined
                : {
                    onChangeAction: (
                      event: React.MouseEvent<HTMLElement>,
                      value: any
                    ) => {
                      setAccountLevel(value);
                    },
                    options: [
                      {
                        label: 'Parent',
                        value: AccountLevel.ParentAccount
                      },
                      { label: 'Sub', value: AccountLevel.SubAccount }
                    ],
                    value: accountLevel
                  }
            }
          />
        )}
        <CardContent
          data-testid='data-transactions'
          disablePadding
          overlayLoading={isInitialLoading}>
          <DataTable
            columnDefs={columnDefs.filter(
              // include columns with no field or if a column include list was provided, include columns that are in the list
              col =>
                !col.field ||
                !includeColumns ||
                includeColumns.includes(col.field)
            )}
            data-testid='data-transactions-table'
            detailCellRenderer={hideDetailCell ? undefined : detailCellRenderer}
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-transactions-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle={
                    !data
                      ? 'Search results will be displayed here.'
                      : 'No results found.'
                  }
                />
              </Stack>
            }
            filterSidePanelComponent={
              !hideFilters ? (
                <Formik
                  initialValues={{
                    amountFrom: '',
                    amountTo: '',
                    cusipOrSymbol: '',
                    dateType: PositionDateType.Trade,
                    endingDate: dayjs().format('YYYY-MM-DD'),
                    sourceTransactionId: '',
                    status: [...statusesExceptReverseAndCancelled],
                    tracerId: '',
                    transactionBaseType: [],
                    transactionTypeCode: '',
                    transferSubAccountId: '',
                    unitsFrom: '',
                    unitsTo: '',
                    ...queryToInputValues(query)
                  }}
                  onSubmit={handleSubmit}>
                  <TransactionsFiltersForm accountLevel={accountLevel} />
                </Formik>
              ) : undefined
            }
            gridRef={gridRef}
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            onSortChanged={handleSortChanged}
            page={page}
            pageSize={pageSize}
            pagination
            paginationSource='server'
            paginationTotal={data?.pagination?.total}
            rowData={data?.results ? data.results : []}
            sort={
              orderBy
                ? [
                    {
                      colId: orderBy,
                      sort: orderByDirection
                    }
                  ]
                : []
            }
          />
        </CardContent>
      </Card>
    </>
  );
};
export default Transactions;
