export type AuditLog = {
  id: number;
  initiatorType: string;
  context: string;
  eventTypeName: string;
  createdOn: string;
  initiatedByUserId: string;
};

export type AuditLogsDto = {
  logs: AuditLog[];
  total: number;
};

export type AuditLogsFilters = {
  eventTypeNames: string[];
  initiatorTypes: string[];
  startDate: string;
  endDate: string;
  search: string;
};

export type TableTriggeredFilters = Pick<
  AuditLogsFilters,
  'search' | 'eventTypeNames'
>;

export const allInitiatorTypes = ['Admin', 'Participant', 'TPA'];
export const allEventTypeNames = [
  'Delete Participant',
  'Update Withdrawal',
  'Update Plan Design',
  'Create Withdrawal',
  'Update Participant Custom Lineup',
  'Update Registration Status'
];

export const initialAuditLogsFilters = {
  endDate: null,
  eventTypeNames: allEventTypeNames,
  initiatorTypes: allInitiatorTypes,
  search: '',
  startDate: null
};
