import ContributionDetailsDto from '@/models/ContributionDetailsDTO.model';
import PlanContributionCorrections from '@/routes/plans/plan-detail/PlanContributionCorrections';
import PlanContributionLostGain from '@/routes/plans/plan-detail/PlanContributionsTab/PlanContributionLostGain/PlanContributionLostGain.component';
import PlanContributionProcessed from '@/routes/plans/plan-detail/PlanContributionsTab/PlanContributionProcessed/PlanContributionProcessed.component';
import PlanContributionReversals from '@/routes/plans/plan-detail/PlanContributionsTab/PlanContributionReversals/PlanContributionReversals.component';
import PlanStatusContribution from '@/routes/plans/plan-detail/PlanContributionStatus/PlanStatusContribution.component';
import PlanFileCard from '@/routes/plans/plan-detail/PlanFile';
import { Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Decimal from 'decimal.js';
import React, { useMemo, useState } from 'react';

import MetadataCard from '../MetadataCard';
import TotalContributionCard from '../TotalContributionCard';

interface PlanContributionOverviewProps {
  sponsorPlanId: string;
  ucid: string;
  contributionData: ContributionDetailsDto;
  planQuery: any;
  isStateIRA: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  contributionReversals: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  contributionStatusTable: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  metaDataCard: {
    paddingLeft: theme.spacing(2)
  },
  totalCard: {
    paddingRight: theme.spacing(2)
  }
}));

const PlanContributionOverview: React.FunctionComponent<
  PlanContributionOverviewProps
> = ({
  sponsorPlanId,
  ucid,
  contributionData,
  planQuery,
  isStateIRA
}: PlanContributionOverviewProps) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const total = useMemo(
    () =>
      Decimal.sum(
        contributionData.totals?.total ?? 0,
        contributionData.corrections?.totals?.total ?? 0
      ).toNumber(),
    [contributionData]
  );

  return (
    <Grid container>
      <Grid className={classes.totalCard} item xs={6}>
        <TotalContributionCard
          doNotAchEmployer={contributionData.doNotAchEmployer}
          forfeitureAmount={contributionData.forfeitureAmount}
          hasCorrections={!!contributionData.corrections?.ucids?.length}
          hasLostGains={!!contributionData.lostGainsUcid}
          recordkeeper={contributionData.recordkeeper}
          rejectedAmount={contributionData?.totals.totalReduction}
          total={total}
        />
      </Grid>
      <Grid item xs={3}>
        <PlanFileCard
          sponsorId={planQuery.data.sponsorId}
          sponsorPlanId={sponsorPlanId}
          ucid={ucid}
        />
      </Grid>
      <Grid className={classes.metaDataCard} item xs={3}>
        <MetadataCard
          planId={+sponsorPlanId}
          sponsorId={+planQuery.data?.sponsorId}
          ucid={contributionData.ucid}
        />
      </Grid>
      <div className={classes.contributionStatusTable}>
        <PlanStatusContribution
          planId={+sponsorPlanId}
          sponsorId={+planQuery.data?.sponsorId}
          ucid={ucid}
        />
      </div>
      <div className={classes.contributionStatusTable}>
        <PlanContributionCorrections
          isStateIRA={isStateIRA}
          sponsorId={planQuery.data.sponsorId}
          sponsorPlanId={sponsorPlanId}
          ucid={ucid}
        />
      </div>
      <div className={classes.contributionStatusTable}>
        <PlanContributionProcessed
          isStateIRA={isStateIRA}
          participantData={contributionData.participantData}
          planId={sponsorPlanId}
          search={search}
          setSearch={setSearch}
          sponsorId={planQuery.data?.sponsorId}
          ucid={contributionData.ucid}
        />
      </div>
      <div className={classes.contributionReversals} id='corrections'>
        {contributionData.corrections?.ucids?.map(value => (
          <PlanContributionReversals
            key={value}
            planId={sponsorPlanId}
            search={search}
            sponsorId={planQuery.data?.sponsorId}
            ucid={value}
          />
        ))}
        {contributionData.lostGainsUcid && (
          <PlanContributionLostGain
            planId={sponsorPlanId}
            search={search}
            sponsorId={planQuery.data?.sponsorId}
            ucid={contributionData.lostGainsUcid}
          />
        )}
      </div>
    </Grid>
  );
};

export default PlanContributionOverview;
