import AppConfig from '@/App.config';
import VestwellTheme from '@/theme/Vestwell.theme';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  Container,
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import 'buffer';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { SnackbarProvider } from './contexts/SnackBarContext';
import UserTokenProvider from './contexts/UserTokenContext';
import { router } from './router';
import './utils/dayjs';

LicenseInfo.setLicenseKey(
  '63f3c8755bccb89b0760eb3a9f53497aTz04NDYzMCxFPTE3Mzk5ODY5NDMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
});

const StyledContainer = styled(Container)({
  padding: '0px 0px !important'
});

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={VestwellTheme}>
          <Auth0Provider
            audience={AppConfig.auth0Config.audience}
            clientId={AppConfig.auth0Config.clientId}
            domain={AppConfig.auth0Config.domain}
            redirectUri={AppConfig.auth0Config.redirectUri}
            useRefreshTokens={true}>
            <UserTokenProvider>
              <SnackbarProvider>
                <StyledContainer maxWidth={false}>
                  <RouterProvider router={router} />
                </StyledContainer>
              </SnackbarProvider>
            </UserTokenProvider>
          </Auth0Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
