import { CellComponentProps } from '@/components/collapsible-table';
import { ForfeitureEventModalData } from '@/consts/forfeiture.constants';
import formatters from '@/utils/Formatters';
import { EditOutlined } from '@mui/icons-material';
import { Box, IconButton, Link, TableCell, Tooltip } from '@mui/material';
import { blue } from '@mui/material/colors';

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ForfeitureEventModal from './ForfeitureEventModal.component';

const ForfeitureEventTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;
  const [isOpenNote, setOpenNote] = React.useState(false);

  const handleClickOpenNote = () => {
    setOpenNote(true);
  };

  let field = (
    <Box sx={{ fontSize: theme => theme.spacing(2) }}>
      {column.field === 'name' ? (
        <Link
          component={RouterLink}
          to={`/participants/${row.id}`}
          underline='hover'>
          {row[column.field]}
        </Link>
      ) : (
        row[column.field]
      )}
    </Box>
  );

  if (column.field === 'action') {
    field = (
      <Box sx={{ display: 'flex' }}>
        <IconButton
          onClick={() => {
            handleClickOpenNote();
          }}>
          <Tooltip title='Edit Event'>
            <EditOutlined
              sx={{
                '&:hover': {
                  color: blue[700]
                },
                color: '#616161E5'
              }}
            />
          </Tooltip>
        </IconButton>

        <ForfeitureEventModal
          buttonText={ForfeitureEventModalData.UPDATE_EVENT_MODAL_BUTTON}
          initialValues={{
            ...row,
            planId: row.planId,
            ucid: row.context.ucid,
            year: row.context.planYear
          }}
          isOpenModal={isOpenNote}
          modalTitle={`Forfeiture Event - ${row.updatedAt}`}
          planParticipants={row.planParticipants}
          setOpen={setOpenNote}
        />
      </Box>
    );
  }

  if (column.field === 'amount') {
    field = (
      <Box sx={{ fontSize: theme => theme.spacing(2) }}>
        {formatters.formatDollars(row[column.field])}
      </Box>
    );
  }

  return (
    <TableCell component='th' scope='row'>
      <Box>{field}</Box>
    </TableCell>
  );
};

export default ForfeitureEventTableCell;
