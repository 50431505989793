export interface SubaPlanFundStatusesDto {
  data: SubaPlanFundStatus[];
}

export interface SubaPlanFundStatus {
  type: string;
  id: number;
  attributes: SubaPlanFundStatusAttribute;
}

export interface SubaPlanFundStatusAttribute {
  planFund: SubaPlanFundDto;
  planFundActions: SubaPlanFundActionDto[];
  status: string;
  custodianAccountNumber?: string;
}

export interface SubaPlanFundActionDto {
  planFundActionId: number;
  planFundId: number;
  addRemoveAction: AddRemoveAction;
  manualExecutionStatus?: ManualExecutionStatus;
}

export interface SubaPlanFundDto {
  planFundId: number;
  sponsorPlanId: string;
  cusip: string;
  fundedByTransfers: boolean;
  notes?: string;
}

export enum AddRemoveAction {
  add = 'add',
  remove = 'remove'
}

export enum ManualExecutionStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED'
}

interface PlanFundExecutionContext {
  planFundActionId: number;
}

interface SubaPlanFundRequest {
  planId: string;
  cusip: string;
  addRemove: AddRemoveAction;
  fundedByTransfer?: string;
  notes?: string;
  tracerId?: string;
}

interface ResponsibleParty {
  id: string;
  idType: string;
  authRole?: string;
}

interface SubaRecordKeeperPlanFundEvent {
  context: PlanFundExecutionContext;
  request: SubaPlanFundRequest;
  responsibleParty: ResponsibleParty;
  actionType: string;
  type: string;
}

export interface SubaRecordKeeperPlanFundEventDto {
  data: SubaRecordKeeperPlanFundEvent;
}
