import Badge from '@/components/badge/Badge.component';
import SimpleTabs, { TabData } from '@/components/simple-tabs';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack/TextStack.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { SecurityApiIncludeOption } from '@/models/ops/security/SecurityApiIncludeOption.model';
import SecurityService from '@/services/ops/security/Security.service';
import SearchIcon from '@mui/icons-material/Search';
import {
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { InstrumentType } from '@vestwell-sub-accounting/models/common/InstrumentType';
import { FundStatus } from '@vestwell-sub-accounting/models/securitiesAndPricing/FundStatus';

import { AxiosError } from 'axios';
import { Field, Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SecuritiesDetailDividendsTab } from './SecuritiesDetailDividendsTab.component';
import { SecuritiesDetailFundRulesTab } from './SecuritiesDetailFundRulesTab.component';
import { SecuritiesDetailGeneralTab } from './SecuritiesDetailGeneralTab.component';
import { SecuritiesDetailPricesTab } from './SecuritiesDetailPricesTab.component';

// Securities use only a handful of our defined instrument types
type SecurityInstrumentType =
  | InstrumentType.cifd
  | InstrumentType.etfd
  | InstrumentType.mmfd
  | InstrumentType.opfd;

const displayInstrumentType = {
  [InstrumentType.cifd]: 'CIT',
  [InstrumentType.etfd]: 'ETF',
  [InstrumentType.mmfd]: 'Money Market Fund',
  [InstrumentType.opfd]: 'Mutual Fund'
};

export const SecuritiesDetailRoute = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [query, setQuery] = useState<string>(params.cusipOrSymbol || '');
  const [queryInputValue, setQueryInputValue] = useState<string>(
    params.cusipOrSymbol || ''
  );
  const { showSnackbar } = useSnackbar();

  const { data: security, isFetching } = useQuery(
    ['SecurityService.get', query],
    () =>
      SecurityService.get(query, {
        include: [
          SecurityApiIncludeOption.custodianFundInformation,
          SecurityApiIncludeOption.divAccrualRateRule,
          SecurityApiIncludeOption.fundRules,
          SecurityApiIncludeOption.standardFundInformation
        ]
      }),
    {
      enabled: query !== '',
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        let message = 'Sorry, something went wrong';

        if (err.isAxiosError && err.response?.status === 404) {
          message = `Security with ticker symbol or CUSIP matching "${query}" not found`;
        }

        showSnackbar({
          message,
          severity: 'error'
        });
      },
      retry: false,
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (query === params.cusipOrSymbol) return;

    // sync query with route param when it updates
    setQuery(params.cusipOrSymbol || '');
    setQueryInputValue(params.cusipOrSymbol || '');
  }, [params.cusipOrSymbol, query]);

  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () =>
      queryInputValue // prevent invalid tab paths (`/ops/securities//general`) when missing queryInputValue
        ? [
            {
              component: security ? (
                <SecuritiesDetailGeneralTab security={security} />
              ) : null,
              label: 'General',
              path: `/ops/securities/${queryInputValue}/general`
            },
            {
              component: security ? (
                <SecuritiesDetailDividendsTab security={security} />
              ) : null,
              label: 'Dividends',
              path: `/ops/securities/${queryInputValue}/dividends`
            },
            {
              component: security ? (
                <SecuritiesDetailPricesTab security={security} />
              ) : null,
              label: 'Prices',
              path: `/ops/securities/${queryInputValue}/prices`
            },
            {
              component: security ? (
                <SecuritiesDetailFundRulesTab security={security} />
              ) : null,
              label: 'Fund Rules',
              path: `/ops/securities/${queryInputValue}/fund-rules`
            }
          ]
        : [],
    [security, queryInputValue]
  );

  let defaultTabIdx = tabElements.findIndex(
    tab => location.pathname === tab.path
  );

  // handle lack of location while waiting for data-dependent navigation
  if (defaultTabIdx === -1) defaultTabIdx = 0;

  const handleSubmit = (values: { queryInputValue: string }) => {
    if (values.queryInputValue === '') {
      navigate('/ops/securities');
    } else {
      // optimistically navigate, even if it may be a 404
      navigate(`/ops/securities/${values.queryInputValue}/general`);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Stack direction='row' spacing={3}>
          <Typography role='heading' variant='h4'>
            Securities
          </Typography>
          <Formik
            enableReinitialize
            initialValues={{ queryInputValue }}
            onSubmit={handleSubmit}>
            {({ values }: { values: { queryInputValue: string } }) => (
              <Form>
                <Field
                  as={OutlinedInput}
                  autoComplete='off'
                  data-testid='security-search-input'
                  endAdornment={
                    <InputAdornment position='end'>
                      {isFetching ? (
                        <CircularProgress size='20px' />
                      ) : (
                        <IconButton
                          onClick={() => handleSubmit(values)}
                          type='submit'>
                          <SearchIcon color='primary' />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                  inputProps={{
                    'aria-labelledby': 'search-accounts-heading'
                  }}
                  name='queryInputValue'
                  placeholder='Find Ticker / CUSIP'
                  size='small'
                  sx={{
                    pr: 0.75 // compensate for InputAdornment padding
                  }}
                />
              </Form>
            )}
          </Formik>
        </Stack>
        {
          // only display content when security data matches the route
          // prettier-ignore
          (
            (security && security.cusip.toLowerCase() === params.cusipOrSymbol?.toLowerCase()) || 
            (security && security.symbol.toLowerCase() === params.cusipOrSymbol?.toLowerCase())
          ) && (
            <>
              <Divider />
              <Stack direction={{ lg: 'row', xs: 'column' }} spacing={4}>
                <Stack spacing={1}>
                  <Typography data-testid="description" role="heading" variant="h5">
                    {security.description}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Typography color="text.secondary" data-testid="symbol" variant="body1">
                      {security.symbol}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography color="text.secondary" data-testid="cusip" variant="body1">
                      {security.cusip}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography color="text.secondary" data-testid="instrument-type" variant="body1">
                      {displayInstrumentType[security.instrumentType as SecurityInstrumentType] || security.instrumentType}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography color="text.secondary" data-testid="issuer" variant="body1">
                      Issued by {security.issuer}
                    </Typography>
                  </Stack>
                </Stack>
                <TextStack divider rowColumnWidth="dynamic">
                  <TextStackItem>
                    <TextLabel>Matrix Fund Status</TextLabel>
                    <TextValue data-testid="matrix-fund-status">
                      <Badge
                        color={
                          security.custodianFundInformation?.fundStatus ===
                          FundStatus.open
                            ? 'success'
                            : 'neutral'
                        }
                      >
                        {security.custodianFundInformation?.fundStatus || 'Not Available'}
                      </Badge>
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Asset Class</TextLabel>
                    <TextValue data-testid="asset-class">
                      {security.standardFundInformation?.mstarAssetClass}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem data-testid="share-class">
                    <TextLabel>Share Class</TextLabel>
                    <TextValue>
                      {security.standardFundInformation?.shareClass}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem data-testid="net-expense-ratio">
                    <TextLabel>Net Expense Ratio</TextLabel>
                    <TextValue>
                      {security.standardFundInformation?.netExpenseRatio}
                    </TextValue>
                  </TextStackItem>
                </TextStack>
              </Stack>
              <SimpleTabs
                data-testid="securities-tabs"
                defaultTabIdx={defaultTabIdx}
                tabs={tabElements}
                tabsAriaLabel="securities-tabs"
              />
            </>
          )
        }
      </Stack>
    </>
  );
};

export default SecuritiesDetailRoute;
