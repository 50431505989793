export enum FeatureLevelPermissions {
  READ_ADVISOR = 'read:advisor',
  READ_ANNUAL_TESTING = 'read:annual_testing',
  READ_COMPANY_PAYROLL = 'read:company:payroll',
  READ_ESA = 'read:esa',
  READ_FIRM = 'read:firm',
  READ_INVESTMENTS = 'read:investments',
  READ_ONBOARD = 'read:onboard',
  READ_PLAN_1 = 'read:plan_1',
  READ_SUBA_ACCOUNTS = 'read:suba_accounts',
  READ_SUBA_ALERTS = 'read:suba_alerts',
  READ_SUBA_RECON = 'read:suba_recon',
  READ_TPA = 'read:tpa',
  READ_POOLED_PLANS = 'read:pooled_plans',
  READ_USER_MANAGEMENT = 'read:user_management',
  READ_USER_MANAGEMENT_ADVISOR = 'read:user_management:advisor',
  READ_USER_MANAGEMENT_PARTICIPANT = 'read:user_management:participant',
  READ_USER_MANAGEMENT_SPONSOR = 'read:user_management:sponsor',
  READ_USER_MANAGEMENT_VSS_STATE_SAVER = 'read:user_management:vss_state_saver',
  READ_USER_MFA = 'read:user:mfa',
  READ_USER_MANAGEMENT_REPORTS = 'read:user_management:reports',
  READ_WHITELABEL_MANAGEMENT = 'read:whitelabel_management',
  TEST = 'test', // for use in tests only to satisfy AccessControl types
  USER_READ = 'user:read',
  WORK_IN_PROGRESS = 'user:work_in_progress',
  WRITE_ADVISOR = 'write:advisor',
  WRITE_AMENDMENTS_ACTIONS = 'write:amendments:actions',
  WRITE_ANNUAL_TESTING_ACTIONS = 'write:annual_testing:actions',
  WRITE_BETA_FEATURE = 'write:beta_feature',
  WRITE_BULK_EMAIL_ACTIONS = 'write:bulk_email:actions',
  WRITE_BULK_UPLOAD_ACTIONS = 'write:bulk_upload:actions',
  WRITE_COMPANY_COMPANY_INFO = 'write:company:company_info',
  WRITE_COMPANY_EXEMPTION = 'write:company:exemption',
  WRITE_COMPANY_PAYROLL = 'write:company:payroll',
  WRITE_COMPANY_REMOVE_BANK_ACCOUNT = 'write:company:remove_bank_account',
  WRITE_COMPANY_PLAN_STATUS = 'write:company:plan_status',
  WRITE_COMPANY_TEAM_SETUP = 'write:company:team_setup',
  WRITE_COMPANY_TPA_CONTACT = 'write:company:tpa_contact',
  WRITE_CONTRIBUTION_ACTION_CENTER = 'write:contribution:action_center',
  WRITE_CONTRIBUTION_CORRECTIONS = 'write:contribution:corrections',
  WRITE_CONTRIBUTIONS_ACTION = 'write:contributions:action',
  WRITE_CONVERSION = 'write:conversion',
  WRITE_CREATE_PLAN = 'write:create_plan',
  WRITE_DEPOSIT_REQUEST_EDIT = 'write:deposit_request_edit',
  WRITE_DOCUMENTS_DELETE = 'write:documents:delete',
  WRITE_DOCUMENTS_GROUPINGS_CREATE = 'write:documents:groupings:create',
  WRITE_DOCUMENTS_UPLOAD = 'write:documents:upload',
  WRITE_ELECTIONS_DEFERRAL_RATE = 'write:elections:deferral_rate',
  WRITE_EMPLOYEES_REMOVE_EMPLOYEE = 'write:employees:remove_employee',
  WRITE_EMPLOYMENT_ELIGIBILITY = 'write:employment:eligibility',
  WRITE_EMPLOYMENT_ELIGIBILITY_SURPAS = 'write:employment:eligibility_surpas',
  WRITE_EMPLOYMENT_EMPLOYMENT = 'write:employment:employment',
  WRITE_EMPLOYMENT_HOURS = 'write:employment:hours',
  WRITE_FIRM = 'write:firm',
  WRITE_FORCE_OUT_DISTRIBUTIONS = 'write:withdrawals:force_out',
  WRITE_FORFEITURE_ACTION = 'write:forfeitures:action',
  WRITE_FRAUD_NOTES = 'write:fraud',
  WRITE_INVEST_CONTRIBUTION_EDIT = 'write:invest_contribution_edit',
  WRITE_INVESTMENTS_MODIFY = 'write:investments:modify',
  WRITE_INVESTMENTS_FUND_CHANGE_APPROVE = 'write:investments_fund_change:approve',
  WRITE_LOANS_ACTION = 'write:loans:action',
  WRITE_LOANS_FEES = 'write:loans:fees',
  WRITE_MASQUERADE_ADVISOR = 'write:masquerade:advisor',
  WRITE_MASQUERADE_PARTICIPANT = 'write:masquerade:participant',
  WRITE_MASQUERADE_SPONSOR = 'write:masquerade:sponsor',
  WRITE_MONEY_OUT_ADDRESS = 'write:money_out_address',
  WRITE_NEW_PLAN_EDIT = 'write:new_plan_edit',
  WRITE_ONBOARD = 'write:onboard',
  WRITE_ONBOARD_STATEFUL_SCHEMA = 'write:onboard:stateful_schema',
  WRITE_PARTICIPANT_DISTRIBUTION_HOLD = 'write:participant:distribution_hold',
  WRITE_PERSONAL_ACCOUNT_STATUS = 'write:personal:account_status',
  WRITE_PERSONAL_CONTACT = 'write:personal:contact',
  WRITE_PERSONAL_IDENTIFICATION = 'write:personal:identification',
  WRITE_PERSONAL_IDENTIFICATION_SSN = 'write:personal:identification_ssn',
  WRITE_PLAN_DESIGN = 'write:plan:design',
  WRITE_PLAN_MAPPINGS = 'write:plan:partner_system_mappings',
  WRITE_PLAN_OVERVIEW = 'write:plan:overview',
  WRITE_REBALANCE_ACTION = 'write:rebalance:action',
  WRITE_ROLLOVERS_ACTION = 'write:rollovers:action',
  WRITE_ROTH_DETAILS = 'write:participant:roth_details',
  WRITE_STATE_IRA_EMPLOYER_BULK_UPLOAD = 'write:state_ira_employer_list_upload',
  WRITE_SUBA_ALERTS = 'write:suba_alerts',
  WRITE_SUBA_DIVIDENDS = 'write:suba_dividends',
  WRITE_SUBA_INITIATE_ACH = 'write:suba_initiate_ach',
  WRITE_SUBA_RECON = 'write:suba_recon',
  WRITE_SUBA_TRANSACTIONS = 'write:suba_transactions',
  WRITE_SUBA_TRANSFER_CASH = 'write:suba_transfer_cash',
  WRITE_TPA_ADD_TPA = 'write:tpa:add_tpa',
  WRITE_TPA_BACKFILL = 'write:tpa:backfill',
  WRITE_TPA_DETAILS_MANAGE_USERS = 'write:tpa_details:manage_users',
  WRITE_TPA_DETAILS_PLANS = 'write:tpa_details:plans',
  WRITE_TPA_REPORTING = 'write:tpa_reporting',
  WRITE_TRADE_REQUEST_EDIT = 'write:trade_request_edit',
  WRITE_UPLOADS = 'write:uploads',
  WRITE_USER_MANAGEMENT = 'write:user_management',
  WRITE_USER_MANAGEMENT_PERSONA_ASSOCIATION = 'write:user_management:persona_association',
  WRITE_USER_MANAGEMENT_LOGIN = 'write:user_management:login',
  WRITE_USER_MANAGEMENT_ADVISOR = 'write:user_management:advisor',
  WRITE_USER_MANAGEMENT_PARTICIPANT = 'write:user_management:participant',
  WRITE_USER_MANAGEMENT_SPONSOR = 'write:user_management:sponsor',
  WRITE_USER_MANAGEMENT_VSS_STATE_SAVER = 'write:user_management:vss_state_saver',
  WRITE_USER_MANAGEMENT_LOGIN_EMAIL = 'write:user_management:login_email',
  WRITE_USER_MANAGEMENT_PASSWORD_RESET = 'write:user_management:password_reset',
  WRITE_USER_MANAGEMENT_ACCOUNT_ACCESS = 'write:user_management:account_access',
  WRITE_USER_MFA = 'write:user:mfa',
  WRITE_VESTING = 'write:vesting',
  WRITE_WITHDRAWAL_FEES = 'write:withdrawal:fees',
  WRITE_WITHDRAWALS_ACTION = 'write:withdrawals:action',
  WRITE_PAYROLL_DEACTIVATION = 'write:payroll:deactivation',
  WRITE_CONTRIBUTION_EXPECTED_PAY_DATE = 'write:contribution:expected_pay_date',
  WRITE_EMPLOYEES_MERGE_ACCOUNTS = 'write:employees:merge_accounts',
  WRITE_ACCOUNT_HOLD = 'write:account_hold',
  WRITE_CONTRIBUTION_HIDE = 'write:contribution:hide',
  WRITE_CONTRIBUTION_ACH_SKIP = 'write:contribution:ach_skip',
  WRITE_CONTRIBUTION = 'write:contribution',
  WRITE_WHITELABEL_MANAGEMENT = 'write:whitelabel_management'
}

export enum DataLevelPermissions {
  READ_COMPANY_PAYGROUP_MASKED = 'read:company:paygroup_bank_info_unmasked'
}

// TODO: change name to 'Permissions' when the legacy permissions are removed
export type NewPermissions = FeatureLevelPermissions | DataLevelPermissions;

// TODO: legacy permissions to be cleaned up
export type OldPermissions =
  | 'user:read'
  | 'user:write'
  | 'user:onboarding'
  | 'user:release_from_hold'
  | 'user:work_in_progress'
  | 'user:email'
  | 'user:program_management'
  | 'user:tpa'
  | 'user:tpa_manager';

export type Permissions = OldPermissions | NewPermissions;
