import AccessControl from '@/components/access-control/AccessControl.component';
import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import { DataTableMenuCell } from '@/components/data-table/DataTable.component';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { EmployeeMatchesDialog } from '@/routes/plans/plan-detail/PlanEmployees/MergeEmployees/EmployeeMatchesDialog.component';
import ParticipantService from '@/services/Participant.service';
import MailIcon from '@mui/icons-material/Mail';
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  MenuItem,
  Stack,
  TableCell,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { DryRunDialog } from './RemoveEmployeeDialog.component';

const useStyles = makeStyles((theme: Theme) => ({
  size: {
    fontSize: theme.spacing(2)
  }
}));

const RegistrationStatusTableCell: React.FC<{ value?: string }> = ({
  value
}) => {
  const classes = useStyles();

  return (
    <TableCell component='th' scope='row'>
      <Box className={classes.size}>
        {value ? (
          <Box alignItems='center' display='flex'>
            <Box mr={1}>
              <Badge color='success'>Registered</Badge>
            </Box>
            <Typography variant='body2'>
              {dayjs(value).format('MM/DD/YYYY')}
            </Typography>
          </Box>
        ) : (
          <Badge color='neutral'>Unregistered</Badge>
        )}
      </Box>
    </TableCell>
  );
};

const WelcomeEmailStatusTableCell: React.FC<{
  record: CellComponentProps['row'];
}> = ({ record }) => {
  const classes = useStyles();

  return (
    <TableCell component='th' scope='row'>
      <Box className={classes.size}>
        {record.welcomeEmailSentAt ? (
          <Box alignItems='center' display='flex'>
            <Box mr={1}>
              {['Delivery', 'Open', 'Click'].includes(
                record.welcomeEmailStatus
              ) && <Badge color='success'>Delivered</Badge>}

              {record.welcomeEmailStatus === 'Bounce' && (
                <Badge color='warning'>Bounced</Badge>
              )}

              {['Rendering Failure', 'Send', 'Complaint'].includes(
                record.welcomeEmailStatus
              ) && <Badge color='neutral'>Not Delivered</Badge>}
            </Box>

            <Typography variant='body2'>
              {dayjs(record.welcomeEmailSentAt).format('MM/DD/YYYY')}
            </Typography>
          </Box>
        ) : (
          '-'
        )}
      </Box>
    </TableCell>
  );
};

const WorkEmailTableCell: React.FC<{
  isSending?: boolean;
  onSend: () => unknown;
  record: CellComponentProps['row'];
}> = props => (
  <TableCell component='th' scope='row'>
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      mr={6}>
      {props.record.workEmail
        ? props.record.workEmail
        : EMPTY_FIELD_PLACEHOLDER}
      {props.record.scope === 'registration' &&
        !props.record.registeredAt &&
        props.record.workEmail && (
          <AccessControl
            requiresOneOf={[
              FeatureLevelPermissions.WRITE_ONBOARD,
              FeatureLevelPermissions.WRITE_EMPLOYMENT_ELIGIBILITY
            ]}>
            <Stack alignItems='center' justifyContent='center'>
              <Tooltip title='Send Welcome Email'>
                <IconButton
                  color='primary'
                  disabled={props.isSending}
                  onClick={props.onSend}>
                  {props.isSending ? (
                    <CircularProgress size={24} />
                  ) : (
                    <MailIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Stack>
          </AccessControl>
        )}
    </Box>
  </TableCell>
);

const PlanEmployeeTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const [, setOpenMenu] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const removeParticipantFromPlanDryRun = useMutation(
    [
      'ParticipantService.removeParticipantFromPlan',
      props.row.id,
      props.row.sponsorPlanId,
      true
    ],
    () => {
      return ParticipantService.removeParticipantFromPlan(
        props.row.id,
        props.row.sponsorPlanId,
        true
      );
    },
    {
      onSuccess: () => {
        setOpen(true);
      }
    }
  );

  const sendWelcomeEmailMutation = useMutation(
    [
      'ParticipantService.sendWelcomeEmail',
      props.row.id,
      props.row.sponsorPlanId
    ],
    () =>
      ParticipantService.sendWelcomeEmail(
        props.row.id,
        props.row.sponsorPlanId
      ),
    {
      onSuccess: () => {
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  const handleClickOpen = async () => {
    await removeParticipantFromPlanDryRun.mutateAsync();
    setOpenMenu(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (props.column.field === 'name') {
    return (
      <TableCell component='th' scope='row'>
        <Box className={classes.size}>
          <Link
            component={RouterLink}
            to={`/participants/${props.row.id}`}
            underline='hover'>
            {props.row[props.column.field]}
          </Link>
        </Box>
      </TableCell>
    );
  }

  if (props.column.field === 'action') {
    return (
      <TableCell
        component='th'
        data-testid={`participant-action-cell-${props.row.id}`}
        scope='row'>
        <Box className={classes.size}>
          {removeParticipantFromPlanDryRun.isLoading ||
          !props.row.planDesignQuerySuccess ? (
            <CircularProgress
              size={18}
              sx={{
                ml: 1
              }}
            />
          ) : (
            <DataTableMenuCell>
              <AccessControl
                requires={[
                  FeatureLevelPermissions.WRITE_EMPLOYEES_REMOVE_EMPLOYEE
                ]}>
                <MenuItem
                  data-testid='remove'
                  disabled={removeParticipantFromPlanDryRun.isLoading}
                  onClick={handleClickOpen}>
                  Remove
                </MenuItem>
              </AccessControl>
              <AccessControl
                requires={[
                  FeatureLevelPermissions.WRITE_EMPLOYEES_MERGE_ACCOUNTS
                ]}>
                <MenuItem
                  data-testid='merge-account'
                  onClick={() => {
                    openDialog({
                      customContent: (
                        <EmployeeMatchesDialog participantData={props.row} />
                      ),
                      dialogProps: {
                        fullWidth: true,
                        maxWidth: 'md'
                      }
                    });
                  }}>
                  Merge Account
                </MenuItem>
              </AccessControl>
            </DataTableMenuCell>
          )}
          <DryRunDialog
            errors={{
              removeParticipantErrors:
                removeParticipantFromPlanDryRun.data?.errors
            }}
            handleClose={handleClose}
            open={open}
            row={props.row}
          />
        </Box>
      </TableCell>
    );
  }

  if (props.column.field === 'registeredAt') {
    return (
      <RegistrationStatusTableCell value={props.row[props.column.field]} />
    );
  }

  if (props.column.field === 'welcomeEmailStatus') {
    return <WelcomeEmailStatusTableCell record={props.row} />;
  }

  if (props.column.field === 'workEmail') {
    return (
      <WorkEmailTableCell
        isSending={sendWelcomeEmailMutation.isLoading}
        onSend={sendWelcomeEmailMutation.mutate}
        record={props.row}
      />
    );
  }

  if (props.column.field === 'elStatus') {
    return (
      <TableCell component='th' data-testid='eligibilityStatus' scope='row'>
        <Box className={classes.size}>
          {props.row[props.column.field]}{' '}
          {props.row?.elStatus === 'Eligible' && props.row?.isLtpt && (
            <b>(LTPT)</b>
          )}
        </Box>
      </TableCell>
    );
  }

  return (
    <TableCell component='th' scope='row'>
      <Box className={classes.size}>{props.row[props.column.field]}</Box>
    </TableCell>
  );
};

export default PlanEmployeeTableCell;
