type Bank = {
  type: 'ACH' | 'WIRE';
  institutionName: string;
  routingNumber?: string;
  accountNumber?: string;
  creditTo?: string;
  furtherCreditTo?: string;
};

type Address = {
  type: 'CHECK' | 'OVERNIGHT_CHECK';
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
};

export type TaxDetails = {
  federalWithholdingPercent: string;
  stateWithholdingCode: string;
  stateWithholdingPercent: string;
  _1099Code?: string;
  rothInitialYear?: number;
  rothQualifiedWithdrawal?: boolean;
  rothBasis?: number;
};

export type CashDeliveryDetails = {
  deliveryType: 'Cash';
  fullAmount: boolean;
  isRoth: boolean;
  amount?: number;
  deliveryDestination: Bank | Address;
  taxDetails: TaxDetails;
  taxType: string;
};

export type RolloverDeliveryDetails = {
  deliveryType: 'Rollover';
  fullAmount: boolean;
  isRoth: boolean;
  partialAmount?: number;
  destination?: string;
  payee?: string;
  memo?: string;
  deliveryDestination?: Address | Bank;
  bankAccountRegistration?: string;
  taxDetails: TaxDetails;
};

export type DeliveryDetails = (RolloverDeliveryDetails | CashDeliveryDetails)[];

export interface WithdrawalDetails {
  amount: number;
  fees: {
    baseFee: number;
    cashWithdrawalDeliveryFee?: number;
    rolloverPreTaxDeliveryFee?: number;
    rolloverRothDeliveryFee?: number;
  };
  status: string;
  distributionMethod: string;
  reason: string;
  rothDestination?: string;
  hardshipReason?: string;
  fundType?: string;
  notes?: string;
}

export interface WithdrawalFees {
  fee: number;
  cashWithdrawalDeliveryFee?: number;
  rolloverPreTaxDeliveryFee?: number;
  rolloverRothDeliveryFee?: number;
}

export type WithdrawalStatus =
  | 'rejected'
  | 'pending'
  | 'initial'
  | 'processing'
  | 'disbursed'
  | 'canceled'
  | 'ltrequestsubmitted'
  | 'ltrequestfailed'
  | 'ltrequestpending';

export enum WithdrawalStatusEnum {
  Rejected = 'rejected',
  Pending = 'pending',
  Initial = 'initial',
  Processing = 'processing',
  Disbursed = 'disbursed',
  Canceled = 'canceled'
}

export function normalizeWithdrawalStatus(
  status: string
): WithdrawalStatusEnum | undefined {
  const normalizedStatus = status?.toLowerCase();
  switch (normalizedStatus) {
    case WithdrawalStatusEnum.Rejected:
      return WithdrawalStatusEnum.Rejected;
    case WithdrawalStatusEnum.Pending:
      return WithdrawalStatusEnum.Pending;
    case WithdrawalStatusEnum.Initial:
      return WithdrawalStatusEnum.Initial;
    case WithdrawalStatusEnum.Processing:
      return WithdrawalStatusEnum.Processing;
    case WithdrawalStatusEnum.Disbursed:
      return WithdrawalStatusEnum.Disbursed;
    case WithdrawalStatusEnum.Canceled:
      return WithdrawalStatusEnum.Canceled;
    default:
      return undefined;
  }
}

export type UpdateWithdrawalStatus = {
  status: WithdrawalStatus;
  forceSkipLocationValidation: boolean;
};
