import { AchDetailsDto, AchDetailsInfo } from '@/models';
import { BulkRebalanceResponseDto } from '@/models/RebalanceDTO.model';
import {
  SubaPlanFundStatusesDto,
  SubaRecordKeeperPlanFundEventDto
} from '@/models/SubaccountingPlanFund.model';
import ApiService from '@/services/Api.service';

class SubAccountingService {
  static async getAchDetail(ucid: string | undefined): Promise<AchDetailsInfo> {
    if (ucid === undefined) {
      throw new Error(`invalid input type`);
    }

    const dto = (await ApiService.getJson(
      `/plan/contributions/${ucid}/ach`
    )) as AchDetailsDto;

    if (!dto || !dto.status) {
      throw new Error(`invalid JSON received from backend for ucid=${ucid}`);
    }

    return {
      amount: dto.request.amount,
      createdAt: dto.createdAt,
      executionId: dto.id,
      resultMessage: dto.result.message,
      resultStatus: dto.result.status,
      source: dto.source,
      status: dto.status,
      subaresultMessage: dto.subaResult.message,
      tracerId: dto.request.tracerId,
      updatedAt: dto.updatedAt,
      workflowStatus: dto.workflowStatus
    } as AchDetailsInfo;
  }

  static async getPlanFundsAndStatuses(
    planId: number
  ): Promise<SubaPlanFundStatusesDto> {
    return ApiService.getJson(`/subaccounting/plan/${planId}`);
  }

  static async addPlanFund(
    planId: number,
    cusip: string,
    notes?: string
  ): Promise<SubaRecordKeeperPlanFundEventDto> {
    return ApiService.putJson(`/subaccounting/plan/${planId}/fund/${cusip}`, {
      fundedByTransfers: false,
      notes
    });
  }

  static async removePlanFund(
    planId: number,
    cusip: string
  ): Promise<SubaRecordKeeperPlanFundEventDto> {
    return ApiService.putJson(
      `/subaccounting/plan/${planId}/fund/${cusip}/remove`,
      {}
    );
  }

  static async bulkRebalance(
    participantIds: string[]
  ): Promise<BulkRebalanceResponseDto> {
    const dto: BulkRebalanceResponseDto = await ApiService.postJson(
      `/participant/rebalance`,
      {
        participantIds: participantIds
      }
    );

    if (!dto) {
      throw new Error(`Invalid JSON received from backend for bulk rebalance`);
    }

    return dto;
  }

  static async syncProgramPlanFunds(
    programId: number,
    funds: { cusip: string; note?: string }[]
  ): Promise<SubaRecordKeeperPlanFundEventDto> {
    return ApiService.postJson(
      `/subaccounting/program/${programId}/plans-fund-sync`,
      funds
    );
  }
}

export default SubAccountingService;
