import Badge from '@/components/badge';
import { CardContent, CardPlaceholder } from '@/components/card';
import DocumentDownloadButton from '@/components/document-download-button';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { BulkAction } from '@/models/BulkAction.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import formatters from '@/utils/Formatters';
import HistoryIcon from '@mui/icons-material/History';
import {
  Box,
  capitalize,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import { ActionCenterDatePicker } from './ActionCenterDatePicker.component';
import { ActionTypes } from './ActionCenterStepperFilters.component';
import { ActionHistoryEmployerTableCollapsibleComponent } from './ActionHistoryEmployerTableCollapsibleComponent';
import ActionHistoryTableCollapsibleComponent from './ActionHistoryTableCollapsibleComponent.component';

interface Filters {
  dateFrom?: string | null;
  dateTo?: string | null;
  triggeredBy: 'any' | string;
  status: 'all' | string;
}

interface FiltersOptions {
  status: string[];
  triggeredBy: string[];
}

interface ActionHistoryTableProps {
  actionType: ActionTypes | '';
}

const employerListStatus: Record<string, 'success' | 'error' | 'neutral'> = {
  abandoned: 'neutral',
  completed: 'success',
  failed: 'error',
  invalid: 'error',
  processing: 'neutral',
  validated: 'success',
  validating: 'neutral'
};

const ActionHistoryTable: React.FunctionComponent<ActionHistoryTableProps> = ({
  actionType
}) => {
  const [filters, setFilters] = useState<Filters>({
    status: 'all',
    triggeredBy: 'any'
  });

  const [filtersOptions, setFiltersOptions] = useState<FiltersOptions>({
    status: [],
    triggeredBy: []
  });

  const bulkWelcomeEmails = useQuery(
    ['ActionCenterService.getBulkWelcomeEmailActions'],
    async () => {
      const data = await ActionCenterService.getBulkWelcomeEmailActions();
      return data?.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    },
    {
      enabled: actionType === ActionTypes.BULK_WELCOME_EMAILS,
      staleTime: Infinity
    }
  );

  const bulkUploadActions = useQuery(
    ['ActionCenterService.getBulkUploadActions'],
    async () => {
      const data = await ActionCenterService.getBulkUploadActions();
      return data?.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    },
    {
      enabled: actionType === ActionTypes.BULK_UPLOAD_FILES,
      staleTime: Infinity
    }
  );

  const bulkEmailActions = useQuery(
    ['ActionCenterService.getBulkEmailActions'],
    async () => {
      const data = await ActionCenterService.getBulkEmailActions();
      return data?.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    },
    {
      enabled: actionType === ActionTypes.BULK_EMAIL,
      staleTime: Infinity
    }
  );

  const bulkEmployerList = useQuery(
    ['ActionCenterService.bulkEmployerList'],
    async () => await ActionCenterService.bulkEmployerList(),
    {
      enabled: actionType === ActionTypes.BULK_EMPLOYER,
      staleTime: Infinity
    }
  );

  const filterData = (bulkActions?: BulkAction[]) => {
    if (!bulkActions) {
      return [];
    }
    return bulkActions.filter(bulkAction => {
      let statusMatch = true;
      if (filters.status !== 'all') {
        statusMatch = filters.status === bulkAction.status;
      }

      let triggeredByMatch = true;
      if (filters.triggeredBy !== 'any') {
        triggeredByMatch =
          typeof bulkAction.initiatedBy === 'string'
            ? filters.triggeredBy === bulkAction.initiatedBy
            : filters.triggeredBy === bulkAction.initiatedBy.id;
      }
      const dateMatch = dayjs(bulkAction.createdAt).isBetween(
        filters.dateFrom || '01/01/1970',
        filters.dateTo || '01/01/3000',
        'date',
        '[]'
      );

      return statusMatch && triggeredByMatch && dateMatch;
    });
  };

  const tableData = useMemo(() => {
    let data;
    switch (actionType) {
      case ActionTypes.BULK_UPLOAD_FILES:
        data = bulkUploadActions.data;
        break;
      case ActionTypes.BULK_EMAIL:
        data = bulkEmailActions.data;
        break;
      case ActionTypes.BULK_EMPLOYER:
        data = bulkEmployerList.data?.data;
        break;
      case ActionTypes.BULK_WELCOME_EMAILS:
        data = bulkWelcomeEmails.data;
        break;
      default:
        data = undefined;
        break;
    }

    return data;
  }, [
    actionType,
    bulkUploadActions,
    bulkEmailActions,
    bulkEmployerList,
    bulkWelcomeEmails
  ]);

  useEffect(() => {
    if (tableData) {
      const statuses = new Set<string>();
      const initiatedByUsers = new Set<string>();

      tableData.forEach(action => {
        statuses.add(action.status);
        initiatedByUsers.add(
          typeof action.initiatedBy === 'string'
            ? action.initiatedBy
            : action.initiatedBy.id
        );
      });

      setFiltersOptions({
        status: Array.from(statuses),
        triggeredBy: Array.from(initiatedByUsers)
      });
      setFilters({
        dateFrom: formatters.formatFromIsoDateCustom(
          tableData[tableData.length - 1]?.createdAt,
          'MM/DD/YYYY'
        ),
        dateTo: dayjs().format('MM/DD/YYYY'),
        status: 'all',
        triggeredBy: 'any'
      });
    }
  }, [tableData]);

  const bulkActionsColumns: GridColDef[] = [
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Date',
      valueFormatter: params =>
        formatters.formatFromIsoDateCustom(params.value, 'MM/DD/YYYY HH:mm:ss')
    },
    {
      field: 'initiatedBy',
      flex: 1,
      headerName: 'Triggered By'
    },
    {
      field: 'status',
      flex: 1,
      headerName: 'Status',
      renderCell: params => (
        <Badge
          color={
            params.row.status === 'running'
              ? 'neutral'
              : params.row.status === 'error'
                ? 'warning'
                : 'success'
          }
          size='small'>
          {capitalize(params.row.status)}
        </Badge>
      )
    },
    {
      field: 'documentKey',
      flex: 1,
      headerName: 'Document Key',
      valueGetter: params => params.row.metadata?.documentKey
    },
    {
      align: 'right',
      field: 'nOfPlans',
      flex: 1,
      headerAlign: 'right',
      headerName: '# of Plans',
      valueGetter: params => params.row.metadata?.numberOfPlans
    }
  ];
  const bulkEmployerColumns: GridColDef[] = [
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Date',
      valueFormatter: params =>
        formatters.formatFromIsoDateCustom(params.value, 'MM/DD/YYYY HH:mm:ss')
    },
    {
      field: 'initiatedBy',
      flex: 1,
      headerName: 'Triggered By',
      valueGetter: params => params.row.initiatedBy?.id
    },
    {
      field: 'status',
      flex: 1,
      headerName: 'Status',
      renderCell: params => (
        <Badge color={employerListStatus[params.row.status]} size='small'>
          {capitalize(params.row.status)}
        </Badge>
      )
    },
    {
      align: 'right',
      field: 'summary',
      flex: 1,
      headerAlign: 'right',
      headerName: '# of Employers',
      valueGetter: params =>
        params.row.summary.existingRows + params.row.summary.newRows
    }
  ];

  const getColumns = () => {
    let columns: GridColDef[];
    switch (actionType) {
      case ActionTypes.BULK_UPLOAD_FILES:
        columns = bulkActionsColumns;
        break;

      case ActionTypes.BULK_EMAIL:
        columns = bulkActionsColumns.map(col => {
          if (col.field === 'documentKey') {
            col.field = 'effectiveDate';
            col.headerName = 'Effective Date';
            col.valueGetter = params =>
              formatters.formatFromIsoDateCustom(
                params.row.metadata?.effectiveDate,
                'MM/DD/YYYY'
              ) || EMPTY_FIELD_PLACEHOLDER;
          }
          return col;
        });
        break;
      case ActionTypes.BULK_EMPLOYER:
        columns = bulkEmployerColumns;
        break;
      case ActionTypes.BULK_WELCOME_EMAILS:
        columns = [
          {
            field: 'createdAt',
            flex: 1,
            headerName: 'Date',
            valueFormatter: params =>
              formatters.formatFromIsoDateCustom(
                params.value,
                'MM/DD/YYYY HH:mm:ss'
              )
          },
          {
            field: 'initiatedBy',
            flex: 1,
            headerName: 'Triggered By',
            valueGetter: params =>
              params.row.initiatedBy?.id || params.row.initiatedBy
          },
          {
            field: 'status',
            flex: 1,
            headerName: 'Status',
            renderCell: params => (
              <Badge
                color={
                  params.row.status === 'running'
                    ? 'neutral'
                    : params.row.status === 'error'
                      ? 'warning'
                      : 'success'
                }
                size='small'>
                {capitalize(params.row.status)}
              </Badge>
            )
          },
          {
            field: 'doc',
            flex: 1,
            headerName: 'Download CSV',
            renderCell: params => {
              return <DocumentDownloadButton docV2={params.row.metadata.doc} />;
            }
          },
          {
            align: 'right',
            field: 'errors',
            flex: 1,
            headerAlign: 'right',
            headerName: 'Failed',
            valueGetter: params => params.row.metadata?.errors
          },
          {
            align: 'right',
            field: 'successes',
            flex: 1,
            headerAlign: 'right',
            headerName: 'Succeeded',
            valueGetter: params => params.row.metadata?.successes
          }
        ];
        break;
      default:
        columns = [];
        break;
    }

    return columns;
  };

  return (
    <CardContent
      data-testid='action-history-table-card-content'
      loading={
        bulkUploadActions.isFetching ||
        bulkEmailActions.isFetching ||
        bulkWelcomeEmails.isFetching
      }
      sx={
        tableData?.length
          ? { height: '100%', padding: '0px !important', width: '100%' }
          : {}
      }>
      {!actionType && (
        <CardPlaceholder
          data-testid='no-action-selected-placeholder'
          icon={<HistoryIcon fontSize='inherit' />}
          subtitle='Select an action type to see its history'
        />
      )}
      {actionType && !tableData?.length && (
        <CardPlaceholder
          data-testid='no-data-placeholder'
          icon={<HistoryIcon fontSize='inherit' />}
          subtitle='There are no bulk actions'
        />
      )}
      {actionType && !!tableData?.length && (
        <Box display='flex' flexDirection='column' height='100%'>
          <Box
            display='flex'
            flexDirection='row'
            gap={theme => theme.spacing(2)}
            marginLeft={theme => theme.spacing(2)}
            marginTop={theme => theme.spacing(1)}>
            <FormControl sx={{ minWidth: '160px' }}>
              <ActionCenterDatePicker
                data-testid='action-history-table-date-from-filter'
                disableFuture
                format='MM/DD/YYYY'
                inputProps={{
                  autoComplete: 'off'
                }}
                label='Date From'
                maxDate={dayjs(filters.dateTo)}
                name='dateFrom'
                onChange={e => {
                  setFilters({ ...filters, dateFrom: e.target.value || null });
                }}
                size='small'
                value={filters.dateFrom}
                variant='outlined'
              />
            </FormControl>
            <FormControl sx={{ minWidth: '160px' }}>
              <ActionCenterDatePicker
                data-testid='action-history-table-date-to-filter'
                disableFuture
                format='MM/DD/YYYY'
                inputProps={{
                  autoComplete: 'off'
                }}
                label='Date To'
                name='dateTo'
                onChange={e => {
                  setFilters({ ...filters, dateTo: e.target.value || null });
                }}
                size='small'
                value={filters.dateTo}
                variant='outlined'
              />
            </FormControl>
            <FormControl
              fullWidth
              size='small'
              sx={{ maxWidth: '300px', minWidth: '160px' }}>
              <InputLabel>Triggered By</InputLabel>
              <Select
                data-testid='action-history-table-triggered-by-filter'
                label='Triggered By'
                onChange={e =>
                  setFilters({ ...filters, triggeredBy: e.target.value })
                }
                value={filters.triggeredBy}>
                <MenuItem value='any'>Any</MenuItem>
                {filtersOptions.triggeredBy.map(triggeredBy => (
                  <MenuItem key={triggeredBy} value={triggeredBy}>
                    {triggeredBy}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              size='small'
              sx={{
                maxWidth: '300px',
                minWidth: '160px',
                pr: theme => theme.spacing(2)
              }}>
              <InputLabel>Status</InputLabel>
              <Select
                data-testid='action-history-table-status-filter'
                label='Status'
                onChange={e =>
                  setFilters({ ...filters, status: e.target.value })
                }
                value={filters.status}>
                <MenuItem value='all'>All</MenuItem>
                {filtersOptions.status.map(status => (
                  <MenuItem key={status} value={status}>
                    {capitalize(status)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {tableData.length ? (
            <Box data-testid='action-history-table' display='flex' flexGrow='1'>
              <DataGridPro
                autoHeight
                columnBuffer={getColumns().length + 1}
                columns={getColumns()}
                disableColumnResize
                disableRowSelectionOnClick
                getDetailPanelContent={params =>
                  actionType === ActionTypes.BULK_EMPLOYER ? (
                    <ActionHistoryEmployerTableCollapsibleComponent
                      row={params.row}
                    />
                  ) : (
                    <ActionHistoryTableCollapsibleComponent row={params.row} />
                  )
                }
                getDetailPanelHeight={() => 'auto'}
                getRowId={row => row.actionId || row.id}
                pagination
                rows={filterData(tableData)}
                sx={{
                  '.MuiDataGrid-columnSeparator': {
                    display: 'none'
                  },
                  border: '0px !important'
                }}
              />
            </Box>
          ) : (
            <Box sx={{ mt: theme => theme.spacing(12) }}>
              <CardPlaceholder
                data-testid='no-data-filter-placeholder'
                icon={<HistoryIcon fontSize='inherit' />}
                subtitle='There are no bulk actions for these filters'
              />
            </Box>
          )}
        </Box>
      )}
    </CardContent>
  );
};
export default ActionHistoryTable;
