import { ColumnApi, GridApi } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import clsx from 'clsx';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useClickAway, useUpdateEffect } from 'react-use';

export type AgGridApi = {
  api?: GridApi;
  columnApi?: ColumnApi;
};

LicenseManager.setLicenseKey(
  'CompanyName=Vestwell Holdings Inc.,LicensedGroup=Vestwell Platform,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=8,LicensedProductionInstancesCount=1,AssetReference=AG-031499,SupportServicesEnd=15_October_2023_[v2]_MTY5NzMyNDQwMDAwMA==6832848fc9fbb21827f4424aeabce2e9'
);

export const AgGrid = forwardRef<
  AgGridApi,
  AgGridReactProps & { 'data-testid'?: string }
>((props, ref) => {
  const $grid = useRef<any>(null);
  const $gridContainer = useRef(null);

  const [isEditing, setIsEditing] = useState(false);

  useImperativeHandle(ref, () => $grid.current);

  const onCellEditingStarted = useCallback(
    params => {
      setIsEditing(true);

      if (props.onCellEditingStarted) {
        props.onCellEditingStarted(params);
      }
    },
    [props]
  );

  const onCellEditingStopped = useCallback(
    params => {
      setIsEditing(false);

      if (props.onCellEditingStopped) {
        props.onCellEditingStopped(params);
      }
    },
    [props]
  );

  useUpdateEffect(() => {
    if (!isEditing) {
      $grid.current?.api?.refreshCells();
    }
  }, [props.context, isEditing]);

  useClickAway(
    $gridContainer,
    e => {
      const isEditorPopover = e
        .composedPath()
        ?.some((path: any) => path.id === 'ag-grid-editor-popover');

      if (isEditorPopover) return;

      $grid.current.api.stopEditing();
      $grid.current.api.clearFocusedCell();
    },
    ['mouseup', 'touchend']
  );

  return (
    <div
      className={clsx('ag-theme-vestwell-admin-ui')}
      data-testid={props['data-testid']}
      ref={$gridContainer}>
      <AgGridReact
        ref={$grid}
        {...props}
        columnDefs={props.columnDefs}
        columnTypes={props.columnTypes}
        context={props.context}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
      />
    </div>
  );
});

AgGrid.defaultProps = {
  columnDefs: [],
  domLayout: 'autoHeight',
  rowData: []
};

AgGrid.displayName = 'AgGrid';
