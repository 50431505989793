interface ConversionContext {
  allocatedAmount?: number;
  forfeiture?: number;
  suspense?: number;
  tradeAmount?: number;
  userFileName?: string;
}

export enum ConversionStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Processed = 'Processed',
  PendingApproval = 'Pending Approval',
  PendingInvestment = 'Pending Investment',
  Failed = 'Failed',
  Complete = 'Complete',
  CompletedWithErrors = 'Completed with Errors'
}

export interface Conversion {
  id?: number;
  fileName: string;
  documentId: number;
  conversionType: string;
  status: ConversionStatus;
  context?: ConversionContext;
  approvedBy: any;
  submittedBy: any;
  createdAt: string;
  updatedAt: string;
  version: number;
}

export interface UpdateConversionDto {
  id?: number;
  documentId?: number;
  status?: ConversionStatus;
  context?: ConversionContext;
  version?: number;
}

export interface ConversionInvestmentsResponse {
  status: string;
  depositCode: string;
  amount: number;
  tracerId: string;
  participantId?: number;
  firstName?: string;
  lastName?: string;
  createdAt: string;
  transactions: any;
}
