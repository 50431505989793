import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import type { PlanV2Dto } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import { Search } from '@mui/icons-material';
import { Alert, LoadingButton } from '@mui/lab';
import {
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery } from '@tanstack/react-query';

import Decimal from 'decimal.js';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce, useToggle, useUpdateEffect } from 'react-use';

import { CancelContribution } from './CancelContribution.component';
import {
  Adjustments,
  Contributions,
  CurrencyField,
  Edit,
  Header,
  NewEmployees
} from './components';

export const ContributionSubmissionConfirmationRoute = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const [search, setSearch] = useState('');
  const [debouncedSesearch, setDebouncedSesearch] = useState('');
  const [doNotAchEmployer, toggledoNotAchEmployer] = useToggle(false);
  const [forfeiture, setForfeiture] = useState(0);

  const plan = useQuery<PlanV2Dto>(
    [PlanService.getPlanById.name, +params.planId],
    () => PlanService.getPlanById(+params.planId),
    { enabled: !!params.planId }
  );

  const rkId = plan.data?.data?.relationships?.recordKeeper?.data?.id;

  const validations = useQuery(
    [
      ContributionService.getContributionValidations.name,
      params.ucid,
      plan.data?.data?.relationships?.sponsor?.data?.id,
      plan.data?.data?.id
    ],
    () =>
      ContributionService.getContributionValidations(
        { ucid: params.ucid },
        {
          sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
          sponsorPlanId: plan.data?.data?.id
        }
      ),
    {
      cacheTime: 1,
      keepPreviousData: false,
      refetchInterval: (data, query) => {
        if (data?.isReady || query.state?.dataUpdateCount > 55) return false;

        if (query.state?.dataUpdateCount <= 5) return 2000;

        return 5000;
      },
      refetchOnMount: 'always'
    }
  );

  const contribution = useQuery(
    [
      ContributionService.getContributionDetails.name,
      0,
      plan.data?.data?.id,
      plan.data?.data?.relationships?.sponsor?.data?.id,
      params.ucid
    ],
    () =>
      ContributionService.getContributionDetails({
        pageSize: 0,
        planId: plan.data?.data?.id,
        sort: 'lastName',
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        ucid: params.ucid
      }),
    {
      cacheTime: 1,
      enabled:
        !!validations.data?.isReady && !!params.ucid && !!plan.data?.data,
      keepPreviousData: false,
      refetchOnMount: 'always'
    }
  );

  const contributionForfeiture = useQuery(
    [
      ContributionService.getForfeiture.name,
      plan.data?.data?.id,
      plan.data?.data?.relationships?.sponsor?.data?.id,
      params.ucid
    ],
    () =>
      ContributionService.getForfeiture(
        {
          ucid: params.ucid
        },
        {
          sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
          sponsorPlanId: plan.data?.data?.id
        }
      ),
    {
      cacheTime: 1,
      enabled:
        !!validations.data?.isReady &&
        !!params.ucid &&
        !!plan.data?.data &&
        !!contribution.data,
      keepPreviousData: false,
      refetchOnMount: 'always'
    }
  );

  const postContributionConfirmation = useMutation(
    () =>
      ContributionService.postContributionConfirmation(
        { ucid: params.ucid },
        {
          doNotAchEmployer,
          sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
          sponsorPlanId: plan.data?.data?.id
        }
      ),
    {
      onError: () =>
        snackbar.showSnackbar({
          message: 'Could not submit!',
          severity: 'error'
        })
    }
  );

  const postForfeiture = useMutation(() =>
    ContributionService.postForfeiture(
      {
        ucid: params.ucid
      },
      {
        forfeitureAmount: forfeiture,
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        sponsorPlanId: plan.data?.data?.id
      }
    )
  );

  useDebounce(() => setDebouncedSesearch(search), 1000, [search]);

  const availableForfeiture = useMemo(
    () =>
      Decimal.sub(
        Math.min(
          contributionForfeiture.data?.maxAvailableAmount ?? 0,
          contributionForfeiture.data?.maxContributionAmount ?? 0
        ),
        forfeiture ?? 0
      ).toNumber(),
    [contributionForfeiture.data, forfeiture]
  );

  const achEmployer = useMemo(
    () =>
      Decimal.sub(
        contribution.data?.totals?.total ?? 0,
        forfeiture ?? 0
      ).toNumber(),
    [contribution.data, forfeiture]
  );

  const employeeContribution = useMemo(() => {
    if (rkId === 6) {
      return contribution.data?.totals?.totalEsaEmployeeDeferral ?? 0;
    }

    return Decimal.sum(
      contribution.data?.totals?.totalAt ?? 0,
      contribution.data?.totals?.totalSd ?? 0,
      contribution.data?.totals?.totalRc ?? 0,
      contribution.data?.totals?.totalEsaEmployeeDeferral ?? 0
    ).toNumber();
  }, [contribution.data, rkId]);

  const employerContribution = useMemo(() => {
    if (rkId === 6) {
      return Decimal.sum(
        contribution.data?.totals?.totalEsaMilestoneBonus ?? 0,
        contribution.data?.totals?.totalEsaInitialDepositBonus ?? 0,
        contribution.data?.totals?.totalEsaEmployerMatch ?? 0
      ).toNumber();
    }

    return Decimal.sum(
      contribution.data?.totals?.totalSh ?? 0,
      contribution.data?.totals?.totalEm ?? 0,
      contribution.data?.totals?.totalPs ?? 0,
      contribution.data?.totals?.totalQc ?? 0,
      contribution.data?.totals?.totalQm ?? 0
    ).toNumber();
  }, [contribution.data, rkId]);

  const onSearchChanged = useCallback(e => setSearch(e.target.value), []);

  const onSubmit = useCallback(async () => {
    if (forfeiture > 0) {
      await postForfeiture.mutateAsync();
    }

    await postContributionConfirmation.mutateAsync();
  }, [forfeiture]);

  useUpdateEffect(() => {
    setForfeiture(contributionForfeiture.data?.defaultForfeitureAmount ?? 0);
  }, [contributionForfeiture.data]);

  useUpdateEffect(() => {
    if (postContributionConfirmation.isSuccess) {
      const timeout = setTimeout(() => {
        snackbar.showSnackbar({
          message: 'Submission successful!',
          severity: 'success'
        });

        navigate(
          `/plans/${plan.data?.data?.id}/contributions/${params.ucid}/overview`,
          { replace: true }
        );
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [postContributionConfirmation.isSuccess]);

  const isLoading =
    validations.isFetching ||
    validations.isLoading ||
    contribution.isFetching ||
    contribution.isLoading ||
    !validations.data?.isReady;

  const isForfeitureValid = availableForfeiture >= 0;

  return (
    <Grid container>
      <Grid mb={2} mt={3} xs={12}>
        <Header
          date={contribution.data?.payrollDate}
          flowSubtype={contribution.data?.key?.flowSubtype}
          isLoading={contribution.isLoading || contribution.isFetching}
          payGroupCode={contribution.data?.division}
          payGroupName={contribution.data?.payGroupName}
          planId={plan.data?.data?.id}
          planName={plan.data?.data?.attributes?.name}
        />
      </Grid>
      <Grid display='flex' mb={5} xs={12}>
        <Grid mr={2}>
          <Edit planId={plan.data?.data?.id} ucid={params.ucid} />
        </Grid>
        <AccessControl requires={[FeatureLevelPermissions.WRITE_CONTRIBUTION]}>
          <Grid>
            <CancelContribution
              flowSubtype={contribution.data?.key?.flowSubtype}
              sponsorId={plan.data?.data?.relationships?.sponsor?.data?.id}
              sponsorPlanId={plan.data?.data?.id}
              ucid={contribution.data?.ucid}
            />
          </Grid>
        </AccessControl>
      </Grid>
      <Grid xs={12}>
        <Card sx={{ border: '2px solid #039BE5' }} variant='outlined'>
          <Grid
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            p={2}>
            <Typography variant='h5'>Review</Typography>
            <AccessControl
              requires={[FeatureLevelPermissions.WRITE_CONTRIBUTION]}>
              <LoadingButton
                data-testid='submitButton'
                disabled={!isForfeitureValid}
                loading={
                  isLoading ||
                  postContributionConfirmation.isLoading ||
                  postContributionConfirmation.isSuccess
                }
                onClick={onSubmit}
                variant='contained'>
                Submit
              </LoadingButton>
            </AccessControl>
          </Grid>
          <Divider />
          <Grid display='flex' p={2}>
            <Grid container display='flex' xs={6}>
              <Grid xs={5}>
                <Typography variant='h6'>Total Processed Amount</Typography>
                <Typography variant='h4'>
                  {isLoading ? (
                    <Skeleton width={150} />
                  ) : (
                    formatters.formatDollars(
                      contribution.data?.totals?.total ?? 0
                    )
                  )}
                </Typography>
              </Grid>
              <Grid xs={7}>
                <Grid display='flex' justifyContent='space-between' my={1}>
                  <Typography>Contributing Employees</Typography>
                  <Typography>
                    {isLoading ? (
                      <Skeleton width={50} />
                    ) : (
                      contribution.data?.contributedCount
                    )}
                  </Typography>
                </Grid>
                <Divider />
                <Grid display='flex' justifyContent='space-between' my={1}>
                  <Typography>Total Employee Contribution</Typography>
                  <Typography>
                    {isLoading ? (
                      <Skeleton width={50} />
                    ) : (
                      formatters.formatDollars(employeeContribution)
                    )}
                  </Typography>
                </Grid>
                <Divider />
                <Grid display='flex' justifyContent='space-between' my={1}>
                  <Typography>Total Employer Contribution</Typography>
                  <Typography>
                    {isLoading ? (
                      <Skeleton width={50} />
                    ) : (
                      formatters.formatDollars(employerContribution)
                    )}
                  </Typography>
                </Grid>
                {![4, 6].includes(rkId) && (
                  <>
                    <Divider />
                    <Grid display='flex' justifyContent='space-between' my={1}>
                      <Typography>Total Loan</Typography>
                      <Typography>
                        {isLoading ? (
                          <Skeleton width={50} />
                        ) : (
                          formatters.formatDollars(
                            contribution.data?.totals?.totalLn
                          )
                        )}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Divider />
                <Grid display='flex' justifyContent='space-between' my={1}>
                  <Typography>Total Rejected Amount</Typography>
                  <Typography color='red'>
                    {isLoading ? (
                      <Skeleton width={50} />
                    ) : (
                      formatters.formatDollars(
                        contribution.data?.totals.totalReduction ?? 0
                      )
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid mx={3}>
              <Divider orientation='vertical' />
            </Grid>
            {![4, 6].includes(rkId) && (
              <Grid container xs={6}>
                <Grid xs={8}>
                  <Typography mb={2} variant='h6'>
                    Funding Contribution
                  </Typography>
                  <Grid display='flex' justifyContent='space-between' my={1}>
                    <Typography>Suspense</Typography>
                    <Typography>
                      {isLoading ? (
                        <Skeleton width={50} />
                      ) : (
                        formatters.formatDollars(0)
                      )}
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid
                    alignItems='center'
                    display='flex'
                    justifyContent='space-between'
                    my={1}>
                    <Grid>
                      <Typography>Forfeiture</Typography>
                      <Typography color='gray' display='flex'>
                        Available:{' '}
                        {isLoading ? (
                          <Skeleton width={50} />
                        ) : (
                          formatters.formatDollars(
                            isForfeitureValid ? availableForfeiture : 0
                          )
                        )}
                      </Typography>
                    </Grid>
                    <Grid>
                      <CurrencyField
                        disabled={
                          contributionForfeiture.isLoading ||
                          contributionForfeiture.isFetching ||
                          contributionForfeiture.isError
                        }
                        error={!isForfeitureValid}
                        helperText={
                          isForfeitureValid ? '' : `Exceed maximum amount`
                        }
                        onChange={setForfeiture}
                        value={forfeiture}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid display='flex' justifyContent='space-between' my={1}>
                    <Typography>ACH Employer</Typography>
                    <Typography>
                      {isLoading ? (
                        <Skeleton width={50} />
                      ) : (
                        formatters.formatDollars(
                          doNotAchEmployer ? 0 : achEmployer
                        )
                      )}
                    </Typography>
                  </Grid>
                  {rkId !== 2 && (
                    <AccessControl
                      requires={[
                        FeatureLevelPermissions.WRITE_CONTRIBUTION_ACH_SKIP
                      ]}>
                      <Grid
                        alignItems='center'
                        data-testid='doNotAchEmployerField'
                        display='flex'
                        justifyContent='space-between'
                        my={1}>
                        <FormGroup
                          onChange={toggledoNotAchEmployer}
                          sx={{ py: 1 }}>
                          <FormControlLabel
                            control={<Checkbox />}
                            disabled={isLoading}
                            label='Don’t ACH Employer'
                          />
                        </FormGroup>
                        {doNotAchEmployer && (
                          <Alert severity='info' sx={{ py: 1 }}>
                            {formatters.formatDollars(achEmployer ?? 0)} from
                            MoneyIn will be used
                          </Alert>
                        )}
                      </Grid>
                    </AccessControl>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
      <Grid my={3} xs={12}>
        <Typography variant='h5'>Details</Typography>
      </Grid>
      <Grid mb={3} xs={3}>
        <TextField
          InputProps={{
            'aria-placeholder': 'Search Employees',
            onChange: onSearchChanged,
            placeholder: 'Search Employees',
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            )
          }}
          fullWidth={true}
          variant='outlined'
        />
      </Grid>
      <Grid my={3} xs={12}>
        {validations.data?.isReady ? (
          <NewEmployees
            search={debouncedSesearch}
            sponsorId={plan.data?.data?.relationships?.sponsor?.data?.id}
            sponsorPlanId={plan.data?.data?.id}
            ucid={params.ucid}
          />
        ) : (
          <Grid>
            <Skeleton height={100} variant='text' />
            <Skeleton height={300} variant='rectangular' />
          </Grid>
        )}
      </Grid>
      <Grid my={3} xs={12}>
        {validations.data?.isReady ? (
          <Adjustments
            search={debouncedSesearch}
            sponsorId={plan.data?.data?.relationships?.sponsor?.data?.id}
            sponsorPlanId={plan.data?.data?.id}
            submissionInitiator={contribution.data?.pngStatuses?.[0]?.initiator}
            ucid={params.ucid}
          />
        ) : (
          <Grid>
            <Skeleton height={100} variant='text' />
            <Skeleton height={300} variant='rectangular' />
          </Grid>
        )}
      </Grid>
      <Grid my={3} xs={12}>
        {validations.data?.isReady ? (
          <Contributions
            planId={+params.planId}
            search={debouncedSesearch}
            ucid={params.ucid}
          />
        ) : (
          <Grid>
            <Skeleton height={100} variant='text' />
            <Skeleton height={300} variant='rectangular' />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
