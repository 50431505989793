import { SecurityApiIncludeOption } from '@/models/ops/security/SecurityApiIncludeOption.model';
import { SecurityDto } from '@/models/ops/security/SecurityDTO.model';
import ApiService from '@/services/Api.service';

class SecurityService {
  static async get(
    cusipOrTicker: string,
    params: {
      include?: SecurityApiIncludeOption[];
    } = {}
  ): Promise<SecurityDto> {
    return ApiService.getJson<SecurityDto>(`/securities/${cusipOrTicker}`, {
      include: params.include?.join(',')
    });
  }

  static async search(params: {
    include?: SecurityApiIncludeOption[];
    mstarFundId?: string;
  }): Promise<SecurityDto[]> {
    return ApiService.getJson<SecurityDto[]>(`/securities`, {
      include: params.include?.join(','),
      mstarFundId: params.mstarFundId
    });
  }
}

export default SecurityService;
