import AccessControl from '@/components/access-control/AccessControl.component';
import { GroupIcon } from '@/components/icon/GroupIcon';
import LinearLoading from '@/components/linear-loading';
import { useDialog } from '@/contexts/DialogContext';
import { useOpsTooling } from '@/contexts/OpsToolingContext';
import usePayrollSetups from '@/hooks/usePayrollSetups';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ExternalPlanIds } from '@/routes/plans/plan-detail/PayGroups/ExternalPlanIds.component';
import { PayGroupsModal } from '@/routes/plans/plan-detail/PayGroups/PayGroupsModal.component';
import { PayGroupsTableRow } from '@/routes/plans/plan-detail/PayGroups/PayGroupsTableRow.component';
import { OpsToolingType } from '@/services/payroll-integrations.service';
import { MoreVert } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import type { Payroll } from '@vestwell-api/scala';

import { sortBy } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import { PayGroupsUpdateModal } from './PayGroupsUpdateModal.component';

interface PayGroupProps {
  sponsorPlanId: number | string;
  sponsorId: number;
  isVoyaPlan?: boolean;
  isStateIRA?: boolean;
}

export const PayGroups: FC<PayGroupProps> = (props: PayGroupProps) => {
  const { showOpsToolingDrawer } = useOpsTooling();
  const ctx = useDialog();
  const payrollSetupsQuery = usePayrollSetups(+props.sponsorPlanId);
  const payrollSetups = useMemo(() => {
    return payrollSetupsQuery?.data?.payrollSetups?.length
      ? sortBy(
          [...payrollSetupsQuery.data.payrollSetups],
          ['isActive']
        ).reverse()
      : [];
  }, [payrollSetupsQuery.data]);
  const isApiPlan = payrollSetupsQuery.data?.isApiPlan || false;

  const [editPaygroup, setEditPaygroup] = useState<
    Payroll.GetSetups.ResponseBody[0] | undefined
  >(undefined);

  const isLoading = !payrollSetupsQuery.isSuccess || payrollSetupsQuery.isError;

  const [showExternalPlanIds, toggleExternalPlanIds] = useToggle(false);
  const [showAddPayGroups, toggleAddPayGroups] = useToggle(false);
  const [showEditPayGroup, toggleEditPayGroup] = useToggle(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const integratedPayGroups = useMemo(
    () =>
      payrollSetups?.filter(
        p => p.integrationMethod180 || p.integrationMethod360
      ),
    [payrollSetups]
  );

  const onClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const handleOpsToolingMenuClick = useCallback(
    (toolingType: OpsToolingType) => {
      showOpsToolingDrawer(+props.sponsorPlanId, toolingType);
      onClose();
    },
    [props.sponsorPlanId]
  );

  return (
    <Paper data-testid='payGroupsTableCard' elevation={0} variant='outlined'>
      {isLoading && <LinearLoading />}
      <Grid container direction='row' justifyContent='space-between'>
        <Typography p={2} variant='h5'>
          Pay Groups
        </Typography>
        <Grid
          alignItems='center'
          display='flex'
          justifyContent='flex-end'
          pr={1}>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_COMPANY_PAYROLL]}>
            <Button
              color='primary'
              data-testid='openIntegrationSettingsBtn'
              disabled={!payrollSetups?.length}
              onClick={() => {
                ctx.openDialog({
                  customContent: (
                    <PayGroupsUpdateModal
                      onUpdate={payrollSetupsQuery.refetch}
                      payGroupsAmount={payrollSetups.length}
                      sponsorPlanId={props.sponsorPlanId}
                    />
                  )
                });
              }}
              variant='text'>
              INTEGRATION SETTINGS
            </Button>
          </AccessControl>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_COMPANY_PAYROLL]}>
            <Button onClick={toggleAddPayGroups}>Add Pay Group</Button>
          </AccessControl>
          <Grid item>
            <IconButton
              aria-controls={anchor ? 'pay-group-menu' : undefined}
              aria-expanded={!!anchor}
              aria-haspopup='true'
              data-testid='openMenuButton'
              onClick={e => setAnchor(e.currentTarget)}
              size='small'
              sx={{ ml: 2 }}>
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchor}
              id='pay-group-menu'
              onClick={onClose}
              onClose={onClose}
              open={!!anchor}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
              <MenuItem
                onClick={() => {
                  toggleExternalPlanIds(true);
                  onClose();
                }}>
                Manage External IDs
              </MenuItem>
              <AccessControl
                requires={[FeatureLevelPermissions.READ_COMPANY_PAYROLL]}>
                {isApiPlan && (
                  <>
                    <MenuItem
                      data-testid='openOpsToolingConnectionStatus'
                      onClick={() =>
                        handleOpsToolingMenuClick(
                          OpsToolingType.connectionStatus
                        )
                      }>
                      View Payroll Provider Data - Connection Status
                    </MenuItem>
                    <MenuItem
                      data-testid='openOpsToolingPaySchedule'
                      onClick={() =>
                        handleOpsToolingMenuClick(OpsToolingType.payGroupInfo)
                      }>
                      View Payroll Provider Data - Pay Schedules
                    </MenuItem>
                  </>
                )}
              </AccessControl>
            </Menu>

            <ExternalPlanIds
              open={showExternalPlanIds}
              sponsorPlanId={+props.sponsorPlanId}
              toggleOpen={toggleExternalPlanIds}
            />
          </Grid>
        </Grid>
      </Grid>
      <TableContainer>
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell
                padding='none'
                sx={{ minWidth: theme => theme.spacing(7) }}
              />
              <TableCell align='left' sx={{ px: 0, py: 1, width: '50%' }}>
                Name
              </TableCell>
              <TableCell align='left' sx={{ px: 0, py: 1, width: '50%' }}>
                Integration
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payrollSetups?.map(row => (
              <PayGroupsTableRow
                key={row.id}
                row={row}
                sponsorId={+props.sponsorId}
                sponsorPlanId={props.sponsorPlanId}
                toggleEditModal={paygroup => {
                  setEditPaygroup(paygroup);
                  toggleEditPayGroup(true);
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!payrollSetupsQuery.isFetching && !payrollSetups?.length && (
        <Grid
          alignItems='center'
          container
          data-testid='noPayGroupsData'
          direction='column'
          justifyContent='space-around'
          pb={5}
          pt={3}>
          <GroupIcon />
          <Typography className='no-pay-groups' variant='body1'>
            No Pay Groups
          </Typography>
        </Grid>
      )}

      <PayGroupsModal
        integratedPayGroups={integratedPayGroups}
        isStatePlan={props.isStateIRA}
        isVoyaPlan={props.isVoyaPlan}
        open={showAddPayGroups}
        payGroups={payrollSetups}
        sponsorId={+props.sponsorId}
        sponsorPlanId={+props.sponsorPlanId}
        toggleOpen={toggleAddPayGroups}
      />

      {showEditPayGroup && (
        <PayGroupsModal
          integratedPayGroups={integratedPayGroups}
          isStatePlan={props.isStateIRA}
          isVoyaPlan={props.isVoyaPlan}
          open={showEditPayGroup}
          payGroup={editPaygroup}
          payGroups={payrollSetups}
          sponsorId={+props.sponsorId}
          sponsorPlanId={+props.sponsorPlanId}
          toggleOpen={toggleEditPayGroup}
        />
      )}
    </Paper>
  );
};
