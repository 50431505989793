import { DividendAccrualRateDto } from '@/models/ops/dividend-accrual-rates/DividendAccrualRateDTO.model';
import ApiService from '@/services/Api.service';

type ApiResponse = {
  results: DividendAccrualRateDto[];
  pagination: {
    pageSize: number;
    total: number;
  };
};

export type DividendAccrualRateSearchParams = {
  cusip: string;
  effectiveStartDate?: string;
  effectiveEndDate?: string;
  orderBy?: keyof DividendAccrualRateDto;
  orderByDirection?: string;
  page?: number;
  pageSize?: number;
};

class DividendAccrualRateService {
  static async search(
    params: DividendAccrualRateSearchParams
  ): Promise<ApiResponse> {
    for (const [key, value] of Object.entries(params)) {
      // filter undefined params to avoid request validation errors
      if (value === undefined || value === '')
        delete params[key as keyof DividendAccrualRateSearchParams];
    }

    return ApiService.getJson<ApiResponse>(`/dividend-accrual-rates`, {
      cusip: params.cusip,
      effectiveEndDate: params.effectiveEndDate,
      effectiveStartDate: params.effectiveStartDate,
      orderBy: params.orderBy,
      orderByDirection: params.orderByDirection,
      page: params.page,
      pageSize: params.pageSize
    });
  }
}

export default DividendAccrualRateService;
