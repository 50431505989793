import AccessControl from '@/components/access-control/AccessControl.component';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ContributionService from '@/services/Contribution.service';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  CardContent,
  CircularProgress,
  Unstable_Grid2 as Grid,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';

import type { FC } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';

import { EditMetadata } from './EditMetadata';

interface MetadataCardProps {
  ucid: string;
  sponsorId: number;
  planId: number;
}

const MetadataCard: FC<MetadataCardProps> = props => {
  const ref = useRef();
  const [isPopperOpen, togglePopperOpen] = useToggle(false);
  const [isEdit, toggleEdit] = useToggle(false);

  const contribution = useQuery(
    [
      ContributionService.getContributionDetails.name,
      props.sponsorId,
      props.planId,
      props.ucid
    ],
    async () => {
      return ContributionService.getContributionDetails({
        planId: props.planId,
        sponsorId: props.sponsorId,
        ucid: props.ucid
      });
    },
    { enabled: !!props.ucid && !!props.planId && !!props.sponsorId }
  );

  const isLtSubmission = useMemo(
    () => contribution.data?.recordkeeper === 'LT',
    [contribution.data]
  );

  const isSubmissionCompleted = useMemo(
    () => ['COMPLETE', 'COMPLETED'].includes(contribution.data?.status),
    [contribution.data]
  );

  const onMouseEnter = useCallback(() => togglePopperOpen(true), []);

  const onMouseLeave = useCallback(() => togglePopperOpen(false), []);

  return (
    <>
      <Card elevation={0} sx={{ height: '100%' }} variant='outlined'>
        <CardContent>
          <Grid
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            mb={2}>
            <Typography variant='h5'>Metadata</Typography>
            <AccessControl
              requires={[
                FeatureLevelPermissions.WRITE_CONTRIBUTION_EXPECTED_PAY_DATE
              ]}>
              <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <LoadingButton
                  data-testid='editButton'
                  disabled={isLtSubmission || !isSubmissionCompleted}
                  loading={contribution.isFetching}
                  onClick={toggleEdit}
                  ref={ref}>
                  Edit
                </LoadingButton>
              </div>
              {isLtSubmission && (
                <Popper
                  anchorEl={ref.current}
                  data-testid='editButtonPopover'
                  open={isPopperOpen}
                  placement='top-start'>
                  <Paper>
                    <Typography p={2}>
                      This is an LT submitted contribution,
                      <br /> please make a PS2 ticket for ABQ <br /> to update
                      this pay date
                    </Typography>
                  </Paper>
                </Popper>
              )}
            </AccessControl>
          </Grid>
          <Grid
            container
            data-testid='expectedPayrollDate'
            justifyContent='space-between'
            mb={2}>
            <Typography
              color={grey[600]}
              data-testid='expectedPayrollDateLabel'>
              Expected Payroll Date
            </Typography>
            <Typography data-testid='expectedPayrollDateValue'>
              {contribution.isFetching ? (
                <CircularProgress color='inherit' size={15} />
              ) : (
                contribution.data?.key?.expectedPayrollDate
              )}
            </Typography>
          </Grid>
          <Grid
            container
            data-testid='payGroupName'
            justifyContent='space-between'
            mb={2}>
            <Typography color={grey[600]} data-testid='payGroupNameLabel'>
              Pay Group
            </Typography>
            <Typography
              color={contribution.data?.key?.payGroupName ? '' : grey[500]}
              data-testid='payGroupNameValue'>
              {contribution.isFetching ? (
                <CircularProgress color='inherit' size={15} />
              ) : (
                contribution.data?.key?.payGroupName ?? 'null'
              )}
            </Typography>
          </Grid>
          <Grid
            container
            data-testid='payGroupCode'
            justifyContent='space-between'
            mb={2}>
            <Typography color={grey[600]} data-testid='payGroupCodeLabel'>
              Pay Group Code
            </Typography>
            <Typography
              color={contribution.data?.division ? '' : grey[500]}
              data-testid='payGroupCodeValue'>
              {contribution.isFetching ? (
                <CircularProgress color='inherit' size={15} />
              ) : (
                contribution.data?.division ?? 'null'
              )}
            </Typography>
          </Grid>
          <Grid
            container
            data-testid='type'
            justifyContent='space-between'
            mb={2}>
            <Typography color={grey[600]} data-testid='typeLabel'>
              Type
            </Typography>
            <Typography data-testid='typeValue'>
              {contribution.isFetching ? (
                <CircularProgress color='inherit' size={15} />
              ) : contribution.data?.isOffCycle ? (
                'Off-Cycle'
              ) : (
                'Regular'
              )}
            </Typography>
          </Grid>
          <Grid
            container
            data-testid='activityId'
            justifyContent='space-between'>
            <Typography color={grey[600]} data-testid='activityIdLabel'>
              Activity ID
            </Typography>
            <Typography data-testid='activityIdValue'>
              {contribution.isFetching ? (
                <CircularProgress color='inherit' size={15} />
              ) : (
                contribution.data?.activityId
              )}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
      <EditMetadata
        isOpen={isEdit}
        onClose={toggleEdit}
        planId={props.planId}
        sponsorId={props.sponsorId}
        ucid={props.ucid}
      />
    </>
  );
};

export default MetadataCard;
