import { useSnackbar } from '@/contexts/SnackBarContext';
import ContributionService from '@/services/Contribution.service';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';

import { useCallback } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type StartRegularContributionProps = {
  division?: string;
  payrollDate: string;
  sponsorId: number;
  planId: number;
};

export const StartRegularContribution: FC<
  StartRegularContributionProps
> = props => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const postRegularContribution = useMutation(
    () =>
      ContributionService.postContribution({
        flowSubtype: 'regular',
        isOffCycle: false,
        payrollDate: props.payrollDate,
        sponsorId: props.sponsorId,
        sponsorPlanId: props.planId,
        ...(props.division ? { division: props.division } : {})
      }),
    {
      onError: () =>
        snackbar.showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        }),
      onSuccess: response =>
        navigate(
          `/plans/${props.planId}/contributions/${response?.ucid}/submission`
        )
    }
  );

  const onSubmit = useCallback(() => postRegularContribution.mutateAsync(), []);

  return (
    <LoadingButton
      data-testid='startRegularContributionButton'
      loading={postRegularContribution.isLoading}
      onClick={onSubmit}
      variant='contained'>
      Start
    </LoadingButton>
  );
};
