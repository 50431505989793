import { PlanFeatures } from '@/models/PlanFeaturesDto.model';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import { omit } from 'lodash';
import { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  cell: {
    color: grey['900']
  },
  header: {
    color: theme.palette.text.primary
  }
}));

export const useGridColumns = (
  planFeatures: PlanFeatures,
  flowSubtype: string,
  recordkeeperName: string
) => {
  const classes = useStyles();

  return useMemo(() => {
    if (!planFeatures || !flowSubtype || !recordkeeperName) return [];

    const columns = [
      {
        cellStyle: { backgroundColor: grey.A100, color: grey['900'] },
        field: 'ssn',
        headerClass: classes.header,
        headerName: 'SSN',
        pinned: 'left',
        type: 'ssn',
        width: 140
      },
      {
        cellStyle: { backgroundColor: grey.A100, color: grey['900'] },
        field: 'first_name',
        headerClass: classes.header,
        headerName: 'First Name',
        pinned: 'left',
        width: 130
      },
      {
        cellStyle: { backgroundColor: grey.A100, color: grey['900'] },
        field: 'last_name',
        headerClass: classes.header,
        headerName: 'Last Name',
        pinned: 'left',
        width: 130
      },
      {
        cellStyle: { backgroundColor: grey.A100, color: grey['900'] },
        field: 'date_of_birth',
        headerClass: classes.header,
        headerName: 'DOB',
        pinned: 'left',
        type: 'date',
        width: 130
      },
      {
        cellStyle: { backgroundColor: grey.A100, color: grey['900'] },
        field: 'employee_group_name',
        headerClass: classes.header,
        headerName: 'Employee Group',
        permissions: {
          recordkeepers: ['Vestwell ESA']
        },
        pinned: 'left',
        width: 150
      },
      {
        cellStyle: { backgroundColor: grey.A100, color: grey['900'] },
        field: 'sd_election',
        headerClass: classes.header,
        headerName: 'PRE-TAX',
        hide: true,
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          planFeatureEnabled: planFeatures?.isPreTaxContributionAllowed,
          recordkeepers: ['Vestwell Sub-Accounting Platform', 'Voya']
        },
        pinned: 'left',
        width: 90
      },
      {
        cellStyle: { backgroundColor: grey.A100, color: grey['900'] },
        field: 'rc_election',
        headerClass: classes.header,
        headerName: 'ROTH',
        hide: true,
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          planFeatureEnabled:
            planFeatures?.isRothIraContributionAllowed ||
            planFeatures?.isSalaryRothDeferral,
          recordkeepers: [
            'Vestwell Sub-Accounting Platform',
            'Voya',
            'Surpas (State IRA)'
          ]
        },
        pinned: 'left',
        width: 80
      },
      {
        cellStyle: { backgroundColor: grey.A100, color: grey['900'] },
        field: 'at_election',
        headerClass: classes.header,
        headerName:
          recordkeeperName === 'Vestwell ESA' ? 'Savings Rate' : 'After-Tax',
        hide: true,
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          planFeatureEnabled: planFeatures?.isAfterTaxContributionAllowed,
          recordkeepers: [
            'Vestwell Sub-Accounting Platform',
            'Voya',
            'Vestwell ESA'
          ]
        },
        pinned: 'left',
        width: 130
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'sd',
        headerClass: classes.header,
        headerName: 'Pre-Tax',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          planFeatureEnabled: planFeatures?.isPreTaxContributionAllowed,
          recordkeepers: ['Vestwell Sub-Accounting Platform', 'Voya']
        },
        type: 'currency',
        width: 120
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'rc',
        headerClass: classes.header,
        headerName: 'Roth',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          planFeatureEnabled:
            planFeatures?.isRothIraContributionAllowed ||
            planFeatures?.isSalaryRothDeferral,
          recordkeepers: [
            'Vestwell Sub-Accounting Platform',
            'Voya',
            'Surpas (State IRA)'
          ]
        },
        type: 'currency',
        width: 120
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'at',
        headerClass: classes.header,
        headerName: 'After-Tax',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          planFeatureEnabled: planFeatures?.isAfterTaxContributionAllowed,
          recordkeepers: ['Vestwell Sub-Accounting Platform', 'Voya']
        },
        type: 'currency',
        width: 120
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'sh',
        headerClass: classes.header,
        headerName: 'S. Harbor',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          planFeatureEnabled:
            planFeatures?.isSafeHarborMatchAllowed ||
            planFeatures?.isSafeHarborNonElectiveAllowed,
          recordkeepers: ['Vestwell Sub-Accounting Platform', 'Voya']
        },
        type: 'currency',
        width: 120
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'em',
        headerClass: classes.header,
        headerName: 'D. Match',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          planFeatureEnabled: planFeatures?.isEmployerMatching,
          recordkeepers: ['Vestwell Sub-Accounting Platform', 'Voya']
        },
        type: 'currency',
        width: 120
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'ps',
        headerClass: classes.header,
        headerName: 'P. Sharing',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          planFeatureEnabled: planFeatures?.isProfitSharing,
          recordkeepers: ['Vestwell Sub-Accounting Platform', 'Voya']
        },
        type: 'currency',
        width: 120
      },
      ...[...Array(5).keys()].reduce(
        (acc, current) => [
          ...acc,
          {
            cellClass: classes.cell,
            field: `loan_num_${current + 1}`,
            headerName: 'Loan Number',
            permissions: {
              flowSubtypes: [
                'regular',
                'off-cycle_supplemental_pay',
                'loan_payment'
              ],
              planFeatureEnabled:
                planFeatures?.isLoansAllowed || planFeatures?.hasPriorLoans,
              recordkeepers: ['Voya']
            },
            width: 150
          },
          {
            cellClass: classes.cell,
            cellEditorParams: params => {
              return {
                values: params.data[`${params.colDef.field}_options`] || []
              };
            },
            editable: params => {
              return params.data?.[`loan_amt_${current + 1}_options`]?.length;
            },
            field: `loan_amt_${current + 1}`,
            headerName: 'Loan Payment',
            permissions: {
              flowSubtypes: [
                'regular',
                'off-cycle_supplemental_pay',
                'loan_payment'
              ],
              planFeatureEnabled:
                planFeatures?.isLoansAllowed || planFeatures?.hasPriorLoans,
              recordkeepers: ['Voya']
            },
            type: 'select',
            width: 150
          }
        ],
        []
      ),
      {
        cellClass: classes.cell,
        editable: recordkeeperName !== 'Voya',
        field: `ln`,
        headerClass: classes.header,
        headerName: 'Loan',
        permissions: {
          flowSubtypes: [
            'regular',
            'off-cycle_supplemental_pay',
            'loan_payment'
          ],
          planFeatureEnabled:
            planFeatures?.isLoansAllowed || planFeatures?.hasPriorLoans,
          recordkeepers: ['Vestwell Sub-Accounting Platform', 'Voya']
        },
        type: 'currency',
        width: 120
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'qc',
        headerClass: classes.header,
        headerName: 'QNEC',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          planFeatureEnabled: planFeatures?.isQnecQmacAllowed,
          recordkeepers: ['Vestwell Sub-Accounting Platform', 'Voya']
        },
        type: 'currency',
        width: 120
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'qm',
        headerClass: classes.header,
        headerName: 'QMAC',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          planFeatureEnabled: planFeatures?.isQnecQmacAllowed,
          recordkeepers: ['Vestwell Sub-Accounting Platform', 'Voya']
        },
        type: 'currency',
        width: 120
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'current_hours',
        headerClass: classes.header,
        headerName: 'Period Hours Worked',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: ['Vestwell Sub-Accounting Platform']
        },
        type: 'numeric',
        width: 200
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'salary',
        headerClass: classes.header,
        headerName: 'Period Gross Pay',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: ['Vestwell Sub-Accounting Platform']
        },
        type: 'currency',
        width: 200
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'esaEeContribution',
        headerClass: classes.header,
        headerName: 'Savings $ amount',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: ['Vestwell ESA']
        },
        type: 'currency',
        width: 190
      },
      {
        cellStyle: { backgroundColor: grey.A100, color: grey['900'] },
        field: 'esaMatch',
        headerClass: classes.header,
        headerName: 'Employer Match',
        permissions: {
          recordkeepers: ['Vestwell ESA']
        },
        type: 'currency',
        width: 200
      },
      {
        cellStyle: { backgroundColor: grey.A100, color: grey['900'] },
        field: 'esaInitialBonus',
        headerClass: classes.header,
        headerName: 'Initial Deposit Bonus',
        permissions: {
          recordkeepers: ['Vestwell ESA']
        },
        type: 'currency',
        width: 200
      },
      {
        cellStyle: { backgroundColor: grey.A100, color: grey['900'] },
        field: 'esaMilestoneBonusAmount',
        headerClass: classes.header,
        headerName: 'Milestone bonus',
        permissions: {
          recordkeepers: ['Vestwell ESA']
        },
        type: 'currency',
        width: 200
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'date_of_hire',
        headerClass: classes.header,
        headerName: 'Date of Hire',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: [
            'Vestwell ESA',
            'Vestwell Sub-Accounting Platform',
            'Voya'
          ]
        },
        type: 'date',
        width: 130
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'date_of_termination',
        headerClass: classes.header,
        headerName: 'Date of Termination',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: [
            'Vestwell ESA',
            'Vestwell Sub-Accounting Platform',
            'Voya'
          ]
        },
        type: 'date',
        width: 200
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'date_of_rehire',
        headerClass: classes.header,
        headerName: 'Date of Rehire',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: ['Vestwell ESA', 'Vestwell Sub-Accounting Platform']
        },
        type: 'date',
        width: 200
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'street_address_1',
        headerClass: classes.header,
        headerName: 'Address Line 1',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: [
            'Vestwell ESA',
            'Vestwell Sub-Accounting Platform',
            'Voya'
          ]
        },
        width: 150
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'street_address_2',
        headerClass: classes.header,
        headerName: 'Address Line 2',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: [
            'Vestwell ESA',
            'Vestwell Sub-Accounting Platform',
            'Voya'
          ]
        },
        width: 150
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'city',
        headerClass: classes.header,
        headerName: 'City',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: [
            'Vestwell ESA',
            'Vestwell Sub-Accounting Platform',
            'Voya'
          ]
        },
        width: 130
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'state',
        headerClass: classes.header,
        headerName: 'State',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: [
            'Vestwell ESA',
            'Vestwell Sub-Accounting Platform',
            'Voya'
          ]
        },
        width: 130
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'zip_code',
        headerClass: classes.header,
        headerName: 'Postal Code',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: [
            'Vestwell ESA',
            'Vestwell Sub-Accounting Platform',
            'Voya'
          ]
        },
        width: 130
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'country',
        headerClass: classes.header,
        headerName: 'Country',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: [
            'Vestwell ESA',
            'Vestwell Sub-Accounting Platform',
            'Voya'
          ]
        },
        width: 130
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'email',
        headerClass: classes.header,
        headerName: 'Work Email',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: ['Vestwell ESA', 'Vestwell Sub-Accounting Platform']
        },
        width: 180
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'marital_status',
        headerClass: classes.header,
        headerName: 'Marital Status',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: ['Voya']
        },
        width: 180
      },
      {
        cellClass: classes.cell,
        editable: true,
        field: 'external_payroll_id',
        headerClass: classes.header,
        headerName: 'External Payroll Id',
        permissions: {
          flowSubtypes: ['regular', 'off-cycle_supplemental_pay'],
          recordkeepers: [
            'Vestwell Sub-Accounting Platform',
            'Voya',
            'Vestwell ESA'
          ]
        },
        width: 200
      }
    ];

    return columns
      .filter(column =>
        column.permissions
          ? (typeof column.permissions?.planFeatureEnabled === 'boolean'
              ? column.permissions?.planFeatureEnabled
              : true) &&
            (column.permissions?.recordkeepers
              ? column.permissions?.recordkeepers?.includes(recordkeeperName)
              : true) &&
            (column.permissions?.flowSubtypes
              ? column.permissions?.flowSubtypes?.includes(flowSubtype)
              : true)
          : true
      )
      ?.map(column => omit(column, 'permissions'));
  }, [recordkeeperName, planFeatures, flowSubtype]);
};
