import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useTransactionCodes } from '@/hooks/ops/useTransactionCodes.hook';
import FundingSource from '@/models/ops/FundingSourceEnum.model';
import { HouseAccountDto } from '@/models/ops/house-accounts/HouseAccountDto.model';
import { SubAccountDto } from '@/models/ops/SubAccountDTO.model';
import { TransferCashPostRequest } from '@/models/ops/transfer-cash/transfer-cash-post-request.model';
import { ParentAccountService } from '@/services/ops/accounts/ParentAccount.service';
import { SubAccountService } from '@/services/ops/accounts/SubAccount.service';
import { BalanceService } from '@/services/ops/balances/Balance.service';
import { TransferCashService } from '@/services/ops/transfer-cash/TransferCash.service';
import formatters from '@/utils/Formatters';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadDoneOutlinedIcon from '@mui/icons-material/DownloadDoneOutlined';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Drawer,
  DrawerProps,
  FormControl,
  FormHelperText,
  Unstable_Grid2 as Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import { useIsFetching, useQuery, useQueryClient } from '@tanstack/react-query';
import { OperationalSubAccountList } from '@vestwell-sub-accounting/models/accountsAndLedgers/OperationalSubAccountList';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';
import { TransactionTypeCode } from '@vestwell-sub-accounting/models/common/TransactionTypeCode';

import { Field, Form, Formik, useFormikContext } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import * as yup from 'yup';

const getHouseAccountLabel = (houseAccount: HouseAccountDto) =>
  `VW House Account - ${formatters.displayCase(houseAccount.opsAccountType)}`;

const getParticipantSubAccountAutocompleteValue = (
  subAccount: SubAccountDto
) => ({
  group: 'sub' as const,
  id: subAccount.subAccountId,
  label: subAccount.fundingSource
    ? `${subAccount.participant?.firstName} ${
        subAccount.participant?.lastName
      } - ${
        FundingSource[subAccount.fundingSource] || subAccount.fundingSource
      }`
    : `${subAccount.participant?.firstName} ${subAccount.participant?.lastName}`,
  sublabel: subAccount.coreAccountId,
  type: 'subAccount' as const
});

const getPlanSubAccountAutocompleteValue = (subAccount: SubAccountDto) => ({
  accountType: subAccount.accountType,
  group: 'plan' as const,
  id: subAccount.subAccountId,
  label: formatters.displayCase(subAccount.accountType),
  type: 'plan' as const
});

const getPlanSubAccountLabel = (subAccount: SubAccountDto) =>
  formatters.displayCase(subAccount.accountType);

type AutocompleteOption =
  | {
      group: 'sub';
      id: string;
      label: string;
      sublabel: string;
      type: 'subAccount';
    }
  | {
      accountType: string;
      group: 'house';
      id: string;
      label: string;
      type: 'house';
    }
  | {
      accountType: string;
      group: 'plan';
      id: string;
      label: string;
      type: 'plan';
    }
  | {
      group: 'participant';
      id: number;
      label: string;
      type: 'participant';
    };

type AutocompleteValue = AutocompleteOption | string;

type TransferCashFormData = {
  amount: string;
  depositCode: '' | TransactionTypeCode;
  fromAccountId: string;
  fromAccountType: string;
  note?: string;
  toAccountId: string;
  toAccountType: string;
  withdrawalCode: '' | TransactionTypeCode;
};

const accountIdSchema = yup
  .string()
  .required()
  .test(
    'same-account',
    'Please double-check the From and To accounts. They are the same.',
    (value, context) =>
      !(context.parent.fromAccountId === context.parent.toAccountId)
  );

const accountTypeSchema = yup
  .string()
  .required()
  .test(
    'house-to-house',
    'Sorry, transfers between house accounts are not allowed.',
    (value, context) =>
      !(
        context.parent.toAccountType?.startsWith('house') &&
        context.parent.fromAccountType?.startsWith('house')
      )
  );

const TransferCashFormDataSchema = yup.object({
  amount: yup.string().required(),
  depositCode: yup.string().required(),
  fromAccountId: accountIdSchema,
  fromAccountType: accountTypeSchema,
  note: yup.string().max(50, 'Must be 50 characters or less'),
  toAccountId: accountIdSchema,
  toAccountType: accountTypeSchema,
  withdrawalCode: yup.string().required()
});

type AccountFieldsProps = {
  accountIdFieldName: 'fromAccountId' | 'toAccountId';
  accountTypeFieldName: 'fromAccountType' | 'toAccountType';
  autocompleteInputValue: string;
  autocompleteValue: null | AutocompleteValue;
  parentAccountId: string;
  planId: string;
  setAutocompleteInputValue: React.Dispatch<React.SetStateAction<string>>;
  setAutocompleteValue: React.Dispatch<
    React.SetStateAction<null | AutocompleteValue>
  >;
  subAccountId?: string;
};

const AccountFields: FC<AccountFieldsProps> = props => {
  const formikContext = useFormikContext<Partial<TransferCashFormData>>();
  const snackbar = useSnackbar();
  const [isAutocompleteMenuOpen, setIsAutocompleteMenuOpen] = useState(false);

  useDebounce(
    () => {
      if (
        props.autocompleteInputValue.length ||
        props.autocompleteInputValue === ''
      ) {
        props.setAutocompleteValue(props.autocompleteInputValue);
      }
    },
    1000,
    [props.autocompleteInputValue]
  );

  useEffect(() => {
    const setAutocompleteValue = async () => {
      if (
        !props.subAccountId ||
        props.accountIdFieldName !== 'fromAccountId' ||
        (typeof props.autocompleteValue !== 'string' &&
          props.autocompleteValue?.id &&
          props.subAccountId === props.autocompleteValue.id)
      )
        return;

      const subAccount = await SubAccountService.getById(props.subAccountId);
      if (!subAccount)
        throw new Error(
          `Sub Account matching id \`${props.subAccountId}\` not found`
        );

      props.setAutocompleteValue(
        [
          SubAccountType.participantCash,
          SubAccountType.participantOutsideInvestment,
          SubAccountType.participantStandard
        ].includes(subAccount.accountType)
          ? getParticipantSubAccountAutocompleteValue(subAccount)
          : getPlanSubAccountAutocompleteValue(subAccount)
      );
    };

    setAutocompleteValue();
  }, [props.subAccountId]);

  const houseAccountsQuery = useQuery(
    ['ParentAccountService.getHouseAccounts'],
    () => ParentAccountService.getHouseAccounts(props.parentAccountId),
    {
      enabled: Boolean(props.parentAccountId),
      placeholderData: () => []
    }
  );

  const planSubAccountsQuery = useQuery(
    ['SubAccountService', props.parentAccountId, 'plan-sub-accounts'],
    () =>
      SubAccountService.searchSubAccounts({
        accountType: OperationalSubAccountList,
        parentAccountId: props.parentAccountId
      }),
    {
      enabled: Boolean(props.parentAccountId),
      placeholderData: () => ({ results: [] })
    }
  );

  const predefinedAccountNames = useMemo(
    () => [
      ...(houseAccountsQuery.data || []).map(getHouseAccountLabel),
      ...(planSubAccountsQuery.data?.results || []).map(getPlanSubAccountLabel)
    ],
    [houseAccountsQuery.data, planSubAccountsQuery.data]
  );

  const subAccountSearchQuery = useQuery(
    ['SubAccountService.search', props.autocompleteValue],
    async () => {
      const subAccounts = await SubAccountService.searchSubAccounts({
        parentAccountId: props.parentAccountId,
        query:
          typeof props.autocompleteValue === 'string'
            ? props.autocompleteValue
            : undefined
      });

      if (!subAccounts.results || !subAccounts.results.length)
        snackbar.showSnackbar({
          message: 'No matching sub accounts found',
          severity: 'warning'
        });

      return subAccounts;
    },
    {
      enabled: Boolean(
        typeof props.autocompleteValue === 'string' &&
          props.autocompleteValue.length &&
          !predefinedAccountNames.includes(props.autocompleteValue) &&
          !formikContext.values[props.accountIdFieldName]
      ),
      onError: (err: Error) => {
        snackbar.showSnackbar({
          message: `Failed to search participants: ${err.message}`,
          severity: 'error'
        });
      }
    }
  );

  return (
    <>
      <FormControl fullWidth>
        <Autocomplete<AutocompleteOption, false, false, true>
          PaperComponent={
            props.autocompleteInputValue === '' &&
            houseAccountsQuery.data &&
            planSubAccountsQuery.data
              ? ({ children }) => (
                  <Paper>
                    {children}
                    <Divider />
                    <Typography padding={2} variant='body2'>
                      Hint: Enter participant name or Core ID to search
                    </Typography>
                  </Paper>
                )
              : Paper
          }
          autoComplete
          disabled={
            formikContext.values.fromAccountId === props.subAccountId &&
            formikContext.values.toAccountId === props.subAccountId
              ? props.accountIdFieldName !== 'toAccountId'
              : (formikContext.values.fromAccountId === props.subAccountId &&
                  props.accountIdFieldName === 'fromAccountId') ||
                (formikContext.values.toAccountId === props.subAccountId &&
                  props.accountIdFieldName === 'toAccountId')
          }
          filterOptions={o => o} // noop per docs for async requests
          freeSolo
          getOptionLabel={option =>
            typeof option === 'string' ? option : option.label
          }
          groupBy={option => option.group}
          handleHomeEndKeys
          inputValue={props.autocompleteInputValue}
          loading={
            houseAccountsQuery.isFetching ||
            planSubAccountsQuery.isFetching ||
            subAccountSearchQuery.isFetching
          }
          onChange={async (event, value, reason) => {
            if (reason === 'createOption' && typeof value === 'string') {
              props.setAutocompleteValue(value);
              setIsAutocompleteMenuOpen(true);
            } else if (
              reason === 'selectOption' &&
              typeof value !== 'string' &&
              value?.type === 'house'
            ) {
              await formikContext.setValues({
                ...formikContext.values,
                [props.accountTypeFieldName]: `house:${value.accountType}`,
                [props.accountIdFieldName]: value.id
              });
              props.setAutocompleteValue(value);
            } else if (
              reason === 'selectOption' &&
              typeof value !== 'string' &&
              value?.type === 'plan'
            ) {
              await formikContext.setValues({
                ...formikContext.values,
                [props.accountTypeFieldName]: `plan:${value.accountType}`,
                [props.accountIdFieldName]: value.id
              });
              props.setAutocompleteValue(value);
            } else if (
              reason === 'selectOption' &&
              typeof value !== 'string' &&
              value?.type === 'subAccount'
            ) {
              await formikContext.setValues({
                ...formikContext.values,
                [props.accountTypeFieldName]: 'sub:subAccountId',
                [props.accountIdFieldName]: value.id
              });
              props.setAutocompleteValue(value);
            } else if (reason === 'clear') {
              await formikContext.setValues({
                ...formikContext.values,
                [props.accountTypeFieldName]: '',
                [props.accountIdFieldName]: ''
              });
              props.setAutocompleteValue(null);
              setIsAutocompleteMenuOpen(false);
            }
          }}
          onClose={event => {
            if (
              event.type === 'keydown' &&
              (event as React.KeyboardEvent<HTMLDivElement>).key === 'Enter'
            )
              return;
            setIsAutocompleteMenuOpen(false);
          }}
          onInputChange={(event, value, reason) => {
            props.setAutocompleteInputValue(value);
            if (reason === 'reset') return;
            formikContext.setValues({
              ...formikContext.values,
              [props.accountTypeFieldName]: '',
              [props.accountIdFieldName]: ''
            });
          }}
          onOpen={() => {
            setIsAutocompleteMenuOpen(true);
          }}
          open={isAutocompleteMenuOpen}
          options={
            (props.autocompleteInputValue === ''
              ? [
                  ...(houseAccountsQuery.data || []).map(houseAccount => ({
                    accountType: houseAccount.opsAccountType,
                    group: 'house' as const,
                    id: houseAccount.subAccountId,
                    label: `VW House Account - ${formatters.displayCase(
                      houseAccount.opsAccountType
                    )}`,
                    type: 'house' as const
                  })),
                  ...(planSubAccountsQuery.data?.results || []).map(
                    getPlanSubAccountAutocompleteValue
                  )
                ]
              : (subAccountSearchQuery.data?.results || []).map(
                  getParticipantSubAccountAutocompleteValue
                )) || []
          }
          renderGroup={params => (
            <Stack component='ul' key={params.key} mb={1} p={0} spacing={1}>
              {params.children}
              {(params.group === 'house' || params.group === 'plan') && (
                <Divider />
              )}
            </Stack>
          )}
          renderInput={inputProps => (
            <TextField
              {...inputProps}
              aria-label={
                props.accountIdFieldName === 'fromAccountId'
                  ? 'From Sub Account'
                  : 'To Sub Account'
              }
              inputProps={{
                ...inputProps.inputProps,
                'data-testid':
                  props.accountIdFieldName === 'fromAccountId'
                    ? `from-sub-account-autocomplete-input`
                    : `to-sub-account-autocomplete-input`
              }}
              label='Sub Account'
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              <Stack spacing={0.5}>
                <Typography variant='body1'>{option.label}</Typography>
                {option.type === 'subAccount' && (
                  <Typography variant='body2'>
                    Core ID: {option.sublabel}
                  </Typography>
                )}
              </Stack>
            </li>
          )}
          selectOnFocus
          size='small'
          value={props.autocompleteValue}
        />
      </FormControl>
    </>
  );
};

const AccountPlaceholder: FC = () => (
  <Paper
    sx={{
      backgroundColor: theme => theme.palette.grey[50],
      padding: 2
    }}
    variant='outlined'>
    <Stack spacing={1}>
      <Typography variant='caption'>—</Typography>
      <TextStack direction='column' spacing={1}>
        <TextStackItem>
          <TextLabel>Confirmed Balance</TextLabel>
          <TextValue>—</TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Pending Balance</TextLabel>
          <TextValue>—</TextValue>
        </TextStackItem>
      </TextStack>
    </Stack>
  </Paper>
);

type TransferCardProps = {
  parentAccountId: string;
  planId: string;
  subAccount?: SubAccountDto;
};

const TransferCard: FC<TransferCardProps> = props => {
  const formikContext = useFormikContext<TransferCashFormData>();
  const [fromAutocompleteInputValue, setFromAutocompleteInputValue] =
    useState('');
  const [toAutocompleteInputValue, setToAutocompleteInputValue] = useState('');
  const [fromAutocompleteValue, setFromAutocompleteValue] =
    useState<AutocompleteValue>('');
  const [toAutocompleteValue, setToAutocompleteValue] =
    useState<AutocompleteValue>('');

  const fromSubAccountBalancesQuery = useQuery(
    ['BalancesService.get', formikContext.values.fromAccountId],
    () => BalanceService.get(formikContext.values.fromAccountId),
    {
      enabled: Boolean(formikContext.values.fromAccountId)
    }
  );

  const incomingTransactionTypeCodesQuery = useTransactionCodes({
    transactionBaseType: [
      TransactionBaseType.Deposit,
      TransactionBaseType.IncomeIn
    ]
  });

  const outgoingTransactionTypeCodesQuery = useTransactionCodes({
    transactionBaseType: [
      TransactionBaseType.IncomeOut,
      TransactionBaseType.Withdrawal
    ]
  });

  const toSubAccountBalancesQuery = useQuery(
    ['BalancesService.get', formikContext.values.toAccountId],
    () => BalanceService.get(formikContext.values.toAccountId),
    {
      enabled: Boolean(formikContext.values.toAccountId)
    }
  );

  useEffect(() => {
    formikContext.validateForm();
  }, [fromSubAccountBalancesQuery.data, toSubAccountBalancesQuery.data]);

  const handleSwapAccounts = async () => {
    setFromAutocompleteInputValue(toAutocompleteInputValue);
    setFromAutocompleteValue(toAutocompleteValue);
    setToAutocompleteInputValue(fromAutocompleteInputValue);
    setToAutocompleteValue(fromAutocompleteValue);

    await formikContext.setValues({
      ...formikContext.values,
      fromAccountId: formikContext.values.toAccountId,
      fromAccountType: formikContext.values.toAccountType,
      toAccountId: formikContext.values.fromAccountId,
      toAccountType: formikContext.values.fromAccountType
    });
  };

  return (
    <Card>
      <Grid container padding={2} spacing={2}>
        <Grid xs>
          <Stack spacing={2}>
            <Typography variant='subtitle1'>From</Typography>
            <AccountFields
              accountIdFieldName='fromAccountId'
              accountTypeFieldName='fromAccountType'
              autocompleteInputValue={fromAutocompleteInputValue}
              autocompleteValue={fromAutocompleteValue}
              parentAccountId={props.parentAccountId}
              planId={props.planId}
              setAutocompleteInputValue={setFromAutocompleteInputValue}
              setAutocompleteValue={setFromAutocompleteValue}
              subAccountId={props.subAccount?.subAccountId}
            />
            {formikContext.values.fromAccountId ? (
              <Paper
                sx={{
                  backgroundColor: theme => theme.palette.primary.light,
                  padding: 2
                }}
                variant='outlined'>
                <Stack spacing={1}>
                  <Typography
                    data-testid='from-sub-account-id'
                    variant='caption'>
                    ID: {formikContext.values.fromAccountId}
                  </Typography>
                  <TextStack direction='column' spacing={1}>
                    <TextStackItem>
                      <TextLabel>Confirmed Balance</TextLabel>
                      <TextValue data-testid='from-sub-account-confirmed-balance'>
                        <Stack direction='row' spacing={1.5}>
                          {fromSubAccountBalancesQuery.data?.cash.confirmed
                            ? formatters.formatDollars(
                                fromSubAccountBalancesQuery.data?.cash.confirmed
                              )
                            : '—'}
                          {typeof fromAutocompleteValue !== 'string' &&
                            fromAutocompleteValue?.group !== 'house' &&
                            formikContext.values.amount !==
                              fromSubAccountBalancesQuery.data?.cash
                                .confirmed && (
                              <SvgIcon
                                color='primary'
                                cursor='pointer'
                                data-testid='use-from-sub-account-confirmed-balance-button'
                                onClick={() =>
                                  formikContext.setFieldValue(
                                    'amount',
                                    fromSubAccountBalancesQuery.data?.cash
                                      .confirmed
                                  )
                                }>
                                <DownloadDoneOutlinedIcon />
                              </SvgIcon>
                            )}
                        </Stack>
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Pending Balance</TextLabel>
                      <TextValue data-testid='from-sub-account-pending-balance'>
                        {fromSubAccountBalancesQuery.data?.cash.pending
                          ? formatters.formatDollars(
                              fromSubAccountBalancesQuery.data?.cash.pending
                            )
                          : '—'}
                      </TextValue>
                    </TextStackItem>
                  </TextStack>
                </Stack>
              </Paper>
            ) : (
              <AccountPlaceholder />
            )}
            <FormControl
              error={
                formikContext.touched.withdrawalCode &&
                Boolean(formikContext.errors.withdrawalCode)
              }
              fullWidth
              size='small'>
              <InputLabel htmlFor='outgoing-transaction-type'>
                Outgoing Transaction Type
              </InputLabel>
              <Field
                SelectDisplayProps={{
                  'data-testid': 'outgoing-transaction-type-select'
                }}
                as={Select}
                id='outgoing-transaction-type'
                label='Outgoing Transaction Type'
                labelId='outgoing-transaction-type-select-label'
                name='withdrawalCode'>
                {(outgoingTransactionTypeCodesQuery.data || []).map(item => (
                  <MenuItem
                    key={item.transactionTypeCode}
                    value={item.transactionTypeCode}>
                    {formatters.displayCase(item.transactionTypeCode)}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText>
                {formikContext.touched.withdrawalCode &&
                  formikContext.errors.withdrawalCode}
              </FormHelperText>
            </FormControl>
          </Stack>
        </Grid>
        <Grid
          alignItems='center'
          display='flex'
          flexDirection='column'
          justifyContent='stretch'
          xs={0.5}>
          <Divider
            orientation='vertical'
            sx={{ flexGrow: 1, height: 'auto' }}
          />
          <IconButton
            color='primary'
            data-testid='swap-from-to-button'
            onClick={handleSwapAccounts}>
            <SwapHorizontalCircleOutlinedIcon />
          </IconButton>
          <Divider
            orientation='vertical'
            sx={{ flexGrow: 1, height: 'auto' }}
          />
        </Grid>
        <Grid xs>
          <Stack spacing={2}>
            <Typography variant='subtitle1'>To</Typography>
            <AccountFields
              accountIdFieldName='toAccountId'
              accountTypeFieldName='toAccountType'
              autocompleteInputValue={toAutocompleteInputValue}
              autocompleteValue={toAutocompleteValue}
              parentAccountId={props.parentAccountId}
              planId={props.planId}
              setAutocompleteInputValue={setToAutocompleteInputValue}
              setAutocompleteValue={setToAutocompleteValue}
              subAccountId={props.subAccount?.subAccountId}
            />
            {formikContext.values.toAccountId ? (
              <Paper
                sx={{
                  backgroundColor: theme => theme.palette.primary.light,
                  padding: 2
                }}
                variant='outlined'>
                <Stack spacing={1}>
                  <Typography data-testid='to-sub-account-id' variant='caption'>
                    ID: {formikContext.values.toAccountId}
                  </Typography>
                  <TextStack direction='column' spacing={1}>
                    <TextStackItem>
                      <TextLabel>Confirmed Balance</TextLabel>
                      <TextValue data-testid='to-sub-account-confirmed-balance'>
                        <Stack direction='row' spacing={2}>
                          {toSubAccountBalancesQuery.data?.cash.confirmed
                            ? formatters.formatDollars(
                                toSubAccountBalancesQuery.data?.cash.confirmed
                              )
                            : '—'}
                          {typeof toAutocompleteValue !== 'string' &&
                            toAutocompleteValue?.group !== 'house' &&
                            formikContext.values.amount !==
                              toSubAccountBalancesQuery.data?.cash
                                .confirmed && (
                              <SvgIcon
                                color='primary'
                                cursor='pointer'
                                data-testid='use-to-sub-account-confirmed-balance-button'
                                onClick={() =>
                                  formikContext.setFieldValue(
                                    'amount',
                                    toSubAccountBalancesQuery.data?.cash
                                      .confirmed
                                  )
                                }>
                                <DownloadDoneOutlinedIcon />
                              </SvgIcon>
                            )}
                        </Stack>
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Pending Balance</TextLabel>
                      <TextValue data-testid='to-sub-account-pending-balance'>
                        {toSubAccountBalancesQuery.data?.cash.pending
                          ? formatters.formatDollars(
                              toSubAccountBalancesQuery.data?.cash.pending
                            )
                          : '—'}
                      </TextValue>
                    </TextStackItem>
                  </TextStack>
                </Stack>
              </Paper>
            ) : (
              <AccountPlaceholder />
            )}
            <FormControl
              error={
                formikContext.touched.depositCode &&
                Boolean(formikContext.errors.depositCode)
              }
              fullWidth
              size='small'>
              <InputLabel htmlFor='incoming-transaction-type'>
                Incoming Transaction Type
              </InputLabel>
              <Field
                SelectDisplayProps={{
                  'data-testid': 'incoming-transaction-type-select'
                }}
                as={Select}
                id='incoming-transaction-type'
                label='Incoming Transaction Type'
                labelId='incoming-transaction-type-select-label'
                name='depositCode'>
                {(incomingTransactionTypeCodesQuery.data || []).map(item => (
                  <MenuItem
                    key={item.transactionTypeCode}
                    value={item.transactionTypeCode}>
                    {formatters.displayCase(item.transactionTypeCode)}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText>
                {formikContext.touched.depositCode &&
                  formikContext.errors.depositCode}
              </FormHelperText>
            </FormControl>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Typography variant='subtitle1'>Transfer Amount</Typography>
        </Grid>
        <Grid xs={4}>
          <FormControl
            error={
              formikContext.touched.amount &&
              Boolean(formikContext.errors.amount)
            }
            fullWidth
            size='small'>
            <InputLabel htmlFor='amount-input'>Amount</InputLabel>
            <Field
              as={OutlinedInput}
              autoComplete='off'
              id='transfer-amount-input'
              inputProps={{
                'data-testid': 'transfer-amount-input'
              }}
              label='Amount'
              name='amount'
              startAdornment={
                <InputAdornment position='start'>$</InputAdornment>
              }
              type='number'
              validate={value =>
                (fromSubAccountBalancesQuery.data?.cash?.confirmed || 0) < value
                  ? 'Amount must be less than or equal to selected source account’s confirmed balance'
                  : undefined
              }
            />
            <FormHelperText>
              {formikContext.touched.amount && formikContext.errors.amount}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={8}>
          <FormControl
            error={
              formikContext.touched.note && Boolean(formikContext.errors.note)
            }
            fullWidth
            size='small'>
            <InputLabel htmlFor='note-input'>Note</InputLabel>
            <Field
              as={OutlinedInput}
              autoComplete='off'
              id='note-input'
              inputProps={{
                'data-testid': 'note-input'
              }}
              label='Note'
              name='note'
            />
            <FormHelperText>
              {formikContext.touched.note && formikContext.errors.note}
            </FormHelperText>
          </FormControl>
        </Grid>
        {formikContext.values.toAccountId &&
          formikContext.values.toAccountType &&
          (formikContext.errors.toAccountId ||
            formikContext.errors.toAccountType) && (
            <Grid xs={12}>
              <Alert
                data-testid='transfer-cash-drawer-validation-alert'
                severity='error'>
                {formikContext.errors.toAccountId ||
                  formikContext.errors.toAccountType}
              </Alert>
            </Grid>
          )}
      </Grid>
    </Card>
  );
};

type TransferCashDrawerProps = DrawerProps & {
  onSubmit: (transferRequest: TransferCashPostRequest) => void;
  parentAccountId: string;
  planId: string;
  subAccount?: SubAccountDto;
};

export const TransferCashDrawer: FC<TransferCashDrawerProps> = ({
  onSubmit,
  parentAccountId,
  planId,
  subAccount,
  ...drawerProps
}) => {
  const navigate = useNavigate();
  const isFetchingBalances = useIsFetching(['BalancesService.get']);
  const isFetchingHouseAccounts = useIsFetching([
    'ParentAccountService.getHouseAccounts'
  ]);
  const isFetchingPlanSubAccounts = useIsFetching([
    'SubAccountService',
    parentAccountId,
    'plan-sub-accounts'
  ]);
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();

  const preselectedSubAccount = useMemo(() => {
    if (!subAccount) return undefined;

    if (subAccount.parentAccount?.accountType === ParentAccountType.House) {
      return {
        id: 'matrix',
        type: `house:${subAccount.accountType}`
      };
    }

    if (subAccount.accountType === SubAccountType.participantStandard) {
      return {
        id: subAccount.subAccountId,
        type: 'sub:subAccountId'
      };
    }

    return {
      id: subAccount.subAccountId,
      type: `plan:${subAccount.accountType}`
    };
  }, [subAccount]);

  const handleSubmit = async (formData: TransferCashFormData) => {
    if (formData.depositCode === '' || formData.withdrawalCode === '') {
      return;
    }

    try {
      const transferRequest = {
        amount: (+formData.amount).toFixed(2),
        comment: formData.note,
        depositCode: formData.depositCode,
        fromId: formData.fromAccountType.startsWith('house')
          ? 'matrix'
          : formData.fromAccountType.startsWith('plan')
            ? planId
            : formData.fromAccountId,
        fromType: formData.fromAccountType,
        toId: formData.toAccountType.startsWith('house')
          ? 'matrix'
          : formData.toAccountType.startsWith('plan')
            ? planId
            : formData.toAccountId,
        toType: formData.toAccountType,
        withdrawalCode: formData.withdrawalCode
      };

      await TransferCashService.post(transferRequest);

      const workflowSearchUrl = `/ops/accounts/${parentAccountId}/conductor?query=${encodeURIComponent(
        JSON.stringify({
          workflowNameTradeCalculatorMethodPairings: [
            { workflowName: 'cashTransferRequest' }
          ]
        })
      )}`;

      queryClient.invalidateQueries([
        'WorkflowService.search',
        parentAccountId
      ]);

      snackbar.showSnackbar({
        action: (
          <Button
            color='inherit'
            endIcon={<ArrowForwardIcon />}
            href={workflowSearchUrl}
            onClick={() => navigate(workflowSearchUrl)}
            size='small'>
            View Transfer
          </Button>
        ),
        message: 'Cash transfer submitted',
        severity: 'success'
      });

      if (typeof onSubmit === 'function') onSubmit(transferRequest);
    } catch (err) {
      snackbar.showSnackbar({
        message: `Failed to submit cash transfer with error: ${err.message}`,
        severity: 'error'
      });
    }
  };

  return (
    <Formik
      initialValues={{
        amount: '',
        depositCode: '',
        fromAccountId: preselectedSubAccount?.id || '',
        fromAccountType: preselectedSubAccount?.type || '',
        note: '',
        toAccountId: '',
        toAccountType: '',
        tracerId: '',
        withdrawalCode: ''
      }}
      onSubmit={handleSubmit}
      validationSchema={TransferCashFormDataSchema}>
      {({ dirty, isSubmitting, isValid }) => (
        <Drawer
          PaperProps={{
            sx: {
              width: 720
            }
          }}
          anchor='right'
          data-testid='transfer-cash-drawer'
          open={drawerProps.open}
          sx={{
            // note: unable to add to theme due to conflict with other drawers and https://github.com/mui/material-ui/issues/36300
            zIndex: theme => theme.zIndex.vestwellAppBar + 1
          }}
          {...drawerProps}>
          {(isFetchingBalances ||
            isFetchingHouseAccounts ||
            isFetchingPlanSubAccounts) > 0 && (
            <LinearProgress
              sx={{ left: 0, position: 'absolute', right: 0, top: 0 }}
            />
          )}
          <Form>
            <Stack justifyContent='space-between' minHeight='100vh'>
              <Typography padding={2} variant='h6'>
                Transfer Cash
              </Typography>
              <Box
                bgcolor='grey.50'
                borderBottom={theme => `1px solid ${theme.palette.grey[300]}`}
                borderTop={theme => `1px solid ${theme.palette.grey[300]}`}
                flexGrow={1}
                minWidth={720}
                p={2}>
                <TransferCard
                  parentAccountId={parentAccountId}
                  planId={planId}
                  subAccount={subAccount}
                />
              </Box>
              <Box>
                <Stack
                  direction='row'
                  justifyContent='right'
                  paddingX={3}
                  paddingY={2}
                  spacing={2}>
                  <Button variant='text'>Cancel</Button>
                  <LoadingButton
                    data-testid='confirm-transfer-button'
                    disabled={!dirty || !isValid}
                    loading={isSubmitting}
                    type='submit'
                    variant='contained'>
                    Confirm & Transfer
                  </LoadingButton>
                </Stack>
              </Box>
            </Stack>
          </Form>
        </Drawer>
      )}
    </Formik>
  );
};
