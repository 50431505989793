import JSONViewer from '@/components/json-viewer';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { ParticipantAccountDetails } from '@/models/ParticipantAccountsDTO.model';
import {
  WithdrawalDto,
  WithdrawalSierraRequest,
  WithdrawalTradeRequestDto
} from '@/models/WithdrawalsDTO.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  SxProps,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';

import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import FeeInfoStack from './FeeInfoStack.component';

type WithdrawalInfoCardProps = {
  isVestwellEsa?: boolean;
  participantId: number;
  requestSubAccountIds?: number[];
  sx?: SxProps;
  withdrawalDetails: WithdrawalDto;
  participantAccounts: ParticipantAccountDetails[];
};

type WithdrawalInfoCardParams = {
  withdrawalId: string;
};

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  tab: {
    textAlign: 'right'
  }
}));

const WithdrawalInfoCard: React.FunctionComponent<WithdrawalInfoCardProps> = (
  props: WithdrawalInfoCardProps
) => {
  const classes = useStyles();

  const { withdrawalDetails, requestSubAccountIds, sx } = props;
  const { withdrawalId } = useParams<WithdrawalInfoCardParams>();

  const [open, setOpen] = useState(false);

  const isCustomWithdrawal =
    withdrawalDetails.attributes.distributionMethod === 'CUSTOM';

  const tradeRequests = useQuery<WithdrawalTradeRequestDto[]>(
    [
      'ParticipantService.getWithdrawalTradeRequests',
      props.participantId,
      withdrawalId
    ],
    () => {
      return ParticipantService.getWithdrawalTradeRequests(
        +props.participantId,
        +withdrawalId
      );
    },
    {
      staleTime: Infinity
    }
  );

  const withdrawalAccounts = useMemo(() => {
    const requestSubaccounts =
      tradeRequests.data && tradeRequests.data.length > 0
        ? tradeRequests.data[0].request?.subAccounts.map(i => +i.coreAccountId)
        : requestSubAccountIds;

    return (
      props.participantAccounts?.filter(account => {
        return requestSubaccounts?.find(accId => +account.id === accId);
      }) ?? []
    );
  }, [props.participantAccounts, tradeRequests.data, requestSubAccountIds]);

  const sierraRequest = useQuery<WithdrawalSierraRequest>(
    ['ParticipantService.getWithdrawalSierraRequest', withdrawalId],
    () => ParticipantService.getWithdrawalSierraRequest(+withdrawalId),
    {
      enabled:
        !isCustomWithdrawal && withdrawalDetails.attributes.status === 'Pending'
    }
  );

  const fundingSourceInfo = (
    <Box>
      <Typography mb={2} sx={{ fontSize: 15, fontWeight: 500 }}>
        Funding Source(s)
      </Typography>
      {withdrawalAccounts.map(acc => (
        <Typography
          key={`fundingSource-${acc.id}`}
          sx={{ color: theme => theme.palette.text.primary }}
          variant='body2'>
          {`${acc.attributes.sourceCode} (id: ${acc.id})`}
        </Typography>
      ))}
    </Box>
  );

  const formatDollars = (value: number | string) =>
    value != null ? formatters.formatDollars(value) : null;

  return (
    <>
      <div className={classes.column}>
        <Card
          sx={{
            borderRadius: '4px 4px 0 0',
            ...sx
          }}
          variant='outlined'>
          <CardHeader
            action={
              withdrawalDetails.attributes.distributionMethod !== 'CUSTOM' &&
              withdrawalDetails.attributes.status === 'Pending' && (
                <Button onClick={() => setOpen(true)}>
                  Preview JSON Request
                </Button>
              )
            }
            sx={{
              '.MuiCardHeader-action': {
                alignItems: 'center',
                display: 'flex',
                height: '100%'
              },
              borderBottom: theme => `1px solid ${theme.palette.grey[300]}`,
              display: 'flex',
              height: '89px'
            }}
            title='Details'
          />
          <CardContent>
            <Grid container rowSpacing={2}>
              <Grid xs={12}>
                <Stack
                  direction='row'
                  divider={<Divider flexItem orientation='vertical' />}
                  spacing={4}>
                  <TextStack direction='column' sx={{ width: '40%' }}>
                    <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                      General
                    </Typography>
                    <TextStackItem>
                      <TextLabel>Type</TextLabel>
                      <TextValue data-testid='withdrawal-type'>
                        {withdrawalDetails.attributes.withdrawalReason}
                      </TextValue>
                    </TextStackItem>
                    {!!withdrawalDetails.attributes.context
                      .createWithdrawalDetails.esaReason && (
                      <TextStackItem>
                        <TextLabel>Reason</TextLabel>
                        <TextValue data-testid='withdrawal-esa-reason'>
                          {
                            withdrawalDetails.attributes.context
                              .createWithdrawalDetails.esaReason
                          }
                        </TextValue>
                      </TextStackItem>
                    )}
                    {!!withdrawalDetails.attributes.context
                      .createWithdrawalDetails.hardship && (
                      <TextStackItem>
                        <TextLabel>Hardship Reason</TextLabel>
                        <TextValue data-testid='withdrawal-hardship-reason'>
                          {
                            withdrawalDetails.attributes.context
                              .createWithdrawalDetails.hardship
                          }
                        </TextValue>
                      </TextStackItem>
                    )}
                    <TextStackItem>
                      <TextLabel>Fund Type</TextLabel>
                      <TextValue data-testid='withdrawal-fund-type'>
                        {withdrawalDetails.attributes.context
                          .createWithdrawalDetails.rolloverAccount ?? null}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Distribution Method</TextLabel>
                      <TextValue data-testid='withdrawal-distribution-method'>
                        {withdrawalDetails?.attributes?.distributionMethod}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Withdrawal Amount</TextLabel>
                      <TextValue data-testid='withdrawal-amount'>
                        {formatters.formatDollars(
                          withdrawalDetails.attributes.context
                            .withdrawalDerivedAmounts.withdrawalAmount
                        ) ?? null}
                      </TextValue>
                    </TextStackItem>
                  </TextStack>
                  <FeeInfoStack
                    isVestwellEsa={!!props.isVestwellEsa}
                    withdrawalDetails={withdrawalDetails}
                  />
                </Stack>
              </Grid>
              <Grid xs={12}>
                {(isCustomWithdrawal || props.isVestwellEsa) &&
                  fundingSourceInfo}
                {!isCustomWithdrawal && !props.isVestwellEsa && (
                  <Stack
                    direction='row'
                    divider={<Divider flexItem orientation='vertical' />}
                    spacing={4}>
                    <TextStack direction='column' sx={{ width: '40%' }}>
                      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                        Tax Preview (Estimated at time of withdrawal request)
                      </Typography>

                      <TextStackItem>
                        <TextLabel>Gross Withdrawal</TextLabel>
                        <TextValue data-testid='gross-withdrawal'>
                          {formatDollars(
                            withdrawalDetails?.attributes.context
                              ?.withdrawalDerivedAmounts?.withdrawalAmount
                          )}
                        </TextValue>
                      </TextStackItem>
                      <TextStackItem>
                        <TextLabel>Federal Tax</TextLabel>
                        <TextValue data-testid='federal-tax'>
                          {formatDollars(
                            withdrawalDetails?.attributes.context?.taxInfo
                              ?.federalTax
                          )}
                        </TextValue>
                      </TextStackItem>
                      <TextStackItem>
                        <TextLabel>State Tax</TextLabel>
                        <TextValue data-testid='state-tax'>
                          {formatDollars(
                            withdrawalDetails?.attributes.context?.taxInfo
                              ?.stateTax
                          )}
                        </TextValue>
                      </TextStackItem>
                      <TextStackItem>
                        <TextLabel>All associated fees</TextLabel>
                        <TextValue data-testid='all-associated-fees'>
                          {formatDollars(
                            withdrawalDetails?.attributes.context?.taxInfo
                              ?.totalFee
                          )}
                        </TextValue>
                      </TextStackItem>
                      <Divider />
                      <TextStackItem>
                        <TextLabel>Net Estimated Withdrawal</TextLabel>
                        <TextValue data-testid='net-estimated-withdrawal'>
                          {formatDollars(
                            withdrawalDetails?.attributes.context?.taxInfo
                              ?.netEstimatedAmount
                          )}
                        </TextValue>
                      </TextStackItem>
                    </TextStack>
                    {fundingSourceInfo}
                  </Stack>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>JSON Request</DialogTitle>
        <DialogContent>
          <JSONViewer json={sierraRequest?.data ?? []} />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default WithdrawalInfoCard;
