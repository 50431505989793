import AccessControl from '@/components/access-control/AccessControl.component';
import Badge from '@/components/badge';
import CardInfoField from '@/components/card-info-field';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ContributionService from '@/services/Contribution.service';
import SubAccountingService from '@/services/SubAccounting.service';
import formatters from '@/utils/Formatters';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { intersection } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';

import { Retry } from './Retry.component';

type AchDetailsProps = {
  ucid: string;
  planId: number;
  sponsorId: number;
};

export const achRetryValidStatuses = [
  'ACH_REQUEST_FAILED',
  'ACH_REQUEST_SUBMITTED',
  'ACH_VALIDATION_ERROR',
  'ACH_REQUEST_VALIDATED',
  'AWAITING_FUNDS',
  'FUNDING_ERROR'
];

export const achRetryInvalidStatuses = [
  'FUNDING_CONFIRMED',
  'COMPLETE',
  'CANCELLED',
  'COMPLETED'
];

export const AchDetails: FC<AchDetailsProps> = props => {
  const [isErrorDialog, toggleErrorDialog] = useToggle(false);
  const [isRetryDialog, toggleRetryDialog] = useToggle(false);

  const achDetails = useQuery(
    [SubAccountingService.getAchDetail.name, props.ucid],
    async () => {
      return SubAccountingService.getAchDetail(props.ucid);
    },
    {
      enabled: !!props.ucid
    }
  );

  const contributionDetails = useQuery(
    [
      ContributionService.getContributionDetails.name,
      props.planId,
      props.sponsorId,
      props.ucid
    ],
    () =>
      ContributionService.getContributionDetails({
        planId: props.planId,
        sponsorId: props.sponsorId,
        ucid: props.ucid
      }),
    {
      enabled: !!props.planId && !!props.ucid && !!props.sponsorId
    }
  );

  const isRetryAchEnabled = useMemo(() => {
    if (
      contributionDetails.data?.key.flowSubtype === 'correction' ||
      contributionDetails.data?.key.flowSubtype === 'lost_gains'
    ) {
      const statuses =
        contributionDetails.data?.pngStatuses?.map(record => record.status) ??
        [];

      return (
        !intersection(statuses, achRetryInvalidStatuses)?.length &&
        achRetryValidStatuses.includes(statuses?.at(-1) ?? '')
      );
    }

    if (
      ['off-cycle_supplemental_pay', 'regular'].includes(
        contributionDetails.data?.key.flowSubtype ?? ''
      )
    ) {
      return contributionDetails.data?.pngStatuses.some(
        pngStatus => pngStatus.status === 'DELIVERED'
      );
    }

    return false;
  }, [contributionDetails.data]);

  const hasExecutionStatusError = useMemo(
    () =>
      ['FAILED', 'SUBA_FAILED', 'SUBA_TIMED-OUT', 'SUBA_ABORTED'].includes(
        achDetails.data?.status ?? ''
      ),
    [achDetails.data]
  );

  const hasWorkflowStatusError = useMemo(
    () =>
      ['FAILED', 'SUBA_FAILED', 'SUBA_TIMED-OUT', 'SUBA_ABORTED'].includes(
        achDetails.data?.workflowStatus ?? ''
      ),
    [achDetails.data]
  );

  const amount = useMemo(
    () =>
      achDetails.data?.amount
        ? formatters.formatContributionAmount(achDetails.data?.amount)
        : '--',
    [achDetails.data]
  );

  const createdAt = useMemo(
    () =>
      achDetails.data?.createdAt
        ? formatters.formatFromIsoDate(achDetails.data?.createdAt)
        : '--',
    [achDetails.data]
  );

  const updatedAt = useMemo(
    () =>
      achDetails.data?.updatedAt
        ? formatters.formatFromIsoDate(achDetails.data?.updatedAt)
        : '--',
    [achDetails.data]
  );

  const executionStatus = useMemo(
    () =>
      achDetails.data?.status
        ? formatters.formatContributionStatusHeader(achDetails.data?.status)
        : '--',
    [achDetails.data]
  );

  const workflowStatus = useMemo(
    () =>
      achDetails.data?.workflowStatus
        ? formatters.formatContributionStatusHeader(
            achDetails.data?.workflowStatus
          )
        : '--',
    [achDetails.data]
  );

  const title = useMemo(() => {
    if (contributionDetails.data?.key.flowSubtype === 'lost_gains')
      return 'Lost Gains';
    if (contributionDetails.data?.key.flowSubtype === 'correction')
      return 'Correction';
    if (
      ['off-cycle_supplemental_pay', 'regular'].includes(
        contributionDetails.data?.key.flowSubtype ?? ''
      )
    )
      return 'Original Contribution';

    return '--';
  }, [contributionDetails.data]);

  const onAchRetrySuccess = useCallback(() => {
    achDetails.refetch();
    contributionDetails.refetch();
  }, [achDetails, contributionDetails]);

  const isLoading =
    achDetails.isFetching ||
    contributionDetails.isFetching ||
    contributionDetails.isFetching;

  return (
    <>
      <CardContent>
        {isLoading && <LinearProgress sx={{ marginBottom: 2 }} />}

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            data-testid='trade-request-table-header'
            sx={{ alignItems: 'center', display: 'flex' }}>
            <Typography mr={2} variant='h6'>
              {title}
            </Typography>
            <Typography color='primary'>{achDetails.data?.tracerId}</Typography>
          </Box>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_CONTRIBUTIONS_ACTION]}>
            {isRetryAchEnabled && (
              <Button
                data-testid='trade-request-retry-ach'
                onClick={toggleRetryDialog}
                variant='outlined'>
                Retry ACH
              </Button>
            )}
          </AccessControl>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Grid container spacing={2}>
          <Grid data-testid='executionStatus' item>
            <Box sx={{ paddingTop: 2 }}>
              <Typography sx={{ marginBottom: 1 }} variant='body2'>
                Execution Status
              </Typography>

              <Grid container>
                <Badge
                  color={
                    achDetails.data?.status
                      ? hasExecutionStatusError
                        ? 'warning'
                        : 'success'
                      : 'neutral'
                  }
                  size='small'>
                  {executionStatus}
                </Badge>

                {(hasWorkflowStatusError || hasExecutionStatusError) && (
                  <IconButton onClick={toggleErrorDialog} size='small'>
                    <OpenInNewOutlinedIcon fontSize='inherit' />
                  </IconButton>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid data-testid='workflowStatus' item>
            <Box sx={{ paddingTop: 2 }}>
              <Typography sx={{ marginBottom: 1 }} variant='body2'>
                Workflow Status
              </Typography>

              <Badge
                color={
                  achDetails.data?.workflowStatus
                    ? hasWorkflowStatusError
                      ? 'warning'
                      : 'success'
                    : 'neutral'
                }
                size='small'>
                {workflowStatus}
              </Badge>
            </Box>
          </Grid>

          <Grid data-testid='amount' item>
            <CardInfoField fieldName='Amount' fieldValue={amount} />
          </Grid>

          <Grid data-testid='createdAt' item>
            <CardInfoField fieldName='Created At' fieldValue={createdAt} />
          </Grid>

          <Grid data-testid='updatedAt' item>
            <CardInfoField fieldName='Updated At' fieldValue={updatedAt} />
          </Grid>

          <Grid data-testid='source' item>
            <CardInfoField
              fieldName='Source'
              fieldValue={achDetails.data?.source ?? '--'}
            />
          </Grid>
        </Grid>
      </CardContent>

      <Dialog onClose={toggleErrorDialog} open={isErrorDialog}>
        <DialogTitle>Error messages</DialogTitle>
        <Typography sx={{ padding: 1 }} variant='body1'>
          {achDetails.data?.resultMessage}
        </Typography>
        <Typography sx={{ padding: 1 }} variant='body1'>
          {achDetails.data?.subaresultMessage}
        </Typography>
      </Dialog>

      <Retry
        isOpen={isRetryDialog}
        onClose={toggleRetryDialog}
        onSuccess={onAchRetrySuccess}
        planId={props.planId}
        type='ACH'
        ucid={props.ucid}
      />
    </>
  );
};
