import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class OffsetEmployerContribution {
  convertFromYesNoToBoolean = Helper.convertFromYesNoToBoolean;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = Helper.convertFromBooleanToYesNo(
      props.offsetEmployerContribution
    );
  }
}

export default OffsetEmployerContribution;
