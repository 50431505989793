import { pick } from 'lodash';

import { Correction, FileRow } from '../types';

export const generateFileRows = (corrections: Correction[]): FileRow[] =>
  corrections?.map(row =>
    pick(row, [
      'ssn',
      'first_name',
      'last_name',
      'em',
      'ln',
      'ps',
      'rc',
      'sd',
      'sh',
      'at',
      'qc',
      'qm',
      'em_original',
      'ln_original',
      'ps_original',
      'rc_original',
      'sd_original',
      'sh_original',
      'at_original',
      'qc_original',
      'qm_original'
    ])
  ) ?? [];
