import { HouseAccountDto } from '@/models/ops/house-accounts/HouseAccountDto.model';
import { ParentToCustodianCashBalanceDTO } from '@/models/ops/parent-to-custodian-cash-balance/ParentToCustodianCashBalanceDTO.model';
import { ParentToCustodianCashBalanceRequest } from '@/models/ops/parent-to-custodian-cash-balance/ParentToCustodianCashBalanceRequest.model';
import { ParentToCustodianPositionDTO } from '@/models/ops/parent-to-custodian-position/ParentToCustodianPositionDTO.model';
import { ParentToCustodianPositionRequest } from '@/models/ops/parent-to-custodian-position/ParentToCustodianPositionRequest.model';
import { ParentAccountDto } from '@/models/ops/ParentAccountDTO.model';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import ApiService from '@/services/Api.service';
import { CustodianId } from '@vestwell-sub-accounting/models/common/CustodianId';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

export type ParentAccountSearchParams = {
  accountType?: ParentAccountType;
  custodianId?: CustodianId;
  query: string;
};

export class ParentAccountService {
  static async searchParentAccounts({
    accountType,
    custodianId,
    query
  }: ParentAccountSearchParams): Promise<ParentAccountDto[]> {
    const results = (await ApiService.getJson('/parent-accounts', {
      accountType,
      custodianId,
      query
    })) as ParentAccountDto[];

    return results;
  }

  static async get(params: {
    custodianAccountNumber: string;
    custodianId: CustodianId;
  }): Promise<ParentAccountDto> {
    return ApiService.getJson('/parent-accounts', params);
  }

  static async getById(id: number | string): Promise<ParentAccountDto> {
    const result = await ApiService.getJson<ParentAccountDto>(
      `parent-accounts/${id}`
    );
    return result;
  }

  static async getHouseAccounts(id: string) {
    return ApiService.getJson<HouseAccountDto[]>(
      `parent-accounts/${id}/house-accounts`
    );
  }

  static async getParentToCustodianCashBalances(
    params: ParentToCustodianCashBalanceRequest
  ): Promise<PaginatedApiResponse<ParentToCustodianCashBalanceDTO[]>> {
    const result = await ApiService.getJson<
      PaginatedApiResponse<ParentToCustodianCashBalanceDTO[]>
    >(
      `parent-accounts/${params.parentAccountId}/parent-to-custodian-cash-balances`,
      params
    );
    return result;
  }

  static async getParentToCustodianPositions(
    params: ParentToCustodianPositionRequest
  ): Promise<PaginatedApiResponse<ParentToCustodianPositionDTO[]>> {
    const result = await ApiService.getJson<
      PaginatedApiResponse<ParentToCustodianPositionDTO[]>
    >(
      `parent-accounts/${params.parentAccountId}/parent-to-custodian-position`,
      params
    );
    return result;
  }
}

export default ParentAccountService;
