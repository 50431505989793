import { AgGridValidation } from '@/components/ag-grid';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanV2Dto } from '@/models';
import { PlanFeaturesDto } from '@/models/PlanFeaturesDto.model';
import ContributionService from '@/services/Contribution.service';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import { Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { AgGridReact } from 'ag-grid-react';
import Decimal from 'decimal.js';
import { uniq } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToggle, useUpdateEffect } from 'react-use';
import { useDebouncedCallback } from 'use-debounce';
import { utils, write } from 'xlsx';
import * as yup from 'yup';

import { DOCUMENT_CATEGORIES } from '../../consts';
import { dateValidator } from '../../helpers';
import { useGridColumns, useGridRows } from '../../hooks';
import { columnTypes } from './AgGridColumnTypes';

type SpreadSheetProps = {
  planId: number;
  ucid: string;
  populate: 'participants' | 'normalized';
};

const useStyles = makeStyles(theme => ({
  pagination: {
    '& .ag-paging-panel': {
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: 400,
      paddingTop: 15
    },
    '& .ag-paging-panel span': {
      fontWeight: 400
    }
  }
}));

export const SpreadSheet: FC<SpreadSheetProps> = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const gridRef = useRef<AgGridReact>(null);
  const [isWarning, toggleIsWarning] = useToggle(false);
  const [isFilterZeros, toggleFilterZeros] = useToggle(false);
  const [search, setSearch] = useState('');
  const [, setSearchParams] = useSearchParams();
  const [isValid, setIsValid] = useState(true);
  const [debouncedData, setDebouncedData] = useState({
    employeeEsaContribution: null,
    employeeGroupName: null,
    participantId: null,
    rowNumber: null
  });
  const [editedRowIds, setEditedRowIds] = useState([]);

  const [gridRows, setGridRows] = useState([]);

  const plan = useQuery<PlanV2Dto>(
    [PlanService.getPlanById.name, +props.planId],
    () => PlanService.getPlanById(+props.planId),
    { enabled: !!props.planId }
  );

  const contribution = useQuery(
    [
      ContributionService.getContributionDetails.name,
      plan.data?.data?.id,
      plan.data?.data?.relationships?.sponsor?.data?.id,
      props.ucid
    ],
    () =>
      ContributionService.getContributionDetails({
        planId: plan.data?.data?.id,
        sort: 'lastName',
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        ucid: props.ucid
      }),
    { enabled: !!props.ucid && !!plan.data?.data }
  );

  const contributionData = useQuery(
    [
      ContributionService.getSubmission.name,
      plan.data?.data?.id,
      plan.data?.data?.relationships?.sponsor?.data?.id,
      props.ucid,
      plan.data?.data.attributes.recordkeeper,
      props.populate
    ],
    () =>
      ContributionService.getSubmission(
        {
          ucid: props.ucid
        },
        {
          planId: plan.data?.data?.id,
          populate: props.populate,
          recordkeeperName: plan.data?.data.attributes.recordkeeper,
          sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id
        }
      ),
    {
      cacheTime: 1,
      enabled: !!plan.data?.data,
      keepPreviousData: false,
      refetchOnMount: 'always'
    }
  );

  const loans = useQuery(
    [
      ContributionService.getParticipantsLoans.name,
      plan.data?.data?.id,
      plan.data?.data?.relationships?.sponsor?.data?.id
    ],
    () =>
      ContributionService.getParticipantsLoans({
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        sponsorPlanId: plan.data?.data?.id
      }),
    {
      enabled:
        !!plan.data?.data && plan.data?.data.attributes.recordkeeper === 'Voya'
    }
  );

  const planFeatures = useQuery<PlanFeaturesDto>(
    [PlanService.getPlanFeatures.name, plan.data?.data?.id],
    () => PlanService.getPlanFeatures(plan.data?.data?.id),
    {
      enabled: !!plan.data
    }
  );

  const esaEmployerContributions = useQuery(
    [
      ParticipantService.getCalculatedEsaEmployerContributions.name,
      debouncedData.participantId,
      debouncedData.employeeEsaContribution,
      debouncedData.employeeGroupName,
      plan.data?.data?.id
    ],
    () =>
      ParticipantService.getCalculatedEsaEmployerContributions(
        {
          participantId: debouncedData.participantId
        },
        {
          employeeEsaContribution: debouncedData.employeeEsaContribution,
          employeeGroupName: debouncedData.employeeGroupName,
          sponsorPlanId: plan.data?.data?.id
        }
      ),
    {
      enabled:
        plan.data?.data.attributes.recordkeeper === 'Vestwell ESA' &&
        !!debouncedData.participantId &&
        !!debouncedData.employeeGroupName &&
        !!debouncedData.rowNumber
    }
  );

  const postContributionS3FileContents = useMutation((csv: string) =>
    ContributionService.postContributionS3FileContents(
      { ucid: props.ucid },
      {
        documentCategoryId:
          DOCUMENT_CATEGORIES[contribution.data?.key?.flowSubtype],
        fileContent: csv,
        fileName: `${plan.data?.data?.attributes?.name}_${plan.data?.data?.id}_${props.ucid}_${contribution.data?.key?.flowSubtype}.csv`,
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        sponsorPlanId: plan.data?.data?.id
      }
    )
  );

  const postIngestionFlowStart = useMutation(() =>
    ContributionService.postIngestionFlowStart({
      flowSubtype: contribution.data?.key?.flowSubtype,
      initiator: 'Admin',
      isManualSubmission: true,
      sourceId: plan.data?.data?.relationships?.sponsor?.data?.id,
      sourceType: 'Sponsor',
      ucid: props.ucid
    })
  );

  const postContributionNormalizationCorrections = useMutation((data: any) =>
    ContributionService.postContributionNormalizationCorrections(
      {
        ucid: props.ucid
      },
      {
        data,
        initiator: 'Admin',
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        sponsorPlanId: plan.data?.data?.id
      }
    )
  );

  useUpdateEffect(() => {
    const arr = [...gridRows];
    arr[debouncedData.rowNumber] = {
      ...arr[debouncedData.rowNumber],
      esaInitialBonus: esaEmployerContributions.data?.data?.initialBonus,
      esaMatch: esaEmployerContributions.data?.data?.employerMatch,
      esaMilestoneBonusAmount:
        esaEmployerContributions.data?.data?.milestoneBonusAmount
    };

    setGridRows(arr);
  }, [esaEmployerContributions.data, esaEmployerContributions.dataUpdatedAt]);

  const debounced = useDebouncedCallback(
    async data =>
      setDebouncedData({
        employeeEsaContribution: data.value,
        employeeGroupName: data.row?.employee_group_name,
        participantId: data.row?.id,
        rowNumber: data.rowNumber
      }),
    2000
  );

  const columns = useGridColumns(
    planFeatures.data?.data?.attributes,
    contribution.data?.key?.flowSubtype,
    contribution.data?.recordkeeper
  );

  const initialRows = useGridRows(
    contributionData.data,
    contribution.data?.recordkeeper,
    columns,
    loans.data,
    props.populate
  );

  const searchRegExp = useMemo(
    () =>
      new RegExp(
        search
          .split(' ')
          ?.map(item => `(?=.*${item?.toLowerCase() ?? ''})`)
          ?.join('')
      ),
    [search]
  );

  const validations = useMemo(() => {
    return yup.array().of(
      yup.object().shape({
        date_of_hire: yup
          .string()
          .test('date_of_hire', 'Invalid date', dateValidator),
        date_of_rehire: yup
          .string()
          .test('date_of_rehire', 'Invalid date', dateValidator),
        date_of_termination: yup
          .string()
          .test('date_of_termination', 'Invalid date', dateValidator)
      })
    );
  }, []);

  const onFileSubmission = useCallback(
    () => setSearchParams({ isEdit: 'false', isManual: 'false' }),
    []
  );

  const onSearchChanged = useCallback(e => setSearch(e.target.value), []);

  const onCellChanged = useCallback(
    (index, field, value) => {
      const arr = [...gridRows];
      arr[index] = {
        ...arr[index],
        [field]: value
      };

      if (field.includes('loan_amt_')) {
        arr[index] = {
          ...arr[index],
          ln: Decimal.sum(
            new Decimal(arr[index]?.loan_amt_1 || 0),
            new Decimal(arr[index]?.loan_amt_2 || 0),
            new Decimal(arr[index]?.loan_amt_3 || 0),
            new Decimal(arr[index]?.loan_amt_4 || 0),
            new Decimal(arr[index]?.loan_amt_5 || 0)
          ).toNumber()
        };
      }

      setGridRows(arr);

      if (props.populate === 'normalized') {
        setEditedRowIds(prevState => uniq([...prevState, index]));
      }

      if (
        plan.data?.data.attributes.recordkeeper === 'Vestwell ESA' &&
        field === 'esaEeContribution'
      ) {
        debounced({
          row: arr[index],
          rowNumber: index,
          value
        });
      }
    },
    [gridRows, plan.data, props.populate]
  );

  const filterName = useCallback(
    (firstName, lastName) =>
      searchRegExp.test(`${firstName} ${lastName}`.toLowerCase()),
    [searchRegExp]
  );

  const filterTotal = useCallback(
    ({ rc, sd, at, sh, em, ps, ln, qc, qm }) =>
      [+rc, +sd, +at, +sh, +em, +ps, +ln, +qc, +qm].some(
        value => !isNaN(value) && value !== 0
      ),
    []
  );

  const doesExternalFilterPass = useCallback(
    node => {
      if (!!search && isFilterZeros) {
        return (
          filterName(node.data?.first_name, node.data?.last_name) &&
          filterTotal(node.data)
        );
      }

      if (search) {
        return filterName(node.data?.first_name, node.data?.last_name);
      }

      if (isFilterZeros) {
        return filterTotal(node.data);
      }

      return true;
    },
    [search, isFilterZeros, filterName]
  );

  const isExternalFilterPresent = useCallback(() => {
    return !!search || isFilterZeros;
  }, [search, isFilterZeros]);

  const onError = useCallback(errors => {
    setIsValid(!Object.keys(errors)?.length);
  }, []);

  const onCellEditingStopped = useCallback(
    () => debounced.flush(),
    [debounced]
  );

  const onSubmit = useCallback(async () => {
    if (props.populate === 'normalized') {
      const excludedKeys = ['uuid'];
      const numericFields = columns
        .filter(column => ['currency', 'numeric'].includes(column.type))
        ?.map(column => column.field);

      const corrections = editedRowIds?.reduce((acc, id) => {
        const updated = gridRows?.[id];
        const initial = initialRows?.[id];

        const correction = Object.entries(updated)?.reduce(
          (acc, [key, value]) => {
            if (
              excludedKeys.includes(key) ||
              value === initial[key] ||
              (numericFields.includes(key) &&
                new Decimal(+value).equals(+initial[key]))
            )
              return acc;

            return {
              ...acc,
              correction: {
                action: 'update',
                errorCode: 'DATA_UPDATE',
                rowNum: initial?.normalizedDataIndex,
                // @ts-ignore
                ...(acc.correction ?? {}),
                [key]: value
              },
              initial: {
                action: 'update',
                errorCode: 'DATA_UPDATE',
                rowNum: initial?.normalizedDataIndex,
                // @ts-ignore
                ...(acc.initial ?? {}),
                [key]: initial[key]
              }
            };
          },
          {}
        );

        return Object.keys(correction)?.length ? [...acc, correction] : acc;
      }, []);

      await postContributionNormalizationCorrections.mutateAsync({
        stages: [
          {
            groups: [
              {
                group: 'Data Update',
                records: corrections
              }
            ],
            stage: 'Data Update'
          }
        ]
      });

      return;
    }

    const workbook = utils.book_new();
    const dataSheet = utils.json_to_sheet(gridRows);
    utils.book_append_sheet(workbook, dataSheet);

    const csv = write(workbook, { bookType: 'csv', type: 'string' });

    await postContributionS3FileContents.mutateAsync(csv);
    await postIngestionFlowStart.mutateAsync();
  }, [gridRows, initialRows, props.populate]);

  useUpdateEffect(() => {
    if (
      postContributionS3FileContents.isError ||
      postIngestionFlowStart.isError ||
      contributionData.isError
    ) {
      snackbar.showSnackbar({
        message: 'Something went wrong!',
        severity: 'error'
      });
    }
  }, [
    postContributionS3FileContents.isError,
    postIngestionFlowStart.isError,
    contributionData.isError
  ]);

  useUpdateEffect(() => {
    if (postIngestionFlowStart.isSuccess) {
      navigate(
        `/plans/${plan.data?.data?.id}/contributions/${props.ucid}/submission/confirmation`
      );
    }
  }, [postIngestionFlowStart.isSuccess]);

  useUpdateEffect(() => {
    if (postContributionNormalizationCorrections.isSuccess) {
      navigate(
        `/plans/${plan.data?.data?.id}/contributions/${props.ucid}/submission/confirmation`
      );
    }
  }, [postContributionNormalizationCorrections.isSuccess]);

  useEffect(() => {
    setGridRows(
      initialRows?.map((record, index) => ({
        ...record,
        uuid: index?.toString()
      }))
    );
  }, [initialRows]);

  useUpdateEffect(() => {
    gridRef.current?.api?.onFilterChanged();
  }, [search, isFilterZeros]);

  const isLoading =
    contributionData.isFetching ||
    !contributionData.isFetchedAfterMount ||
    contribution.isFetching ||
    planFeatures.isFetching ||
    plan.isFetching ||
    postContributionS3FileContents.isLoading ||
    postIngestionFlowStart.isLoading ||
    postContributionNormalizationCorrections.isLoading;

  return (
    <>
      <Card elevation={0} sx={{ width: '100%' }} variant='outlined'>
        <Grid container display='flex' justifyContent='space-between' p={2}>
          <Grid>
            <Typography variant='h5'>Contribution</Typography>
          </Grid>
          <Grid display='flex'>
            <Grid mr={2}>
              <LoadingButton
                data-testid='uploadFileInstead'
                loading={isLoading}
                onClick={toggleIsWarning}>
                Upload file instead
              </LoadingButton>
            </Grid>
            <Grid>
              <LoadingButton
                data-testid='continue'
                disabled={!isValid}
                loading={isLoading}
                onClick={onSubmit}
                type='submit'
                variant='contained'>
                Continue
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <Card variant='outlined'>
          {(contributionData.isFetching ||
            !contributionData.isFetchedAfterMount ||
            contribution.isFetching ||
            planFeatures.isFetching ||
            plan.isFetching) && <LinearProgress />}
          <Grid display='flex'>
            <Grid p={2}>
              <TextField
                InputProps={{
                  'aria-placeholder': 'Search Employees',
                  onChange: onSearchChanged,
                  placeholder: 'Search Employees',
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  )
                }}
                data-testid='search'
                variant='outlined'
              />
            </Grid>
            <Grid alignItems='center' display='flex'>
              <Switch
                data-testid='hideEmployeesWithoutContributions'
                onChange={toggleFilterZeros}
              />
              <Typography color='GrayText'>
                Hide Employees without contributions
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.pagination} my={2}>
            <AgGridValidation
              alwaysShowVerticalScroll
              columnDefs={columns as ColDef[]}
              columnTypes={columnTypes}
              doesExternalFilterPass={doesExternalFilterPass}
              isExternalFilterPresent={isExternalFilterPresent}
              loadingOverlayComponent={CircularProgress}
              onCellChanged={onCellChanged}
              onCellEditingStopped={onCellEditingStopped}
              onError={onError}
              pagination
              paginationPageSize={15}
              primaryKey='uuid'
              ref={gridRef}
              rowData={gridRows}
              singleClickEdit
              suppressRowClickSelection
              validations={validations}
            />
          </Grid>
        </Card>
      </Card>
      <Dialog
        data-testid='uploadFile-dialog'
        fullWidth
        maxWidth='xs'
        onClose={toggleIsWarning}
        open={isWarning}>
        <DialogTitle data-testid='uploadFile-dialog-title'>
          Upload File
        </DialogTitle>
        <DialogContent>
          <Typography data-testid='uploadFile-dialog-content'>
            Entered data will be lost. Continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid display='flex'>
            <Grid mr={2}>
              <Button
                data-testid='uploadFile-dialog-close'
                onClick={toggleIsWarning}>
                Keep editing
              </Button>
            </Grid>
            <Grid>
              <Button
                color='error'
                data-testid='uploadFile-dialog-confirm'
                onClick={onFileSubmission}
                variant='contained'>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
