import { SimpleUpload } from '@/components/simple-upload/SimpleUpload.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanV2Dto } from '@/models';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import { FileUpload as FileUploadIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useCallback } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle, useUpdateEffect } from 'react-use';

import { DOCUMENT_CATEGORIES } from '../consts';
import { getInvalidHeaders, toCsv } from '../helpers';
import { HeaderMapping } from './HeaderMapping.component';
import { UnrecognizedHeaders } from './UnrecognizedHeaders.component';

type UploadProps = {
  ucid: string;
  planId: number;
};

export const Upload: FC<UploadProps> = props => {
  const [isUnrecognized, toggleIsUnrecognized] = useToggle(false);
  const [isHeaderMapping, toggleIsHeaderMapping] = useToggle(false);
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const plan = useQuery<PlanV2Dto>(
    [PlanService.getPlanById.name, props.planId],
    () => PlanService.getPlanById(props.planId),
    { enabled: !!props.planId }
  );

  const contribution = useQuery(
    [
      ContributionService.getContributionDetails.name,
      plan.data?.data?.id,
      plan.data?.data?.relationships?.sponsor?.data?.id,
      props.ucid
    ],
    () =>
      ContributionService.getContributionDetails({
        planId: plan.data?.data?.id,
        sort: 'lastName',
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        ucid: props.ucid
      }),
    { enabled: !!props.ucid && !!plan.data?.data }
  );

  const headerValidations = useQuery<Record<any, any>>(
    [
      ContributionService.getHeaderValidations.name,
      props.ucid,
      plan.data?.data?.relationships?.sponsor?.data?.id,
      plan.data?.data?.id
    ],
    () =>
      ContributionService.getHeaderValidations(
        {
          ucid: props.ucid
        },
        {
          sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
          sponsorPlanId: plan.data?.data?.id
        }
      ),
    { enabled: false }
  );

  const postContributionS3FileContents = useMutation((csv: string) =>
    ContributionService.postContributionS3FileContents(
      { ucid: props.ucid },
      {
        documentCategoryId:
          DOCUMENT_CATEGORIES[contribution.data?.key?.flowSubtype],
        fileContent: csv,
        fileName: `${plan.data?.data?.attributes?.name}_${plan.data?.data?.id}_${props.ucid}_${contribution.data?.key?.flowSubtype}.csv`,
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        sponsorPlanId: plan.data?.data?.id
      }
    )
  );

  const postIngestionFlowStart = useMutation(() =>
    ContributionService.postIngestionFlowStart({
      flowSubtype: contribution.data?.key?.flowSubtype,
      initiator: 'Admin',
      isManualSubmission: true,
      sourceId: plan.data?.data?.relationships?.sponsor?.data?.id,
      sourceType: 'Sponsor',
      ucid: props.ucid
    })
  );

  const onSelect = useCallback(async file => {
    await postContributionS3FileContents.mutateAsync(toCsv(file[0]?.data));
    await headerValidations.refetch();
  }, []);

  useUpdateEffect(() => {
    const hasRecognizedColumns =
      headerValidations?.data?.validationResults?.some(
        header => header.fileNames?.length
      );

    if (!hasRecognizedColumns) {
      toggleIsUnrecognized();
      return;
    }

    const hasHeaderErrors =
      getInvalidHeaders(headerValidations?.data)?.length > 0;

    if (!hasHeaderErrors) {
      postIngestionFlowStart.mutateAsync();
      return;
    }

    toggleIsHeaderMapping();
  }, [headerValidations.data, headerValidations.dataUpdatedAt]);

  useUpdateEffect(() => {
    if (postIngestionFlowStart.isSuccess) {
      navigate(
        `/plans/${plan.data?.data?.id}/contributions/${props.ucid}/submission/confirmation`
      );
    }
  }, [postIngestionFlowStart.isSuccess]);

  useUpdateEffect(() => {
    if (
      postContributionS3FileContents.isError ||
      postIngestionFlowStart.isError
    ) {
      snackbar.showSnackbar({
        message: 'Something went wrong!',
        severity: 'error'
      });
    }
  }, [postContributionS3FileContents.isError, postIngestionFlowStart.isError]);

  useUpdateEffect(() => {
    if (postContributionS3FileContents.isSuccess) {
      snackbar.showSnackbar({
        message: 'File successfully uploaded!',
        severity: 'success'
      });
    }
  }, [postContributionS3FileContents.isSuccess]);

  return (
    <>
      <SimpleUpload
        accept={{
          'application/vnd.ms-excel': ['.xlsx'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xls'
          ],
          'text/csv': ['.csv']
        }}
        data-testid='upload'
        onSelect={onSelect}>
        <LoadingButton
          loading={
            plan.isFetching ||
            contribution.isFetching ||
            headerValidations.isFetching ||
            postContributionS3FileContents.isLoading
          }
          startIcon={<FileUploadIcon />}
          variant='outlined'>
          Upload File
        </LoadingButton>
      </SimpleUpload>
      <UnrecognizedHeaders
        isOpen={isUnrecognized}
        onClose={toggleIsUnrecognized}
      />
      <HeaderMapping
        isOpen={isHeaderMapping}
        onClose={toggleIsHeaderMapping}
        sponsorId={plan.data?.data?.relationships?.sponsor?.data?.id}
        sponsorPlanId={plan.data?.data?.id}
        ucid={props.ucid}
      />
    </>
  );
};
