import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import CollapsibleTable from '@/components/collapsible-table';
import TooltipButton from '@/components/tool-tip-button';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { WhitelabelManagementTableCell } from '@/routes/ops/whitelabel/WhitelabelManagementTableCell.component';
import { WhitelabelService } from '@/services/whitelabel.service';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  InputAdornment,
  Paper,
  styled,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { FC, useCallback, useMemo, useState } from 'react';
import type { WhitelabelEntityResponse } from 'scala-sdk';
import { useDebounce } from 'use-debounce';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
  width: '100%'
}));

const SearchSection = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(2),
  width: theme.spacing(60)
}));

const whitelabelColumns: GridColDef[] = [
  {
    field: 'whitelabelId',
    headerName: 'ID',
    sortable: false
  },
  {
    field: 'logo',
    headerName: 'Logo',
    sortable: false
  },
  {
    field: 'subdomain',
    headerName: 'Subdomain Name',
    sortable: false
  },
  {
    field: 'status',
    headerName: 'Subdomain Status',
    sortable: false
  },
  {
    field: 'updatedAt',
    headerName: 'Last updated',
    sortable: false
  },
  { field: 'updatedBy', headerName: 'Updated by', sortable: false }
];

export const WhitelabelManagement: FC = () => {
  const { showSnackbar } = useSnackbar();

  const [searchTerm, setSearchTerm] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);

  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);

  const hasAccess = useHasPermissions({
    requiresOneOf: [FeatureLevelPermissions.WRITE_WHITELABEL_MANAGEMENT]
  });

  const whitelabelEntities = useQuery<WhitelabelEntityResponse[]>(
    ['whitelabel.getEntities', debouncedSearchTerm],
    () => WhitelabelService.getEntities(),
    {
      select: data =>
        data.filter(
          entity =>
            entity.subdomain.includes(debouncedSearchTerm) ||
            entity.whitelabelId.toString().includes(debouncedSearchTerm)
        ),
      staleTime: Infinity
    }
  );

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPageNumber(1);
  }, []);

  const onClick = useCallback(() => {
    showSnackbar({
      message: 'This feature is not yet implemented.',
      severity: 'success'
    });
  }, []);

  const pager = useMemo(
    () => ({
      onPageNumberChanged: (zeroIndexedPageNumber: number) => {
        return setPageNumber(zeroIndexedPageNumber + 1);
      },
      onRowsPerPageChanged: (newRowsPerPage: number) => {
        return setRowsPerPage(newRowsPerPage);
      },
      pageNumber: pageNumber - 1,
      rowsPerPage
    }),
    [pageNumber, rowsPerPage]
  );

  return (
    <>
      <Typography variant='h4'>White Label Management</Typography>
      <StyledPaper elevation={0} variant='outlined'>
        <SearchSection>
          <div>
            <StyledTextField
              InputProps={{
                onChange,
                placeholder: 'Search by white label ID or subdomain name',
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                )
              }}
              data-testid='searchField'
              value={searchTerm}
              variant='outlined'
            />
          </div>
          <TooltipButton
            disabled={whitelabelEntities.isLoading || hasAccess.isForbidden}
            handleOnClick={onClick}
            startIcon={<AddIcon />}
            sx={{ marginRight: (theme: Theme) => theme.spacing(2) }}
            testId='newSubdomainButton'
            tooltipMessage='You do not have the necessary permissions to create a subdomain'
            variant='contained'>
            New subdomain
          </TooltipButton>
        </SearchSection>
        <CollapsibleTable
          cellComponent={WhitelabelManagementTableCell}
          columns={whitelabelColumns}
          isLoading={whitelabelEntities.isFetching}
          pager={pager}
          rootPaperElevation={0}
          tableData={whitelabelEntities.data || []}
          useDivAsBackground
        />
      </StyledPaper>
    </>
  );
};
