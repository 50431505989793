import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { StatefulSchemaTrackingState } from '@/models/PlanV2DTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Grid, IconButton, Menu, Stack, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { DefaultTheme, makeStyles } from '@mui/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React, { memo, useCallback } from 'react';

type PlanOnboardPizzaTrackerCardProps = {
  title: string;
  status: PlanTrackingStatus;
  date?: string | null;
  mr?: number;
  schemaTrackingState?: StatefulSchemaTrackingState;
};

type MenuButtonProps = {
  children?: React.ReactNode;
  schemaTrackingState?: PlanOnboardPizzaTrackerCardProps['schemaTrackingState'];
};

const OrderedMenuOptions = [
  {
    humanReadable: 'In Progress',
    status: 'in_progress'
  },
  {
    humanReadable: 'Needs Attention',
    status: 'needs_attention'
  },
  {
    humanReadable: 'Completed Successfully',
    status: 'completed_successfully'
  }
] as const;

const MenuButton: React.FC<MenuButtonProps> = (props: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSchemaMutation = useMutation(
    ['PlanService.updateStatefulSchema', props.schemaTrackingState?.id],
    async (updatedStatus: string) => {
      if (
        !props.schemaTrackingState?.id ||
        !props.schemaTrackingState?.attributes?.state
      ) {
        throw new Error(
          `stateful schema cannot be updated without an id and state`
        );
      }

      return PlanService.updateStatefulSchema(
        props.schemaTrackingState.id,
        props.schemaTrackingState.attributes.state,
        updatedStatus
      );
    }
  );

  const handleMenuOptionClicked = useCallback(
    async (menuOpt: (typeof OrderedMenuOptions)[number]) => {
      try {
        await updateSchemaMutation.mutateAsync(menuOpt.status);

        queryClient.invalidateQueries();

        showSnackbar({
          message: `Status updated to: "${menuOpt.humanReadable}"`,
          severity: 'success'
        });
      } catch (e) {
        console.error(e);
        showSnackbar({
          message: `Status failed to update`,
          severity: 'error'
        });
      }

      return handleClose();
    },
    [updateSchemaMutation, showSnackbar, queryClient]
  );

  return (
    <>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='settings'
        data-testid='plan-onboard-pizza-tracker-card-menu-toggle'
        id='plan-onboard-pizza-tracker-card-menu-toggle'
        onClick={handleClick}
        sx={theme => ({
          mr: theme.spacing(-1),
          mt: theme.spacing(-1),
          p: 0.75
        })}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        <MenuList dense>
          {OrderedMenuOptions.map(menuOpt => (
            <MenuItem
              disabled={
                props.schemaTrackingState?.attributes.status === menuOpt.status
              }
              key={menuOpt.status}
              onClick={() => handleMenuOptionClicked(menuOpt)}>
              <ListItemText>Set to "{menuOpt.humanReadable}"</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

type PlanTrackingStatus =
  | 'NOT STARTED'
  | 'STARTED'
  | 'IN PROGRESS'
  | 'PENDING'
  | 'SCHEDULED'
  | 'SENT'
  | 'SENT FOR SIGNATURE'
  | 'SIGNED'
  | 'TARGET'
  | 'SUBMITTED'
  | 'DONE'
  | 'COMPLETE';

const STATUS_COLORS: Record<
  PlanTrackingStatus,
  { backgroundColor: (opacity: number) => string; fontColor: string }
> = {
  COMPLETE: {
    backgroundColor: opacity => `rgba(136, 230, 196, ${opacity})`,
    fontColor: 'rgb(25, 133, 95)'
  },
  DONE: {
    backgroundColor: opacity => `rgba(136, 230, 196, ${opacity})`,
    fontColor: 'rgb(25, 133, 95)'
  },
  'IN PROGRESS': {
    backgroundColor: opacity => `rgba(136, 202, 230, ${opacity})`,
    fontColor: 'rgb(6, 121, 175)'
  },
  'NOT STARTED': {
    backgroundColor: opacity => `rgba(219, 224, 231, ${opacity})`,
    fontColor: 'rgb(115, 115, 115)'
  },
  PENDING: {
    backgroundColor: opacity => `rgba(125, 98, 236, ${opacity})`,
    fontColor: 'rgb(255, 255, 255)'
  },
  SCHEDULED: {
    backgroundColor: opacity => `rgba(244, 185, 143, ${opacity})`,
    fontColor: 'rgb(236, 101, 23)'
  },
  SENT: {
    backgroundColor: opacity => `rgba(136, 230, 196, ${opacity})`,
    fontColor: 'rgb(25, 133, 95)'
  },
  'SENT FOR SIGNATURE': {
    backgroundColor: opacity => `rgba(244, 185, 143, ${opacity})`,
    fontColor: 'rgb(236, 101, 23)'
  },
  SIGNED: {
    backgroundColor: opacity => `rgba(136, 230, 196, ${opacity})`,
    fontColor: 'rgb(25, 133, 95)'
  },
  STARTED: {
    backgroundColor: opacity => `rgba(136, 202, 230, ${opacity})`,
    fontColor: 'rgb(6, 121, 175)'
  },
  SUBMITTED: {
    backgroundColor: opacity => `rgba(136, 202, 230, ${opacity})`,
    fontColor: 'rgb(6, 121, 175)'
  },
  TARGET: {
    backgroundColor: opacity => `rgba(219, 224, 231, ${opacity})`,
    fontColor: 'rgb(115, 115, 115)'
  }
};

const useStyles = makeStyles<DefaultTheme, PlanOnboardPizzaTrackerCardProps>({
  date: {
    opacity: 0.6
  },
  statusPill: {
    backgroundColor: props => STATUS_COLORS[props.status].backgroundColor(0.3)
  },
  statusText: {
    color: props => STATUS_COLORS[props.status].fontColor
  }
});

const PlanOnboardPizzaTrackerCard: React.FC<
  PlanOnboardPizzaTrackerCardProps
> = (props: PlanOnboardPizzaTrackerCardProps) => {
  const classes = useStyles(props);

  return (
    <Grid
      border='1px solid #E0E0E0'
      borderRadius='0.25rem 0.25rem'
      container
      display='flex'
      flexDirection='column'
      minHeight='9.5rem'
      p={2}>
      <Grid item mb={2}>
        <Stack
          alignItems='flex-start'
          display='flex'
          flexDirection='row'
          justifyContent='space-between'>
          <Grid display='flex' item>
            <Typography mr={1} variant='body1'>
              {props.title}
            </Typography>
          </Grid>
          {props.schemaTrackingState !== null &&
            props.schemaTrackingState !== undefined && (
              <AccessControl
                requires={[
                  FeatureLevelPermissions.WRITE_ONBOARD_STATEFUL_SCHEMA
                ]}>
                <Grid display='flex' item>
                  <MenuButton schemaTrackingState={props.schemaTrackingState} />
                </Grid>
              </AccessControl>
            )}
        </Stack>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item>
            <Box
              borderRadius='1.75rem'
              className={classes.statusPill}
              display='inline-block'
              mb={0.5}
              px='0.75rem'
              py='0.375rem'>
              <Typography
                className={classes.statusText}
                fontWeight={500}
                variant='body2'>
                {capitalize(props.status)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {props.date && (
        <Grid item>
          <Typography className={classes.date} variant='body1'>
            {dayjs.utc(props.date).format('MM/DD/YYYY')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(PlanOnboardPizzaTrackerCard);
