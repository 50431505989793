import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useHeap } from '@/hooks';
import { useFixPlanRoute } from '@/hooks/useFixPlanRoute.hook';
import type { PlanV2Dto } from '@/models';
import type { PostContributionPayload } from '@/models/Contribution.model';
import type { CreateReversalsPayload } from '@/models/ContributionReversals.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { router } from '@/router';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { ArrowForwardSharp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { DataGridPro, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { useMutation, useQuery } from '@tanstack/react-query';

import Decimal from 'decimal.js';
import { pickBy } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSessionStorage, useToggle, useUpdateEffect } from 'react-use';

import { Header, Total } from './components';
import { convertJsonToCsv, generateFileRows } from './helpers';
import type { Correction, TableRow } from './types';

const useStyles = makeStyles(() => ({
  grid: {
    marginTop: 25
  },
  negativeAmount: {
    backgroundColor: red[50],
    color: red[700]
  },
  positiveAmount: {
    backgroundColor: green[50],
    color: green[700]
  }
}));

type PlanContributionCorrectionOverviewRouteProps = {
  sponsorPlanId: string;
  ucid: string;
  planType: string;
};

const PlanContributionCorrectionOverviewRoute: FunctionComponent = () => {
  const [corrections] = useSessionStorage<Correction[]>('corrections');
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<PlanContributionCorrectionOverviewRouteProps>();

  const [comment, setComment] = useState('');
  const [responsibleParty, setResponsibleParty] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [reason, setReason] = useState('');
  const [correctionUcid, setCorrectionUcid] = useState('');
  const [retryCount, setRetryCount] = useState(0);
  const [isSubmitting, toggleSubmitting] = useToggle(false);

  const classes = useStyles();

  const plan = useQuery<PlanV2Dto>(
    [PlanService.getPlanById.name, params.sponsorPlanId],
    () => PlanService.getPlanById(params.sponsorPlanId),
    {
      enabled: !!params.sponsorPlanId,
      staleTime: Infinity
    }
  );

  useFixPlanRoute();

  const payrollSetups = useQuery(
    [PlanService.getPlanPayrollSetups.name, params.sponsorPlanId],
    () => PlanService.getPlanPayrollSetups(params.sponsorPlanId),
    {
      enabled: !!params.sponsorPlanId && !!params.ucid,
      staleTime: Infinity
    }
  );

  const contribution = useQuery(
    [
      PlanService.getContributionsDetails.name,
      params.sponsorPlanId,
      params.ucid
    ],
    async () => {
      return PlanService.getContributionsDetails(
        params.sponsorPlanId,
        params.ucid
      );
    },
    {
      enabled: !!params.sponsorPlanId && !!params.ucid,
      staleTime: Infinity
    }
  );

  useQuery(
    [
      PlanService.getContributionsDetails.name,
      params.sponsorPlanId,
      correctionUcid
    ],
    async () => {
      return PlanService.getContributionsDetails(
        params.sponsorPlanId,
        correctionUcid
      );
    },
    {
      enabled: !!correctionUcid && retryCount !== 5,
      onSuccess: data => {
        if (data.status !== 'SUBMISSION_STARTED' || retryCount === 4) {
          snackbar.showSnackbar({
            message: 'Correction Submitted'
          });

          sessionStorage.removeItem('corrections');
          sessionStorage.removeItem('initialRows');
          sessionStorage.removeItem('rows');
          sessionStorage.removeItem('info');

          return navigate(
            `/plans/${params.sponsorPlanId}/contributions/${params.ucid}/overview`,
            { replace: true }
          );
        }

        return setRetryCount(prev => ++prev);
      },
      refetchInterval: 5000
    }
  );

  const postCorrections = useMutation((data: CreateReversalsPayload) => {
    return ContributionService.postContributionCorrections(data);
  });

  const postIngestionFlowStart = useMutation((data: any) => {
    return ContributionService.postIngestionFlowStart(data);
  });

  const postContribution = useMutation((data: PostContributionPayload) =>
    ContributionService.postContribution(data)
  );

  const rows = useMemo(
    () =>
      corrections.reduce<TableRow[]>((acc, current) => {
        const result = Object.entries(
          pickBy(
            current,
            (value, key) =>
              ['sd', 'rc', 'at', 'sh', 'ln', 'em', 'ps', 'qc', 'qm'].includes(
                key
              ) &&
              current[key as keyof Correction] !==
                current[`${key}_original` as keyof Correction]
          )
        )?.reduce<any>(
          (tableRows, [key, value]) => [
            ...tableRows,
            {
              first_name: current.first_name,
              funding_source: key,
              id: `${current.participant_id}_${key}`,
              last_name: current.last_name,
              new_value: value,
              old_value: current[`${key}_original` as keyof Correction],
              participant_id: current.participant_id
            }
          ],
          []
        );

        return [...acc, ...result];
      }, []),
    [corrections]
  );

  const totals = useMemo(() => {
    const correctedAmount = rows?.reduce((acc, current) => {
      return acc.plus(
        Decimal.sub(current.old_value ?? 0, current.new_value ?? 0)
      );
    }, new Decimal(0));

    return {
      correctedAmount: contribution.data?.totals?.total
        ? new Decimal(contribution.data?.totals?.total)
            .minus(correctedAmount)
            .toNumber()
        : 0,
      differenceAmount:
        (contribution.data?.totals?.total
          ? new Decimal(contribution.data?.totals?.total)
              .minus(
                new Decimal(contribution.data?.totals?.total).minus(
                  correctedAmount
                )
              )
              .negated()
              .toNumber()
          : 0) || 0,
      totalAmount: contribution.data?.totals?.total ?? 0
    };
  }, [contribution.data, rows]);

  const isSubmitDisabled = useMemo(() => {
    if (!responsibleParty || !reason) return true;

    if (reason === 'Other' && !otherReason) return true;

    if (responsibleParty === 'Needs further review' && !comment) return true;

    return false;
  }, [reason, otherReason, responsibleParty, comment]);

  const user = useMemo(() => userService.getUser(), []);

  useHeap(user);

  const onCancel = useCallback(
    () =>
      navigate(
        `/plans/${params.sponsorPlanId}/contributions/${params.ucid}/overview`,
        { replace: true }
      ),
    [params, history]
  );

  const onSubmit = useCallback(async () => {
    toggleSubmitting();

    if (
      !plan.data?.data?.relationships?.sponsor?.data?.id ||
      !plan.data?.data?.id
    )
      return;

    const getDivisionToUse = () => {
      const originalDivision = contribution.data?.division;
      const payrollSetupDivisions = payrollSetups.data?.map(
        row => row.division
      );

      if (payrollSetupDivisions?.includes(originalDivision)) {
        return originalDivision;
      } else {
        return payrollSetupDivisions?.find(
          division => typeof division !== 'undefined'
        );
      }
    };

    const divisionToUse = getDivisionToUse();

    const createdContribution = await postContribution.mutateAsync({
      flowSubtype: 'correction',
      isOffCycle: true,
      parentUcid: contribution.data?.ucid,
      payrollDate: contribution.data?.payrollDate,
      reason: otherReason || reason,
      responsibleParty,
      sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
      sponsorPlanId: plan.data?.data?.id,
      ...(comment ? { comment } : {}),
      ...(divisionToUse ? { division: divisionToUse } : {})
    });

    await postCorrections.mutateAsync({
      categoryId: 10,
      file: convertJsonToCsv(generateFileRows(corrections)),
      planId: plan.data?.data?.id,
      planName: plan.data?.data?.attributes?.name,
      sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
      ucid: createdContribution.ucid
    });

    await postIngestionFlowStart.mutateAsync({
      flowSubtype: 'correction',
      initiator: 'Admin',
      isManualSubmission: true,
      sourceId: plan.data?.data?.relationships?.sponsor?.data?.id,
      sourceType: 'Sponsor',
      ucid: createdContribution.ucid
    });

    setCorrectionUcid(createdContribution.ucid);
  }, [
    corrections,
    payrollSetups.data,
    plan.data,
    postCorrections,
    contribution.data,
    postContribution,
    postIngestionFlowStart,
    reason,
    otherReason,
    responsibleParty,
    comment,
    toggleSubmitting
  ]);

  const onEdit = useCallback(() => {
    navigate(
      `/plans/${params.sponsorPlanId}/contributions/${params.ucid}/corrections`,
      { replace: true, state: location.state }
    );
  }, [params, history]);

  const onReasonChanged = useCallback(
    e => {
      setReason(e.target?.value);
      setOtherReason('');
    },
    [setReason, setOtherReason]
  );

  const onOtherReasonChanged = useCallback(
    e => setOtherReason(e.target?.value),
    [setOtherReason]
  );

  const onResponsiblePartyChanged = useCallback(
    e => {
      setResponsibleParty(e.target?.value);
      setComment('');
    },
    [setResponsibleParty, setComment]
  );

  const onCommentChanged = useCallback(
    e => setComment(e.target?.value),
    [setComment]
  );

  useUpdateEffect(() => {
    if (
      postContribution.isError ||
      postCorrections.isError ||
      postIngestionFlowStart.isError
    ) {
      snackbar.showSnackbar({
        message: 'Error sending the correction',
        severity: 'error'
      });

      navigate(
        `/plans/${params.sponsorPlanId}/contributions/${params.ucid}/overview`,
        { replace: true }
      );
    }
  }, [
    postContribution.isError,
    postCorrections.isError,
    postIngestionFlowStart.isError
  ]);

  useEffect(() => {
    const cleanSessionStorage = router.subscribe(state => {
      if (state.historyAction === 'POP') {
        return;
      }

      sessionStorage.removeItem('corrections');

      if (
        state.location.pathname !==
        `/plans/${params.sponsorPlanId}/contributions/${params.ucid}/corrections`
      ) {
        sessionStorage.removeItem('initialRows');
        sessionStorage.removeItem('rows');
        sessionStorage.removeItem('info');
      }
    });

    return () => cleanSessionStorage();
  }, [location.pathname, params]);

  return (
    <div>
      <Box sx={{ mb: 3 }}>
        <Header
          label={
            location?.state?.isFullReversal
              ? `Reverse ${contribution.data?.payrollDate} Contribution`
              : `Review ${contribution.data?.payrollDate} Contribution Correction`
          }
          payrollDate={contribution.data?.payrollDate}
          planId={params.sponsorPlanId}
          planName={plan.data?.data?.attributes?.name}
          ucid={params.ucid}>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_CONTRIBUTION_CORRECTIONS]}>
            {location?.state?.isFullReversal ? (
              <Button
                data-testid='correctionOverview-cancel-button'
                disabled={
                  postCorrections.isLoading || postCorrections.isSuccess
                }
                onClick={onCancel}
                sx={{
                  mr: 2
                }}
                variant='outlined'>
                Cancel
              </Button>
            ) : (
              <Button
                data-testid='correctionOverview-edit-button'
                disabled={
                  postCorrections.isLoading || postCorrections.isSuccess
                }
                onClick={onEdit}
                sx={{
                  mr: 2
                }}
                variant='outlined'>
                Back to Edit
              </Button>
            )}
            <LoadingButton
              data-testid='correctionOverview-submit-button'
              disabled={isSubmitDisabled}
              loading={isSubmitting}
              loadingIndicator='Submitting...'
              onClick={onSubmit}
              variant='contained'>
              Submit corrections
            </LoadingButton>
          </AccessControl>
        </Header>
      </Box>
      <Grid>
        <Box alignItems='center' display='flex'>
          <Box sx={{ mr: 3 }}>
            <Total
              label='Total Contribution'
              value={totals.totalAmount}
              valueTestId='correctionOverview-total-amount'
            />
          </Box>
          <ArrowForwardSharp sx={{ mr: 3 }} />
          <Box sx={{ mr: 3 }}>
            <Total
              label='Corrected Amount'
              value={totals.correctedAmount}
              valueTestId='correctionOverview-corrected-amount'
            />
          </Box>
          <Box>
            <Total
              label='Difference'
              value={totals.differenceAmount}
              valueColor={
                totals.differenceAmount !== 0
                  ? totals.differenceAmount > 0
                    ? green[700]
                    : red[700]
                  : undefined
              }
              valuePrefix={totals.differenceAmount > 0 ? '+' : ''}
              valueTestId='correctionOverview-difference-amount'
            />
          </Box>
        </Box>
      </Grid>
      {!location?.state?.isFullReversal && (
        <Grid className={classes.grid} container>
          <Grid data-testid='correctionOverview-table' item xs={8}>
            <DataGridPro
              autoHeight
              columnBuffer={5}
              columns={[
                {
                  field: 'employee',
                  flex: 1,
                  headerName: 'Employee',
                  valueGetter: data =>
                    `${data.row?.last_name}, ${data.row?.first_name}`
                },
                {
                  field: 'field',
                  flex: 1,
                  headerName: 'Funding Source',
                  valueFormatter: (data: GridValueFormatterParams<string>) =>
                    ({
                      at: 'After-Tax',
                      em: 'D. Match',
                      ln: 'Loan',
                      ps: 'P. Sharing',
                      qc: 'QNEC',
                      qm: 'QMAC',
                      rc: 'Roth',
                      sd: 'Pre-Tax',
                      sh: 'S. Harbor'
                    })[data.value] || '--',
                  valueGetter: data => data.row?.funding_source
                },
                {
                  align: 'right',
                  field: 'old_value',
                  flex: 1,
                  headerAlign: 'right',
                  headerName: 'Old Value',
                  valueFormatter: data => formatters.formatDollars(data.value)
                },
                {
                  align: 'right',
                  field: 'new_value',
                  flex: 1,
                  headerAlign: 'right',
                  headerName: 'New Value',
                  valueFormatter: data => formatters.formatDollars(data.value)
                },
                {
                  align: 'right',
                  cellClassName: data =>
                    data.value > 0
                      ? classes.positiveAmount
                      : classes.negativeAmount,
                  field: 'difference',
                  flex: 1,
                  headerAlign: 'right',
                  headerName: 'Difference',
                  valueFormatter: data =>
                    (data.value > 0 ? '+' : '') +
                    formatters.formatDollars(data.value),
                  valueGetter: data =>
                    Decimal.sub(
                      data.row?.old_value ?? 0,
                      data.row?.new_value ?? 0
                    )
                      .negated()
                      .toNumber()
                }
              ]}
              pageSizeOptions={[10]}
              paginationModel={{ page: 0, pageSize: 10 }}
              rows={rows}
            />
          </Grid>
        </Grid>
      )}
      <Grid sx={{ mt: 3, width: 300 }}>
        <Box sx={{ mb: 2 }}>
          <FormControl
            data-testid='correctionOverview-reason-dropdown'
            fullWidth
            required>
            <InputLabel>Reason for correction</InputLabel>
            <Select
              autoWidth
              defaultValue=''
              id='correctionOverview-reason-dropdown'
              label='Reason for correction'
              onChange={onReasonChanged}>
              <MenuItem
                data-testid='correctionOverview-reason-dropdown'
                value='Calculation error'>
                Calculation error
              </MenuItem>
              <MenuItem
                data-testid='correctionOverview-reason-dropdown'
                value='Duplicate payroll'>
                Duplicate payroll
              </MenuItem>
              <MenuItem
                data-testid='correctionOverview-reason-dropdown'
                value='Incorrect deferrals'>
                Incorrect deferrals
              </MenuItem>
              <MenuItem
                data-testid='correctionOverview-reason-dropdown'
                value='Match not included'>
                Match not included
              </MenuItem>
              <MenuItem
                data-testid='correctionOverview-reason-dropdown'
                value='Row mismatch'>
                Row mismatch
              </MenuItem>
              <MenuItem
                data-testid='correctionOverview-reason-dropdown'
                value='True-ups'>
                True-ups
              </MenuItem>
              <MenuItem
                data-testid='correctionOverview-reason-dropdown'
                value='Wrong file'>
                Wrong file
              </MenuItem>
              <MenuItem
                data-testid='correctionOverview-reason-dropdown'
                value='Other'>
                Other
              </MenuItem>
            </Select>
          </FormControl>
          {reason === 'Other' && (
            <TextField
              data-testid='correctionOverview-otherReason-input'
              fullWidth
              label='Other reason'
              onChange={onOtherReasonChanged}
              required
              sx={{ mt: 1 }}
            />
          )}
        </Box>
        <FormControl
          data-testid='correctionOverview-responsibleParty-dropdown'
          fullWidth
          required>
          <InputLabel>Responsible party</InputLabel>
          <Select
            autoWidth
            defaultValue=''
            label='Responsible party'
            onChange={onResponsiblePartyChanged}>
            <MenuItem
              data-testid='correctionOverview-responsibleParty-dropdown'
              value='Employer responsible'>
              Employer responsible
            </MenuItem>
            <MenuItem
              data-testid='correctionOverview-responsibleParty-dropdown'
              value='Vestwell responsible'>
              Vestwell responsible
            </MenuItem>
            <MenuItem
              data-testid='correctionOverview-responsibleParty-dropdown'
              value='Payroll provider responsible'>
              Payroll provider responsible
            </MenuItem>
            <MenuItem
              data-testid='correctionOverview-responsibleParty-dropdown'
              value='Needs further review'>
              Needs further review
            </MenuItem>
          </Select>
        </FormControl>
        {responsibleParty === 'Needs further review' && (
          <TextField
            data-testid='correctionOverview-comment-input'
            fullWidth
            label='Comment'
            onChange={onCommentChanged}
            required
            sx={{ mt: 1 }}
          />
        )}
      </Grid>
    </div>
  );
};

export default PlanContributionCorrectionOverviewRoute;
