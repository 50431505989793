import { PlanParticipantsInfo } from '@/models/PlanParticipantsDTO.model';
import ForfeitureEventsAndUsage from '@/routes/plans/plan-detail/PlanForfeituresTab/ForfeitureEventsAndUsage.component';
import { PlanService } from '@/services/Plan.service';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { useMemo } from 'react';

import ForfeitureInfo from './ForfeitureInfo.component';

interface PlanForfeituresTabProps {
  sponsorPlanId: number;
}

const getEmployeeNameAndSsnString = (participant: any) => {
  const { id, name, ssn } = participant;
  const names = name
    .split(',')
    .map((i: string) => i.trim())
    .reverse()
    .join(' ');
  return `${names} (XXX-XX-${ssn.substr(-4)}) ${id} `;
};

const MAX_VALUE = 2147483647;

const PlanForfeituresTab = (props: PlanForfeituresTabProps): JSX.Element => {
  const { sponsorPlanId } = props;

  const planParticipantsList = useQuery<PlanParticipantsInfo>(
    ['PlanService.getParticipantsByPlanId', sponsorPlanId, 1, MAX_VALUE],
    async () => {
      const participantsByPlan: PlanParticipantsInfo =
        await PlanService.getParticipantsByPlanId({
          pageNumber: 1,
          pageSize: MAX_VALUE,
          planId: sponsorPlanId,
          sort: ['lastName', 'name']
        });

      return participantsByPlan;
    },
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const planParticipants = useMemo(
    () =>
      planParticipantsList.data?.participants.map(
        getEmployeeNameAndSsnString
      ) as string[],
    [planParticipantsList.data?.participants]
  );

  return (
    <>
      <div className='Plan-Forfeitures'>
        <Box sx={{ paddingBottom: '24px' }}>
          <ForfeitureInfo sponsorPlanId={sponsorPlanId} />
        </Box>
        <ForfeitureEventsAndUsage
          planParticipants={planParticipants}
          sponsorPlanId={sponsorPlanId}
        />
      </div>
    </>
  );
};

export default PlanForfeituresTab;
