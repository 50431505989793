import SimpleDropdown from '@/components/simple-dropdown';
import { useSnackbar } from '@/contexts/SnackBarContext';
import ForfeitureEventDto, {
  ForfeitureEvent
} from '@/models/ForfeitureEventsDTO.model';
import { PlanService } from '@/services/Plan.service';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMutation, useQuery } from '@tanstack/react-query';

import dayjs, { Dayjs } from 'dayjs';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect } from 'react';
import * as yup from 'yup';

interface ForfeitureEventModalProps {
  modalTitle: string;
  isOpenModal: boolean;
  buttonText: string;
  planParticipants: string[];
  setOpen: (value: boolean) => void;
  initialValues: any;
}

const testDecimals = (numberOfDecimals: number) => {
  return (value?: number) => {
    const valueString = value !== undefined ? value.toString() : '';
    const regexString = `^-?\\s*(?=.*[0-9])\\d*(?:\\.\\d{1,${numberOfDecimals}})?\\s*$`;
    return new RegExp(regexString).test(valueString);
  };
};

const isParticipantRequired = (reason: string) =>
  [
    'Terminated Participant With Unvested Balance Received Full Distribution',
    'Terminated Participant With Unvested Balance Exceeded Termination Period',
    'Missing Participant'
  ].includes(reason);

const isUcidRequired = (reason: string) =>
  'Correction of Erroneous Matching Contribution' === reason;

const isPlanYearRequired = (reason: string) =>
  'Matching Contribution Refund After ACP Test Failure' === reason;

const validationSchema = yup.object({
  amount: yup
    .number()
    .moreThan(-0.0001, 'Amount must be a positive number')
    .required('Amount is required')
    .test('is-decimal', 'Please use two decimal places', testDecimals(2)),
  date: yup.date().required('Date is required'),
  participant: yup.string().when('reason', {
    is: (value: string) => isParticipantRequired(value) || value === undefined,
    then: schema => schema.required('Participant is required')
  }),
  reason: yup.string().required('Reason is required'),
  ucid: yup.string().when('reason', {
    is: (value: string) => isUcidRequired(value),
    then: schema => schema.required('UCID is required')
  }),
  year: yup.string().when('reason', {
    is: (value: string) => isPlanYearRequired(value),
    then: schema => schema.required('Year is required')
  })
});

const forfeitureReasons = [
  'Terminated Participant With Unvested Balance Received Full Distribution',
  'Terminated Participant With Unvested Balance Exceeded Termination Period',
  'Matching Contribution Refund After ACP Test Failure',
  'Correction of Erroneous Matching Contribution',
  'Missing Participant',
  'Other'
];

const getYears = () => {
  const currentYear = new Date().getFullYear();
  return _.range(currentYear, currentYear + 11, 1).map(i => i.toString());
};

const getParticipantId = (participant: string) => {
  const participantInfo = participant.trim().split(' ');
  return participantInfo[participantInfo.length - 1];
};

const ForfeitureEventModal = (
  props: ForfeitureEventModalProps
): JSX.Element => {
  const {
    isOpenModal,
    planParticipants,
    buttonText,
    modalTitle,
    setOpen,
    initialValues
  } = props;
  const [selectedReason, setReason] = React.useState(initialValues.reason);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (isOpenModal) setOpen(true);
    else setOpen(false);
  }, [isOpenModal, setOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  const { refetch } = useQuery<ForfeitureEventDto>(
    ['PlanService.getForfeitureEvents', initialValues.planId],
    () => PlanService.getForfeitureEvents(initialValues.planId),
    {
      staleTime: Infinity
    }
  );

  const updateForfeitureEvent = useMutation(
    (eventDto: ForfeitureEvent) =>
      PlanService.updateForfeitureEvent(initialValues.planId, eventDto),
    {
      onError: () => {
        showSnackbar({
          message: 'Unable to update forfeiture event.',
          severity: 'error'
        });
      },
      onSuccess: () => {
        handleClose();
        showSnackbar({
          message: 'Forfeiture event has been updated!',
          severity: 'success'
        });
        refetch();
      }
    }
  );

  const createForfeitureEvent = useMutation(
    (eventDto: ForfeitureEvent) =>
      PlanService.saveForfeitureEvent(initialValues.planId, eventDto),
    {
      onError: () => {
        showSnackbar({
          message: 'Unable to create forfeiture event.',
          severity: 'error'
        });
      },
      onSuccess: () => {
        handleClose();
        showSnackbar({
          message: 'Forfeiture event has been created!',
          severity: 'success'
        });
        refetch();
      }
    }
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          const dto = {
            data: {
              attributes: {
                amt: values.amount,
                context: {
                  ...(isPlanYearRequired(selectedReason) && {
                    planYear: values.year
                  }),
                  ...(isUcidRequired(selectedReason) && { ucid: values.ucid }),
                  ...(isParticipantRequired(selectedReason) && {
                    participantId: getParticipantId(values.participant)
                  })
                },
                date: dayjs(values.date).toISOString(),
                note: values.note,
                reason: values.reason
              },
              id: values.id
            }
          } as ForfeitureEvent;

          if (dto.data.id) await updateForfeitureEvent.mutateAsync(dto);
          else await createForfeitureEvent.mutateAsync(dto);

          resetForm();
        }}
        validationSchema={validationSchema}>
        {formik => (
          <Dialog
            fullWidth
            maxWidth='md'
            onClose={() => {
              handleClose();
              formik.resetForm();
            }}
            open={isOpenModal}>
            <DialogTitle>{modalTitle}</DialogTitle>

            <DialogContent
              sx={{
                paddingTop: 3
              }}>
              <Form data-testid='query-form'>
                <SimpleDropdown
                  fieldId='reason'
                  fieldName='Reason'
                  fieldValues={forfeitureReasons}
                  onChange={event => {
                    setReason(event.target.value);
                    formik.setFieldValue('reason', event.target.value);
                  }}
                />
                <Box sx={{ display: 'flex', mt: '27px' }}>
                  {[
                    'Terminated Participant With Unvested Balance Received Full Distribution',
                    'Terminated Participant With Unvested Balance Exceeded Termination Period',
                    'Missing Participant'
                  ].includes(selectedReason) && (
                    <Autocomplete
                      freeSolo
                      id='participant'
                      onChange={(e, value) =>
                        formik.setFieldValue('participant', value)
                      }
                      options={planParticipants}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true
                          }}
                          error={Boolean(formik.errors.participant)}
                          helperText={
                            formik.errors.participant?.toString() || ''
                          }
                          label='Participant'
                          onChange={formik.handleChange}
                          sx={{ mr: '16px', width: 220 }}
                          value={formik.values.participant}
                        />
                      )}
                      value={formik.values.participant}
                    />
                  )}
                  {[
                    'Matching Contribution Refund After ACP Test Failure'
                  ].includes(selectedReason) && (
                    <Box sx={{ mr: '16px', width: 220 }}>
                      <SimpleDropdown
                        fieldId='year'
                        fieldName='Year'
                        fieldValues={getYears()}
                      />
                    </Box>
                  )}
                  <FormControl
                    error={Boolean(formik.errors.amount)}
                    sx={{ width: 230 }}>
                    <InputLabel htmlFor='amount'>Amount</InputLabel>
                    <Field
                      as={OutlinedInput}
                      error={Boolean(formik.errors.amount)}
                      id='amount'
                      label='Amount'
                      name='amount'
                      onChange={formik.handleChange}
                      startAdornment={
                        <InputAdornment position='start'>$</InputAdornment>
                      }
                      type='number'
                      value={formik.values.amount}
                    />
                    <FormHelperText sx={{ p: 0 }}>
                      {formik.errors.amount?.toString() ?? ' '}
                    </FormHelperText>
                  </FormControl>
                  <FormControl sx={{ ml: '16px', width: 220 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label='Date'
                        onChange={(newValue: Dayjs | null) => {
                          formik.setFieldValue('date', newValue);
                        }}
                        slotProps={{
                          textField: {
                            InputLabelProps: {
                              shrink: true
                            },
                            //@ts-expect-error
                            'data-testid': 'date-input',
                            error: !!formik.errors.date
                          }
                        }}
                        value={dayjs(formik.values.date)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  {['Correction of Erroneous Matching Contribution'].includes(
                    selectedReason
                  ) && (
                    <TextField
                      InputLabelProps={{
                        shrink: true
                      }}
                      error={Boolean(formik.errors.ucid)}
                      helperText={formik.errors.ucid?.toString() || ''}
                      id='ucid'
                      label='UCID'
                      name='ucid'
                      onChange={formik.handleChange}
                      sx={{ ml: '16px', width: 220 }}
                      value={formik.values.ucid}
                    />
                  )}
                </Box>
                <TextField
                  fullWidth
                  id='outlined-multiline-static'
                  label='Note'
                  multiline
                  name='note'
                  onChange={formik.handleChange}
                  rows={4}
                  sx={{ mt: '10px' }}
                  value={formik.values.note}
                />
              </Form>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
              <Button
                onClick={() => {
                  handleClose();
                  formik.resetForm();
                }}>
                Close
              </Button>
              <LoadingButton
                disabled={selectedReason === '' || !formik.isValid}
                onClick={() => formik.handleSubmit()}
                type='submit'
                variant='contained'>
                {buttonText}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </LocalizationProvider>
  );
};

export default ForfeitureEventModal;
