import { useSnackbar } from '@/contexts/SnackBarContext';
import { ActionCenterService } from '@/services/ActionCenter.service';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { FileDownloadOutlined } from '@mui/icons-material';
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FC } from 'react';
import { useToggle } from 'react-use';

import { BulkActionsPreviewConfig } from './useBulkActionCenterConfig.hook';

type BulkEmployerPreviewDialogProps = Omit<BulkActionsPreviewConfig, 'data'> & {
  data: {
    id: number;
    summary: {
      existingRows?: number;
      invalidRows?: number;
      newRows?: number;
    };
  };
};

export const BulkEmployerPreviewDialog: FC<
  BulkEmployerPreviewDialogProps
> = props => {
  const { showSnackbar } = useSnackbar();
  const [createEmployers, toggleCreateEmployers] = useToggle(false);
  const [updateEmployers, toggleUpdateEmployers] = useToggle(false);
  const [exempt5500, toggleExempt5500] = useToggle(false);
  const queryClient = useQueryClient();

  const downloadEmployerListReportMutation = useMutation(
    ['PlanService.downloadEmployerListReport'],
    () => ActionCenterService.downloadEmployerListReport(props.data.id),
    {
      onSuccess: data => {
        InMemoryFileDownloadService.triggerFileDownload(
          data.data,
          data.fileName
        );
      }
    }
  );

  const ingestEmployerListMutation = useMutation(
    ['ActionCenterService.ingestEmployerList'],
    () =>
      ActionCenterService.ingestEmployerList(
        props.data.id,
        createEmployers,
        updateEmployers,
        exempt5500
      ),
    {
      onSuccess: () => {
        toggleCreateEmployers(false);
        toggleUpdateEmployers(false);
        toggleExempt5500(false);
        props.onConfirm();
        queryClient.invalidateQueries({
          queryKey: ['ActionCenterService.bulkEmployerList']
        });
        showSnackbar({
          message: 'Employer ingestion triggered succesfully',
          severity: 'success'
        });
      }
    }
  );

  const handleClose = () => {
    toggleCreateEmployers(false);
    toggleUpdateEmployers(false);
    toggleExempt5500(false);
    props.handleClose();
  };

  return (
    <Dialog
      data-testid='action-center-employer-preview-dialog'
      fullWidth
      maxWidth='sm'
      onClose={props.handleClose}
      open={props.open}>
      <DialogTitle pl={2}>Ingest Uploaded File</DialogTitle>
      <Alert severity={props.data?.summary.invalidRows ? 'error' : 'success'}>
        {props.data?.summary.invalidRows
          ? `Found ${props.data.summary.invalidRows} invalid rows. Download the report, fix the errors and re-upload the file.`
          : 'No validation errors found.'}
      </Alert>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox checked={exempt5500} onChange={toggleExempt5500} />
          }
          label='All Exempt 5500'
        />
        <TableContainer sx={{ maxHeight: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>New Employers</TableCell>
              <TableCell>{props.data?.summary.newRows ?? 0}</TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createEmployers}
                      disabled={!props.data?.summary.newRows}
                      onChange={toggleCreateEmployers}
                    />
                  }
                  label='Create new employers'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Existing Employers</TableCell>
              <TableCell>{props.data?.summary.existingRows ?? 0}</TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={updateEmployers}
                      disabled={!props.data?.summary.existingRows}
                      onChange={toggleUpdateEmployers}
                    />
                  }
                  label='Update existing employers'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total </TableCell>
              <TableCell>
                {(props.data?.summary.newRows ?? 0) +
                  (props.data?.summary.existingRows ?? 0)}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            downloadEmployerListReportMutation.mutate();
          }}
          size='small'
          startIcon={<FileDownloadOutlined />}>
          Download Report .CSV
        </Button>
        <Button onClick={handleClose}>Close</Button>
        <Button
          data-testid='action-center-preview-dialog-confirm-button'
          disabled={
            (!createEmployers && !updateEmployers) ||
            props.data?.summary.invalidRows > 0
          }
          onClick={() => ingestEmployerListMutation.mutate()}
          variant='contained'>
          Submit{' '}
          {` ${(createEmployers ? props.data?.summary.newRows : 0) + (updateEmployers ? props.data?.summary.existingRows : 0)} Employers`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
