import AccessControl from '@/components/access-control/AccessControl.component';
import { CardPlaceholder } from '@/components/card';
import CorrectedIcon from '@/components/icon/CorrectedIcon';
import LinearLoading from '@/components/linear-loading';
import { useOpsTooling } from '@/contexts/OpsToolingContext';
import usePayrollSetups from '@/hooks/usePayrollSetups';
import ContributionForTable from '@/models/ContributionForTable.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { StartOffCycleContribution } from '@/routes/plans/plan-contribution-submission';
import ContributionService from '@/services/Contribution.service';
import { OpsToolingType } from '@/services/payroll-integrations.service';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { MoreVert, PriceCheckOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useMutation, useQuery } from '@tanstack/react-query';

import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { SubmissionActions } from './SubmissionActions.component';

const useStyles = makeStyles((theme: Theme) => ({
  anchorIcon: {
    marginLeft: theme.spacing(2)
  },
  titleBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2.5, 2)
  }
}));

interface PlanContributionsTabProps {
  isStateIRA: boolean;
  sponsorPlanId: number;
  sponsorId?: number;
  isEsa?: boolean;
}

const commonColumns: GridColDef[] = [
  {
    field: 'transaction',
    flex: 1.5,
    headerName: 'Transaction',
    minWidth: 220,
    renderCell: params =>
      params.row.ucid ? (
        <Link
          alignItems='center'
          component='button'
          display='flex'
          gap={1.25}
          onClick={params.row?.navigate}
          underline='hover'>
          {params.row.transaction}
          {params.row.hasLostGains && (
            <Tooltip title='Lost Gain'>
              <PriceCheckOutlined fontSize='small' />
            </Tooltip>
          )}
          {params.row.hasCorrections && (
            <Tooltip title='This contribution has corrections'>
              <CorrectedIcon fontSize='small' />
            </Tooltip>
          )}
        </Link>
      ) : (
        params.row.transaction
      ),
    sortable: true
  },
  {
    field: 'processingStatus',
    flex: 1,
    headerName: 'Status',
    minWidth: 144,
    sortable: true
  },
  {
    field: 'payGroupName',
    flex: 1,
    headerName: 'Paygroup Name',
    minWidth: 144,
    renderCell: params => (
      <Stack>
        <Typography>{params.row.payGroupName}</Typography>
        <Box>
          <Typography component='span' variant='body2'>
            Code:{' '}
          </Typography>
          <Typography
            color={params.row?.division ? 'black' : ''}
            component='span'
            variant='body2'>
            {params.row?.division ?? 'null'}
          </Typography>
        </Box>
      </Stack>
    ),
    sortable: true
  }
];

const columns: GridColDef[] = [
  ...commonColumns,
  {
    field: 'total',
    flex: 1,
    headerName: 'Total Amount',
    minWidth: 120,
    sortable: true
  },
  {
    field: 'totalPreTax',
    flex: 1,
    headerName: 'Pre-Tax',
    minWidth: 120,
    sortable: true
  },
  {
    field: 'totalRoth',
    flex: 1,
    headerName: 'Roth',
    minWidth: 120,
    sortable: true
  },
  {
    field: 'totalLoan',
    flex: 0.75,
    headerName: 'Loan',
    minWidth: 120,
    sortable: true
  },
  {
    field: 'totalCompany',
    flex: 0.75,
    headerName: 'Company',
    minWidth: 120,
    sortable: true
  },
  {
    field: 'payrollStartedDate',
    flex: 0.75,
    headerName: 'Initiated Date',
    sortable: true
  },
  {
    field: 'actions',
    flex: 0.5,
    headerName: '',
    minWidth: 200,
    renderCell: params => params.row.actions,
    sortable: true
  }
];

export const esaColumns: GridColDef[] = [
  ...commonColumns,
  {
    field: 'totalEsaEmployee',
    flex: 1,
    headerName: 'Employee Contrib.',
    minWidth: 120,
    sortable: true
  },
  {
    field: 'totalEsaEmployer',
    flex: 1,
    headerName: 'Employer Contrib.',
    minWidth: 120,
    sortable: true
  },
  {
    field: 'totalEsa',
    flex: 1,
    headerName: 'Total Amount',
    minWidth: 120,
    sortable: true
  },
  {
    field: 'payrollStartedDate',
    flex: 1,
    headerName: 'Initiated Date',
    minWidth: 120,
    sortable: true
  },
  {
    field: 'actions',
    flex: 0.5,
    headerName: '',
    minWidth: 200,
    renderCell: params => params.row.actions,
    sortable: false
  }
];

const PlanContributionsTab: React.FC<PlanContributionsTabProps> = props => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const contributionColumns = useMemo(
    () =>
      props.isStateIRA
        ? columns.filter(
            column =>
              column.headerName !== 'Pre-Tax' &&
              column.headerName !== 'Loan' &&
              column.headerName !== 'Company' &&
              column.headerName !== 'Total Amount'
          )
        : props.isEsa
          ? esaColumns
          : columns,
    [props.isStateIRA, props.isEsa]
  );

  const contributionsQuery = useQuery<ContributionForTable[]>(
    ['PlanService.getContributions', props.sponsorPlanId],
    async () => {
      const contributionsResponse: ContributionForTable[] =
        await PlanService.getContributions(props.sponsorPlanId);
      return formatters
        .formatContributionsResponse(contributionsResponse)
        ?.filter(contribution => !contribution.parentUcid);
    }
  );

  const postResume = useMutation(
    (body: { ucid: string; sponsorId: number; sponsorPlanId: number }) =>
      ContributionService.postResume(body),
    {
      onSuccess: (data, variables) => {
        const pages = {
          CONFIRMATION_PAGE: `/plans/${variables.sponsorPlanId}/contributions/${variables.ucid}/submission/confirmation`,
          OVERVIEW_PAGE: `/plans/${variables.sponsorPlanId}/contributions/${variables.ucid}/overview`,
          SUBMISSION_PAGE: `/plans/${variables.sponsorPlanId}/contributions/${variables.ucid}/submission`
        };

        navigate(
          `${pages[data.redirectTo] ?? ''}${data.isEdit ? '?isEdit=true' : ''}`
        );
      }
    }
  );

  const contributionData = useMemo<ContributionForTable[]>(
    () =>
      contributionsQuery.data?.map(contribution => ({
        ...contribution,
        actions: (
          <SubmissionActions
            contribution={{ ...contribution, sponsorId: props.sponsorId }}
          />
        ),
        id: uuidv4(),
        navigate: () => {
          if (
            userService.hasPermission(
              FeatureLevelPermissions.WRITE_CONTRIBUTION
            )
          ) {
            return postResume.mutateAsync({
              sponsorId: props.sponsorId,
              sponsorPlanId: contribution.sponsorPlanId,
              ucid: contribution.ucid
            });
          }

          navigate(
            `/plans/${contribution.sponsorPlanId}/contributions/${contribution.ucid}/overview`
          );
        }
      })) ?? [],
    [contributionsQuery.data, props.sponsorId]
  );

  const { showOpsToolingDrawer } = useOpsTooling();

  const payrollSetupsQuery = usePayrollSetups(+props.sponsorPlanId);
  const isApiPlan = payrollSetupsQuery.data?.isApiPlan || false;

  const onClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const handleOpsToolingMenuClick = useCallback(() => {
    showOpsToolingDrawer(+props.sponsorPlanId, OpsToolingType.payrollInfo);
    onClose();
  }, [props.sponsorPlanId]);

  return (
    <>
      {contributionsQuery.isFetching ? (
        <LinearLoading />
      ) : contributionsQuery.isError === undefined ? (
        <Typography data-testid='plan-contributions-error'>
          Error retrieving contributions
        </Typography>
      ) : (
        <Card variant='outlined'>
          <Box className={classes.titleBox}>
            <Typography variant='h5'>Contribution History</Typography>
            <Box display='flex'>
              <AccessControl
                requires={[FeatureLevelPermissions.WRITE_CONTRIBUTION]}>
                <StartOffCycleContribution
                  planId={props.sponsorPlanId}
                  sponsorId={props.sponsorId}
                />
              </AccessControl>
              <AccessControl
                requires={[FeatureLevelPermissions.READ_COMPANY_PAYROLL]}>
                {isApiPlan && (
                  <>
                    <IconButton
                      aria-controls={anchor ? 'ops-tooling-menu' : undefined}
                      aria-expanded={!!anchor}
                      aria-haspopup='true'
                      className={classes.anchorIcon}
                      data-testid='openMenuButton'
                      onClick={e => setAnchor(e.currentTarget)}
                      size='small'>
                      <MoreVert />
                    </IconButton>
                    <Menu
                      anchorEl={anchor}
                      id='ops-tooling-menu'
                      onClick={onClose}
                      onClose={onClose}
                      open={!!anchor}
                      transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top'
                      }}>
                      <MenuItem
                        data-testid='openOpsToolingPayrolls'
                        onClick={handleOpsToolingMenuClick}>
                        View Payroll Provider Data - Payrolls
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </AccessControl>
            </Box>
          </Box>
          <Box borderColor={grey[300]} borderTop={1}>
            <DataGridPro
              autoHeight={!contributionData.length}
              autosizeOptions={{
                includeHeaders: true,
                includeOutliers: true
              }}
              columns={contributionColumns}
              data-testid='plan-contributions-data'
              disableColumnMenu
              disableRowSelectionOnClick
              getRowHeight={() => 'auto'}
              getRowId={row => row.id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25
                  }
                }
              }}
              loading={postResume.isLoading}
              pageSizeOptions={[25, 50, 100]}
              pagination
              rows={contributionData}
              slots={{
                noRowsOverlay: () => (
                  <Stack
                    alignItems='center'
                    data-testid='no-data-communication-table'
                    height='100%'
                    justifyContent='center'>
                    <CardPlaceholder
                      data-testid='no-data-contributions-plan'
                      subtitle='No data for this plan'
                    />
                  </Stack>
                )
              }}
              sx={{
                '.MuiDataGrid-columnSeparator': {
                  display: 'none'
                },
                border: '0px !important',
                fontSize: theme => theme.typography.body1.fontSize
              }}
            />
          </Box>
        </Card>
      )}
    </>
  );
};

export default PlanContributionsTab;
