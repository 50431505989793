import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class AllowHardshipSafeHarbor {
  convertToApiState = Helper.convertFromYesNoToBoolean;

  disabled: boolean;

  options = Constants.yesNoOptions;

  output: string | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.output = Helper.convertFromBooleanToYesNo(
      Boolean(
        props.allowHardshipSafeHarbor || props.allowHardshipSafeHarborApply
      )
    );
  }
}

export default AllowHardshipSafeHarbor;
