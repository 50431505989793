import { CardPlaceholder } from '@/components/card';
import CollapsibleTable from '@/components/collapsible-table';
import { FormattedRolloverDto } from '@/models/RolloversDTO.model';
import { PlanService } from '@/services/Plan.service';
import { Card, CardHeader, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import PlanRolloverTableCell from './PlanRolloverTableCell.component';

interface PlanRolloversTabProps {
  sponsorPlanId: number;
}

const columns = [
  { field: 'id', headerName: 'Rollover Request ID', width: 130 },
  { field: 'participantName', headerName: 'Participant Name', width: 130 },
  { field: 'participantId', headerName: 'Participant ID', width: 130 },
  { field: 'status', headerName: 'Status', width: 130 },
  { field: 'accountType', headerName: 'Account Type', width: 130 },
  { field: 'pretaxAmount', headerName: 'Pre-tax Amount', width: 130 },
  { field: 'rothAmount', headerName: 'Roth Amount', width: 130 },
  { field: 'createdAt', headerName: 'Created At Date', width: 130 },
  { field: 'updatedAt', headerName: 'Last Updated Date', width: 130 }
];

const PlanRolloversTab = (props: PlanRolloversTabProps): JSX.Element => {
  const { sponsorPlanId } = props;

  const rolloversQuery = useQuery<FormattedRolloverDto[]>(
    ['PlanService.getRollovers', sponsorPlanId],
    () => PlanService.getRollovers(sponsorPlanId),
    {
      staleTime: Infinity
    }
  );

  if (rolloversQuery.isFetching) {
    return <Typography>Loading...</Typography>;
  }
  if (rolloversQuery.isError === undefined) {
    return <Typography>Error retrieving rollovers</Typography>;
  }
  if (rolloversQuery.data && rolloversQuery.data.length === 0) {
    return (
      <Card
        sx={{
          fontSize: theme => theme.spacing(2.4),
          padding: theme => theme.spacing(2),
          textAlign: 'center'
        }}>
        <Typography
          sx={{ marginBottom: theme => theme.spacing(1), textAlign: 'left' }}
          variant='h5'>
          Rollovers
        </Typography>
        <CardPlaceholder
          data-testid='no-data-rollovers-plan'
          subtitle='No data for this plan'
        />
      </Card>
    );
  }

  return (
    <>
      <Card className='Plan-Contributions_Data'>
        <CardHeader title='Rollovers' />
        <CollapsibleTable
          cellComponent={PlanRolloverTableCell}
          columns={columns}
          tableData={rolloversQuery.data || []}
        />
      </Card>
    </>
  );
};

export default PlanRolloversTab;
