import Badge from '@/components/badge/Badge.component';
import Link from '@/components/link/Link.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack/TextStack.component';
import Tree from '@/components/tree';
import { TreeItem } from '@/components/tree/Tree.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { InvestRolloverContributionRequest } from '@/models/ops/alerts/InvestRolloverContributionRequest.model';
import { RolloverStatusColorMap } from '@/models/ops/common/RolloverStatusColorMap.model';
import { SubaExecutionStatusColorMap } from '@/models/ops/common/SubaExecutionStatusColorMap.model';
import { SubaExecutionStatusDisplayMap } from '@/models/ops/common/SubaExecutionStatusDisplayMap.model';
import { workflowStatusColorMap } from '@/models/ops/workflows/WorkflowStatusColorMap.model';
import { WorkflowStatusEnumsObject } from '@/models/ops/workflows/WorkflowStatusEnumObject.model';
import AlertService from '@/services/ops/alerts/Alert.service';
import DepositPlanService from '@/services/ops/deposit-plans/DepositPlan.service';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { AxiosError } from 'axios';
import { forwardRef, useCallback, useContext } from 'react';

import { AlertContext } from '../../AlertContext';
import {
  isSubaExecutionComplete,
  isSubaExecutionFailed,
  isWorkflowComplete,
  isWorkflowFailed
} from '../../helpers';
import { useGetParentAccountByCustodianId } from '../../useGetParentAccountByCustodianId.hook';
import { useGetWorkflowByTracerId } from '../../useGetWorkflowByTracerId.hook';
import { useUpdateAlertMutation } from '../../useUpdateAlertMutation.hook';
import { CardHeader } from './CardHeader.component';
import { TransferFunds } from './TransferFunds.component';

export const Rollover = forwardRef<HTMLDivElement>((props, ref) => {
  const alert = useContext(AlertContext);
  const cashTransferWorkflowQuery = useGetWorkflowByTracerId(
    alert?.details.cashTransferWorkflowTracerId
  );
  const getParentAccountQuery = useGetParentAccountByCustodianId(
    alert?.custodianAccountNumber
  );
  const investContributionWorkflowQuery = useGetWorkflowByTracerId(
    alert?.details.investContributionWorkflowTracerId
  );
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const updateAlertMutation = useUpdateAlertMutation();

  const handleChangeParticipant = useCallback(() => {
    updateAlertMutation.mutate({
      alertId: alert.id,
      updateRequest: {
        details: {
          investContributionWorkflowTracerId: null,
          investorId: null,
          rolloverId: null
        }
      }
    });
  }, [alert, updateAlertMutation]);

  const handleChangePlan = useCallback(() => {
    updateAlertMutation.mutate({
      alertId: alert.id,
      updateRequest: {
        details: {
          depositPlanId: null
        }
      }
    });
  }, [alert, updateAlertMutation]);

  const investRolloverContributionMutation = useMutation(
    ['AlertService.investRolloverContributionMutation'],
    ({
      alertId,
      params
    }: {
      alertId: number;
      params?: InvestRolloverContributionRequest;
    }) => AlertService.investRolloverContribution(alertId, params),
    {
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;

        showSnackbar({
          message: `Failed to invest funds: ${message}`,
          severity: 'error'
        });
      },
      onSuccess: updatedAlert => {
        showSnackbar({ message: 'Trade request submitted' });
        queryClient.setQueryData(
          ['AlertService.getById', updatedAlert.id],
          updatedAlert
        );
      }
    }
  );

  const depositPlanQuery = useQuery(
    ['DepositPlanService.get', alert?.details?.depositPlanId],
    () => DepositPlanService.get(alert?.details?.depositPlanId.toString()),
    {
      enabled: Boolean(alert?.details?.depositPlanId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;

        showSnackbar({
          message: `Failed to get plan details: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const participantQuery = useQuery(
    ['ParticipantService.getParticipantById', alert?.details?.investorId],
    () => ParticipantService.getParticipantById(alert?.details?.investorId),
    {
      enabled: Boolean(alert?.details?.investorId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;

        showSnackbar({
          message: `Failed to get participant details: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const rolloverQuery = useQuery(
    ['ParticipantService.getRollover', alert?.details?.rolloverId],
    () =>
      ParticipantService.getRollover(
        +alert?.details?.investorId,
        +alert?.details?.rolloverId
      ),
    {
      enabled: Boolean(
        alert?.details?.investorId && alert?.details?.rolloverId
      ),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;

        showSnackbar({
          message: `Failed to get rollover details: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const tradeRequestQuery = useQuery(
    [
      'ParticipantService.getRolloverTradeRequests',
      alert?.details?.rolloverId,
      alert?.details?.investContributionWorkflowTracerId
    ],
    async () => {
      const rolloverTradeRequests =
        await ParticipantService.getRolloverTradeRequests(
          +alert?.details?.investorId,
          +alert?.details?.rolloverId
        );

      if (!Array.isArray(rolloverTradeRequests)) return null;

      return (
        rolloverTradeRequests.find(
          rolloverTradeRequest =>
            rolloverTradeRequest.context?.rolloverId.toString() ===
            alert?.details?.rolloverId
        ) || null
      );
    },
    {
      enabled: Boolean(
        alert?.details?.investorId &&
          alert?.details?.rolloverId &&
          alert?.details?.investContributionWorkflowTracerId
      ),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;

        showSnackbar({
          message: `Failed to get rollover trade requests: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const isCashTransferComplete = isWorkflowComplete(
    cashTransferWorkflowQuery.data?.workflowStatus
  );
  const isHouseAccount =
    getParentAccountQuery.data?.accountType === ParentAccountType.House;
  const isRothRollover = Boolean(
    rolloverQuery.data?.data.attributes.rothAmount
  );

  return (
    <Card data-testid='rollover-card' ref={ref}>
      <CardHeader
        action={
          <Link
            data-testid='rollover-link'
            target='_blank'
            to={`/participant/${alert?.details?.investorId}/rollovers/${alert?.details?.rolloverId}`}>
            View rollover
          </Link>
        }
        title={
          <>
            Rollover ID: {alert?.details?.rolloverId}
            {rolloverQuery.data?.data.attributes.status && (
              <Badge
                color={
                  RolloverStatusColorMap[
                    rolloverQuery.data?.data.attributes.status
                  ]
                }
                data-testid='rollover-status'
                size='small'>
                {rolloverQuery.data?.data.attributes.status}
              </Badge>
            )}
          </>
        }
      />
      <Divider />
      <CardContent>
        {alert === undefined ||
        cashTransferWorkflowQuery.isInitialLoading ||
        depositPlanQuery.isInitialLoading ||
        investContributionWorkflowQuery.isInitialLoading ||
        participantQuery.isInitialLoading ||
        rolloverQuery.isInitialLoading ||
        tradeRequestQuery.isInitialLoading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Stack spacing={2}>
              <Grid columns={13} container spacing={2}>
                <Grid xs={6}>
                  <TextStack direction='column'>
                    <TextStackItem>
                      <TextLabel>Participant</TextLabel>
                      <TextValue
                        data-testid='rollover-participant'
                        links={
                          alert?.details?.investorId
                            ? [
                                {
                                  'data-testid': 'rollover-participant-link',
                                  label: `ID: ${alert?.details?.investorId}`,
                                  target: '_blank',
                                  to: `/participants/${alert?.details?.investorId}`
                                }
                              ]
                            : undefined
                        }>
                        {participantQuery.data?.firstName}{' '}
                        {participantQuery.data?.lastName}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Plan</TextLabel>
                      <TextValue
                        data-testid='rollover-plan'
                        links={
                          alert?.details?.depositPlanId
                            ? [
                                {
                                  'data-testid': 'plan-link',
                                  label: `ID: ${alert?.details?.depositPlanId}`,
                                  target: '_blank',
                                  to: `/plans/${alert?.details?.depositPlanId}`
                                }
                              ]
                            : undefined
                        }>
                        {depositPlanQuery.data?.planName}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>
                        Previous Plan
                        <br />
                        Provider
                      </TextLabel>
                      <TextValue data-testid='rollover-previous-plan-provider'>
                        {rolloverQuery.data?.data.attributes.accountProvider}
                      </TextValue>
                    </TextStackItem>
                  </TextStack>
                </Grid>
                <Grid display='flex' justifyContent='center' xs={1}>
                  <Divider orientation='vertical' />
                </Grid>
                <Grid xs={6}>
                  <TextStack direction='column'>
                    <TextStackItem>
                      <TextLabel>Type</TextLabel>
                      <TextValue data-testid='rollover-type'>
                        {isRothRollover ? 'Roth' : 'Pre-tax'}
                      </TextValue>
                    </TextStackItem>
                    <TextStackItem>
                      <TextLabel>Amount</TextLabel>
                      <TextValue data-testid='rollover-amount'>
                        {formatters.formatDollars(
                          rolloverQuery.data?.data.attributes.rothAmount ||
                            rolloverQuery.data?.data.attributes.preTaxAmount
                        )}
                      </TextValue>
                    </TextStackItem>
                    {isRothRollover && (
                      <TextStackItem>
                        <TextLabel>Roth Basis</TextLabel>
                        <TextValue
                          data-testid='rollover-roth-basis'
                          tooltip={
                            rolloverQuery.data?.data.attributes
                              ?.rothCostBasis &&
                            (!alert?.details
                              ?.investContributionWorkflowTracerId ||
                              isSubaExecutionFailed(
                                tradeRequestQuery.data?.status
                              ))
                              ? 'Unconfirmed'
                              : undefined
                          }>
                          {rolloverQuery.data?.data.attributes.rothCostBasis
                            ? formatters.formatDollars(
                                rolloverQuery.data?.data.attributes
                                  ?.rothCostBasis
                              )
                            : undefined}
                        </TextValue>
                      </TextStackItem>
                    )}
                    {isRothRollover && (
                      <TextStackItem>
                        <TextLabel>Initial Roth Year</TextLabel>
                        <TextValue
                          data-testid='rollover-initial-roth-year'
                          tooltip={
                            rolloverQuery.data?.data.attributes
                              ?.rothStartDate &&
                            (!alert?.details
                              ?.investContributionWorkflowTracerId ||
                              isSubaExecutionFailed(
                                tradeRequestQuery.data?.status
                              ))
                              ? 'Unconfirmed'
                              : undefined
                          }>
                          {rolloverQuery.data?.data.attributes.rothStartDate
                            ? formatters.formatFromIsoDateCustom(
                                rolloverQuery.data?.data.attributes
                                  .rothStartDate,
                                'YYYY'
                              )
                            : undefined}
                        </TextValue>
                      </TextStackItem>
                    )}
                  </TextStack>
                </Grid>
              </Grid>
              {isHouseAccount && (
                <>
                  <TransferFunds
                    onChangePlan={handleChangePlan}
                    prompt='Funds must be transferred to the plan’s account before they can be invested.'
                  />
                  {alert?.details.cashTransferWorkflowTracerId &&
                    !isCashTransferComplete && (
                      <Alert severity='warning'>
                        Rollover investment options will become available once
                        the transfer is complete.
                      </Alert>
                    )}
                </>
              )}
              {((isHouseAccount && isCashTransferComplete) ||
                !isHouseAccount) && (
                <Alert
                  action={
                    isSubaExecutionComplete(tradeRequestQuery.data?.status) &&
                    alert?.alertStatus !== AlertStatus.Closed && (
                      <Button
                        data-testid='rollover-close-ticket-button'
                        onClick={() => {
                          updateAlertMutation.mutate({
                            alertId: alert.id,
                            updateRequest: {
                              alertStatus: AlertStatus.Closed
                            }
                          });
                        }}
                        size='small'>
                        Close Ticket
                      </Button>
                    )
                  }
                  icon={false}
                  severity={
                    isSubaExecutionFailed(tradeRequestQuery.data?.status)
                      ? 'warning'
                      : isSubaExecutionComplete(tradeRequestQuery.data?.status)
                        ? 'success'
                        : 'info'
                  }
                  variant='outlined'>
                  <AlertTitle>Invest Rollover</AlertTitle>
                  <Tree spacing={1}>
                    {tradeRequestQuery.data && (
                      <TreeItem>
                        <Stack alignItems='center' direction='row' spacing={1}>
                          <Typography variant='body2'>
                            Trade request has been submitted.
                          </Typography>
                          <Badge
                            color={
                              SubaExecutionStatusColorMap[
                                tradeRequestQuery.data.status
                              ] || 'neutral'
                            }
                            size='small'>
                            {SubaExecutionStatusDisplayMap[
                              tradeRequestQuery.data.status
                            ] || tradeRequestQuery.data.status}
                          </Badge>
                        </Stack>
                      </TreeItem>
                    )}
                    {investContributionWorkflowQuery.data && (
                      <TreeItem>
                        <Stack alignItems='center' direction='row' spacing={1}>
                          <Link
                            data-testid='invest-contribution-workflow-link'
                            target='_blank'
                            to={`/ops/workflows/${investContributionWorkflowQuery.data.id}`}
                            typography={{ variant: 'body2' }}>
                            Tracer ID:{' '}
                            {alert.details.investContributionWorkflowTracerId}
                          </Link>
                          {investContributionWorkflowQuery.data
                            .workflowStatus && (
                            <Badge
                              color={
                                workflowStatusColorMap[
                                  investContributionWorkflowQuery.data
                                    ?.workflowStatus
                                ]
                              }
                              size='small'>
                              {formatters.displayCase(
                                formatters.getValueKey(
                                  WorkflowStatusEnumsObject,
                                  investContributionWorkflowQuery.data
                                    .workflowStatus
                                )
                              )}
                            </Badge>
                          )}
                        </Stack>
                      </TreeItem>
                    )}
                    {isWorkflowFailed(
                      investContributionWorkflowQuery.data?.workflowStatus
                    ) ? (
                      <Stack direction='row' spacing={1}>
                        <LoadingButton
                          data-testid='rollover-retry-invest-button'
                          loading={investRolloverContributionMutation.isLoading}
                          size='small'
                          type='submit'
                          variant='contained'>
                          Try Again
                        </LoadingButton>
                        {!investRolloverContributionMutation.isLoading && (
                          <Button
                            data-testid='rollover-change-participant-button'
                            onClick={handleChangeParticipant}
                            size='small'>
                            Change Participant
                          </Button>
                        )}
                      </Stack>
                    ) : !alert?.details?.investContributionWorkflowTracerId ? (
                      <Stack direction='row' spacing={1}>
                        <LoadingButton
                          data-testid='rollover-invest-button'
                          loading={investRolloverContributionMutation.isLoading}
                          onClick={() =>
                            investRolloverContributionMutation.mutate({
                              alertId: alert.id
                            })
                          }
                          size='small'
                          variant='contained'>
                          Confirm & Invest
                        </LoadingButton>
                        {!investRolloverContributionMutation.isLoading && (
                          <Button
                            data-testid='rollover-change-participant-button'
                            onClick={handleChangeParticipant}
                            size='small'>
                            Change Participant
                          </Button>
                        )}
                      </Stack>
                    ) : null}
                  </Tree>
                </Alert>
              )}
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
});

Rollover.displayName = 'Rollover';
