import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class PayPlanFees {
  convertFromYesNoToBoolean = Helper.convertFromYesNoToBoolean;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = Helper.convertFromBooleanToYesNo(props.payPlanFees);
  }
}

export default PayPlanFees;
