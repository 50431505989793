import * as yup from 'yup';

interface TestContextExtended {
  from: {
    value: any;
  }[];
}

const additionalDataSchema = yup.object({
  additionalData: yup.object({
    hce: yup.array().of(
      yup.object({
        fundingSource: yup.string().required('Source is required'),
        id: yup
          .number()
          .required('Name is required')
          .test(
            'test duplications',
            'Cannot accept duplicate name',
            function (hceId, context: yup.TestContext & TestContextExtended) {
              return context.from[1].value.hce.filter(hce => hce.id === hceId)
                .length > 1
                ? false
                : true;
            }
          ),
        value: yup
          .number()
          .transform(value => (Number.isNaN(value) ? null : value))
          .nullable()
          .required('Value is required')
      })
    )
  })
});

export const annualTestExecutionValidationSchema = yup.object({
  result402g: additionalDataSchema,
  result415: additionalDataSchema,
  resultACP: additionalDataSchema,
  resultADP: additionalDataSchema
});
