import Card from '@/components/card';
import { FindingTextMap } from '@/models/FraudrankerResult.model';
import { FraudrankerService } from '@/services/ops/fraudranker/Fraudranker.service';
import {
  CardContent,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import Grid2, { Grid2Props } from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';

import { FC, useMemo } from 'react';

const COLUMN_COUNT = 3;

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: '500'
}));

const StyledTableRow = styled(TableRow)(() => ({
  ':last-child:not(.MuiTableRow-head) .MuiTableCell-root': {
    borderBottom: '1px solid transparent'
  }
}));

const StyledTableCell = styled(TableCell)(() => ({
  '&[role="rowheader"]': {
    fontWeight: 600
  },
  ':first-of-type': {
    paddingLeft: '0.25rem'
  },
  ':last-of-type': {
    paddingRight: 0,
    textAlign: 'end'
  },
  fontSize: '1rem',
  height: '40px',
  paddingBottom: 0,
  paddingTop: 0
}));

interface StyledGridCellProps extends Grid2Props {
  index: number;
  rowCount: number;
}

const StyledGridCell = styled(Grid2)<StyledGridCellProps>(props => ({
  borderRight: `${props.index < props.rowCount * (COLUMN_COUNT - 1) ? '1' : '0'}px solid ${props.theme.palette.grey[300]}`,
  height: '100%',
  marginLeft: props.index >= props.rowCount ? props.theme.spacing(2) : 0,
  marginTop: 0
}));

const StyledGridContainer = styled('div')<{ rowCount: number }>(props => ({
  display: 'grid',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'auto',
  gridTemplateRows: `repeat(${props.rowCount}, 38px)`
}));

export const FraudrankerHeader: FC = () => {
  const fraudrankerOverviewQuery = useQuery(
    ['FraudrankerService.getOverview'],
    () => FraudrankerService.getOverview(),
    {
      staleTime: Infinity
    }
  );

  const rowCount = useMemo(() => {
    return Math.ceil(
      (Object.entries(fraudrankerOverviewQuery.data?.findings.overall ?? {})
        .length ?? 0) / COLUMN_COUNT
    );
  }, [fraudrankerOverviewQuery.data?.findings.overall]);

  return (
    <Grid2 container spacing={2}>
      <Grid2 lg={3} pl={0}>
        <Card sx={{ height: '100%' }}>
          <CardContent>
            <Stack mb={2}>
              <StyledTypography>Recent Stats</StyledTypography>
              <Typography variant='caption'>Previous Business Day</Typography>
            </Stack>
            <Table>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>
                    <Typography variant='body2'>Withdrawals per day</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      sx={{ color: theme => theme.palette.text.primary }}
                      variant='body2'>
                      {fraudrankerOverviewQuery.data?.stats.withdrawalsPerDay ??
                        0}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>
                    <Typography variant='body2'>Loans per day</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      sx={{ color: theme => theme.palette.text.primary }}
                      variant='body2'>
                      {fraudrankerOverviewQuery.data?.stats.loansPerDay ?? 0}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>
                    <Typography variant='body2'>Average fraud score</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      sx={{ color: theme => theme.palette.text.primary }}
                      variant='body2'>
                      {fraudrankerOverviewQuery.data?.stats.averageFraudScore ??
                        0}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid2>
      <Grid2 lg={9}>
        <Card sx={{ height: '100%' }}>
          <CardContent>
            <Stack mb={2}>
              <StyledTypography>Fraudranker Report</StyledTypography>
              <Typography variant='caption'>Past 30 days</Typography>
            </Stack>
            <StyledGridContainer rowCount={rowCount}>
              {Object.entries(
                fraudrankerOverviewQuery.data?.findings.overall ?? {}
              ).map(([key, count], index) => (
                <StyledGridCell
                  container
                  index={index}
                  key={key}
                  rowCount={rowCount}
                  spacing={2}>
                  <Grid2 alignContent='center' py={0} xs={6}>
                    <Typography variant='body2'>
                      {FindingTextMap[key]}
                    </Typography>
                  </Grid2>
                  <Grid2 alignContent='center' py={0} textAlign='end' xs={2}>
                    <Typography
                      sx={{ color: theme => theme.palette.text.primary }}
                      variant='body2'>
                      {count}
                    </Typography>
                  </Grid2>
                </StyledGridCell>
              ))}
            </StyledGridContainer>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2>
  );
};

FraudrankerHeader.displayName = 'FraudrankerHeader';
