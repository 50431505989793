import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import { FormControlLabel, Switch } from '@mui/material';
import {
  useIsMutating,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';

import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

const ForfeiturePreferencesDTOSchema = yup.object().shape({
  data: yup.object().shape({
    offsetEmployerContribution: yup.boolean().required(),
    payPlanFees: yup.boolean().required()
  })
});

type UpdatePlanDesignForfeiturePreferencesProps = {
  name: 'offsetEmployerContribution' | 'payPlanFees';
  label: string;
  sponsorPlanId: number;
  planDesignTemplate: Record<string, any>;
};

const UpdatePlanDesignForfeiturePreferences: React.FC<
  UpdatePlanDesignForfeiturePreferencesProps
> = (props): JSX.Element => {
  const { name, label, sponsorPlanId, planDesignTemplate } = props;

  const isAnyForfeiturePreferenceMutating =
    useIsMutating({
      mutationKey: ['PlanService.updateForfeiturePref']
    }) > 0;

  const forfeiturePreferences = useMemo(
    () => ({
      offsetEmployerContribution:
        !!planDesignTemplate.offsetEmployerContribution.convertFromYesNoToBoolean(
          planDesignTemplate.offsetEmployerContribution.output
        ),
      payPlanFees: !!planDesignTemplate.payPlanFees.convertFromYesNoToBoolean(
        planDesignTemplate.payPlanFees.output
      )
    }),
    [planDesignTemplate]
  );

  const [uiState, setUIState] = useState<boolean>(
    forfeiturePreferences?.[name]
  );

  useEffect(() => {
    setUIState(!!forfeiturePreferences?.[name]);
  }, [forfeiturePreferences]);

  const accessControlCheck = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_FORFEITURE_ACTION]
  });

  const queryClient = useQueryClient();

  const { showSnackbar } = useSnackbar();

  const isPlanDesignFetching =
    queryClient.isFetching({
      predicate: ({ queryHash }) =>
        /PlanService.getPlanDesignById/.test(queryHash)
    }) > 0;

  const forfeiturePreferencesMutation = useMutation(
    ['PlanService.updateForfeiturePref'],
    async (forfeitureUpdate: Record<string, boolean>) => {
      return PlanService.updateForfeiturePref(
        sponsorPlanId,
        ForfeiturePreferencesDTOSchema.validateSync({
          data: {
            ...forfeiturePreferences,
            ...forfeitureUpdate
          }
        })
      );
    },
    {
      onMutate(forfeitureUpdate: Record<string, boolean>) {
        // optimistically update UI
        setUIState(!!forfeitureUpdate?.[name]);
      },
      onSettled(data, error) {
        showSnackbar(
          error
            ? {
                message: 'Error updating plan!',
                severity: 'error'
              }
            : {
                message: 'Success!',
                severity: 'success'
              }
        );

        if (error) {
          // undo optimistic UI update
          setUIState(!!forfeiturePreferences?.[name]);
        }

        // either way force everyone to refetch plan design
        queryClient.invalidateQueries({
          predicate: ({ queryHash }) =>
            /PlanService.getPlanDesignById/.test(queryHash)
        });
      }
    }
  );

  return (
    <FormControlLabel
      checked={uiState}
      control={
        <Switch
          checked={uiState}
          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
            await forfeiturePreferencesMutation.mutateAsync({
              [name]: event.target.checked
            });
          }}
        />
      }
      disabled={
        !accessControlCheck.isAllowed ||
        isAnyForfeiturePreferenceMutating ||
        isPlanDesignFetching ||
        forfeiturePreferences == null // forfeiturePreferences are optional
      }
      key={name}
      label={label}
    />
  );
};

export default UpdatePlanDesignForfeiturePreferences;
