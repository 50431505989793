import AccessControl from '@/components/access-control/AccessControl.component';
import Badge from '@/components/badge';
import {
  useEsaPlanModal,
  usePlanModal
} from '@/components/entity-modals/EntityModals.component';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import { useGetPlanScheduledChanges } from '@/components/scheduled-changes/useGetPlanScheduledChanges';
import { UNVERIFIED_PRIOR_PROVIDER_ID } from '@/consts/plan.constants';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanDesign, SafeHarborType } from '@/models/PlanDesign.model';
import { PooledPlan } from '@/models/PooledPlanDTO.model';
import { ScheduledChange } from '@/models/ScheduledChangesDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';
import { PlanDetails } from '@/routes/plans/plan-detail/PlanTab/PlanDetails.component';
import { EsaService } from '@/services/Esa.service';
import { PlanService } from '@/services/Plan.service';
import { PlanDesignService } from '@/services/PlanDesign.service';
import { PriorProviderService } from '@/services/PriorProviders.service';
import RecordKeeperService from '@/services/RecordKeeper.service';
import { userService } from '@/services/User.service';
import VestwellTheme from '@/theme/Vestwell.theme';
import {
  Box,
  Button,
  Grid,
  Link,
  Tab,
  Tabs,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { PlanStats } from '../PlanStats.component';
import { EsaPlanGroupsTable } from './EsaPlanGroupsTable/EsaPlanGroupsTable.component';
import {
  editPlanDetailsFormToScheduleChangesPayload,
  getEditPlanDesignValues,
  getPlanTemplate,
  planDataToFormValues
} from './utils';

const useStyles = makeStyles((theme: Theme) => ({
  detailsTab: {
    minWidth: 'unset'
  },
  planInfoContainer: {
    padding: '1.5rem'
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.main
  },
  value: {
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    marginLeft: '0.5rem'
  }
}));

type UpdatePlanButtonProps = {
  sponsorPlanId: number | string;
  isEsa?: boolean;
};

const UpdatePlanButton: React.FC<UpdatePlanButtonProps> = props => {
  const planModal = usePlanModal({
    action: 'edit',
    planId: +props.sponsorPlanId
  });

  const esaPlanModal = useEsaPlanModal({
    action: 'edit',
    planId: +props.sponsorPlanId
  });

  const modal = props.isEsa ? esaPlanModal : planModal;

  return (
    <Button
      color='primary'
      data-testid='edit-plan-main-info'
      disabled={!modal.isEnabled}
      onClick={() => modal.showModal()}
      variant='outlined'>
      EDIT
    </Button>
  );
};

interface PlanTabProps {
  sponsorPlanId: string | number;
  isVestwellSubaccounting?: boolean;
  pooledPlanData?: PooledPlan;
  isPooledPlan?: boolean;
  isStateIRA?: boolean;
  isEsa?: boolean;
}

const DEFAULT_VALUE = '--';

export const PlanTab: React.FC<PlanTabProps> = props => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();
  const [tabValue, setTabValue] = useState<string>('design');
  const [uploadingData, setUploadingData] = useState<PlanDesign>({});

  const { data: planDesignData, isFetching: isPlanDesignDataFetching } =
    useQuery(
      [
        'PlanService.getPlanDesignById',
        +props.sponsorPlanId,
        props.isPooledPlan
      ],
      () => {
        return PlanService.getPlanDesignById(props.sponsorPlanId);
      },
      {
        enabled: Boolean(props.sponsorPlanId) && !props.isPooledPlan,
        staleTime: Infinity
      }
    );

  const planQuery = useQuery(
    ['PlanService.getPlanById', +props.sponsorPlanId],
    () => {
      return PlanService.getPlanById(props.sponsorPlanId);
    },
    {
      enabled: !!props.sponsorPlanId,
      staleTime: Infinity
    }
  );

  const esaPrograms = useQuery(['EsaService.getEsaPrograms'], () =>
    EsaService.getEsaPrograms()
  );

  const queryClient = useQueryClient();
  const scheduledChanges = useGetPlanScheduledChanges({
    planId: +props.sponsorPlanId
  });
  const scheduledChangesHistory = useGetPlanScheduledChanges({
    applied: true,
    enabled: false,
    planId: +props.sponsorPlanId
  });

  const { data: csms, isFetching } = useQuery(
    ['PlanService.getCsms'],
    () => PlanService.getCsms(),
    {
      staleTime: Infinity
    }
  );

  const safeHarborTypes = useQuery<SafeHarborType[]>(
    ['PlanDesignService.getSafeHarborTypes'],
    () => PlanDesignService.getSafeHarborTypes(),
    {
      staleTime: Infinity
    }
  );

  const updatePlanDesignMutation = useMutation(
    ['PlanService.updatePlanDesign'],
    (dto: PlanDesign) => {
      return PlanService.updatePlanDesign(props.sponsorPlanId, dto);
    }
  );

  const getOrCreatePriorProviderMutation = useMutation(
    ['PriorProviderService.postPriorProvider'],
    (priorProviderName: string) =>
      PriorProviderService.postPriorProvider(priorProviderName)
  );

  const savePooledPlanDesignDefaultsMutation = useMutation(
    ['PlanService.updatePooledPlanDesignDefauts'],
    async (args: { payload: Record<string, any>; confirmed: boolean }) => {
      return PlanService.updatePooledPlanDesignDefaults(
        props.sponsorPlanId,
        args.payload,
        args.confirmed
      );
    }
  );

  const createScheduleChange = useMutation(
    ['PlanService.createScheduleChange'],
    (params: Omit<ScheduledChange, 'createdAt' | 'createdBy' | 'id'>) => {
      return PlanService.createScheduleChange(props.sponsorPlanId, params);
    },
    {
      onSuccess: (_data, variables) => {
        if (dayjs(variables.effectiveDate).isSameOrBefore(dayjs())) {
          queryClient.invalidateQueries([
            'PlanService.getPlanDesignById',
            +props.sponsorPlanId,
            props.isPooledPlan
          ]);
          scheduledChangesHistory.invalidate('plan_design');
        } else {
          scheduledChanges.invalidate('plan_design');
        }

        showSnackbar({ message: 'Success!', severity: 'success' });
      }
    }
  );

  const discretionaryMatchDollarCaps = useQuery(
    ['PlanDesignService.getDiscretionaryMatchDollarCaps'],
    () => PlanDesignService.getDiscretionaryMatchDollarCaps(),
    {
      staleTime: Infinity
    }
  )?.data;

  const planData = useMemo(() => {
    const planTemplate = getPlanTemplate(
      {
        isEsa: props.isEsa,
        isPooledPlan: props.isPooledPlan,
        planDesignData: planDesignData,
        pooledPlanData: props.pooledPlanData
      },
      csms
    );

    planTemplate?.contributionMatchFormula?.setDollarCapOptions(
      discretionaryMatchDollarCaps
    );

    planTemplate.safeHarborMatchType?.set(
      planDesignData?.data?.employerContribution?.safeHarborMatchType,
      safeHarborTypes.data || []
    );

    return planTemplate;
  }, [
    props.isPooledPlan,
    csms,
    discretionaryMatchDollarCaps,
    planDesignData?.data,
    props.isEsa,
    props.pooledPlanData?.pooledPlanDefault?.planDesign,
    safeHarborTypes.data,
    uploadingData
  ]);

  const planOverview = useMemo(
    () =>
      props.isPooledPlan
        ? props.pooledPlanData?.pooledPlanDefault?.planDesign?.overview
        : planDesignData?.data?.overview,
    [
      props.isPooledPlan,
      planDesignData?.data,
      props.pooledPlanData?.pooledPlanDefault?.planDesign
    ]
  );

  const listItems = useMemo(
    () => [
      {
        condition: !props.isPooledPlan && props.pooledPlanData,
        label: 'Pooled Plan',
        testId: 'pooled-plan',
        valueComponent: () => (
          <Box>
            <Typography
              color={theme => theme.palette.text.primary}
              data-testid='pooled-plan-type-value'
              variant='body2'>
              {props.pooledPlanData.pooledPlanName}
            </Typography>
            <Link
              component={RouterLink}
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontSize: '0.875rem',
                marginLeft: '0.5rem'
              }}
              to={`/pooled-plans/${props.pooledPlanData.pooledPlanId}`}>
              ID: {props.pooledPlanData.pooledPlanId}
              <OpenInNewIcon
                sx={{
                  fontSize: '0.875rem',
                  marginLeft: '0.25rem'
                }}
              />
            </Link>
          </Box>
        )
      },
      {
        condition: !props.isPooledPlan && !props.isEsa,
        label: 'Payroll',
        testId: 'payroll',
        value: planOverview?.payroll || DEFAULT_VALUE
      },
      {
        condition: props.isPooledPlan,
        label: 'Pooled Plan Type',
        testId: 'pooled-plan-type',
        value: props.pooledPlanData?.pooledPlanType?.toUpperCase()
      },
      {
        condition:
          props.isPooledPlan &&
          props.pooledPlanData?.pooledPlanType === 'mep' &&
          props.pooledPlanData?.pooledPlanSubType,
        label: 'Pooled Plan Sub Type',
        testId: 'pooled-plan-sub-type',
        value: props.pooledPlanData?.pooledPlanSubType
      },
      {
        condition: props.isPooledPlan,
        label: 'Plan design status',
        testId: 'pooled-plan-status',
        valueComponent: () => (
          <Box display='flex' ml={1}>
            <Badge
              color={
                props.pooledPlanData?.pooledPlanStatus?.toLowerCase() ===
                'confirmed'
                  ? 'success'
                  : 'neutral'
              }
              size='small'>
              {capitalize(props.pooledPlanData?.pooledPlanStatus || '')}
            </Badge>
          </Box>
        )
      },
      {
        condition: !props.isPooledPlan,
        label: 'Firm',
        testId: 'firm',
        value: planOverview?.companyName || DEFAULT_VALUE
      },
      {
        condition: true,
        label: 'Advisor',
        testId: 'advisor',
        value: `${planOverview?.advisorFirstName} ${planOverview?.advisorLastName}`
      },
      {
        condition: !props.isPooledPlan && !props.isEsa,
        hideFromTpa: true,
        label: 'Plan Tier',
        testId: 'plan-tier',
        value: planOverview?.planTierName || DEFAULT_VALUE
      },
      {
        condition: !props.isEsa,
        hideFromTpa: true,
        label: 'TPA',
        testId: 'tpa',
        value: planOverview?.tpaFiduciary || DEFAULT_VALUE
      },
      {
        condition: !props.isPooledPlan && !props.isEsa,
        label: 'Integration Level',
        testId: 'integration-level',
        value: planOverview?.integrationLevel || DEFAULT_VALUE
      },
      {
        condition: !props.isEsa,
        label: 'Program',
        testId: 'program',
        value: planOverview?.investmentProgram || DEFAULT_VALUE
      },
      {
        condition: !props.isPooledPlan && !props.isEsa,
        hideFromTpa: true,
        label: 'Recordkeeper',
        testId: 'recordkeeper',
        value:
          RecordKeeperService.formatRKName(
            planDesignData?.data?.recordkeeperAndCustodian?.partnerSystemName
          ) || DEFAULT_VALUE
      },
      {
        condition: !props.isPooledPlan,
        label: 'Onboarding Type',
        testId: 'onboarding-type',
        value:
          Helper.getConversionTypeName(
            planOverview?.conversionType as string
          ) || DEFAULT_VALUE
      },
      {
        condition: !props.isPooledPlan,
        label: 'Plan Type',
        testId: 'plan-type',
        value: planOverview?.planType
      },
      {
        condition: props.isEsa,
        label: 'Plan Effective Date',
        testId: 'plan-effective-date',
        value: planDesignData?.data?.effectiveDate?.vestwellStartDate
          ? dayjs(planDesignData.data.effectiveDate.vestwellStartDate).format(
              'MM/DD/YY'
            )
          : DEFAULT_VALUE
      },
      {
        condition: props.isEsa,
        label: 'ESA Program',
        testId: 'esaProgram',
        value: esaPrograms.data?.find(
          program =>
            program.id ===
            planQuery.data?.data.relationships.esaProgram?.data.id
        )?.name
      }
    ],
    [
      props.pooledPlanData,
      props.isPooledPlan,
      props.isEsa,
      planOverview,
      planDesignData,
      planQuery
    ]
  );

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setTabValue(value);
  };

  const makeUpdatePlanDesignPayload = async (
    editPlanDetailsForm: Record<string, any>
  ) => {
    const calculatedPlanDesignData = props.isPooledPlan
      ? props.pooledPlanData?.pooledPlanDefault?.planDesign || {}
      : planDesignData?.data || {};
    try {
      const editPlanDesignFormValues = getEditPlanDesignValues(
        calculatedPlanDesignData,
        planData,
        editPlanDetailsForm
      );

      if (
        editPlanDetailsForm.priorProviderId === UNVERIFIED_PRIOR_PROVIDER_ID
      ) {
        editPlanDesignFormValues.recordkeeperAndCustodian.priorProviderId = (
          await getOrCreatePriorProviderMutation.mutateAsync(
            editPlanDetailsForm.priorProviderUnverified
          )
        )?.data?.priorProviderId;
      }

      return editPlanDesignFormValues;
    } catch (error) {
      console.error(
        `encountered error building payload to update plan design`,
        error
      );
      throw error;
    }
  };

  const handleSubmit = async (
    data: Record<string, any>,
    pooledPlan = false,
    confirmed = false
  ) => {
    try {
      const payload = await makeUpdatePlanDesignPayload(data);

      const result = await (pooledPlan
        ? savePooledPlanDesignDefaultsMutation.mutateAsync({
            confirmed,
            payload
          })
        : updatePlanDesignMutation.mutateAsync(payload));

      showSnackbar({
        message: pooledPlan && !confirmed ? 'Draft saved!' : 'Success!',
        severity: 'success'
      });
      closeDialog();
      if (result) {
        await queryClient.invalidateQueries({
          predicate: ({ queryHash }) => /PlanService/.test(queryHash)
        });
      }
    } catch (err) {
      console.error(err);
      showSnackbar({
        message: 'Error updating plan design!',
        severity: 'error'
      });
    }
  };

  const onCreateScheduleChange = async (data: Record<string, any>) => {
    try {
      const initialValues = planDataToFormValues(planData);

      if (dayjs(data.scheduledChangesEffectiveDate).isSameOrBefore(dayjs())) {
        await updatePlanDesignMutation.mutateAsync(
          await makeUpdatePlanDesignPayload(data)
        );
      }

      await createScheduleChange.mutateAsync({
        changeType: 'plan_design',
        effectiveDate: dayjs(data.scheduledChangesEffectiveDate).isSameOrBefore(
          dayjs()
        )
          ? dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
          : dayjs(data.scheduledChangesEffectiveDate).format(
              'YYYY-MM-DDTHH:mm:ssZ'
            ),
        entityId: +props.sponsorPlanId,
        entityType: 'sponsor_plan',
        payload: editPlanDetailsFormToScheduleChangesPayload(
          initialValues,
          data,
          {
            planData,
            planDesignData: planDesignData || { data: {} }
          }
        )
      });

      closeDialog();
    } catch (err) {
      showSnackbar({
        message: 'Error!',
        severity: 'error'
      });
    }
  };

  if (isPlanDesignDataFetching || isFetching || safeHarborTypes.isFetching) {
    return <Typography className='Plan-Loading'>Loading...</Typography>;
  }

  return (
    <>
      {!(
        props.pooledPlanData?.pooledPlanName &&
        props.pooledPlanData?.pooledPlanId
      ) &&
        !props.isEsa && <PlanStats sponsorPlanId={+props.sponsorPlanId} />}
      <Box
        border='1px solid'
        borderColor='#E5E5E5'
        borderRadius='0.25rem'
        mb='1.5rem'
        mt={3}>
        <AccessControl requires={[FeatureLevelPermissions.WRITE_PLAN_OVERVIEW]}>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='flex-end'
            minHeight='70px'
            paddingRight='1.5rem'>
            {!props.isPooledPlan && (
              <UpdatePlanButton
                isEsa={props.isEsa}
                sponsorPlanId={props.sponsorPlanId}
              />
            )}
          </Box>
        </AccessControl>
        <Grid
          classes={{ root: classes.planInfoContainer }}
          container
          spacing={2}
          wrap='wrap'>
          {listItems.map(i => {
            const ValueComponent = i.valueComponent;
            return (
              <React.Fragment key={i.label}>
                {i.condition && (
                  <AccessControl hideFromTPA={i.hideFromTpa}>
                    <Grid display='flex' gap={1} item md={3} xs={4}>
                      <Typography
                        data-testid={`${i.testId}-field`}
                        variant='body2'>
                        {i.label}
                      </Typography>
                      {ValueComponent ? (
                        <ValueComponent />
                      ) : (
                        <Typography
                          color={theme => theme.palette.text.primary}
                          data-testid={`${i.testId}-value`}
                          variant='body2'>
                          {i.value}
                        </Typography>
                      )}
                    </Grid>
                  </AccessControl>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
        <Box
          bgcolor={VestwellTheme.palette.background.paper}
          display='flex-inline'
          fontSize='0.875rem'
          pl='1.5rem'>
          <Tabs
            classes={{ indicator: classes.tabIndicator }}
            data-testid='plan-main-tabs-navigation'
            onChange={handleChange}
            value={tabValue}>
            <Tab
              classes={{ root: classes.detailsTab }}
              data-testid='design-tab'
              label='DESIGN'
              value='design'
            />
            <Tab
              classes={{ root: classes.detailsTab }}
              data-testid='general-info-tab'
              label='GENERAL INFO'
              value='generalInfo'
            />
            {props.isVestwellSubaccounting && (
              <Tab
                classes={{ root: classes.detailsTab }}
                data-testid='preferences-tab'
                label='PREFERENCES'
                value='preferences'
              />
            )}
          </Tabs>
        </Box>
      </Box>
      {tabValue === 'design' && props.isEsa ? (
        <EsaPlanGroupsTable planId={+props.sponsorPlanId} />
      ) : (
        <PlanDetails
          activeTab={tabValue}
          csms={csms}
          data={planData}
          isEsa={props.isEsa}
          isLoading={isPlanDesignDataFetching && planQuery.isFetching}
          isPooledPlan={props.isPooledPlan}
          isStateIRA={props.isStateIRA}
          isTpaUser={userService.isTpaUser()}
          onCloseDialog={closeDialog}
          onCreateScheduleChange={onCreateScheduleChange}
          onOpenDialog={openDialog}
          onUpdate={handleSubmit}
          onUpload={setUploadingData}
          planDesignData={planDesignData}
          sponsorPlanId={+props.sponsorPlanId}
          uploadingData={uploadingData}
        />
      )}
    </>
  );
};

PlanTab.displayName = 'PlanTab';

PlanTab.defaultProps = {
  isEsa: false,
  isPooledPlan: false,
  isStateIRA: false,
  isVestwellSubaccounting: false
};
