import Badge from '@/components/badge';
import CollapsibleTable from '@/components/collapsible-table';
import LinearLoading from '@/components/linear-loading';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import CashTransfersTableCell from '@/routes/ops/fee-management/CashTransfersTableCell.component';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import {
  Card,
  CardContent,
  Divider,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';

import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import BillingDetailsTableCell from './BillingDetailsTableCell.component';
import { colorByBillingStatus } from './BillingTableCell.component';

const feeColumns = [
  { field: 'feeType', headerName: 'Fee Type', width: 130 },
  { field: 'requestedAmount', headerName: 'Requested Amount', width: 130 },
  { field: 'collectedAmount', headerName: 'Collected Amount', width: 130 },
  { field: 'subAccounts', headerName: 'Sub Accounts', width: 130 }
];

const cashTransfersColumns = [
  { field: 'type', headerName: 'Type', width: 130 },
  { field: 'amount', headerName: 'Amount', width: 130 },
  { field: 'accountId', headerName: 'To Account', width: 130 },
  { field: 'workflowStatus', headerName: 'Workflow Status', width: 130 },
  { field: 'executionStatus', headerName: 'Execution Status', width: 130 },
  { field: 'tracerId', headerName: 'Tracer ID', width: 130 }
];

const FeeDetailsRoute: React.FC = () => {
  const params = useParams();

  const billingPeriodQuery = useQuery(
    [`PlanService.billingPeriodId(${params.billingPeriodId})`],
    () => PlanService.getBillingPeriodById(+params.billingPeriodId),
    {
      staleTime: Infinity
    }
  );

  const billingFee = useQuery(
    ['PlanService.getBillingFeeByPlan'],
    () => {
      return PlanService.getBillingFeeByPlan(
        billingPeriodQuery.data?.id,
        +params.planId
      );
    },
    {
      enabled: !!billingPeriodQuery.data?.id
    }
  );

  const transfersData = useMemo(() => {
    return billingFee.data?.cashTransfers?.map(transfer => {
      return {
        ...transfer,
        executionStatus: billingFee.data?.executionStatus,
        workflowStatus: billingFee.data?.workflowStatus
      };
    });
  }, [billingFee.data]);

  return billingPeriodQuery.isFetching || billingFee.isFetching ? (
    <LinearLoading />
  ) : (
    <>
      <Stack
        alignItems='center'
        direction='row'
        divider={<span>/</span>}
        spacing={2}>
        <Link to='/ops/fee-management'>
          <Typography color={blue[500]} variant='body2'>
            Fees & Billings
          </Typography>
        </Link>
        <Link to={`/ops/fee-management/${params.billingPeriodId}`}>
          <Typography color={blue[500]} variant='body2'>
            Submission Details
          </Typography>
        </Link>
        <Typography color={blue[500]} variant='body2'>
          Billing ID {params.billingPeriodId}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant='h4'>{billingFee.data?.planName}</Typography>
        <Typography variant='body2'>
          Plan ID: {billingFee.data?.sponsorPlanId}
        </Typography>
      </Stack>
      <Stack mt={4}>
        <Stack alignItems='center' direction='row' spacing={2}>
          <Card variant='outlined'>
            <Typography fontWeight={700} ml={2} mt={2} variant='body2'>
              Details
            </Typography>
            <CardContent>
              <TextStack direction='column' spacing={1}>
                <TextStackItem>
                  <TextLabel>Status</TextLabel>
                  <TextValue>
                    <Badge
                      color={
                        colorByBillingStatus[
                          billingFee.data?.status || 'Not yet submitted'
                        ]
                      }>
                      {billingFee.data?.status}
                    </Badge>
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Period</TextLabel>
                  <TextValue>{`${formatters.formatFromIsoDateCustom(
                    billingFee.data?.periodStartDate,
                    'MM/DD/YYYY'
                  )} - ${formatters.formatFromIsoDateCustom(
                    billingFee.data?.periodEndDate,
                    'MM/DD/YYYY'
                  )}`}</TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Requested Amount</TextLabel>
                  <TextValue>
                    {formatters.formatDollars(billingFee.data?.amount)}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Collected Amount</TextLabel>
                  <TextValue>{EMPTY_FIELD_PLACEHOLDER}</TextValue>
                </TextStackItem>
              </TextStack>
            </CardContent>
          </Card>
          <Card variant='outlined'>
            <Typography fontWeight={700} ml={2} mt={2} variant='body2'>
              Metadata
            </Typography>
            <CardContent>
              <TextStack direction='column' spacing={1}>
                <TextStackItem>
                  <TextLabel>Submitted by</TextLabel>
                  <TextValue>{billingFee.data?.submittedBy?.id}</TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Submitted on</TextLabel>
                  <TextValue>{billingFee.data?.submittedAt}</TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Last updated</TextLabel>
                  <TextValue>{billingFee.data?.updatedAt}</TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>TracerID</TextLabel>
                  <TextValue>{billingFee.data?.tracerId}</TextValue>
                </TextStackItem>
              </TextStack>
            </CardContent>
          </Card>
        </Stack>
      </Stack>

      <Paper sx={{ my: 2, p: 2 }} variant='outlined'>
        <Typography sx={{ mb: 2 }} variant='h5'>
          Request Details
        </Typography>
        {billingPeriodQuery.data?.purpose === 'SponsorFees' ? (
          <Stack
            direction='row'
            divider={<Divider flexItem orientation='vertical' />}
            spacing={2}>
            <TextStack direction='column' spacing={1}>
              <TextStackItem>
                <TextLabel>Amount</TextLabel>
                <TextValue>
                  {formatters.formatDollars(
                    billingFee.data?.fees?.[0]?.requestedAmount
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Forfeiture Amount</TextLabel>
                <TextValue>{EMPTY_FIELD_PLACEHOLDER}</TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Spending Budget Amount</TextLabel>
                <TextValue>{EMPTY_FIELD_PLACEHOLDER}</TextValue>
              </TextStackItem>
            </TextStack>
            <TextStack direction='column' spacing={1}>
              <TextStackItem>
                <TextLabel>Routing Number</TextLabel>
                <TextValue>
                  {billingFee.data?.fees?.[0]?.bankRoutingNumber}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Account Number</TextLabel>
                <TextValue>
                  {billingFee.data?.fees?.[0]?.bankAccountNumber}
                </TextValue>
              </TextStackItem>
            </TextStack>
            <TextStack></TextStack>
          </Stack>
        ) : (
          <CollapsibleTable
            cellComponent={BillingDetailsTableCell}
            columns={feeColumns}
            data-testid='billing-details-table-component'
            disablePagination
            tableData={billingFee.data?.fees || []}
          />
        )}
      </Paper>
      {billingFee.data?.cashTransfers?.length > 0 && (
        <Paper sx={{ my: 2 }} variant='outlined'>
          <Typography sx={{ m: 2 }} variant='h5'>
            Transfers
          </Typography>
          <CollapsibleTable
            cellComponent={CashTransfersTableCell}
            columns={cashTransfersColumns}
            data-testid='cash-transfer-table-component'
            disablePagination
            tableData={transfersData || []}
          />
        </Paper>
      )}
    </>
  );
};

export default FeeDetailsRoute;
