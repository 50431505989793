import { SubaExecutionStatus } from '@/models/SubaExecution.model';

export const SubaExecutionStatusColorMap: Partial<
  Record<
    SubaExecutionStatus,
    'error' | 'info' | 'neutral' | 'success' | 'warning'
  >
> = {
  CANCELED: 'warning',
  CREATED: 'neutral',
  FAILED: 'error',
  REPLAYED: 'info',
  STARTED: 'neutral',
  SUBA_ABORTED: 'error',
  SUBA_FAILED: 'error',
  SUBA_REVERSED: 'warning',
  SUBA_RUNNING: 'neutral',
  SUBA_STARTED: 'neutral',
  SUBA_SUCCEEDED: 'info',
  'SUBA_TIMED-OUT': 'error',
  SUCCEEDED: 'success'
};
