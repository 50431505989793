import { useSnackbar } from '@/contexts/SnackBarContext';
import DistributionDeclarationsService from '@/services/ops/distribution-declarations/DistributionDeclarations.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { DistributionType } from '@vestwell-sub-accounting/models/common/DistributionType';

import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';

type ReverseDividendDeclarationDialogProps = {
  open: boolean;
  onClose: () => void;
  declarationId?: number;
  dividendActivityQueueId?: number;
  dividendType: string;
  distributionType: string;
  reinvestDate: string;
  cusip: string;
  onReverse?: () => void;
};

export const ReverseDividendDeclarationDialog = (
  props: ReverseDividendDeclarationDialogProps
) => {
  const { showSnackbar } = useSnackbar();
  const reverseDividendDeclarationMutation = useMutation(
    ['DistributionDeclarationsService.startHistorialDividendProcess'],
    () => {
      return DistributionDeclarationsService.startHistorialDividendProcessor({
        dividendActivityIds: props.dividendActivityQueueId
          ? [props.dividendActivityQueueId]
          : undefined,
        dividendDeclarationId: props.declarationId,
        mode: 'REVERSE',
        tracerId: `UI-${uuidv4()}`
      });
    },
    {
      onError: (error: AxiosError) => {
        showSnackbar({
          message: `Error reversing Dividend Declaration: ${error.message}`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          autoHideDuration: 10000,
          message:
            'Dividend Declaration reverse has been submitted and is currently processing...',
          severity: 'success'
        });
        props.onClose();
        if (typeof props.onReverse === 'function') {
          props.onReverse();
        }
      }
    }
  );

  const displayDistributionTypeMap = {
    [DistributionType.DIV]: 'Dividend',
    [DistributionType.LTCG]: 'LTCG',
    [DistributionType.OTHER]: 'Other',
    [DistributionType.STCG]: 'STCG'
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={() => {
        props.onClose();
      }}
      open={props.open}>
      <DialogTitle
        sx={{
          pb: 1 // there is an unknown rule somewhere setting 8px important padding to the top of DialogContent so we have to compensate here
        }}>
        Reverse Dividend Declaration
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0
        }}>
        <Box
          data-testid='dividend-declaration-reverse-summary'
          sx={{
            bgcolor: theme => theme.palette.primary.light,
            px: 3.25,
            py: 2
          }}>
          <Typography>
            {props.dividendType}{' '}
            {displayDistributionTypeMap[props.distributionType]} type
            declaration
            {props.reinvestDate
              ? ` with a reinvest date of ${formatters.formatFromIsoDateCustom(
                  props.reinvestDate,
                  'MM/DD/YYYY'
                )}`
              : ''}
          </Typography>
          <Typography
            sx={{ color: theme => theme.palette.grey[600], fontSize: 14 }}>
            CUSIP: {props.cusip}
          </Typography>
        </Box>
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 1 }}>
            Are you sure you wish to reverse this Dividend Declaration?
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          px: 3,
          py: 2.25
        }}>
        <Button
          onClick={() => {
            props.onClose();
          }}>
          Nevermind
        </Button>

        <LoadingButton
          loading={reverseDividendDeclarationMutation.isLoading}
          onClick={() => {
            reverseDividendDeclarationMutation.mutate();
          }}
          variant='contained'>
          Reverse
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
