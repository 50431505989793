import LinearLoading from '@/components/linear-loading';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { SecurityMasterService } from '@/services/SecurityMaster.service';
import SubAccountingService from '@/services/SubAccounting.service';
import { useQuery } from '@tanstack/react-query';

import { sortBy } from 'lodash';
import { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { FUND_STATUS_LABELS } from './consts';
import { SecuritiesTab } from './SecuritiesTab.component';
import { SecurityTableRow } from './types';

const getStatus = (status: string | undefined) => {
  switch (status) {
    case 'inactive':
      return FUND_STATUS_LABELS.INACTIVE_STATUS;
    case 'pending_deactivation':
      return FUND_STATUS_LABELS.PENDING_DEACTIVATION_STATUS;
    case 'active':
      return FUND_STATUS_LABELS.ACTIVE_STATUS;
    case 'pending_add':
      return FUND_STATUS_LABELS.PENDING_ACTIVATION_STATUS;
    case 'not_sent':
      return FUND_STATUS_LABELS.NOT_SENT;
    default:
      return FUND_STATUS_LABELS.NEW_STATUS;
  }
};

export const ProgramSecuritiesTab: FC = () => {
  const { id: programId } = useParams();
  const securitiesQuery = useQuery(
    ['ProgramSecurities', +programId],
    async () => {
      const programFunds = await ProgramService.getProgramFunds(+programId);
      const trialRequest = await ProgramService.getProgramPlans(
        +programId,
        1,
        0,
        '',
        'id',
        'asc'
      );

      const programPlans = (
        await ProgramService.getProgramPlans(
          +programId,
          1,
          trialRequest.meta.count,
          '',
          'id',
          'asc'
        )
      ).data.filter(
        e =>
          e.attributes.custodianAccountNumber &&
          e.attributes.recordkeeperId === 5 &&
          [
            'Ready - Awaiting Effective Date',
            'Pending',
            'Termination Requested',
            'Active',
            'Deconversion Requested',
            'Onboarding'
          ].includes(e.attributes.status)
      );

      const planIds = programPlans.map(e => e.id);

      const planFundsAndStatuses = await Promise.all(
        planIds.map(planId =>
          SubAccountingService.getPlanFundsAndStatuses(planId)
        )
      );

      const planFundsStatus = planFundsAndStatuses.map((e, i) => ({
        ...e,
        planInfo: programPlans[i]
      }));

      if (!planFundsStatus.length) {
        return {
          isInitiallyDirty: false,
          numberOfPlans: planIds.length,
          rows: []
        };
      }

      const securities = sortBy(
        await SecurityMasterService.getSecuritiesByCusips(
          programFunds.map(item => item.cusip)
        ),
        ['symbol']
      );

      const plunFundStatusByCusipByPlan = planFundsStatus?.map(e => ({
        data: new Map(
          e.data.map(item => [item.attributes.planFund.cusip, item.attributes])
        ),
        planId: e.planInfo.id,
        planName: e.planInfo.attributes.name
      }));

      const rows = [
        ...(securities?.map((security, secIndex) => {
          return {
            cusip: security.cusip,
            fundName: security.fundName,
            id: secIndex,
            symbol: security.symbol,
            ...plunFundStatusByCusipByPlan?.reduce(
              (acc, item, index) => ({
                ...acc,
                [`planId${index}`]: item.planId,
                [`planName${index}`]: item.planName,
                [`status${index}`]: getStatus(
                  item.data.get(security.cusip)?.status
                ),
                requestNote:
                  item.data.get(security.cusip)?.planFund?.notes || ''
              }),
              {}
            )
          };
        }) || [])
      ] as SecurityTableRow[];

      const isInitiallyDirty = rows.some(row =>
        Array.from({ length: planIds.length }).some(
          (_, i) => row?.[`status${i}`] === FUND_STATUS_LABELS.NEW_STATUS
        )
      );

      return {
        isInitiallyDirty,
        numberOfPlans: planIds.length,
        rows
      };
    },
    { cacheTime: 0 }
  );

  const onSubmitSecurities = useCallback(() => securitiesQuery.refetch(), []);

  if (securitiesQuery.isFetching) {
    return <LinearLoading />;
  }

  return (
    <SecuritiesTab
      isInitiallyDirty={securitiesQuery.data.isInitiallyDirty}
      numberOfPlans={securitiesQuery.data.numberOfPlans}
      onSubmitSecurities={onSubmitSecurities}
      programId={+programId}
      rows={securitiesQuery.data.rows}
    />
  );
};

SecuritiesTab.displayName = 'ProgramSecuritiesTab';
