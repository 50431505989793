/* eslint-disable sort-keys-plus/sort-keys */
import AccessControl from '@/components/access-control/AccessControl.component';
import CardIconInfoField from '@/components/card-icon-info-field/CardIconInfoField.component';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import useSponsor from '@/hooks/useSponsor';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import SponsorService from '@/services/Sponsor.service';
import { companyPayrollContactsSchema } from '@/utils/validations/CompanyPayrollContactsSchema.schema';
import {
  AddOutlined,
  ContactsOutlined,
  EmailOutlined,
  PersonOutline,
  PhoneOutlined
} from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { Sponsors } from '@vestwell-api/scala';

import React, { useCallback } from 'react';

interface CompanyPayrollContactsProps {
  sponsorId: number;
}

export const CompanyPayrollContactsTab: React.FC<
  CompanyPayrollContactsProps
> = props => {
  const payrollContacts = useQuery(
    ['SponsorService.getSponsorPayrollContacts', props.sponsorId],
    () => SponsorService.getSponsorPayrollContacts(+props.sponsorId),
    {
      enabled: Boolean(props.sponsorId)
    }
  );

  const sponsorQuery = useSponsor(props.sponsorId.toString());
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const editPayrollContact = useMutation(
    ['SponsorService.putSponsorPayrollContact', props.sponsorId],
    (data: {
      payrollContactId: number;
      body: Sponsors.PutPayrollContact.RequestBody;
    }) =>
      SponsorService.putSponsorPayrollContact(
        +props.sponsorId,
        data.payrollContactId,
        data.body
      ),
    {
      onError: async () => {
        showSnackbar({
          message: `Payroll Contact could not be edited.`,
          severity: 'error'
        });
      },
      onSuccess: async payrollContact => {
        await payrollContacts.refetch();
        showSnackbar({
          message: `Payroll Contact ${payrollContact.firstName} ${payrollContact.lastName} edited successfully`,
          severity: 'success'
        });
      }
    }
  );

  const addPayrollContact = useMutation(
    ['SponsorService.postSponsorPayrollContact', props.sponsorId],
    (data: Sponsors.PostPayrollContact.RequestBody) => {
      if (sponsorQuery.data?.data?.attributes?.name) {
        return SponsorService.postSponsorPayrollContact(+props.sponsorId, {
          ...data,
          company: sponsorQuery.data?.data?.attributes?.name
        });
      }
      throw new Error('Missing company name');
    },
    {
      onError: async () => {
        showSnackbar({
          message: `Payroll Contact could not be added.`,
          severity: 'error'
        });
      },
      onSuccess: async payrollContact => {
        await payrollContacts.refetch();
        showSnackbar({
          message: `Payroll Contact ${payrollContact.firstName} ${payrollContact.lastName} added successfully.`,
          severity: 'success'
        });
      }
    }
  );

  const onAdd = useCallback(() => {
    openDialog({
      actionButtons: {
        cancelButton: {
          children: 'Cancel'
        },
        submitButton: {
          children: 'Add'
        }
      },
      onSubmit: async values => {
        await addPayrollContact.mutateAsync(
          values as Sponsors.PostPayrollContact.RequestBody
        );
      },
      steps: [
        {
          fields: {
            firstName: {
              label: 'First Name'
            },
            lastName: {
              label: 'Last Name'
            },
            role: {
              label: 'Role'
            },
            phoneNumber: {
              label: 'Phone Number'
            },
            email: {
              label: 'Email'
            }
          },
          title: 'Add Payroll Contact'
        }
      ],
      validationSchema: companyPayrollContactsSchema
    });
  }, [addPayrollContact, openDialog]);

  const onEdit = useCallback(
    (pc: Sponsors.GetPayrollContacts.ResponseBody[0]) => {
      openDialog({
        actionButtons: {
          cancelButton: {
            children: 'Cancel'
          },
          submitButton: {
            children: 'Edit'
          }
        },
        onSubmit: async values => {
          await editPayrollContact.mutateAsync({
            body: {
              ...(values as Sponsors.PutPayrollContact.RequestBody),
              company: pc.company
            },
            payrollContactId: pc.payrollContactId
          });
        },
        steps: [
          {
            fields: {
              firstName: {
                initialValue: pc.firstName,
                label: 'First Name'
              },
              lastName: {
                initialValue: pc.lastName,
                label: 'Last Name'
              },
              role: {
                initialValue: pc.role,
                label: 'Role'
              },
              phoneNumber: {
                initialValue: pc.phoneNumber,
                label: 'Phone Number'
              },
              email: {
                initialValue: pc.email,
                label: 'Email'
              }
            },
            title: 'Edit Payroll Contact'
          }
        ],
        validationSchema: companyPayrollContactsSchema
      });
    },
    [editPayrollContact, openDialog]
  );

  return (
    <Grid container data-testid='plan-company-payroll-contacts-tab-content'>
      <Grid
        item
        sx={{
          alignItems: 'flex-start',
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between'
        }}
        xs={12}>
        <Typography fontSize='1.2rem' fontWeight={500} pl={2}>
          Payroll Contacts
        </Typography>
        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_COMPANY_COMPANY_INFO]}>
          <Button onClick={onAdd} variant='text'>
            {' '}
            <AddOutlined
              fontSize='small'
              sx={{
                marginRight: 1
              }}
            />{' '}
            Add Payroll Contact{' '}
          </Button>
        </AccessControl>
      </Grid>
      {payrollContacts.data?.map(pc => (
        <Grid item key={pc.payrollContactId} my={2} xs={4}>
          <Grid pb={2}>
            <CardIconInfoField
              icon={<PersonOutline />}
              subtitle={pc.role}
              title={[pc.firstName, pc.lastName].join(' ')}
              tooltip='Payroll Contact Name'
            />
          </Grid>
          <CardIconInfoField
            icon={<PhoneOutlined />}
            subtitle={pc.phoneNumber}
            tooltip='Payroll Contact Number'
          />
          <CardIconInfoField
            icon={<EmailOutlined />}
            subtitle={pc.email}
            tooltip='Payroll Contact Email'
          />
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_COMPANY_COMPANY_INFO]}>
            <Grid item ml={3.5}>
              <Button onClick={() => onEdit(pc)} variant='text'>
                EDIT
              </Button>
            </Grid>
          </AccessControl>
        </Grid>
      ))}
      {!payrollContacts.isFetching && !payrollContacts.data?.length && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            paddingTop: '50px',
            width: '100%'
          }}>
          <ContactsOutlined
            fontSize='large'
            sx={{
              color: theme => theme.palette.grey[300]
            }}
          />
          <Typography
            sx={{
              fontSize: '1.2rem',
              fontWeight: '500'
            }}>
            No Payroll Contacts
          </Typography>
          <Typography
            sx={{
              color: theme => theme.palette.grey[600]
            }}>
            Payroll contacts from pay groups will appear here.
          </Typography>
        </Box>
      )}
    </Grid>
  );
};
