import AppConfig from '@/App.config';
import {
  CancelSponsorExemptionPayload,
  ContributionDetailsDto,
  CreatePrimaryContactDto,
  CreateSponsorExemptionDto,
  CreateSponsorExemptionPayload,
  DocumentDto,
  DocumentSlotMetaData,
  SponsorExemptionDto,
  SponsorTeammates,
  UpdateSponsorDto,
  UpdateSponsorPayload
} from '@/models';
import { CreateSponsorPrimaryContactInviteDto } from '@/models/CreateSponsorPrimaryContactInviteDTO.model';
import { UserRegistrationLinkResponseDto } from '@/models/CreateSponsorUserInviteDTO.model';
import { OnboardingSponsorDto } from '@/models/OnboardingSponsorDTO.model';
import { ScheduledChange } from '@/models/ScheduledChangesDTO.model';
import { SponsorDocumentListDto } from '@/models/SponsorDocumentDTO.model';
import SponsorDto from '@/models/SponsorDTO.model';
import { SponsorTeammateData } from '@/models/SponsorTeammates.model';
import SponsorUserInvitesDto from '@/models/SponsorUserInvitesDTO.model';
import SponsorUserInvitesInfo, {
  SponsorUserInviteRelationships
} from '@/models/SponsorUserInvitesInfo.model';
import UpdateSponsorGeneralInformationPayload from '@/models/UpdateSponsorGeneralInformationPayload.model';
import { PatchSponsorUserInviteBodyDto } from '@/models/UpdateSponsorUserInviteDTO.model';
import { UserInvite } from '@/models/UserInvite.model';
import { UserRolesDto } from '@/models/UserRolesDTO.model';
import { OWNER_SPONSOR_ROLES } from '@/routes/plans/plan-detail/PlanUsersTable/PlanUsersTable';
import ApiService from '@/services/Api.service';
import { userService } from '@/services/User.service';
import type { Sponsors } from '@vestwell-api/scala';

import type { BankAccountResponse } from 'scala-sdk';
import { v4 as uuidv4 } from 'uuid';

class SponsorService {
  static async getSponsorById(sponsorId: string | number): Promise<SponsorDto> {
    const dto = await ApiService.getJson<SponsorDto>(`/sponsors/${sponsorId}`);

    if (!dto || !dto.data || !dto.data.attributes) {
      throw new Error(
        `invalid JSON received from backend for sponsorId=${sponsorId}`
      );
    }

    return dto;
  }

  static async updateSponsor(
    sponsorId: string | number,
    body: UpdateSponsorDto
  ): Promise<UpdateSponsorPayload> {
    const dto = await ApiService.patchJson<
      UpdateSponsorDto,
      UpdateSponsorPayload
    >(`/sponsors/${sponsorId}`, body);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for sponsorId=${sponsorId}`
      );
    }

    return dto;
  }

  static async getSponsorPayrollContacts(
    sponsorId: number
  ): Promise<Sponsors.GetPayrollContacts.ResponseBody> {
    const res =
      await ApiService.getJson<Sponsors.GetPayrollContacts.ResponseBody>(
        `/sponsors/${sponsorId}/payroll-contacts`
      );

    if (!res) {
      throw new Error(
        `invalid JSON received from backend for sponsorId=${sponsorId} /sponsors/:sponsorId/payroll-contacts`
      );
    }

    return res;
  }

  static async postSponsorPayrollContact(
    sponsorId: number,
    body: Sponsors.PostPayrollContact.RequestBody
  ): Promise<Sponsors.PostPayrollContact.ResponseBody> {
    const res = await ApiService.postJson<
      Sponsors.PostPayrollContact.RequestBody,
      Sponsors.PostPayrollContact.ResponseBody
    >(`/sponsors/${sponsorId}/payroll-contacts`, body);

    if (!res) {
      throw new Error(
        `error adding payroll contact for sponsorId=${sponsorId} /sponsors/:sponsorId/payroll-contacts`
      );
    }

    return res;
  }

  static async putSponsorPayrollContact(
    sponsorId: number,
    payrollContactId: number,
    body: Sponsors.PutPayrollContact.RequestBody
  ): Promise<Sponsors.PutPayrollContact.ResponseBody> {
    const res = await ApiService.putJson<
      Sponsors.PutPayrollContact.RequestBody,
      Sponsors.PutPayrollContact.ResponseBody
    >(`/sponsors/${sponsorId}/payroll-contacts/${payrollContactId}`, body);

    if (!res) {
      throw new Error(
        `error updating payrollContactId=${payrollContactId} for sponsorId=${sponsorId} /sponsors/:sponsorId/payroll-contacs/:payroll-contact-id`
      );
    }

    return res;
  }

  static async getSponsorBankAccounts(
    sponsorId: number
  ): Promise<BankAccountResponse[]> {
    const res = await ApiService.getJson<BankAccountResponse[]>(
      `/sponsors/${sponsorId}/bank-accounts`
    );

    if (!res) {
      throw new Error(
        `invalid JSON received from backend for sponsorId=${sponsorId} /sponsors/:sponsorId/bank-accounts`
      );
    }

    return res;
  }

  static async postSponsorBankAccount(
    sponsorId: number,
    body: Sponsors.PostBankAccounts.RequestBody
  ): Promise<Sponsors.PostBankAccounts.ResponseBody> {
    const res = await ApiService.postJson<
      Sponsors.PostBankAccounts.RequestBody,
      Sponsors.PostBankAccounts.ResponseBody
    >(`/sponsors/${sponsorId}/bank-accounts`, body);

    if (!res) {
      throw new Error(
        `error adding bankAccount for sponsorId=${sponsorId} /sponsors/:sponsorId/bank-accounts`
      );
    }

    return res;
  }

  static async putSponsorBankAccount(
    sponsorId: number,
    bankAccountId: number,
    body: Sponsors.PutBankAccounts.RequestBody
  ): Promise<Sponsors.PutBankAccounts.ResponseBody> {
    const res = await ApiService.putJson<
      Sponsors.PutBankAccounts.RequestBody,
      Sponsors.PutBankAccounts.ResponseBody
    >(`/sponsors/${sponsorId}/bank-accounts/${bankAccountId}`, body);

    if (!res) {
      throw new Error(
        `error updating bankAccountId=${bankAccountId} for sponsorId=${sponsorId} /sponsors/:sponsorId/bank-accounts/:bank-account-id`
      );
    }

    return res;
  }

  static deleteSponsorBankAccount(params: {
    bankAccountId: number;
    sponsorId: number;
  }): Promise<Sponsors.DeleteBankAccounts.ResponseBody> {
    return ApiService.deleteJson<Sponsors.DeleteBankAccounts.ResponseBody>(
      `/sponsors/${params.sponsorId}/bank-accounts/${params.bankAccountId}`
    );
  }

  static async getSponsorTeammates(
    sponsorId: number | string
  ): Promise<SponsorTeammateData[]> {
    const dto = await ApiService.getJson<SponsorTeammates>(
      `/sponsors/${sponsorId}/users`
    );

    const token = userService.getTokenData();
    if (!token) {
      throw new Error('api request made before user was authorized');
    }
    const { tpaId } = token;

    // alex: vestwell users do not have TPA id
    const isVestwellUser = !tpaId;

    if (!dto || !dto.data) {
      throw new Error(
        `invalid JSON received from backend for sponsorId=${sponsorId}`
      );
    }

    const teammates = dto.data || [];
    const sponsorTeammatesWithKeys = teammates.map(data => {
      return { ...data, key: uuidv4() };
    });

    if (AppConfig.environment === 'production' && !isVestwellUser) {
      return sponsorTeammatesWithKeys.filter(
        teammate => !teammate.attributes.email.includes('@vestwell.com')
      );
    }

    return sponsorTeammatesWithKeys as SponsorTeammateData[];
  }

  static async getSponsorUserInvites(
    sponsorId: number | string,
    searchByEmail?: string,
    includeRegistrationUrl?: boolean
  ): Promise<SponsorUserInvitesInfo> {
    const dto = (await ApiService.getJson(
      `/sponsors/${sponsorId}/user-invites`,
      { includeRegistrationUrl }
    )) as SponsorUserInvitesDto;

    if (!dto || !dto.data) {
      throw new Error(
        `invalid JSON received from backend for sponsorId=${sponsorId}`
      );
    }

    let sponsorUserInvite;
    const sortedInvites = [...dto.data].sort(
      (a, b) =>
        new Date(b.attributes.expiresAt).getTime() -
        new Date(a.attributes.expiresAt).getTime()
    );
    if (!searchByEmail) {
      // state invite 6 digit code is not always guaranteed as first entry
      // should look for owner_creator role on invite
      const ownerRole = 2;
      sponsorUserInvite = sortedInvites.filter(invite =>
        invite.attributes.rolesGranted.includes(ownerRole)
      )[0];
    } else {
      sponsorUserInvite = sortedInvites.filter(
        invite => invite.attributes.email === searchByEmail
      )[0];
    }

    return {
      registrationUrl: dto.registrationUrl,
      relationships: sponsorUserInvite?.relationships || null,
      sponsorUserInviteCode: sponsorUserInvite?.attributes?.inviteCode || null,
      sponsorUserInviteExpiresAt:
        sponsorUserInvite?.attributes?.expiresAt || null,
      sponsorUserInvites: dto.data
    };
  }

  static async deleteSponsorUserInvite(
    inviteId: string | number
  ): Promise<any> {
    const dto = (await ApiService.deleteJson(
      `/sponsors/user-invites/${inviteId}`
    )) as any;

    return dto;
  }

  static async getSponsorUserInvitesV2(
    sponsorId: number | string
  ): Promise<Sponsors.GetUserInvitesV2.ResponseBody> {
    const dto: Sponsors.GetUserInvitesV2.ResponseBody =
      await ApiService.getJson(`/sponsors/${sponsorId}/v2/user-invites`);

    return dto;
  }

  static async getRegistrationURL(
    sponsorId: number,
    firmId: number,
    inviteCode?: string
  ): Promise<string> {
    return ApiService.getJson(`/sponsors/${sponsorId}/v2/registration-link`, {
      firmId,
      inviteCode
    });
  }

  static async updateSponsorUserInvite(
    sponsorId: string | number,
    body: PatchSponsorUserInviteBodyDto
  ): Promise<PatchSponsorUserInviteBodyDto> {
    const dto: PatchSponsorUserInviteBodyDto = await ApiService.patchJson(
      `/sponsors/${sponsorId}/user-invites`,
      body
    );

    return dto;
  }

  static async getDocumentsBySponsor(
    sponsorId: string | number
  ): Promise<SponsorDocumentListDto> {
    const dto = (await ApiService.getJson(
      `/sponsors/${sponsorId}/documents`
    )) as SponsorDocumentListDto;

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for sponsorId=${sponsorId}`
      );
    }

    return dto;
  }

  static async getDocumentsBySponsorEntity(
    sponsorId: string | number
  ): Promise<DocumentSlotMetaData[]> {
    return ApiService.getJson(`/sponsors/${sponsorId}/entity/documents`);
  }

  static async getDocumentForSponsorId(
    sponsorId: string | number,
    documentId: string | number
  ): Promise<DocumentDto> {
    return ApiService.getJson(`/sponsors/${sponsorId}/document/${documentId}`);
  }

  static isHeldStatus(contribution?: ContributionDetailsDto): boolean {
    if (!contribution) return false;
    if (!contribution.pngStatuses) return false;
    if (!contribution.pngStatuses.length) return false;

    const { pngStatuses } = contribution;
    const finalStatus = pngStatuses[pngStatuses.length - 1];

    if (!finalStatus) return false;

    const { status } = finalStatus;

    return status === 'HELD';
  }

  static async getSponsorExemption(
    sponsorExemptionId: number | string | undefined
  ): Promise<SponsorExemptionDto> {
    if (typeof sponsorExemptionId === 'undefined') {
      throw new Error('sponsorExemptionId is undefined');
    }

    const dto = await ApiService.getJson<SponsorExemptionDto>(
      `/sponsor-exemptions/${sponsorExemptionId}`
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for sponsorExemptionId=${sponsorExemptionId}`
      );
    }
    return dto;
  }

  static async getUserRoles(): Promise<UserRolesDto> {
    const dto = await ApiService.getJson<UserRolesDto>(`/user-roles`);

    if (!dto) {
      throw new Error(`invalid JSON received from backend for user-roles`);
    }

    return dto;
  }

  static async createSponsorPrimaryContact(
    sponsorId: number,
    primaryContact: CreatePrimaryContactDto
  ): Promise<void> {
    return ApiService.postJson(`/sponsors/${sponsorId}/primary-contact`, {
      primaryContactEmail: primaryContact.primaryContactEmail,
      primaryContactFirstName: primaryContact.primaryContactFirstName,
      primaryContactLastName: primaryContact.primaryContactLastName
    });
  }

  static async sendSponsorPrimaryContactInviteEmail(
    sponsorId: number,
    email: string
  ): Promise<UserRegistrationLinkResponseDto> {
    const roles = await this.getUserRoles();

    const roleIds = roles.data
      .filter(role => OWNER_SPONSOR_ROLES.includes(role.attributes.name))
      .map(role => role.id);

    return ApiService.postJson(
      `/sponsors/${sponsorId}/primary-contact/invite`,
      {
        deliveryMethod: 'email',
        email,
        rolesGranted: roleIds
      }
    );
  }

  static async createSponsorUserInviteAndSendEmail(
    sponsorId: number | string,
    email: string,
    userRoles: string[] | undefined
  ): Promise<UserRegistrationLinkResponseDto> {
    const dto: UserRegistrationLinkResponseDto = await ApiService.postJson(
      `/sponsors/${sponsorId}/user-invites`,
      {
        deliveryMethod: 'email',
        email,
        rolesGranted: userRoles
      }
    );

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for sponsorExemptionId=`
      );
    }
    return dto;
  }

  static async createSponsorUserInvite(
    sponsorId: number | string,
    email: string,
    userRoles: string[] | undefined
  ): Promise<{ data: UserInvite<SponsorUserInviteRelationships> }> {
    const dto: { data: UserInvite<SponsorUserInviteRelationships> } =
      await ApiService.postJson(`/sponsors/${sponsorId}/create/user-invites`, {
        deliveryMethod: 'email',
        email,
        rolesGranted: userRoles
      });

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for sponsorExemptionId=`
      );
    }
    return dto;
  }

  static async createSponsorExemption(
    sponsorExemptionId: number | string,
    body: CreateSponsorExemptionDto
  ): Promise<CreateSponsorExemptionPayload> {
    const dto = await ApiService.postJson<
      CreateSponsorExemptionDto,
      CreateSponsorExemptionPayload
    >(`/sponsor-exemptions`, body);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for sponsorExemptionId=${sponsorExemptionId}`
      );
    }
    return dto;
  }

  static async cancelSponsorExemption(
    sponsorExemptionId: number | string
  ): Promise<CancelSponsorExemptionPayload> {
    const dto = await ApiService.postJson<
      Record<string, unknown>,
      CancelSponsorExemptionPayload
    >(`/sponsor-exemptions/${sponsorExemptionId}/cancel`, {});

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for sponsorExemptionId=${sponsorExemptionId}`
      );
    }

    return dto;
  }

  // TODO: move this to plan controller
  static async postReleaseFromHold(
    planId: number | string,
    ucid: number | string
  ): Promise<void> {
    if (typeof planId === 'undefined') {
      throw new Error('planId undefined');
    }

    // mbildner: response from scala is empty
    // we rely on the status code to know if this succeeded
    await ApiService.postJson<any, never>(
      `/sponsors/${planId}/contributions/${ucid}/release-hold`,
      {}
    );
  }

  static async createUserSponsorRelationship(
    userId: number | string,
    body: { entityId: number }
  ): Promise<{ rowsAdded: number }[]> {
    const dto = await ApiService.postJson<
      { entityId: number },
      { rowsAdded: number }[]
    >(`/users/${userId}/relationships/sponsors`, body);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for userId=${userId}`
      );
    }

    return dto;
  }

  static async deleteUserSponsorRelationship(
    userId: number | string,
    body: { entityId: number }
  ): Promise<{ rowsRemoved: number; exceptions: [] }[]> {
    const dto = await ApiService.patchJson<
      { entityId: number },
      { rowsRemoved: number; exceptions: [] }[]
    >(`/users/${userId}/relationships/sponsors`, body);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for userId=${userId}`
      );
    }

    return dto;
  }

  static async sendSponsorUserInvite(
    sponsorId: number | string,
    email: string,
    inviteCode: string
  ): Promise<{ data: UserInvite<SponsorUserInviteRelationships> }> {
    const dto: { data: UserInvite<SponsorUserInviteRelationships> } =
      await ApiService.postJson(`/sponsors/${sponsorId}/send/user-invites`, {
        email,
        inviteCode
      });

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for sendSponsorUserInvite sponsorId=${sponsorId}`
      );
    }
    return dto;
  }

  static async sendSponsorWelcomeInvite(
    sponsorId: number | string,
    sponsorPlanId: number,
    email: string,
    inviteCode: string
  ): Promise<{ data: UserInvite<SponsorUserInviteRelationships> }> {
    const dto: { data: UserInvite<SponsorUserInviteRelationships> } =
      await ApiService.postJson(`/sponsors/${sponsorId}/send/welcome-email`, {
        email,
        inviteCode,
        sponsorPlanId
      });

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for sendSponsorWelcomeInvite sponsorId=${sponsorId}`
      );
    }
    return dto;
  }

  static async getOnboardingSponsorById(
    sponsorId: string | number
  ): Promise<OnboardingSponsorDto> {
    const dto = await ApiService.getJson<OnboardingSponsorDto>(
      `/sponsors/${sponsorId}/onboarding`
    );

    if (!dto || !dto.fields) {
      throw new Error(
        `invalid JSON received from backend for sponsorId=${sponsorId}`
      );
    }

    return dto;
  }

  static async updateSponsorGeneralInformation(
    sponsorPlanId: string | number,
    body: Partial<UpdateSponsorGeneralInformationPayload>
  ): Promise<{ failures: { [key: string]: unknown } }> {
    const dto = await ApiService.patchJson<
      Partial<UpdateSponsorGeneralInformationPayload>,
      { failures: { [key: string]: unknown } }
    >(`/sponsors/${sponsorPlanId}/general-information`, body);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for sponsorPlanId=${sponsorPlanId}`
      );
    }

    return dto;
  }

  static async createSponsorPrimaryContactInvite(
    sponsorId: string | number,
    dto: CreateSponsorPrimaryContactInviteDto
  ): Promise<UserRegistrationLinkResponseDto> {
    const roles = await this.getUserRoles();

    const roleIds = roles.data
      .filter(role => OWNER_SPONSOR_ROLES.includes(role.attributes.name))
      .map(role => role.id);

    const createPrimaryContactInviteDto = {
      deliveryMethod: 'email',
      email: dto.email,
      rolesGranted: roleIds
    };

    return ApiService.postJson(
      `/sponsors/${sponsorId}/primary-contact/invite`,
      createPrimaryContactInviteDto
    );
  }

  static async sendSponsorBlackoutNotice(
    sponsorId: string | number,
    sponsorPlanId: string | number
  ): Promise<void> {
    return ApiService.postJson(`/sponsors/${sponsorId}/send/blackout-notice`, {
      sponsorPlanId
    });
  }

  static async sendSponsorEligibilityReviewReminderEmail(
    sponsorId: string | number,
    sponsorPlanId: string | number
  ): Promise<boolean> {
    return ApiService.postJson(
      `/sponsors/${sponsorId}/send/eligibility-review-reminder-notice`,
      {
        sponsorPlanId
      }
    );
  }

  static async createScheduleChange(
    sponsorId: number | string,
    scheduledChange: Omit<ScheduledChange, 'createdAt' | 'createdBy' | 'id'>
  ): Promise<{ data: Record<string, never> }> {
    return ApiService.postJson(
      `/sponsors/${sponsorId}/schedule`,
      scheduledChange
    );
  }

  static async getScheduledChanges(
    sponsorId: number,
    applied = false
  ): Promise<ScheduledChange[]> {
    return ApiService.getJson(`/sponsors/${sponsorId}/schedule`, {
      applied
    });
  }
}

export default SponsorService;
