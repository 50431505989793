import Badge from '@/components/badge';
import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import DataTable, {
  DataTableBadgeCell,
  DataTableMenuCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { displayDistributionTypeMap } from '@/models/ops/distribution-declarations/DisplayDistributionTypeMap.model';
import { DistributionDeclarationDto } from '@/models/ops/distribution-declarations/DistributionDeclarationDTO.model';
import { dividendStatusColorMap } from '@/models/ops/distribution-declarations/DividendStatusColorMap.model';
import { DividendActivityQueueDto } from '@/models/ops/dividends/dividend-activity-queue-DTO.model';
import { DividendActivityQueueRequest } from '@/models/ops/dividends/dividend-activity-queue-request.model';
import { TransactionDto } from '@/models/ops/transactions/TransactionDTO.model';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import DividendService from '@/services/ops/dividends/Dividend.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { DividendStatus } from '@vestwell-sub-accounting/models/common/DividendStatus';
import { DividendActivityQueueStatus } from '@vestwell-sub-accounting/models/common/HoldingTankStatus';

import { ColDef } from 'ag-grid-community';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useState } from 'react';

import { CalculateDividendDeclarationDialog } from './CalculateDividendDeclarationDialog.component';
import { RecalculateDividendDeclarationDialog } from './RecalculateDividendDeclarationDialog.component';
import { ReverseDividendDeclarationDialog } from './ReverseDividendDeclarationDialog.component';
import { SecuritiesDetailUtils } from './utils/SecuritiesDetailUtils';

export const DividendDeclarationDetail = ({
  dividendDeclaration,
  onClose
}: {
  dividendDeclaration: DistributionDeclarationDto;
  onClose: () => void;
}) => {
  const { showSnackbar } = useSnackbar();

  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_DIVIDENDS
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState<keyof TransactionDto | undefined>();
  const [orderByDirection, setOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >();

  const [openReverseDialog, setOpenReverseDialog] = useState(false);
  const [openCalculateDialog, setOpenCalculateDialog] = useState(false);
  const [openRecalculateDialog, setOpenRecalculateDialog] = useState(false);
  const [selectedDividendActivityQueue, setSelectedDividendActivityQueue] =
    useState<DividendActivityQueueDto>();

  const { data, isFetching } = useQuery(
    [
      'DividendService.searchDividendActivityQueue',
      dividendDeclaration,
      page,
      pageSize,
      orderBy,
      orderByDirection
    ],
    () => {
      if (!dividendDeclaration.id) {
        const emptyResponse: PaginatedApiResponse<DividendActivityQueueDto[]> =
          {
            pagination: {
              page,
              pageSize,
              total: 0
            },
            results: []
          };
        return Promise.resolve(emptyResponse);
      }
      const queryWithPagination: DividendActivityQueueRequest = {
        declarationId: dividendDeclaration.id,
        orderBy: orderBy || 'id',
        orderByDirection: orderByDirection || 'desc',
        page,
        pageSize
      };
      return DividendService.searchDividendActivityQueue(queryWithPagination);
    },
    {
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Dividend Activity Queue search failed: ${message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  const columnDefs: ColDef[] = [
    {
      cellRenderer: (cellData: { data: DividendActivityQueueDto }) => {
        const isAfterReinvest =
          cellData.data.refReinvestDate &&
          dayjs().format('YYYY-MM-DD') > cellData.data.refReinvestDate;

        const canReverse =
          hasWritePermissions &&
          isAfterReinvest &&
          cellData.data.status === DividendActivityQueueStatus.finished;
        const canCalculate =
          (hasWritePermissions &&
            isAfterReinvest &&
            cellData.data.status ===
              DividendActivityQueueStatus.noDivDeclFound) ||
          cellData.data.status === DividendActivityQueueStatus.reversed;
        const canRecalculate =
          hasWritePermissions &&
          isAfterReinvest &&
          (cellData.data.status === DividendActivityQueueStatus.finished ||
            cellData.data.status === DividendActivityQueueStatus.failed);
        return (
          <>
            {canReverse || canCalculate || canRecalculate ? (
              <DataTableMenuCell>
                {canReverse && (
                  <MenuItem
                    onClick={() => {
                      setSelectedDividendActivityQueue(cellData.data);
                      setOpenReverseDialog(true);
                    }}>
                    Reverse Dividend Declaration
                  </MenuItem>
                )}
                {canCalculate && (
                  <MenuItem
                    onClick={() => {
                      setSelectedDividendActivityQueue(cellData.data);
                      setOpenCalculateDialog(true);
                    }}>
                    Calculate Dividend Declaration
                  </MenuItem>
                )}
                {canRecalculate && (
                  <MenuItem
                    onClick={() => {
                      setSelectedDividendActivityQueue(cellData.data);
                      setOpenRecalculateDialog(true);
                    }}>
                    Recalculate Dividend Declaration
                  </MenuItem>
                )}
              </DataTableMenuCell>
            ) : (
              ' ' // empty space to prevent showing the blank dash when no actions are available
            )}
          </>
        );
      },
      cellStyle: {
        paddingLeft: 16,
        paddingRight: 16
      },
      field: 'cusip',
      headerName: '',
      maxWidth: 75,
      minWidth: 75
    },
    {
      cellRenderer: (cellData: { data: DividendActivityQueueDto }) => {
        return cellData.data.id;
      },
      field: 'id',
      headerName: 'Daq Id',
      resizable: true,
      sortable: true,
      type: 'numericColumn',
      width: 100
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: DividendActivityQueueDto }) => {
        const name =
          cellData.data.parentAccount?.accountType === 'SuperOmnibus' ||
          cellData.data.parentAccount?.accountType === 'House'
            ? cellData.data.parentAccount?.accountName
            : cellData.data.parentAccount?.planParentAccount.planId;
        return (
          <DataTableStackCell
            primary={name || ''}
            primaryLinkProps={{
              to: `/ops/accounts/${cellData.data.parentAccount.id}`
            }}
            secondary={`ID: ${cellData.data.parentAccount.id}`}
          />
        );
      },
      field: 'parentAccount.parentAccountId',
      headerName: 'Parent Account',
      minWidth: 420,
      sortable: false
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: DividendActivityQueueDto }) => {
        const displayDividendParentAccountTransactionStatus = cellData.data
          ?.status
          ? formatters.getValueKey(DividendStatus, cellData.data.status)
          : DividendStatus.pending; // default to pending if missing dividendMaster
        return (
          <>
            {cellData.data.status && (
              <DataTableBadgeCell
                color={
                  dividendStatusColorMap[
                    cellData.data?.status || DividendStatus.pending // default to pending if missing dividendMaster
                  ]
                }>
                {formatters.displayCase(
                  displayDividendParentAccountTransactionStatus
                )}
              </DataTableBadgeCell>
            )}
          </>
        );
      },
      field: 'orderStatus',
      headerName: 'Status',
      sortable: false
    },
    {
      cellRenderer: (cellData: { data: DividendActivityQueueDto }) => {
        // blank if not status paid or amount is missing
        return cellData.data.status === DividendActivityQueueStatus.finished &&
          cellData.data.netAmount
          ? formatters.formatDollars(cellData.data.netAmount)
          : '';
      },
      field: 'amount',
      headerName: 'Ttl. Paid Amount',
      resizable: true,
      sortable: true,
      type: 'numericColumn'
    },
    {
      cellRenderer: (cellData: { data: DividendActivityQueueDto }) => {
        // blank if not status paid or units are missing
        return cellData.data.status === DividendActivityQueueStatus.finished &&
          cellData.data.reinvestUnits
          ? formatters.formatDecimal(cellData.data.reinvestUnits, 3)
          : '';
      },
      field: 'units',
      headerName: 'Ttl. Reinvest Units',
      resizable: true,
      sortable: true,
      type: 'numericColumn'
    }
  ];

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
    } else {
      setOrderBy(newSort[0].colId as keyof TransactionDto);
      setOrderByDirection(newSort[0].sort);
    }
  };

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const actions =
    SecuritiesDetailUtils.determineStatusAndActionsByActivity(
      dividendDeclaration
    );

  // a step function is called through the API, so we don't know if it was
  // successful after requesting, so we invalidate cache after 10 seconds to give enough time
  // we will do a heavy invalidate of all queries due to multiple levels of queries populating the div pages
  const queryClient = useQueryClient();
  const invalidateSearchQuery = () => {
    setTimeout(() => {
      queryClient.invalidateQueries();
    }, 10000);
  };

  return (
    <>
      {selectedDividendActivityQueue && (
        <CalculateDividendDeclarationDialog
          cusip={selectedDividendActivityQueue.refCusip}
          distributionType='TODO!!'
          dividendActivityQueueId={selectedDividendActivityQueue.id}
          dividendType={selectedDividendActivityQueue.refDividendType}
          onCalculate={() => invalidateSearchQuery()}
          onClose={() => setOpenCalculateDialog(false)}
          open={openCalculateDialog}
          reinvestDate={selectedDividendActivityQueue.refReinvestDate}
        />
      )}
      {selectedDividendActivityQueue && (
        <ReverseDividendDeclarationDialog
          cusip={selectedDividendActivityQueue.refCusip}
          distributionType='TODO!!'
          dividendActivityQueueId={selectedDividendActivityQueue.id}
          dividendType={selectedDividendActivityQueue.refDividendType}
          onClose={() => setOpenReverseDialog(false)}
          onReverse={() => invalidateSearchQuery()}
          open={openReverseDialog}
          reinvestDate={selectedDividendActivityQueue.refReinvestDate}
        />
      )}
      {selectedDividendActivityQueue && (
        <RecalculateDividendDeclarationDialog
          cusip={selectedDividendActivityQueue.refCusip}
          distributionType='todo'
          dividendActivityQueueId={selectedDividendActivityQueue.id}
          dividendType={selectedDividendActivityQueue.refDividendType}
          onClose={() => setOpenRecalculateDialog(false)}
          onRecalculate={() => invalidateSearchQuery()}
          open={openRecalculateDialog}
          reinvestDate={selectedDividendActivityQueue.refReinvestDate}
        />
      )}
      <Stack
        direction={{ lg: 'row', xs: 'column' }}
        spacing={{ lg: 6, xs: 2 }}
        sx={{ mb: 4 }}>
        <Box>
          <Button onClick={onClose} startIcon={<ArrowBackIcon />}>
            Back to Declarations
          </Button>
          <Typography role='heading' variant='h5'>
            Dividend Declaration Detail
          </Typography>
          <Typography
            data-testid='dividend-distribution-dividend-master-id'
            variant='subtitle1'>
            ID: {dividendDeclaration.id}
          </Typography>
        </Box>

        <TextStack divider rowColumnWidth='dynamic'>
          <TextStackItem>
            <TextLabel>Status</TextLabel>
            <TextValue data-testid='dividend-distribution-detail-status'>
              <Badge color={dividendStatusColorMap[actions.overallStatus]}>
                {formatters.displayCase(actions.overallStatus)}
              </Badge>
            </TextValue>
          </TextStackItem>

          <TextStackItem>
            <TextLabel>Type</TextLabel>
            <TextValue data-testid='dividend-distribution-detail-type'>
              {displayDistributionTypeMap[dividendDeclaration.distributionType]}
            </TextValue>
          </TextStackItem>

          <TextStackItem>
            <TextLabel>Record Date</TextLabel>
            <TextValue data-testid='dividend-distribution-detail-record-date'>
              {dividendDeclaration.recordDate === null
                ? ''
                : formatters.formatFromIsoDateCustom(
                    dividendDeclaration.recordDate,
                    'MM/DD/YY'
                  )}
            </TextValue>
          </TextStackItem>

          <TextStackItem>
            <TextLabel>Payment Date</TextLabel>
            <TextValue data-testid='dividend-distribution-detail-payment-date'>
              {formatters.formatFromIsoDateCustom(
                dividendDeclaration.paymentDate,
                'MM/DD/YY'
              )}
            </TextValue>
          </TextStackItem>

          <TextStackItem>
            <TextLabel>Reinvest Date</TextLabel>
            <TextValue data-testid='dividend-distribution-detail-reinvest-date'>
              {dividendDeclaration.reinvestDate === null
                ? ''
                : formatters.formatFromIsoDateCustom(
                    dividendDeclaration.reinvestDate,
                    'MM/DD/YY'
                  )}
            </TextValue>
          </TextStackItem>

          <TextStackItem>
            <TextLabel>Reinvest Price</TextLabel>
            <TextValue data-testid='dividend-distribution-detail-reinvest-price'>
              {dividendDeclaration.reinvestPrice === null
                ? ''
                : formatters.formatDollars(
                    dividendDeclaration.reinvestPrice,
                    2
                  )}
            </TextValue>
          </TextStackItem>
        </TextStack>
      </Stack>

      <Card data-testid='dividend-distribution-detail-parent-accounts-card'>
        <CardHeader title='Associated Parent Accounts' />
        <CardContent disablePadding overlayLoading={isFetching}>
          <DataTable
            columnDefs={columnDefs}
            columnSizing='flex'
            data-testid='data-parent-account-transactions-table'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-parent-account-transactions-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No results found.'
                />
              </Stack>
            }
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            onSortChanged={handleSortChanged}
            page={page}
            pageSize={pageSize}
            pagination
            paginationSource='server'
            paginationTotal={data?.pagination?.total}
            rowData={data?.results || []}
            sort={
              orderBy
                ? [
                    {
                      colId: orderBy,
                      sort: orderByDirection
                    }
                  ]
                : []
            }
          />
        </CardContent>
      </Card>
    </>
  );
};
