import { useSnackbar } from '@/contexts/SnackBarContext';
import ContributionService from '@/services/Contribution.service';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { FLOW_SUBTYPES } from './consts';

type CancelProps = {
  ucid: string;
  sponsorId: number;
  sponsorPlanId: number;
  flowSubtype?: string;
};

export const CancelContribution: FC<CancelProps> = props => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const cancelSubmission = useMutation(
    () =>
      ContributionService.postCancelSubmission(
        {
          ucid: props.ucid
        },
        {
          cancellationReason: '',
          sponsorId: props.sponsorId,
          sponsorPlanId: props.sponsorPlanId
        }
      ),
    {
      onError: () =>
        snackbar.showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        }),
      onSuccess: () => {
        snackbar.showSnackbar({
          message: 'Contribution successfully canceled!',
          severity: 'success'
        });

        navigate(`/plans/${props.sponsorPlanId}/contributions`, {
          replace: true
        });
      }
    }
  );

  const onSubmit = useCallback(() => cancelSubmission.mutateAsync(), []);

  return (
    <>
      <LoadingButton
        color='error'
        data-testid='cancelContributionButton'
        loading={cancelSubmission.isLoading}
        onClick={toggleIsOpen}
        variant='outlined'>
        Cancel
      </LoadingButton>
      <Dialog
        data-testid='cancelContribution-dialog'
        fullWidth
        maxWidth='xs'
        onClose={toggleIsOpen}
        open={isOpen}>
        <DialogTitle data-testid='cancelContribution-dialog-title'>
          Cancel {FLOW_SUBTYPES[props.flowSubtype] ?? ''} Contribution
        </DialogTitle>
        <DialogContent data-testid='cancelContribution-dialog-content'>
          <Typography>
            Are you sure you want to cancel? This contribution will be deleted.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid display='flex'>
            <Grid mr={2}>
              <Button
                data-testid='cancelContribution-dialog-closeButton'
                onClick={toggleIsOpen}>
                Close
              </Button>
            </Grid>
            <Grid>
              <LoadingButton
                color='error'
                data-testid='cancelContribution-dialog-submitButton'
                loading={cancelSubmission.isLoading}
                onClick={onSubmit}
                variant='contained'>
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
