import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import CheckBoxForm from '@/components/checkbox-form/CheckBoxForm.component';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker/DatePicker';
import {
  FraudRankerReport,
  FraudrankerReportQueryParams
} from '@/models/FraudrankerResult.model';
import { FraudrankerService } from '@/services/ops/fraudranker/Fraudranker.service';
import formatters from '@/utils/Formatters';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Field, Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const MIN_DISTRIBUTION_AMOUNT = 0;
const MAX_DISTRIBUTION_AMOUNT = 100000;

const ColoredCircle = styled('span')<{
  color: string;
}>(props => {
  return {
    backgroundColor: props.color,
    borderRadius: 100,
    height: 15,
    width: 15
  };
});

const fraudColor = (score: number) => {
  if (score < 60) {
    return '#43A047';
  }
  if (score < 100) {
    return '#FB8C00';
  }
  return '#E53935';
};

const columnDefs = [
  {
    cellRenderer: ({ data }: { data: FraudRankerReport }) => (
      <Link
        component={RouterLink}
        to={
          data.reason === 'Loan'
            ? `/participant/${data.participant.id}/loans/${data.id}/details`
            : `/participant/${data.participant.id}/withdrawals/${data.id}/details`
        }>
        {data.id}{' '}
      </Link>
    ),
    field: 'id',
    headerName: 'ID',
    resizable: true
  },
  {
    field: 'requestDate',
    resizable: true,
    valueFormatter: ({ value }: { value: string }) =>
      formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
  },
  {
    autoHeight: true,
    cellRenderer: ({ data }: { data: FraudRankerReport }) => (
      <DataTableStackCell
        primary={data.participant.name}
        primaryLinkProps={{
          to:
            data.reason === 'Loan'
              ? `/participants/${data.participant.id}/loans`
              : `/participants/${data.participant.id}/withdrawals`
        }}
        secondary={data.participant.id.toString()}
      />
    ),
    field: 'participant',
    headerName: 'Participant',
    resizable: true
  },
  {
    field: 'amount',
    resizable: true,
    type: 'numericColumn',
    valueFormatter: ({ value }: { value: string }) =>
      formatters.formatDollars(value)
  },
  {
    field: 'reason',
    resizable: true
  },
  {
    cellRenderer: ({ data }: { data: FraudRankerReport }) => (
      <Stack alignItems='center' direction='row' spacing={1}>
        <Typography>{data.score}</Typography>
        <ColoredCircle color={fraudColor(data.score)} />
      </Stack>
    ),
    field: 'score',
    resizable: true,
    type: 'rightAligned'
  },
  {
    cellRenderer: ({ data }: { data: FraudRankerReport }) =>
      data.calloutParticipant ? (
        <CheckCircleIcon color='action' />
      ) : (
        <CloseIcon color='action' />
      ),
    cellStyle: {
      alignItems: 'center'
    },
    field: 'calloutParticipant',
    headerName: 'Callout to Part.',
    resizable: true,
    type: 'rightAligned'
  },
  {
    cellRenderer: ({ data }: { data: FraudRankerReport }) => (
      <Link component={RouterLink} target='_blank' to={data.jiraTicket}>
        {data.jiraTicket?.substring(data.jiraTicket?.lastIndexOf('/') + 1)}
      </Link>
    ),
    field: 'jiraTicket',
    headerName: 'Ticket',
    resizable: true,
    type: 'rightAligned'
  }
];

type GridFilters = {
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
  id?: string;
  fraudType?: 'Withdrawal' | 'Loan' | 'LoanAndWithdrawal';
  status?: '' | 'New' | 'Review' | 'Done';
  hasTicket?: boolean;
  calloutToParticipant?: boolean;
  distributionAmount?: number[];
  fraudScore?: '' | 'Green' | 'Yellow' | 'Red';
  scoreModified?: boolean;
  from?: string;
  to?: string;
};

const initialValues: GridFilters = {
  calloutToParticipant: false,
  distributionAmount: [MIN_DISTRIBUTION_AMOUNT, MAX_DISTRIBUTION_AMOUNT],
  fraudScore: '',
  fraudType: 'LoanAndWithdrawal',
  from: '',
  hasTicket: false,
  id: '',
  scoreModified: false,
  status: '',
  to: ''
};

export const FraudrankerTable: FC = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [query, setQuery] = useState<GridFilters>(initialValues);

  const reportData = useQuery(
    ['FraudrankerService.getReport', page, pageSize, query],
    () => {
      const distributionAmoutSet =
        query.distributionAmount?.[0] !== MIN_DISTRIBUTION_AMOUNT ||
        query.distributionAmount?.[1] !== MAX_DISTRIBUTION_AMOUNT;
      const params: FraudrankerReportQueryParams = {
        calloutToParticipant: query.calloutToParticipant
          ? query.calloutToParticipant
          : null,
        distributionAmountMax: distributionAmoutSet
          ? query.distributionAmount[1]
          : null,
        distributionAmountMin: distributionAmoutSet
          ? query.distributionAmount[0]
          : null,
        fraudScore: query.fraudScore !== '' ? query.fraudScore : null,
        fraudType: query.fraudType,
        from: query.from !== '' ? query.from : null,
        hasTicket: query.hasTicket ? query.hasTicket : null,
        id: query.id ? +query.id : null,
        pageNumber: page,
        pageSize,
        scoreModified: query.scoreModified,
        status: query?.status !== '' ? query?.status : null,
        to: query.to !== '' ? query.to : null
      };
      return FraudrankerService.getReport(params);
    },
    {
      staleTime: Infinity
    }
  );

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const handleSubmit = (formData: GridFilters) => {
    setPage(1);
    setQuery({ ...formData });
  };

  return (
    <Card>
      <CardHeader title='Withdrawals & Loans' />
      <CardContent disablePadding overlayLoading={reportData.isLoading}>
        <DataTable
          columnDefs={columnDefs}
          columnSizing='fit'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-table'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No results found.'
              />
            </Stack>
          }
          filterSidePanelComponent={
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ values }) => (
                <Form>
                  <Stack
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    spacing={2}>
                    <FormControl variant='outlined'>
                      <Field
                        InputProps={{
                          endAdornment: <SearchIcon />
                        }}
                        as={TextField}
                        data-testid='id-input'
                        name='id'
                        placeholder='Search by ID'
                        size='small'
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel shrink>Type</InputLabel>
                      <Field
                        as={Select}
                        displayEmpty
                        input={<OutlinedInput label='Type' notched />}
                        label='Type'
                        name='fraudType'
                        size='small'>
                        <MenuItem value='LoanAndWithdrawal'>
                          Loan and Withdrawal
                        </MenuItem>
                        <MenuItem value='Loan'>Only Loan</MenuItem>
                        <MenuItem value='Withdrawal'>Only Withdrawal</MenuItem>
                      </Field>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel shrink>Status</InputLabel>
                      <Field
                        as={Select}
                        displayEmpty
                        input={<OutlinedInput label='Status' notched />}
                        label='Status'
                        name='status'
                        size='small'>
                        <MenuItem value=''>Any</MenuItem>
                        <MenuItem value='New'>New</MenuItem>
                        <MenuItem value='Review'>Review</MenuItem>
                        <MenuItem value='Done'>Done</MenuItem>
                      </Field>
                    </FormControl>
                    <CheckBoxForm label='Has ticket' name='hasTicket' />
                    <CheckBoxForm
                      label='Callout to participant'
                      name='calloutToParticipant'
                    />
                    <Divider flexItem textAlign='left'>
                      Distribution Amount
                    </Divider>
                    <Stack alignItems='center' width='100%'>
                      <FormControl sx={{ width: '92%' }}>
                        <Field
                          as={Slider}
                          max={MAX_DISTRIBUTION_AMOUNT}
                          min={MIN_DISTRIBUTION_AMOUNT}
                          name='distributionAmount'
                        />
                      </FormControl>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        width='100%'>
                        <Typography variant='caption'>
                          {formatters.formatDollars(
                            values.distributionAmount[0],
                            0
                          )}
                        </Typography>
                        <Typography variant='caption'>
                          {formatters.formatDollars(
                            values.distributionAmount[1],
                            0
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider flexItem textAlign='left'>
                      Fraud score
                    </Divider>
                    <FormControl fullWidth>
                      <Field
                        as={Select}
                        displayEmpty
                        input={<OutlinedInput />}
                        label='Fraud score'
                        name='fraudScore'
                        size='small'>
                        <MenuItem value=''>All</MenuItem>
                        <MenuItem value='Green'>Green (less than 60)</MenuItem>
                        <MenuItem value='Yellow'>Yellow (60 - 99)</MenuItem>
                        <MenuItem value='Red'>Red (100 or more)</MenuItem>
                      </Field>
                    </FormControl>
                    {/* Hide until functionality is added to backend */}
                    {/* <CheckBoxForm
                      label='Score is modified'
                      name='scoreModified'
                    /> */}
                    <Divider flexItem textAlign='left'>
                      Date
                    </Divider>
                    <FormControl>
                      <Field
                        as={DatePicker}
                        autoComplete='off'
                        data-testid='startDate'
                        disableFuture
                        label='From'
                        name='from'
                        size='small' // FormControl doesn't pass to our DatePicker
                        variant='outlined'
                      />
                    </FormControl>
                    <FormControl>
                      <Field
                        as={DatePicker}
                        autoComplete='off'
                        data-testid='endDate'
                        disableFuture
                        label='To'
                        name='to'
                        size='small' // FormControl doesn't pass to our DatePicker
                        variant='outlined'
                      />
                    </FormControl>
                    <Button
                      data-testid='submit'
                      type='submit'
                      variant='outlined'>
                      Apply
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          }
          onPageChanged={handlePageChanged}
          onPageSizeChanged={handlePageSizeChanged}
          page={page}
          pageSize={pageSize}
          pageSizeOptions={[10, 15, 20]}
          pagination
          paginationSource='server'
          paginationTotal={reportData.data?.totalCount ?? 0}
          rowData={reportData.data?.data ?? []}
        />
      </CardContent>
    </Card>
  );
};

FraudrankerTable.displayName = 'FraudrankerTable';
