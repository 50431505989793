import ApiService from '@/services/Api.service';

export enum OpsToolingType {
  connectionStatus = 'connectionStatus',
  companyInfo = 'companyInfo',
  payGroupInfo = 'payGroupInfo',
  payrollInfo = 'payrollInfo',
  employeeInfo = 'employeeInfo'
}

export default class PayrollIntegrationsService {
  static async getOpsToolingData(params: {
    sponsorPlanId: number;
    toolingType: string;
    startDate?: string;
    endDate?: string;
  }): Promise<unknown> {
    return ApiService.getJson(
      `/payrollIntegrations/sponsorPlan/${params.sponsorPlanId}/tooling-${params.toolingType}`,
      {
        endDate: params.endDate,
        startDate: params.startDate
      }
    );
  }

  static async deletePayrollIntegration(params: {
    payrollSetupId: number;
  }): Promise<unknown> {
    return ApiService.deleteJson(
      `/payrollIntegrations/payrollSetup/${params.payrollSetupId}`
    );
  }

  static async updatePayrollIntegration(params: {
    sponsorPlanId: number;
    payrollSetupId: number;
    integratedPayrollSetupId: number;
  }): Promise<unknown> {
    return ApiService.patchJson(
      `/payrollIntegrations/sponsorPlan/${params.sponsorPlanId}`,
      {
        integratedPayrollSetupId: params.integratedPayrollSetupId,
        payrollSetupId: params.payrollSetupId
      }
    );
  }
}
