import Badge from '@/components/badge';
import { navigateToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import ErrorMessage from '@/components/error-message';
import LinearLoading from '@/components/linear-loading';
import { QueueErrorCard } from '@/components/queue-error-card/QueueErrorCard.component';
import SimpleTextarea from '@/components/simple-textarea';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import {
  BankAccountDto,
  DeliveryDetails,
  ParticipantAccountsDto,
  ParticipantInfo,
  WithdrawalDetails,
  WithdrawalDto
} from '@/models';
import { PlanV2Dto } from '@/models/PlanV2DTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  CashDeliveryDetails,
  normalizeWithdrawalStatus,
  RolloverDeliveryDetails,
  WithdrawalStatusEnum
} from '@/models/Withdrawals.model';
import {
  CustomWithdrawalDisbursement,
  CustomWithdrawalRothBasisResponse,
  CustomWithdrawalRothCostBasisDto,
  WithdrawalDetailsDto,
  WithdrawalSierraRequest,
  WithdrawalTradeRequestDto
} from '@/models/WithdrawalsDTO.model';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Unstable_Grid2 as Grid,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import { NotesInfoCard } from '../NotesInfoCard.component';
import FraudInfoCard from './FraudInfoCard.component';
import ParticipantInfoCard from './ParticipantInfoCard.component';
import { WithdrawalActions } from './WithdrawalActions.component';
import WithdrawalCustomRequestDialog from './WithdrawalCustomRequest/WithdrawalCustomRequestDialog.component';
import { WithdrawalDestinationsCard } from './WithdrawalDestinationsCard.component';
import WithdrawalDetailsDataGridsCard from './WithdrawalDetailsDataGrids/WithdrawalDetailsDataGridsCard.component';
import WithdrawalDistributionsCard from './WithdrawalDistributionsCard.component';
import WithdrawalInfoCard from './WithdrawalInfoCard.component';

type WithdrawalDetailRouteProps = {
  participantId: string;
  withdrawalId: string;
};

function getRolloverDelivery(
  disbursement: CustomWithdrawalDisbursement,
  details: WithdrawalDetailsDto
) {
  const isPreTax = disbursement.taxType === 'preTax';

  return {
    amount: disbursement?.amount,
    deliveryDestination: {
      accountNumber: isPreTax
        ? details.rolloverPretaxAccountNumber
        : details.rolloverRothAccountNumber,
      address1:
        isPreTax || details.rolloverIsRothSameAddr
          ? details.rolloverPretaxAddress1
          : details.rolloverRothAddress1,
      address2:
        isPreTax || details.rolloverIsRothSameAddr
          ? details.rolloverPretaxAddress2?.trim()
          : details.rolloverRothAddress2?.trim(),
      city:
        isPreTax || details.rolloverIsRothSameAddr
          ? details.rolloverPretaxCity
          : details.rolloverRothCity,
      creditTo: isPreTax
        ? details.rolloverPretaxForCreditTo
        : details.rolloverRothForCreditTo,
      furtherCreditTo: isPreTax
        ? details.rolloverPretaxForFurtherCreditTo
        : details.rolloverRothForFurtherCreditTo,
      institutionName: isPreTax
        ? details.rolloverPretaxBankName
        : details.rolloverRothBankName,
      routingNumber: isPreTax
        ? details.rolloverPretaxRoutingNumber
        : details.rolloverRothRoutingNumber,
      state:
        isPreTax || details.rolloverIsRothSameAddr
          ? details.rolloverPretaxState
          : details.rolloverRothState,
      type:
        isPreTax || details.rolloverIsRothSameAddr
          ? details.rolloverPreTaxDeliveryMethod
          : details.rolloverRothDeliveryMethod,
      zip:
        isPreTax || details.rolloverIsRothSameAddr
          ? details.rolloverPretaxZip
          : details.rolloverRothZip
    },
    deliveryType: 'Rollover',
    destination:
      isPreTax || details.rolloverIsRothSameAddr
        ? details.rolloverPreTaxDestination
        : details.rolloverRothDestination,
    fullAmount: disbursement.disbursementType === 'full',
    isRoth: !isPreTax,
    memo: disbursement.referenceMemo,
    partialAmount: isPreTax
      ? details.partialAmountPretax
      : details.partialAmountRoth,
    payee: disbursement.payeeName,
    pretaxBankAccountRegistration: isPreTax
      ? details.rolloverPretaxBankAccountRegistration
      : details.rolloverRothBankAccountRegistration
  };
}

function getCashDelivery(
  disbursement: CustomWithdrawalDisbursement,
  details: WithdrawalDetailsDto,
  bankDetails?: BankAccountDto
) {
  return {
    amount: disbursement?.amount,
    deliveryDestination: {
      address1: details.cashWithdrawalAddress1,
      address2: details.cashWithdrawalAddress2,
      city: details.cashWithdrawalCity,
      creditTo: details.cashWithdrawalForCreditTo,
      furtherCreditTo: details.cashWithdrawalForFurtherCreditTo,
      state: details.cashWithdrawalState,
      type: details.cashWithdrawalDeliveryMethod,
      zip: details.cashWithdrawalZip,
      ...bankDetails
    },
    deliveryType: 'Cash',
    fullAmount: disbursement.disbursementType === 'full',
    isRoth: disbursement.taxType === 'roth',
    taxType: disbursement.taxType
  };
}

const formatPercentValue = (number: string) => {
  return !isNaN(+number) ? (+number * 100).toString() : '0';
};

const colorByStatus: Record<
  string,
  | 'success'
  | 'neutral'
  | 'warning'
  | 'info'
  | 'lightWarning'
  | 'error'
  | undefined
> = {
  Canceled: 'info',
  Disbursed: 'success',
  Initial: 'neutral',
  'LT Request Failed': 'warning',
  'LT Request Pending': 'neutral',
  'LT Request Submitted': 'neutral',
  Pending: 'neutral',
  Processing: 'neutral',
  Rejected: 'info'
};

const WithdrawalDetailsRoute: React.FunctionComponent = () => {
  const { participantId, withdrawalId } =
    useParams<WithdrawalDetailRouteProps>();
  const { userHasValidToken } = useUserToken();
  const { showSnackbar } = useSnackbar();
  const [isCustomRequestOpen, setCustomRequestOpen] = useState(false);
  const { openDialog } = useDialog();

  const participantQuery = useQuery<ParticipantInfo>(
    ['ParticipantService.getParticipantById', participantId?.toString()],
    async () => ParticipantService.getParticipantById(participantId),
    {
      enabled: Boolean(participantId && userHasValidToken),
      staleTime: Infinity
    }
  );

  const planQuery = useQuery<PlanV2Dto>(
    [
      'PlanService.getPlanById',
      participantQuery.data?.sponsorPlanId?.toString()
    ],
    () => {
      return participantQuery.data?.sponsorPlanId
        ? PlanService.getPlanById(participantQuery.data.sponsorPlanId)
        : ({} as PlanV2Dto);
    },
    {
      enabled: Boolean(
        participantId &&
          userHasValidToken &&
          participantQuery.data?.sponsorPlanId
      ),
      staleTime: Infinity
    }
  );

  const withdrawalQuery = useQuery<WithdrawalDto>(
    ['ParticipantService.getWithdrawal', participantId, withdrawalId],
    () => ParticipantService.getWithdrawal(+participantId, +withdrawalId),
    {
      enabled: Boolean(participantId && userHasValidToken)
    }
  );

  const isCustomWithdrawal = useMemo(
    () => withdrawalQuery.data?.attributes?.distributionMethod === 'CUSTOM',
    [withdrawalQuery.data?.attributes.distributionMethod]
  );

  const notesMutation = useMutation(
    ['ParticipantService.updateWithdrawalNotes', participantId, withdrawalId],
    (notes: string) =>
      ParticipantService.updateWithdrawalNotes(
        +participantId,
        +withdrawalId,
        notes
      ),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating withdrawal notes',
          severity: 'error'
        });
      },
      onSuccess: () => {
        withdrawalQuery.refetch();
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  const fraudNotesMutation = useMutation(
    ['ParticipantService.updateFraudNotes', participantId, withdrawalId],
    (notes: string) =>
      ParticipantService.updateFraudNotes(+participantId, +withdrawalId, notes),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating withdrawal notes',
          severity: 'error'
        });
      },
      onSuccess: () => {
        withdrawalQuery.refetch();
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  const withdrawalDetails: WithdrawalDetails | undefined = useMemo(() => {
    return withdrawalQuery.data
      ? {
          amount:
            withdrawalQuery.data.attributes.context.withdrawalDerivedAmounts
              .withdrawalAmount,
          distributionMethod:
            withdrawalQuery.data.attributes.distributionMethod,
          fees: {
            baseFee:
              withdrawalQuery.data.attributes.context.withdrawalDerivedAmounts
                .fee,
            cashWithdrawalDeliveryFee:
              withdrawalQuery.data.attributes.context.withdrawalDerivedAmounts
                .cashWithdrawalDeliveryFee,
            rolloverPreTaxDeliveryFee:
              withdrawalQuery.data.attributes.context.withdrawalDerivedAmounts
                .rolloverPreTaxDeliveryFee,
            rolloverRothDeliveryFee:
              withdrawalQuery.data.attributes.context.withdrawalDerivedAmounts
                .rolloverRothDeliveryFee
          },
          fundType:
            withdrawalQuery.data.attributes.context.createWithdrawalDetails
              .rolloverAccount,
          hardshipReason:
            withdrawalQuery.data.attributes.context.createWithdrawalDetails
              .hardship,
          notes: withdrawalQuery.data.attributes.notes,
          reason: withdrawalQuery.data.attributes.withdrawalReason,
          rothDestination:
            withdrawalQuery.data.attributes.context.createWithdrawalDetails
              .rolloverRothDestination,
          status: withdrawalQuery.data.attributes.status
        }
      : undefined;
  }, [withdrawalQuery.data]);

  const accountsQuery = useQuery<ParticipantAccountsDto>(
    ['ParticipantService.getParticipantAccounts', participantId],
    () => ParticipantService.getParticipantAccounts(participantId),
    {
      enabled: Boolean(participantId && userHasValidToken),
      staleTime: Infinity
    }
  );

  const bankDetails = useQuery<BankAccountDto>(
    ['ParticipantService.getBankAccount', participantId],
    () => {
      const bankAccountId =
        withdrawalQuery.data?.attributes.context.createWithdrawalDetails
          .cashWithdrawalBankAccountId ?? 0;

      return ParticipantService.getBankAccount(+participantId, bankAccountId);
    },
    {
      enabled: Boolean(
        participantId &&
          userHasValidToken &&
          withdrawalQuery.data?.attributes.context.createWithdrawalDetails
            ?.cashWithdrawalBankAccountId
      ),
      staleTime: Infinity
    }
  );

  const tradeRequests = useQuery<WithdrawalTradeRequestDto[]>(
    [
      'ParticipantService.getWithdrawalTradeRequests',
      participantId,
      withdrawalId
    ],
    () => {
      return ParticipantService.getWithdrawalTradeRequests(
        +participantId,
        +withdrawalId
      );
    },
    {
      refetchInterval: data => {
        return data?.some((row: WithdrawalTradeRequestDto) =>
          [
            'FAILED',
            'SUBA_RUNNING',
            'SUBA_FAILED',
            'SUBA_ABORTED',
            'SUBA_TIMED-OUT',
            'SUCCEEDED',
            'CANCELED'
          ].includes(row.status)
        ) &&
          withdrawalDetails?.status &&
          ['Rejected', 'Canceled'].includes(withdrawalDetails.status)
          ? false
          : 5000;
      }
    }
  );

  const isEsaRecordkeeper = useMemo(() => {
    return planQuery.data?.data?.attributes.recordkeeper === 'Vestwell ESA';
  }, [planQuery.data?.data?.attributes.recordkeeper]);

  const sierraRequest = useQuery<WithdrawalSierraRequest>(
    ['ParticipantService.getWithdrawalSierraRequest', withdrawalId],
    () => ParticipantService.getWithdrawalSierraRequest(+withdrawalId),
    {
      enabled:
        !!withdrawalQuery.data &&
        !isCustomWithdrawal &&
        !tradeRequests.data?.length
    }
  );

  const rothBasisQuery = useQuery<CustomWithdrawalRothBasisResponse>(
    [
      'ParticipantService.getRothBasisAmounts',
      participantId,
      withdrawalQuery.data?.attributes.withdrawalReason
    ],
    () =>
      ParticipantService.getRothBasisAmounts({
        disbursements: isCustomWithdrawal
          ? withdrawalQuery.data?.attributes.context.customWithdrawalDetails
              .disbursements
          : sierraRequest.data?.data?.subaRequest?.disbursements,
        participantId: +participantId,
        reason: withdrawalQuery.data?.attributes.withdrawalReason
      } as CustomWithdrawalRothCostBasisDto),
    {
      enabled: Boolean(
        withdrawalQuery.data &&
          (isCustomWithdrawal ? true : sierraRequest.data?.data)
      )
    }
  );

  const deliveryDetails: DeliveryDetails = useMemo(() => {
    if (!withdrawalQuery.data) return [];
    if (!isCustomWithdrawal) {
      const { createWithdrawalDetails: details } =
        withdrawalQuery.data.attributes.context;

      const disbursements =
        tradeRequests?.data?.[0] && withdrawalDetails?.status != 'Pending'
          ? tradeRequests?.data[0]?.request?.disbursements
          : sierraRequest.data?.data?.subaRequest?.disbursements;

      return (
        disbursements?.map((disbursement, index) => {
          const taxDetails = {
            _1099Code: [
              disbursement._1099Code,
              disbursement.additional1099rCode
            ].join(''),
            federalWithholdingPercent: formatPercentValue(
              disbursement.federalWithholdingPercent
            ),
            rothBasis:
              disbursement.rothOrAfterTaxBasisAmount ??
              rothBasisQuery.data?.data[index]?.amount,
            rothInitialYear: disbursement.rothInitialYear,
            rothQualifiedWithdrawal: disbursement.rothQualifiedWithdrawal,
            stateWithholdingCode: disbursement.stateWithholdingCode,
            stateWithholdingPercent: formatPercentValue(
              disbursement.stateWithholdingPercent
            )
          };

          if (
            withdrawalQuery.data.attributes.distributionMethod ===
            'CASH_AND_ROLLOVER'
          ) {
            //full distribution in CASH_AND_ROLLOVER is always rollover
            if (disbursement.disbursementType === 'full') {
              return {
                ...getRolloverDelivery(disbursement, details),
                taxDetails
              } as RolloverDeliveryDetails;
            }
            return {
              ...getCashDelivery(disbursement, details, bankDetails.data),
              taxDetails
            } as CashDeliveryDetails;
          } else if (
            withdrawalQuery.data.attributes.distributionMethod.includes(
              'ROLLOVER'
            )
          ) {
            return {
              ...getRolloverDelivery(disbursement, details),
              taxDetails
            } as RolloverDeliveryDetails;
          }

          return {
            ...getCashDelivery(disbursement, details, bankDetails.data),
            taxDetails
          } as CashDeliveryDetails;
        }) ?? []
      );
    }

    const { customWithdrawalDetails: details } =
      withdrawalQuery.data.attributes.context;

    return details.disbursements?.map((disbursment, index) => {
      const isPreTax = disbursment.taxType === 'preTax';

      if (disbursment.isRollover) {
        return {
          bankAccountRegistration: undefined,
          deliveryDestination: {
            accountNumber: disbursment.receivingBankAccountNumber,
            address1: disbursment.useParticipantAddress
              ? participantQuery.data?.address?.address1
              : disbursment.payeeAddress,
            address2: disbursment.useParticipantAddress
              ? participantQuery.data?.address?.address2
              : '',
            city: disbursment.useParticipantAddress
              ? participantQuery.data?.address?.city
              : disbursment.payeeCity,
            furtherCreditTo: disbursment.furtherCreditToName,
            institutionName: disbursment.receivingBankName,
            routingNumber: disbursment.receivingBankRoutingNumber,
            state: disbursment.useParticipantAddress
              ? participantQuery.data?.address?.state
              : disbursment.payeeState,
            type: disbursment.disbursementMethod,
            zip: disbursment.useParticipantAddress
              ? participantQuery.data?.address?.zip
              : disbursment.payeeZip
          },
          deliveryType: 'Rollover',
          destination: undefined,
          fullAmount: disbursment.disbursementType === 'full',
          isRoth: !isPreTax,
          memo: disbursment.referenceMemo ?? null,
          partialAmount: disbursment.amount ?? null,
          payee: disbursment.payeeName ?? null,
          taxDetails: {
            _1099Code: [
              disbursment._1099Code,
              disbursment.additional1099rCode
            ].join(''),
            federalWithholdingPercent: disbursment.federalWithholdingPercent,
            rothBasis:
              disbursment.rothOrAfterTaxBasisAmount ??
              rothBasisQuery.data?.data[index]?.amount,
            rothInitialYear: disbursment.rothInitialYear,
            rothQualifiedWithdrawal: disbursment.rothQualifiedWithdrawal,
            stateWithholdingCode: disbursment.stateWithholdingCode,
            stateWithholdingPercent: disbursment.stateWithholdingPercent
          }
        } as RolloverDeliveryDetails;
      }
      return {
        amount: disbursment.amount ?? 0,
        deliveryDestination: {
          accountNumber: disbursment.receivingBankAccountNumber,
          address1: disbursment.useParticipantAddress
            ? participantQuery.data?.address?.address1
            : disbursment.payeeAddress,
          address2: disbursment.useParticipantAddress
            ? participantQuery.data?.address?.address2
            : '',
          city: disbursment.useParticipantAddress
            ? participantQuery.data?.address?.city
            : disbursment.payeeCity,
          creditTo: null,
          furtherCreditTo: disbursment.furtherCreditToName,
          institutionName: disbursment.receivingBankName,
          routingNumber: disbursment.receivingBankRoutingNumber,
          state: disbursment.useParticipantAddress
            ? participantQuery.data?.address?.state
            : disbursment.payeeState,
          type: disbursment.disbursementMethod,
          zip: disbursment.useParticipantAddress
            ? participantQuery.data?.address?.zip
            : disbursment.payeeZip
        },
        deliveryType: 'Cash',
        fullAmount: disbursment.disbursementType === 'full',
        isRoth: !isPreTax,
        taxDetails: {
          _1099Code: [
            disbursment._1099Code,
            disbursment.additional1099rCode
          ].join(''),
          federalWithholdingPercent: disbursment.federalWithholdingPercent,
          rothBasis:
            disbursment.rothOrAfterTaxBasisAmount ??
            rothBasisQuery.data?.data[index]?.amount,
          rothInitialYear: disbursment.rothInitialYear,
          rothQualifiedWithdrawal: disbursment.rothQualifiedWithdrawal,
          stateWithholdingCode: disbursment.stateWithholdingCode,
          stateWithholdingPercent: disbursment.stateWithholdingPercent
        },
        taxType: disbursment.taxType
      } as CashDeliveryDetails;
    });
  }, [
    isCustomWithdrawal,
    bankDetails.data,
    participantQuery.data,
    rothBasisQuery.data,
    sierraRequest.data,
    tradeRequests.data,
    withdrawalDetails?.status,
    withdrawalQuery.data
  ]);

  const withdrawalActionsEnabled = useMemo(() => {
    return (
      (userService.hasPermission(
        FeatureLevelPermissions.WRITE_WITHDRAWALS_ACTION
      ) &&
        planQuery?.data?.data.attributes.recordkeeper ===
          'Vestwell Sub-Accounting Platform') ||
      isEsaRecordkeeper
    );
  }, [planQuery?.data?.data.attributes.recordkeeper]);

  const requestSubAccountIds = useMemo(() => {
    const ids = isCustomWithdrawal
      ? withdrawalQuery?.data?.attributes?.context?.customWithdrawalDetails?.accounts.map(
          i => i.accountId
        )
      : sierraRequest?.data?.data?.subaRequest?.subAccounts.map(
          i => +i.coreAccountId
        );
    return ids ?? [];
  }, [withdrawalQuery.data, sierraRequest.data]);

  const openEditNotesModal = useCallback(
    () =>
      openDialog({
        actionButtons: {
          cancelButton: {
            children: 'Cancel'
          },
          submitButton: {
            children: 'Save'
          }
        },
        onSubmit: values => {
          notesMutation.mutate(values.notes);
        },
        steps: [
          {
            fields: {
              notes: {
                component: (
                  <SimpleTextarea label='' name='notes' placeholder='Notes' />
                ),
                initialValue: withdrawalDetails.notes,
                label: 'Notes'
              }
            },
            title: 'Edit Notes'
          }
        ]
      }),
    [withdrawalDetails]
  );

  const hasPlan1ReadPermission = userService.hasPermission(
    FeatureLevelPermissions.READ_PLAN_1
  );

  const requestError =
    participantQuery.error || (planQuery.error as any | undefined);

  const isFetching = participantQuery.isFetching || planQuery.isFetching;

  const isSuccess =
    participantQuery.isSuccess &&
    planQuery.isSuccess &&
    withdrawalDetails &&
    deliveryDetails;

  const withdrawalStatus = normalizeWithdrawalStatus(
    withdrawalQuery.data?.attributes.status
  );

  const onActionConfirm = () => {
    withdrawalQuery.refetch();
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (participantQuery.data?.sponsorPlanId === 1 && !hasPlan1ReadPermission) {
      return navigateToErrorPage(navigate, new Error('403'));
    }

    if (
      planQuery.data &&
      ![
        'LT',
        'Vestwell (RK) - Folio',
        'Vestwell Sub-Accounting Platform',
        'Vestwell ESA'
      ].includes(planQuery.data?.data?.attributes.recordkeeper ?? '')
    ) {
      return navigateToErrorPage(navigate, new Error('403'));
    }

    const error = participantQuery.error || planQuery.error;
    if (error) {
      return navigateToErrorPage(navigate, error as Error);
    }
  }, [participantQuery.data, planQuery.data, hasPlan1ReadPermission, navigate]);

  return (
    <>
      {isFetching && <LinearLoading />}
      {isSuccess && (
        <>
          <Button
            component={RouterLink}
            startIcon={<ArrowBackOutlinedIcon />}
            to={`/participants/${participantId}/withdrawals`}
            variant='text'>
            SEE ALL WITHDRAWALS
          </Button>
          <Stack alignItems='center' direction='row' ml={1} spacing={1}>
            <Typography variant='h6'>
              Withdrawal Request ID: {withdrawalId}
            </Typography>
            <Badge
              color={colorByStatus[withdrawalQuery.data?.attributes.status]}>
              {withdrawalQuery.data?.attributes.status ||
                EMPTY_FIELD_PLACEHOLDER}
            </Badge>
          </Stack>
          {withdrawalStatus === WithdrawalStatusEnum.Processing &&
            (tradeRequests.data === undefined ||
              tradeRequests.data?.length === 0 ||
              [
                'FAILED',
                'SUBA_FAILED',
                'SUBA_ABORTED',
                'SUBA_TIMED-OUT',
                'CANCELED'
              ].includes(tradeRequests.data[0]?.status)) && (
              <Typography ml={1}>
                There were issue with Trade Request
              </Typography>
            )}
          <Stack mt={2} spacing={2}>
            <Grid
              columnSpacing={{ md: 3, sm: 2, xs: 1 }}
              container
              justifyContent='space-between'>
              {withdrawalActionsEnabled && (
                <Grid>
                  <WithdrawalActions
                    distributionMethod={withdrawalDetails.distributionMethod}
                    missingParticipantAddressinfo={[
                      participantQuery.data?.address.address1,
                      participantQuery.data?.address.city,
                      participantQuery.data?.address.state,
                      participantQuery.data?.address.zip
                    ].some(isEmpty)}
                    onSubmit={onActionConfirm}
                    participantId={participantId}
                    planBlackoutActive={
                      (planQuery.data?.data.attributes.isBlackout ||
                        planQuery.data?.data.attributes
                          .isOffboardingBlackoutActive) ??
                      false
                    }
                    tradeRequestStatus={tradeRequests?.data?.[0]?.status}
                    withdrawalId={withdrawalId}
                    withdrawalStatus={withdrawalStatus}
                  />
                </Grid>
              )}
              {withdrawalDetails.distributionMethod === 'CUSTOM' &&
                withdrawalStatus === WithdrawalStatusEnum.Pending && (
                  <Grid>
                    <LoadingButton
                      color='primary'
                      onClick={() => setCustomRequestOpen(true)}
                      sx={{
                        visibility:
                          withdrawalDetails.distributionMethod === 'CUSTOM' &&
                          withdrawalStatus === WithdrawalStatusEnum.Pending
                            ? 'visible'
                            : 'hidden'
                      }}
                      variant='contained'>
                      Update and Submit
                    </LoadingButton>
                    <WithdrawalCustomRequestDialog
                      dto={withdrawalQuery.data}
                      isOpen={isCustomRequestOpen}
                      onClose={() => setCustomRequestOpen(false)}
                      participantId={+participantId}
                    />
                  </Grid>
                )}
            </Grid>
            <Grid columnSpacing={{ md: 3, sm: 2, xs: 1 }} container>
              <Grid xs={8}>
                <Stack spacing={2}>
                  <WithdrawalInfoCard
                    isVestwellEsa={isEsaRecordkeeper}
                    participantAccounts={accountsQuery.data?.data}
                    participantId={+participantId}
                    requestSubAccountIds={requestSubAccountIds}
                    withdrawalDetails={withdrawalQuery.data}
                  />
                  {!isCustomWithdrawal && (
                    <WithdrawalDestinationsCard
                      participantId={participantId}
                      withdrawalId={withdrawalId}
                    />
                  )}
                  {(planQuery.data?.data?.attributes.recordkeeper ===
                    'Vestwell Sub-Accounting Platform' ||
                    isEsaRecordkeeper) && (
                    <WithdrawalDistributionsCard
                      deliveryDetails={deliveryDetails}
                      participantAccounts={accountsQuery.data?.data}
                      requestSubAccountIds={requestSubAccountIds}
                      tradeRequests={tradeRequests.data}
                    />
                  )}
                </Stack>
              </Grid>

              <Grid xs={4}>
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <NotesInfoCard
                      notes={withdrawalDetails.notes}
                      onClickEdit={openEditNotesModal}
                      title='withdrawal'
                    />
                  </Grid>
                  <Grid xs={12}>
                    <ParticipantInfoCard
                      balance={{
                        total: accountsQuery.data?.stats.balance.total,
                        vestedTotal:
                          accountsQuery.data?.stats.vesting.vestedTotal
                      }}
                      participant={participantQuery.data}
                      participantId={participantId}
                      sponsorPlan={planQuery?.data?.data}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <FraudInfoCard
                      fraudNotes={withdrawalQuery.data?.attributes.fraudNotes}
                      fraudrankerResult={
                        withdrawalQuery.data?.attributes.fraudRankResult
                      }
                      jiraTicket={
                        withdrawalQuery.data?.attributes.context.jiraTicketKey
                      }
                      mutate={fraudNotesMutation.mutate}
                      participantInfo={participantQuery.data}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <QueueErrorCard type='Withdrawal' value={withdrawalId} />
            <WithdrawalDetailsDataGridsCard
              participantId={participantId}
              tradeRequests={tradeRequests.data}
              withdrawalId={withdrawalId}
            />
          </Stack>
        </>
      )}
      {requestError && (
        <ErrorMessage
          error={requestError.message || 'An Unknown Error Occurred'}
        />
      )}
    </>
  );
};

export default WithdrawalDetailsRoute;
