import { Box, Typography } from '@mui/material';

import React from 'react';

interface ForfeitureEventCollapsibleProps {
  row: Record<string, any>;
}

const ForfeitureEventCollapsible = (
  props: ForfeitureEventCollapsibleProps
): JSX.Element => {
  const { row } = props;

  return (
    <>
      <Box
        sx={{
          borderTop: theme => `1px solid ${theme.palette.grey[300]}`,
          padding: '16px 0'
        }}>
        <Typography color='grey.700'>Note</Typography>
        <Typography variant='body1'>{row.note}</Typography>
      </Box>
      <Box sx={{ padding: '16px 0' }}>
        <Typography color='grey.700'>Context</Typography>
        <Typography
          sx={{ backgroundColor: '#F5F5F5', padding: '10px' }}
          variant='body2'>
          {JSON.stringify(row.context, null, 4).replace(/"/g, '')}
        </Typography>
      </Box>
    </>
  );
};

export default ForfeitureEventCollapsible;
